import React from 'react';
import { ExclamationTriangleIcon, InformationCircleIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';

const SEVERITY = {
  warning: {
    outer: 'bg-yellow-50',
    icon: 'text-yellow-400',
    inner: 'text-yellow-700',
  },
  info: {
    outer: 'bg-primary-50',
    icon: 'text-primary-400',
    inner: 'text-primary-700',
  },
};

export default function Alert({ severity = 'info', text, className }) {
  let Icon = severity === 'info' ? InformationCircleIcon : ExclamationTriangleIcon;
  return (
    <div className={classNames('flex rounded p-4 items-center', SEVERITY[severity].outer, className)}>
      <div className="flex-shrink-0 mr-2">
        <Icon className={classNames('h-5 w-5', SEVERITY[severity].icon)} aria-hidden="true" />
      </div>
      <p className={SEVERITY[severity].inner}>{text}</p>
    </div>
  );
}
