/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useRef } from 'react';
import { debounce } from 'lodash';
import classNames from 'classnames';
import i18n from '../../i18n';

export default function LabelInput({
  label: labelComp,
  type,
  value: valueIn,
  disabled,
  onChange,
  onSubmit,
  className,
  errorText,
}) {
  const [value, setValue] = useState(valueIn || '');
  const [error, setError] = useState(null);

  const validateFn = (val) => {
    // eslint-disable-next-line max-len
    let isEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/g.test(val);
    if (type === 'email' && !isEmail) {
      return i18n.t('oneuser.validationErrors.email');
    }

    return null;
  };

  const validateInput = (val) => {
    let result = validateFn(val);
    setError(result);
  };

  const checkValidityDebounce = useRef(
    debounce(async (val) => {
      validateInput(val);
    }, 800),
  ).current;

  useEffect(() => () => {
    checkValidityDebounce.cancel();
  }, [checkValidityDebounce]);

  const onChangeInt = (e) => {
    let val = e.target.value;
    setValue(val);
    checkValidityDebounce(val);
    if (onChange != null) {
      let isValid = validateFn(val) == null;
      onChange(val, isValid);
    }
  };

  return (
    <form onSubmit={(e) => {
      e.preventDefault();
      if (onSubmit != null) {
        onSubmit(e);
      }
    }}
    >
      <label className={classNames('flex flex-col', className)}>
        {labelComp}
        <input
          onChange={onChangeInt}
          value={value}
          type={type}
          disabled={disabled}
          className={classNames(
            `flex-1
          block
          border
          rounded
          px-2
          py-1
          text-base`,
            // eslint-disable-next-line max-len
            (error == null && errorText == null) && 'border-neutral-300 hover:border-primary-300 focus:border-primary-300 focus:ring-primary-300 disabled:border-neutral-300',
            // eslint-disable-next-line max-len
            (error != null || errorText != null) && 'border-red-500 hover:border-red-300 focus:border-red-500 focus:ring-red-300',
          )}
        />
      </label>
      {(error != null || errorText != null) && <p className="text-red-500 text-xs mt-1">{errorText || error}</p>}
    </form>
  );
}
