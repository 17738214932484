/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import { Auth } from 'aws-amplify';

import createLogger from '../util/Logger';
import ApplicationAPIError from '../errors/ApplicationAPIError';
import ApplicationError from '../errors/ApplicationError';
import i18n from '../i18n';
import envConfig from '../config';

const logger = createLogger('API');

// PROD
const url = envConfig.API_URL;

const instance = axios.create({
  baseURL: url,
});

instance.interceptors.request.use(
  async (config) => {
    if (
      Auth._config != null
      && Auth._config.userPoolId != null
      && Auth._config.userPoolId !== ''
    ) {
      try {
        const token = (await Auth.currentAuthenticatedUser()).signInUserSession
          .accessToken.jwtToken;
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${token}`;
      } catch (e) {
        // user is not authenticated, ignore the error
      }
    }

    return config;
  },
  (error) => Promise.reject(error),
);

export { instance as axiosInstance };

function addRequestParam(uri, key, value, isFirstParam) {
  let result = uri;
  let isFirstParamResult = isFirstParam;

  if (key !== null && value !== null) {
    result += isFirstParamResult ? '?' : '&';
    result += `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
    isFirstParamResult = false;
  }

  return [result, isFirstParamResult];
}

export class API {
  constructor() {
    this.axios = instance;
  }

  async create(endpoint, entity) {
    try {
      logger.debug('triggering post request', { endpoint, entity });
      return await this.axios.post(endpoint, entity, {
        headers: {
          'content-type': 'application/json',
        },
      });
    } catch (e) {
      logger.warn('API POST request triggered error', { msg: e.message, resp: e.response });
      if (e.response && e.response.data.error && e.response.data.error.errorCode) {
        // request did happen, but error returned (status !== 2XX)
        throw new ApplicationAPIError(e.response.status, e.response.data.error.errorCode);
      } else {
        throw new Error(e.message);
      }
    }
  }

  async update(endpoint, entity) {
    try {
      logger.debug('triggering put request', { endpoint, entity });
      return await this.axios.put(endpoint, entity, {
        headers: {
          'content-type': 'application/json',
        },
      });
    } catch (e) {
      logger.warn('API PUT request triggered error', { msg: e.message, resp: e.response });
      if (e.response && e.response.data.error && e.response.data.error.errorCode) {
        // request did happen, but error returned (status !== 2XX)
        throw new ApplicationAPIError(e.response.status, e.response.data.error.errorCode);
      } else if ((e.message) === 'Network Error') {
        // Unknown error or request error like Network issues
        throw new ApplicationError(i18n.t('general.errorOccured'));
      } else {
        throw new Error(e.message);
      }
    }
  }

  /** @typedef {Object} ReadOptions REST params to be provided in the request
   * @property {string} key Key to append to the endpoint
   * @property {number} limit Limit to provide to the request
   * @property {Object} lastEvaluated JSON object of last evaluated object for pagination
   * @property {string} orderby String to be provided in the request for sorting
   * @property {string[]} includes String array of entities to include in the response
   * @property {Object} filter Map of additional filters to add in the request
   * @property {Object} customParamMap Map of additional query string parameters to provide in the request
  */

  /**
   * Send a read request to the server
   *
   * @param {string} endpoint The endpoint to query
   * @param {ReadOptions} [options] Addition options to send in the request
   */
  async read(endpoint, options) {
    let requParams = {
      key: null,
      limit: null,
      lastEvaluated: null,
      orderby: null,
      includes: null,
      filter: null,
      customParamMap: null,
      ...options,
    };

    let requUrl = endpoint;

    if (requParams.key !== null) {
      requUrl += `/${requParams.key}`;
    }

    let isFirstParam = true;
    [requUrl, isFirstParam] = addRequestParam(requUrl, 'limit', requParams.limit, isFirstParam);
    // eslint-disable-next-line max-len
    [requUrl, isFirstParam] = addRequestParam(requUrl, 'lastEvaluated', JSON.stringify(requParams.lastEvaluated), isFirstParam);
    [requUrl, isFirstParam] = addRequestParam(requUrl, 'orderby', requParams.orderby, isFirstParam);
    if (requParams.includes !== null) {
      [requUrl, isFirstParam] = addRequestParam(requUrl, 'includes', requParams.includes.join(','), isFirstParam);
    }
    if (requParams.filter !== null) {
      Object.keys(requParams.filter).forEach((key) => {
        [requUrl, isFirstParam] = addRequestParam(requUrl, key, requParams.filter[key], isFirstParam);
      });
    }
    if (requParams.customParamMap !== null) {
      Object.keys(requParams.customParamMap).forEach((key) => {
        [requUrl, isFirstParam] = addRequestParam(requUrl, key, requParams.customParamMap[key], isFirstParam);
      });
    }

    logger.debug('Build URI for API request', { requUrl });

    try {
      return await this.axios.get(requUrl);
    } catch (e) {
      logger.error('API request triggered error', { msg: e.message });
      if (e.response && e.response.data.error && e.response.data.error.errorCode) {
        // request did happen, but error returned (status !== 2XX)
        throw new ApplicationAPIError(e.response.status, e.response.data.error.errorCode);
      } else if ((e.message) === 'Network Error') {
        // Unknown error or request error like Network issues
        throw new ApplicationError(i18n.t('general.error'));
      } else {
        throw new Error(e.message);
      }
    }
  }

  /**
   * Send a read request to the server
   *
   * @param {string} endpoint The endpoint to query
   * @param {ReadOptions} [options] Addition options to send in the request
   */
  async delete(endpoint, options) {
    let requParams = {
      key: null,
      customParamMap: null,
      ...options,
    };

    let requUrl = endpoint;

    if (requParams.key !== null) {
      requUrl += `/${requParams.key}`;
    }

    let isFirstParam = true;

    if (requParams.customParamMap !== null) {
      Object.keys(requParams.customParamMap).forEach((key) => {
        [requUrl, isFirstParam] = addRequestParam(requUrl, key, requParams.customParamMap[key], isFirstParam);
      });
    }

    logger.debug('Build URI for API delete request', { requUrl });

    try {
      return await this.axios.delete(requUrl);
    } catch (e) {
      logger.error('API request triggered error', { msg: e.message });
      if (e.response && e.response.data.error && e.response.data.error.errorCode) {
        // request did happen, but error returned (status !== 2XX)
        throw new ApplicationAPIError(e.response.status, e.response.data.error.errorCode);
      } else if ((e.message) === 'Network Error') {
        // Unknown error or request error like Network issues
        throw new ApplicationError(i18n.t('general.error'));
      } else {
        throw new Error(e.message);
      }
    }
  }
}
