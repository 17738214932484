import axios from 'axios';

import createLogger from './Logger';

const logger = createLogger('Utils');

export default class Utils {
  static isEmailValid(email) {
    // eslint-disable-next-line max-len
    return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/g.test(email);
  }

  static formatNumberUnitPrefix(num) {
    if (num == null) {
      return null;
    }

    const units = [
      { value: 1000000, unit: 'M' },
      { value: 1000, unit: 'K' },
    ];

    let unit = units.find((u) => num > u.value);
    let result = num.toString();
    if (unit != null) {
      result = (num / unit.value).toFixed(1) + unit.unit;
    }

    return result;
  }

  static formatTime(timeInSeconds) {
    const getSeconds = `0${(timeInSeconds % 60)}`.slice(-2);
    const minutes = `${Math.floor(timeInSeconds / 60)}`;
    const getMinutes = `0${minutes % 60}`.slice(-2);

    return `${getMinutes}:${getSeconds}`;
  }

  static async uploadPodcast({ file, onProgress, episodeApi }) {
    let tempFilename = null;
    let presignedUrlData = await episodeApi.getPresignedUrlForPodcast((file.name || 'podcast.wav').replace(/\s/g, ''));
    logger.debug('Server responded with success status', { presignedUrlData });

    // save fileName for later signed image request
    tempFilename = presignedUrlData.fields.fileName;

    // create form and header data
    let form = new FormData();
    let headerObject = {};

    let keys = Object.keys(presignedUrlData.fields);
    keys.forEach((key) => {
      headerObject[key] = presignedUrlData.fields[key];
      form.append(key, presignedUrlData.fields[key]);
    });

    headerObject['Content-Type'] = 'audio';
    form.append('Content-Type', 'audio');
    form.append('file', file);

    // upload podcast
    await axios({
      method: 'post',
      headers: { headerObject },
      data: form,
      url: presignedUrlData.url,
      onUploadProgress: (progressEvent) => {
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        if (onProgress != null) {
          onProgress(percentCompleted);
        }
      },
    });
    logger.log(`file upload was successful ${presignedUrlData}`);

    return tempFilename;
  }

  static async uploadImage({ file, onProgress, showApi }) {
    let tempFilename = null;
    let presignedUrlData = await showApi.getPresignedUrlForShowImage((file.name || 'image.png').replace(/\s/g, ''));
    logger.debug('Server responded with success status', { presignedUrlData });

    // save fileName for later signed image request
    tempFilename = presignedUrlData.fields.fileName;

    // create form and header data
    let form = new FormData();
    let headerObject = {};

    let keys = Object.keys(presignedUrlData.fields);
    keys.forEach((key) => {
      headerObject[key] = presignedUrlData.fields[key];
      form.append(key, presignedUrlData.fields[key]);
    });

    headerObject['Content-Type'] = 'image';
    form.append('Content-Type', 'image');
    form.append('file', file);

    // upload podcast
    await axios({
      method: 'post',
      headers: { headerObject },
      data: form,
      url: presignedUrlData.url,
      onUploadProgress: (progressEvent) => {
        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        if (onProgress != null) {
          onProgress(percentCompleted);
        }
      },
    });
    logger.log(`file upload was successful ${presignedUrlData}`);

    return tempFilename;
  }
}
