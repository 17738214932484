import React, { Fragment, useEffect, useState } from 'react';

import { Dialog, Transition } from '@headlessui/react';
import { CheckCircleIcon, CheckIcon, EnvelopeIcon } from '@heroicons/react/24/outline';

import useTenantSettings from '../../hooks/useTenantSettings';
import i18n from '../../i18n';
import useAlertNotification from '../../hooks/useAlertNotification';
import TenantApi from '../../api/TenantApi';
import useQuery from '../../hooks/useUrlQuery';
import { defaultErrorHandler } from '../../errors/ApplicationError';
import Spinner from '../../components/Basic/Spinner';

// const userApi = new UserApi();
const tenantApi = new TenantApi();

function SuccessModal({
  show,
  handleClose,
}) {
  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-70 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6"
              >
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                    <CheckCircleIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                      {i18n.t('billing.successModalTitle')}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-vase text-gray-500">
                        {i18n.t('billing.successModalContent')}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 flex flex-row justify-end">
                  <button
                    type="button"
                    className="inline-flex
                      justify-center
                      rounded-md
                      border
                      border-transparent
                      disabled:bg-gray-300
                      disabled:text-gray-500
                      bg-primary-500
                      px-4
                      py-2
                      text-base
                      font-medium
                      text-white
                      shadow-sm
                      enabled:hover:bg-primary-400
                      focus:outline-none
                      focus:ring-2
                      enabled:focus:ring-primary-500
                      focus:ring-offset-2
                      sm:text-sm"
                    onClick={handleClose}
                  >
                    {i18n.t('billing.successModalClose')}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

function ContactModal({
  show,
  handleClose,
}) {
  const [message, setMessage] = useState(i18n.t('billing.contactDefaultMessage'));
  const [isSending, setIsSending] = useState(false);
  const { addError } = useAlertNotification();

  const sendContact = async () => {
    setIsSending(true);
    try {
      await tenantApi.contactEnterprisePlan(message);
      handleClose();
    } catch (e) {
      defaultErrorHandler(e, addError);
    } finally {
      setIsSending(false);
    }
  };

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-70 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6"
              >
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-primary-100">
                    <EnvelopeIcon className="h-6 w-6 text-primary-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                      {i18n.t('billing.contactTitle')}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-vase text-gray-500">
                        {i18n.t('billing.contactContent')}
                      </p>
                    </div>
                  </div>
                  <div className="mt-2">
                    <textarea
                      rows={3}
                      name="message"
                      id="message"
                      className="block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                      value={message}
                      onChange={(e) => {
                        setMessage(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 flex flex-row justify-end">
                  <button
                    type="button"
                    className="inline-flex
                      justify-center
                      rounded-md
                      border
                      border-transparent
                      disabled:bg-gray-300
                      disabled:text-gray-500
                      bg-primary-500
                      px-4
                      py-2
                      text-base
                      font-medium
                      text-white
                      shadow-sm
                      enabled:hover:bg-primary-400
                      focus:outline-none
                      focus:ring-2
                      enabled:focus:ring-primary-500
                      focus:ring-offset-2
                      sm:text-sm"
                    onClick={handleClose}
                  >
                    {i18n.t('billing.contactClose')}
                  </button>
                  <button
                    type="button"
                    className="inline-flex
                      justify-center
                      rounded-md
                      border
                      border-transparent
                      disabled:bg-gray-300
                      disabled:text-gray-500
                      bg-primary-500
                      px-4
                      py-2
                      text-base
                      font-medium
                      text-white
                      shadow-sm
                      enabled:hover:bg-primary-400
                      focus:outline-none
                      focus:ring-2
                      enabled:focus:ring-primary-500
                      focus:ring-offset-2
                      sm:text-sm
                      ml-1"
                    onClick={() => sendContact()}
                  >
                    {isSending && <Spinner className="m-0 mr-1 border-primary-300" size={5} />}
                    {i18n.t('billing.contactContactButton')}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

function PricingTable() {
  const [showContactForm, setShowContactForm] = useState(false);
  const [checkoutInProcess, setCheckoutInProcess] = useState(null);
  const { addError } = useAlertNotification();
  const checkout = async (planId) => {
    if (checkoutInProcess != null) {
      return;
    }

    try {
      setCheckoutInProcess(planId);
      let data = await tenantApi.stripeCheckout(planId);
      window.location.href = data.stripeUrl;
    } catch (e) {
      addError(i18n.t('billing.openCheckoutError'));
    } finally {
      setCheckoutInProcess(null);
    }
  };
  return (
    <>
      <div className="flex justify-start">
        <div className="grid gap-8 lg:grid-cols-3 lg:gap-12 xl:gap-18 max-w-fit">
          <div className="w-56 h-72 rounded-lg shadow border border-gray-100 flex flex-col p-4">
            <div className="self-end"><span className="text-yellow-900 bg-yellow-300 rounded-lg px-2 -m-2 text-sm">{i18n.t('billing.limitedOffer')}</span></div>
            <p className="text-lg font-bold">Creator</p>
            <div className="flex flex-row items-end pt-2">
              <p className="text-4xl font-extrabold">€5,80</p>
              <p className="pl-2 text-lg text-gray-500">{i18n.t('billing.perMonth')}</p>
            </div>
            <div className="flex flex-row pt-2 items-center">
              <CheckIcon className="h-5 w-5 text-primary-500" />
              <p className="pl-1">{i18n.t('billing.aiIncluded')}</p>
            </div>
            <div className="flex flex-row items-center">
              <CheckIcon className="h-5 w-5 text-primary-500" />
              <p className="pl-1">{i18n.t('billing.nProducer', { amount: '2' })}</p>
            </div>
            <div className="flex flex-row items-center">
              <CheckIcon className="h-5 w-5 text-primary-500" />
              <p className="pl-1">{i18n.t('billing.nMember', { amount: '100' })}</p>
            </div>
            <div className="flex-1 flex justify-center items-end">
              <button
                type="button"
                className="flex flex-row items-center px-3 py-1 disabled:bg-gray-300 disabled:text-gray-500 rounded bg-primary-500 hover:bg-primary-400 active:bg-primary-600 text-white text-base"
                onClick={() => checkout(3)}
              >
                {checkoutInProcess === 3 && <Spinner className="border-gray-200 mr-2" size={4} />}
                {i18n.t('billing.upgrade')}
              </button>
            </div>
          </div>
          <div className="w-56 h-72 rounded-lg shadow border border-gray-100 flex flex-col p-4">
            <div className="self-end"><span className="text-yellow-900 bg-yellow-300 rounded-lg px-2 -m-2 text-sm">{i18n.t('billing.limitedOffer')}</span></div>
            <p className="text-lg font-bold">Business</p>
            <div className="flex flex-row items-end pt-2">
              <p className="text-4xl font-extrabold">€600</p>
              <p className="pl-2 text-lg text-gray-500">{i18n.t('billing.perMonth')}</p>
            </div>
            <div className="flex flex-row pt-2 items-center">
              <CheckIcon className="h-5 w-5 text-primary-500" />
              <p className="pl-1">{i18n.t('billing.aiIncluded')}</p>
            </div>
            <div className="flex flex-row items-center">
              <CheckIcon className="h-5 w-5 text-primary-500" />
              <p className="pl-1">{i18n.t('billing.nProducer', { amount: '3' })}</p>
            </div>
            <div className="flex flex-row items-center">
              <CheckIcon className="h-5 w-5 text-primary-500" />
              <p className="pl-1">{i18n.t('billing.nMember', { amount: '1000' })}</p>
            </div>
            <div className="flex-1 flex justify-center items-end">
              <button
                type="button"
                className="flex flex-row px-3 py-1 disabled:bg-gray-300 disabled:text-gray-500 rounded bg-primary-500 hover:bg-primary-400 active:bg-primary-600 text-white text-base"
                onClick={() => checkout(1)}
              >
                {checkoutInProcess === 1 && <Spinner className="border-gray-200 mr-2" size={4} />}
                {i18n.t('billing.upgrade')}
              </button>
            </div>
          </div>
          <div className="w-56 h-72 rounded-lg shadow border border-gray-100 flex flex-col p-4">
            <div className="self-end"><span className="text-yellow-900 rounded-lg px-2 -m-2 text-sm" /></div>
            <p className="text-lg font-bold">Enterprise</p>
            <div className="flex flex-row items-end pt-2">
              <p className="text-3xl font-bold">{i18n.t('billing.letsTalk')}</p>
            </div>
            <div className="flex flex-row pt-2 items-center">
              <CheckIcon className="h-5 w-5 text-primary-500" />
              <p className="pl-1">{i18n.t('billing.aiIncluded')}</p>
            </div>
            <div className="flex flex-row items-center">
              <CheckIcon className="h-5 w-5 text-primary-500" />
              <p className="pl-1">{i18n.t('billing.nProducer', { amount: '6+' })}</p>
            </div>
            <div className="flex flex-row items-center">
              <CheckIcon className="h-5 w-5 text-primary-500" />
              <p className="pl-1">{i18n.t('billing.nMember', { amount: '1000+' })}</p>
            </div>
            <div className="flex flex-row items-center">
              <CheckIcon className="h-5 w-5 text-primary-500" />
              <p className="pl-1">SSO</p>
            </div>
            <div className="flex-1 flex justify-center items-end">
              <button
                type="button"
                className="px-3 py-1 disabled:bg-gray-300 disabled:text-gray-500 rounded bg-primary-500 hover:bg-primary-400 active:bg-primary-600 text-white text-base"
                onClick={() => setShowContactForm(true)}
              >
                {i18n.t('billing.contact')}
              </button>
            </div>
          </div>
        </div>
      </div>
      <ContactModal show={showContactForm} handleClose={() => setShowContactForm(false)} />
    </>
  );
}

export default function Billing() {
  const { plan: activePlan } = useTenantSettings();
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [loadingCustomerPortal, setLoadingCustomerPortal] = useState(false);
  const { addError } = useAlertNotification();
  // const user = useSelector((s) => s.user.user);
  // const [showBackdrop, setShowBackdrop] = useState(false);
  const urlQuery = useQuery();

  const isSuccessRedirect = urlQuery.get('success');

  const manageSubscription = async () => {
    if (loadingCustomerPortal) {
      return;
    }

    setLoadingCustomerPortal(true);
    try {
      let portalUrl = await tenantApi.stripeCustomerPortal();
      window.open(portalUrl.stripeUrl, '_blank').focus();
    } catch (e) {
      defaultErrorHandler(e, addError);
    } finally {
      setLoadingCustomerPortal(false);
    }
  };

  useEffect(() => {
    if (isSuccessRedirect != null) {
      setShowSuccessDialog(true);
    }
  }, [isSuccessRedirect]);

  return (
    <>
      <div className="py-6 h-full">
        <div className="flex flex-col h-full">
          <div className="flex flex-col px-4">
            <h3 className="text-xl font-bold mb-4">{i18n.t('billing.billing')}</h3>
            {/* <p className="text-sm text-gray-500">{i18n.t('billing.billingOwner')}</p>
            <div className="flex">
              <UserSearch
                placeholder={i18n.t('billing.chooseBillingOwner')}
                selectedValue={`${user.firstName} ${user.lastName}`}
                onUserSelection={async (selectedUser) => {
                  let userId = selectedUser.objectID;
                  setShowBackdrop(true);
                  try {
                    await userApi.updateBillingOwner(userId);
                  } catch (e) {
                    defaultErrorHandler(e, addError);
                  } finally {
                    setShowBackdrop(false);
                  }
                }}
              />
            </div> */}
            <div className="sm:overflow-hidden">
              <div className="space-y-6 bg-white py-6">
                <div>
                  {activePlan === 2 && (
                  <p className="text-sm text-gray-600">{i18n.t('billing.warningEnterprisePlan')}</p>
                  )}
                </div>
                {activePlan === 0 && <PricingTable />}
              </div>
              {activePlan !== 0 && activePlan !== 2 && (
              <>
                <p className="max-w-2xl pb-4">{i18n.t('billing.stripeCustomerPortalInfo')}</p>
                <div className="flex flex-col max-w-fit space-y-1">
                  <button
                    type="button"
                    disabled={activePlan === 0 || activePlan === 2}
                    className="flex flex-row px-3 py-1 disabled:bg-gray-300 disabled:text-gray-500 rounded bg-primary-500 hover:bg-primary-400 active:bg-primary-600 text-white text-base"
                    onClick={manageSubscription}
                  >
                    {loadingCustomerPortal && <Spinner className="border-gray-200 mr-2" size={4} />}
                    {i18n.t('billing.manageSubscription')}
                  </button>
                </div>
              </>
              )}
            </div>
          </div>
        </div>
      </div>
      <SuccessModal
        show={showSuccessDialog}
        handleClose={() => setShowSuccessDialog(false)}
      />
      {/* {showBackdrop && (
        <div className="fixed w-full h-full flex justify-center items-center inset-0 bg-black opacity-70 z-50">
          <Spinner size={6} color="primary-500" />
        </div>
      )} */}
    </>
  );
}
