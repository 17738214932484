import moment from 'moment';
import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS, registerables,
} from 'chart.js';
import 'chartjs-adapter-moment';

import getPlugins from '../../util/AnalyticsUtil';

ChartJS.register(...registerables);

// Shades of primary color. Make a input prop if necessary
const DATASET_COLORS = [
  '#457995',
  '#94BBCC',
  '#DDEAF0',
  '#F2F8F9',
];

export default function MonthlyBarChart({
  labels, data: dataIn, stacked = false, maxOffset, isError,
}) {
  let data = (Array.isArray(dataIn[0]) ? dataIn : [dataIn]);

  return (
    <Bar
      data={{
        labels,
        datasets: data.map((d, i) => (
          {
            data: d,
            error: isError,
            backgroundColor: DATASET_COLORS[i],
            barThickness: 20,
            borderRadius: 3,
          })),
      }}
      options={{
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: { display: false },
          tooltip: {
            callbacks: {
              title(context) {
                return moment(context[0].label).format('MMM YYYY');
              },
            },
          },
        },
        layout: {
          padding: {
            top: 15,
            right: 15,
            left: 10,
            bottom: 5,
          },
        },
        scales: {
          x: {
            stacked,
            type: 'time',
            title: {
              display: false,
            },
            time: {
              unit: 'month',
              displayFormats: {
                month: 'MMM',
              },
            },
            grid: {
              display: false,
            },
          },
          y: {
            stacked,
            title: {
              display: false,
            },
            suggestedMax: maxOffset ? data.reduce((prev, val) => Math.max(prev, val), 0) + 1 : null,
            ticks: {
              precision: 0,
            },
          },
        },
      }}
      plugins={getPlugins()}
    />
  );
}
