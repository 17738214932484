import { API } from './api';

export default class EpisodeAPI {
  constructor() {
    this.api = new API();
  }

  async getPresignedUrlForIntro(filename) {
    let data = await this.api.create('show/temp-episode-intro', {
      filename,
    });

    return data.data;
  }

  async getPresignedUrlForPodcast(filename) {
    let data = await this.api.create('show/temp-episode-podcast', {
      filename,
    });

    return data.data;
  }

  async getSignedTempFileUrl(filename) {
    let data = await this.api.read('signed-temp-file', {
      key: filename,
    });

    return data.data;
  }

  async createEpisode(name, description, showId, podcastPath) {
    let data = await this.api.create('episode', {
      name,
      description,
      showId,
      podcastPath,
    });
    return data.data;
  }

  async createProposedEpisodes(episodes, showId) {
    let data = await this.api.create('episodes', {
      episodes,
      showId,
    });
    return data.data;
  }

  async getEpisodeList(showId, limit, lastEvaluated) {
    let data = await this.api.read(`show/${showId}/episodes`, {
      limit,
      lastEvaluated,
      customParamMap: {
        allEpisodes: true,
      },
    });

    return data.data;
  }

  async deleteEpisode(episodeId, showId) {
    let data = await this.api.delete('episode', {
      key: episodeId,
      customParamMap: {
        showId,
      },
    });

    return data.data;
  }

  async getEpisode(episodeId) {
    let data = await this.api.read('episode', {
      key: episodeId,
    });

    return data.data;
  }

  async updateEpisode(episodeId, name, description) {
    let data = await this.api.update(`episode/${episodeId}`, {
      name,
      description,
    });

    return data.data;
  }

  async getEpisodeResearch(episodeId) {
    let data = await this.api.read(`episode/${episodeId}/research`);

    return data.data.content;
  }

  async updateEpisodeResearch(episodeId, researchJsonString) {
    await this.api.update(`episode/${episodeId}/research`, {
      content: researchJsonString,
    });
  }

  async getEpisodeStructure(episodeId) {
    let data = await this.api.read(`episode/${episodeId}/structure`);

    return data.data;
  }

  async addEpisodeStructure(
    episodeId,
    title,
    content,
    length,
    afterBlockId = null,
  ) {
    let data = await this.api.create(`episode/${episodeId}/structure`, {
      afterId: afterBlockId,
      title,
      content,
      timeIndicationInSeconds: length,
    });

    return data.data;
  }

  async createEpisodeStructures(episodeId, structure) {
    let data = await this.api.create(`episode/${episodeId}/structures`, {
      structure,
    });

    return data.data;
  }

  async updateEpisodeStructure(episodeId, blockId, title, content, length) {
    let data = await this.api.update(`episode/${episodeId}/structure`, {
      blockId,
      title,
      content,
      timeIndicationInSeconds: length,
    });

    return data.data;
  }

  async deleteEpisodeStructure(episodeId, blockId) {
    let data = await this.api.delete(
      `episode/${episodeId}/structure/${blockId}`,
    );

    return data.data;
  }

  async proposeEpisodeStructure(episodeId, markdown) {
    let data = await this.api.create(`episode/${episodeId}/proposeStructure`, {
      researchMarkdown: markdown,
    });

    return data.data;
  }

  async updateEpisodePodcastFile(episodeId, tempPodcastPath) {
    await this.api.update(`episode/${episodeId}`, {
      tempPodcastPath,
    });
  }

  async addEpisodeProductionComment(episodeId, comment) {
    await this.api.create(`episode/${episodeId}/productionComment`, {
      comment,
    });
  }

  async getEpisodeProductionFeed(episodeId) {
    let data = await this.api.read(`episode/${episodeId}/productionFeed`);

    return data.data;
  }

  async publishEpisode(episodeId) {
    await this.api.create(`episode/${episodeId}/publish`);
  }

  async scheduleEpisode(episodeId, scheduleDate) {
    await this.api.update(`episode/${episodeId}/schedule`, {
      scheduleDate,
    });
  }

  async getUnpublishedUserEpisodes() {
    let data = await this.api.read('episodes');

    return data.data;
  }

  async syncPlaytime(episodeId, playtime) {
    await this.api.update(`episode/${episodeId}/playtime`, {
      playtime,
    });
  }
}
