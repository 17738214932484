/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useRef, useState } from 'react';
import {
  CloudArrowUpIcon,
  MicrophoneIcon,
  PauseIcon,
  StopIcon,
} from '@heroicons/react/24/solid';
import RecordRTC from 'recordrtc';
import { Prompt, Link } from 'react-router-dom';

import Editor from '../../../components/Editor/Editor';
import EpisodeStructureList from './EpisodeStructureList';
import i18n from '../../../i18n';
import WarningModal from '../../../components/WarningModal/WarningModal';
import EpisodeAPI from '../../../api/EpisodeApi';
import useAlertNotification from '../../../hooks/useAlertNotification';
import { defaultErrorHandler } from '../../../errors/ApplicationError';
import Utils from '../../../util/Utils';
import UpdatePodcastFileButton from './UpdatePodcastFileButton';
import Spinner from '../../../components/Basic/Spinner';
import createLogger from '../../../util/Logger';

const { StereoAudioRecorder } = RecordRTC;

const episodeApi = new EpisodeAPI();
const logger = createLogger('EpisodeProductionRecord');

export default function EpisodeProductionRecord({ episodeId, structure, refreshEpisode }) {
  const [isUploading, setIsUploading] = useState(false);
  const [uploadingPercentage, setUploadingPercentage] = useState(0);
  const [showRecordingExistsWarningDialog, setShowRecordingExistsWarningDialog] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [recordingStarted, setRecordingStarted] = useState(false);
  const [timerInSeconds, setTimerInSeconds] = useState(0);
  const [blob, setBlob] = useState(null);
  const recorderRef = useRef(null);
  const mediaStreamRef = useRef(null);
  const timerRef = useRef(null);
  const { addError, addSuccess } = useAlertNotification();

  const updatePodcastFile = async (f) => {
    if (isUploading) {
      return;
    }

    setIsUploading(true);
    setUploadingPercentage(0);
    try {
      let tempName = await Utils.uploadPodcast({
        file: f,
        onProgress: (progress) => setUploadingPercentage(progress),
        episodeApi,
      });
      await episodeApi.updateEpisodePodcastFile(episodeId, tempName);
      await refreshEpisode();
      addSuccess(i18n.t('notifications.podcastUploadSuccessfully'));
    } catch (e) {
      defaultErrorHandler(e, addError);
    } finally {
      setIsUploading(false);
    }
  };

  async function getMediaStream() {
    let mediaStream = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: false,
      echoCancellation: true,
    });
    mediaStreamRef.current = mediaStream;
    let recorder = new RecordRTC(mediaStream, {
      type: 'audio',
      mimeType: 'audio/wav',
      recorderType: StereoAudioRecorder,
      audioBitsPerSecond: 128000,
    });
    recorderRef.current = recorder;

    return recorder;
  }

  const startRecording = async () => {
    let recorder = await getMediaStream();
    recorder.startRecording();
    setBlob(null);
    setIsRecording(true);
    setRecordingStarted(true);
    timerRef.current = setInterval(() => setTimerInSeconds((t) => t + 1), 1000);
  };

  useEffect(() => () => {
    // cleanup any active recorders before leaving the page
    logger.debug('Cleanup recorders');
    if (recorderRef.current) {
      recorderRef.current.destroy();
      recorderRef.current = null;
    }

    if (mediaStreamRef.current) {
      mediaStreamRef.current.getTracks().forEach((track) => track.stop());
      mediaStreamRef.current = null;
    }
  }, []);

  return (
    <>
      <div className="sticky flex flex-col w-full border border-gray-200 inset-y-0 justify-center items-center py-4 mb-4 bg-gray-50 z-20 rounded min-w-min">
        <div className="flex flex-row items-center">
          {isRecording && <div className="w-4 h-4 bg-red-700 animate-pulse rounded-full" />}
          <span className="font-bold text-lg text-black ml-1">{Utils.formatTime(timerInSeconds)}</span>
        </div>
        <div className="flex sm:flex-row flex-col justify-center mt-1 w-full gap-2">
          <div className="ml-3 flex sm:flex-row flex-col items-center flex-1 justify-between">
            <UpdatePodcastFileButton
              episodeId={episodeId}
              onBeforeUpdateFinished={async () => {
                await refreshEpisode();
              }}
            />
            <span className="uppercase text-sm text-gray-500 font-bold">{i18n.t('episodeDetails.production.or')}</span>
            <button
              type="button"
              className="flex flex-row bg-primary-500 rounded px-2 py-1 text-white hover:bg-primary-400 active:bg-primary-600 justify-center"
              onClick={async () => {
                if (recorderRef.current == null && blob == null) {
                  startRecording();
                } else if (recorderRef.current == null) {
                  setShowRecordingExistsWarningDialog(true);
                } else if (recorderRef.current.state) {
                  let recorder = recorderRef.current;

                  if (recorder.getState() === 'recording') {
                    recorder.pauseRecording();
                    setIsRecording(false);
                    clearInterval(timerRef.current);
                  } else {
                    recorder.resumeRecording();
                    setIsRecording(true);
                    timerRef.current = setInterval(() => setTimerInSeconds((t) => t + 1), 1000);
                  }
                }
              }}
            >
              {!isRecording && <MicrophoneIcon className="w-5 h-5 text-white mr-1" />}
              {isRecording && <PauseIcon className="w-5 h-5 text-white mr-1" />}
              {!isRecording && !recordingStarted && i18n.t('episodeDetails.production.record')}
              {!isRecording && recordingStarted && i18n.t('episodeDetails.production.resume')}
              {isRecording && i18n.t('episodeDetails.production.pause')}
            </button>
          </div>
          <div className="flex-1 flex sm:justify-start justify-center">
            <button
              type="button"
              disabled={!recordingStarted}
              className="flex flex-row bg-primary-500 rounded px-2 py-1 text-white hover:bg-primary-400 active:bg-primary-600 disabled:bg-gray-300 disabled:text-gray-500 justify-center"
              onClick={async () => {
                setIsRecording(false);
                setTimerInSeconds(0);
                setRecordingStarted(false);
                clearInterval(timerRef.current);
                await new Promise((resolve) => {
                  recorderRef.current.stopRecording(() => {
                    setBlob(recorderRef.current.getBlob());
                    resolve();
                  });
                });
                recorderRef.current.destroy();
                recorderRef.current = null;
                mediaStreamRef.current.getTracks().forEach((track) => track.stop());
                mediaStreamRef.current = null;
              }}
            >
              <StopIcon className="w-5 h-5 mr-1" />
              {i18n.t('episodeDetails.production.stop')}
            </button>
          </div>
        </div>
        <div>
          {blob && <audio controls className="mt-4" src={URL.createObjectURL(blob)} />}
        </div>
        <div>
          {blob && (
          <button
            type="button"
            className="flex flex-row bg-primary-500 rounded px-2 py-1 text-white hover:bg-primary-400 active:bg-primary-600 justify-center mt-2"
            onClick={async () => {
              await updatePodcastFile(blob);
            }}
          >
            {isUploading ? <Spinner size={4} className="border-gray-200" /> : <CloudArrowUpIcon className="w-5 h-5 text-white mr-1" />}
            <span>{isUploading ? `${uploadingPercentage}%` : i18n.t('episodeDetails.production.save')}</span>
          </button>
          )}
        </div>
      </div>
      <div className="grid grid-cols-1 2xl:grid-cols-2 2xl:space-x-4 mt-4">
        <div>
          <h3 className="text-xl font-bold">{i18n.t('episodeDetails.preparation.researchNotes')}</h3>
          <Editor className="mt-2" episodeId={episodeId} editable={false} />
        </div>
        <div className="mt-4 2xl:mt-0">
          <h3 className="text-xl font-bold">{i18n.t('episodeDetails.preparation.structure')}</h3>
          <div className="mt-2">
            {structure && structure.length > 0 ? (
              <EpisodeStructureList
                canEdit={false}
                structure={structure}
              />
            ) : (
              <p className="pt-2 max-w-[80%]">
                {i18n.t('episodeDetails.production.emptyStructure1')}
                {' '}
                <Link className="text-primary-500" to={`/app/episode/${episodeId}/preparation`}>{i18n.t('episodeDetails.production.emptyStructure2')}</Link>
                {' '}
                {i18n.t('episodeDetails.production.emptyStructure3')}
              </p>
            ) }
          </div>
        </div>
      </div>
      <WarningModal
        openWarningModal={showRecordingExistsWarningDialog}
        closeWarningModal={() => setShowRecordingExistsWarningDialog(false)}
        title={i18n.t('episodeDetails.production.overwriteWarningTitle')}
        contentText={i18n.t('episodeDetails.production.overwriteWarningText')}
        positiveButtonText={i18n.t('episodeDetails.production.overwriteWarningButton')}
        colorScheme="yellow"
        buttonColorScheme="primary"
        handleWarningModal={() => {
          // restart recording
          startRecording();
        }}
      />
      <Prompt message={i18n.t('episodeDetails.production.unsavedRecording')} when={blob != null || recordingStarted} />
    </>
  );
}
