import { API } from './api';

export default class ShowAPI {
  constructor() {
    this.api = new API();
  }

  async getPresignedUrlForShowImage(filename) {
    let data = await this.api.create('show/temp-show-image', {
      filename,
    });

    return data.data;
  }

  async getSignedTempFileUrl(filename) {
    let data = await this.api.read('signed-temp-file', {
      key: filename,
    });

    return data.data;
  }

  async createShow(name, description, imagePath = null, channelId = null, producers = null, organization = null, motiviation = '', topic = '', topicDescription = '') {
    let data = await this.api.create('show', {
      name,
      description,
      imagePath,
      channelId,
      producers,
      organization,
      goal: motiviation,
      topic,
      topicDescription,
    });
    return data.data;
  }

  async getShowsOfUser(limit, lastEvaluated) {
    let data = await this.api.read('shows', {
      limit,
      lastEvaluated,
    });
    return data.data;
  }

  /**
   * Get show data along with episodes
   * If no limit is provided then default limit of the API will be used
   * If limit is specified as 0, then episodes will be skipped
   *
   * @param {string} id, the showId
   * @param {number} limit, number of episodes to return
   */
  async getShowWithEpisodes(id, limit) {
    let data = await this.api.read('show', {
      key: id,
      includes: ['episodes', 'channelMembers', 'producers'],
      customParamMap: {
        allEpisodes: true,
        webapp: true,
      },
      ...(limit && { limit }),
    });

    return data.data;
  }

  async getShow(id) {
    let data = await this.api.read('show', {
      key: id,
      includes: ['producers'],
      customParamMap: {
        webapp: true,
      },
    });

    return data.data;
  }

  async deleteShow(showId) {
    let data = await this.api.delete('show', {
      key: showId,
    });

    return data.data;
  }

  async getProducers(showId) {
    let data = await this.api.read(`show/${showId}/producers`, {});

    return data.data;
  }

  async updateProducers(showId, producers) {
    let data = await this.api.update('show/producers', {
      showId,
      producers,
    });

    return data.data;
  }

  async updateOwners(showId, owners) {
    let data = await this.api.update(`show/${showId}/owners`, {
      owners,
    });

    return data.data;
  }

  async updateShowDetails(showId, name, description, organization) {
    let data = await this.api.update(`show/${showId}`, {
      name,
      description,
      organization,
    });

    return data.data;
  }

  async updateShowImage(imagePath, showId) {
    let data = await this.api.update(`show/${showId}/image`, {
      imagePath,
    });

    return data.data;
  }

  async getShowGuidanceQuestions(showId) {
    let data = await this.api.read(`show/${showId}/guidanceQuestions`, {
    });

    return data.data;
  }

  async getShowAnalytics(showId) {
    let data = await this.api.read(`show/${showId}/analytics`, {
      includes: ['top3', 'totalListeners', 'avgListeners', 'dropOff', 'monthlyListening', 'monthlyListeners'],
    });

    return data.data;
  }

  async createShowGoalProposal(topicDescription) {
    let data = await this.api.create('show/goal', {
      topicDescription,
    });
    return data.data;
  }

  async createShowEpisodesProposal(showId, amount) {
    let data = await this.api.create(`show/${showId}/proposeEpisodes`, {
      amount,
    });
    return data.data;
  }

  async getUserShowsAnalytics() {
    let data = await this.api.read('analytics/userShows');

    return data.data;
  }
}
