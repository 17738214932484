import SearchApi from '../api/SearchApi';

import { defaultErrorHandler } from '../errors/ApplicationError';

import createLogger from '../util/Logger';

import useAlertNotification from './useAlertNotification';

const logger = createLogger('ShowDetailsScreen');

const searchApi = new SearchApi();

export default function useSearch() {
  const { addError } = useAlertNotification();

  const searchRequest = (requests, type) => {
    logger.debug('starting search');
    return searchApi.search(requests, type)
      .catch((err) => {
        defaultErrorHandler(err, (msg) => {
          logger.debug('Received error', { msg });
          addError(msg);
        });
      });
  };

  return [searchRequest];
}
