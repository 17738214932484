import React, { Fragment, useEffect, useState } from 'react';
import {
  ChevronRightIcon,
  InformationCircleIcon,
  MagnifyingGlassIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { Dialog, Transition } from '@headlessui/react';
import ChannelAPI from '../../../../../api/ChannelApi';
import Spinner from '../../../../../components/Basic/Spinner';
import i18n from '../../../../../i18n';
import useAlertNotification from '../../../../../hooks/useAlertNotification';
import { defaultErrorHandler } from '../../../../../errors/ApplicationError';
import WarningModal from '../../../../../components/WarningModal/WarningModal';

const GROUP_ROLE_ADMIN = 1;

function SlideOver({ open, setOpen, selectedGroup }) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-30" onClose={setOpen}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="p-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-lg font-medium text-gray-900">{selectedGroup.channelName}</Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-primary-500"
                            onClick={() => setOpen(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <ul className="flex-1 divide-y divide-gray-200 overflow-y-auto">
                      {selectedGroup.members.map((member) => (
                        <li key={member.id}>
                          <div className="relative flex items-center py-6 px-5">
                            <div className="absolute inset-0" aria-hidden="true" />
                            <div className="relative flex min-w-0 flex-1 items-center">
                              <span className="relative inline-block flex-shrink-0">
                                <div key={member.id} className="inline-flex h-10 w-10 rounded-full bg-gray-400 justify-center items-center">
                                  <span className="text-base text-white">{`${member.firstName.charAt(0)}${member.lastName.charAt(0)}`}</span>
                                </div>
                              </span>
                              <p className="ml-4 truncate text-sm font-medium text-gray-900">{`${member.firstName} ${member.lastName}`}</p>
                            </div>
                            {member.role === GROUP_ROLE_ADMIN && <span className="text-gray-500">{i18n.t('showDetails.audience.admin')}</span>}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

const groupsApi = new ChannelAPI();

export default function ShowAudience({ show, refreshShow }) {
  const [slideOverOpen, setSlideOverOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState({ members: [] });
  const [showUnassignWarningDialog, setShowUnassignWarningDialog] = useState(false);
  const [showAssignWarningDialog, setShowAssignWarningDialog] = useState(false);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [assignGroupId, setAssignGroupId] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [groups, setGroups] = useState(null);
  const { addError, addSuccess } = useAlertNotification();

  useEffect(() => {
    groupsApi.getChannels(null)
      .then((groupsData) => {
        setGroups(groupsData);
      })
      .catch((err) => {
        defaultErrorHandler(err, addError);
      });
  }, []);

  const onUnassign = async () => {
    try {
      setShowBackdrop(true);
      setShowUnassignWarningDialog(false);
      await groupsApi.assignNewChannel(show.showId, null);
      addSuccess(i18n.t('showDetails.audience.unassignSuccessHeader'), i18n.t('showDetails.audience.unassignSuccessText'));
      await refreshShow();
    } catch (e) {
      defaultErrorHandler(e, addError);
    } finally {
      setShowBackdrop(false);
    }
  };

  const onAssign = async (groupId) => {
    try {
      setShowBackdrop(true);
      setShowAssignWarningDialog(false);
      await groupsApi.assignNewChannel(show.showId, groupId);
      addSuccess(i18n.t('showDetails.audience.assignSuccessHeader'), i18n.t('showDetails.audience.assignSuccessText'));
      await refreshShow();
    } catch (e) {
      defaultErrorHandler(e, addError);
    } finally {
      setShowBackdrop(false);
    }
  };

  // note below logic only works as we don't have pagination implemented for groups (as a user should be regularly only in a few groups)
  let renderGroups = [];
  if (groups && show && show.channel) {
    // if the show has a channel assigned, move that show to the top
    let assignedGroup = groups.find((g) => show.channel && g.channelId === show.channel.id);
    if (assignedGroup != null) {
      renderGroups.push(assignedGroup);
    }
  }
  if (groups && show) {
    // filter out the assigned group as it has been added to the top already.
    // apply the client side search
    renderGroups.push(...groups.filter((g) => (show.channel == null || g.channelId !== show.channel.id) && (searchValue === '' || g.channelName.toUpperCase().includes(searchValue.toUpperCase()))));
  }

  return (
    <>
      <h3 className="text-xl font-bold">{i18n.t('showDetails.audience.groups')}</h3>
      <div className="mt-8 flex flex-col">
        <div className="flex justify-between items-baseline pr-4 max-w-2xl">
          <div className="relative mt-1 rounded-md shadow-sm w-1/3">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
            <input
              type="text"
              name="searchUser"
              id="searchUser"
              className="block w-full rounded-md hover:border-gray-400 border-gray-300 pl-10 focus:border-primary-500 focus:ring-primary-500 text-sm"
              placeholder={i18n.t('showDetails.audience.searchGroups')}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
            />
          </div>
          <button
            type="button"
            disabled={show == null || show.channel == null}
            onClick={() => setShowUnassignWarningDialog(true)}
            className="px-3 py-1 disabled:bg-gray-300 disabled:text-gray-500 rounded bg-primary-500 hover:bg-primary-400 active:bg-primary-600 text-white text-base"
          >
            {i18n.t('showDetails.audience.unassign')}
          </button>
        </div>
        <div className="overflow-visible inline-block max-w-2xl py-2 align-middle pr-4">
          <div className="overflow-visible shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className="w-full divide-y divide-gray-300 table-auto">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="py-3 px-2 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-6 w-0"
                  >
                    <span className="sr-only">Assign</span>
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                  >
                    {i18n.t('showDetails.audience.group')}
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                  >
                    <span className="sr-only">Member List</span>
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 w-0"
                  >
                    <span className="sr-only">Open Member List</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white pointer-events-auto">
                {(groups == null || show == null) && <tr><td colSpan="5"><div className="flex justify-center my-4"><Spinner size={6} color="primary-500" className="self-center" /></div></td></tr>}
                {groups && show && renderGroups.map((group) => (
                  <tr
                    key={group.channelId}
                    className="hover:bg-gray-100 cursor-pointer pointer-events-auto"
                    onClick={() => {
                      setSelectedGroup(group);
                      setSlideOverOpen(true);
                    }}
                  >
                    <td className={classNames(show != null && show.channel != null && group.channelId === show.channel.id ? 'border-l-primary-500' : 'border-l-transparent', 'whitespace-nowrap py-4 px-2 text-sm text-gray-900 border-l-4 border-l-primary-500 text-center')}>
                      {show != null && show.channel != null && group.channelId === show.channel.id ? (
                        <div className="inline-flex rounded-xl px-2 border text-primary-700 border-primary-700">
                          <span>{i18n.t('showDetails.audience.assigned')}</span>
                        </div>
                      )
                        : (
                          <button
                            type="button"
                            className="px-3 py-1 disabled:bg-gray-300 disabled:text-gray-500 rounded bg-primary-500 hover:bg-primary-400 active:bg-primary-600 text-white"
                            onClick={(e) => {
                              e.stopPropagation();
                              setAssignGroupId(group.channelId);
                              setShowAssignWarningDialog(true);
                            }}
                          >
                            {i18n.t('showDetails.audience.assign')}
                          </button>
                        )}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900 font-medium truncate max-w-[20rem] min-w-[5rem]" title={group.channelName}>{group.channelName}</td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right flex justify-end items-center min-w-max">
                      <div className="isolate flex -space-x-1 overflow-hidden shrink-0">
                        {group.members.slice(0, 5).map((member, i, a) => (
                          <div key={member.id} className={`relative z-${a.length * 10 - i * 10} inline-flex h-6 w-6 rounded-full ring-2 ring-white bg-gray-400 justify-center items-center`}>
                            <span className="text-xs text-white">{`${member.firstName.charAt(0)}${member.lastName.charAt(0)}`}</span>
                          </div>
                        ))}
                      </div>
                      {group.members.length > 5 && (<span className="tracking-tight pl-2">{`+ ${group.members.length - 5} more`}</span>)}
                    </td>
                    <td className="px-3 py-1 pl-10 text-right">
                      <ChevronRightIcon className="h-4 w-4 text-gray-400" />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <SlideOver open={slideOverOpen} setOpen={setSlideOverOpen} selectedGroup={selectedGroup} />
      <WarningModal
        closeWarningModal={() => setShowUnassignWarningDialog(false)}
        handleWarningModal={() => onUnassign()}
        openWarningModal={showUnassignWarningDialog}
        title={i18n.t('showDetails.audience.unassignDialogTitle')}
        contentText={i18n.t('showDetails.audience.unassignDialogContent')}
        positiveButtonText={i18n.t('showDetails.audience.unassign')}
      />
      <WarningModal
        closeWarningModal={() => setShowAssignWarningDialog(false)}
        handleWarningModal={() => onAssign(assignGroupId)}
        openWarningModal={showAssignWarningDialog}
        title={i18n.t('showDetails.audience.assignDialogTitle')}
        contentText={i18n.t('showDetails.audience.assignDialogContent')}
        positiveButtonText={i18n.t('showDetails.audience.assign')}
        icon={InformationCircleIcon}
        colorScheme="primary"
      />
      {showBackdrop && (
      <div className="fixed w-full h-full flex justify-center items-center inset-0 bg-black opacity-70 z-50">
        <Spinner size={6} color="primary-500" />
      </div>
      )}
    </>
  );
}
