/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Fragment, useEffect, useState } from 'react';
import {
  XMarkIcon,
} from '@heroicons/react/24/outline';

import {
  Dialog,
  Transition,
} from '@headlessui/react';
import classNames from 'classnames';
import i18n from '../../../i18n';
import { defaultErrorHandler } from '../../../errors/ApplicationError';
import useAlertNotification from '../../../hooks/useAlertNotification';
import Spinner from '../../../components/Basic/Spinner';
import EpisodeAPI from '../../../api/EpisodeApi';

const episodeApi = new EpisodeAPI();

const NAME_CHARACTER_LIMIT = 150;
const DESCRIPTION_CHARACTER_LIMIT = 4000;

export default function EpisodeEditModal({
  showDialog,
  episode,
  refreshEpisode,
  handleClose,
}) {
  const [showBackdrop, setShowbackdrop] = useState(false);
  const [episodeName, setEpisodeName] = useState((episode && episode.name) || '');
  const [episodeDescription, setEpisodeDescription] = useState((episode && episode.description) || '');
  const { addError } = useAlertNotification();

  useEffect(() => {
    setEpisodeName((episode && episode.name) || '');
    setEpisodeDescription((episode && episode.description) || '');
  }, [episode]);

  useEffect(() => {
    if (showDialog) {
      setEpisodeName(episode.name);
      setEpisodeDescription(episode.description);
    }
  }, [showDialog]);

  const updateShow = async () => {
    if (episodeName.length === 0) {
      return;
    }

    setShowbackdrop(true);
    try {
      await episodeApi.updateEpisode(episode.episodeId, episodeName, episodeDescription);
      await refreshEpisode();
      handleClose(false);
    } catch (e) {
      defaultErrorHandler(e, addError);
    } finally {
      setShowbackdrop(false);
    }
  };

  const handleCloseInternal = () => {
    handleClose(false);
  };

  return (
    <Transition.Root show={showDialog} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleCloseInternal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-70 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg lg:max-w-xl sm:p-6"
              >
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0 focus:ring-offset-2"
                    onClick={() => handleClose(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div>
                  <div>
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                      {i18n.t('episodeDetails.edit.dialogTitle')}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        {i18n.t('episodeDetails.edit.dialogText')}
                      </p>
                    </div>
                    <div className="h-[1px] w-full bg-gray-300 rounded my-4" />
                    <div className="relative">
                      <label
                        htmlFor="episodeName"
                        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs text-gray-600"
                      >
                        {i18n.t('episodeDetails.edit.episodeName')}
                      </label>
                      <input
                        type="text"
                        name="episodeName"
                        id="episodeName"
                        className={classNames(
                          'block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-500 text-sm sm:leading-6',
                          episodeName.length === 0 && 'focus:ring-red-500',
                        )}
                        autoComplete="off"
                        value={episodeName}
                        maxLength={NAME_CHARACTER_LIMIT}
                        onChange={(e) => setEpisodeName(e.target.value)}
                      />
                      <div>
                        <span className={classNames('text-xs ml-1', episodeName.length === 0 ? 'text-red-600' : 'text-gray-700')}>{`${episodeName.length}/${NAME_CHARACTER_LIMIT}`}</span>
                        {episodeName.length === 0 && <span className="text-xs ml-1 text-red-600">{i18n.t('episodeDetails.edit.nameEmpty')}</span>}
                      </div>
                    </div>
                    <div className="relative mt-6">
                      <label htmlFor="episodeDescription" className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs text-gray-600">
                        {i18n.t('episodeDetails.edit.episodeDescription')}
                      </label>
                      <div className="mt-2">
                        <textarea
                          rows={10}
                          name="episodeDescription"
                          id="episodeDescription"
                          className="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-500 sm:py-1.5 sm:text-sm sm:leading-6"
                          value={episodeDescription}
                          maxLength={DESCRIPTION_CHARACTER_LIMIT}
                          onChange={(e) => setEpisodeDescription(e.target.value)}
                        />
                      </div>
                      <span className="text-gray-700 text-xs ml-1">{`${episodeDescription.length}/${DESCRIPTION_CHARACTER_LIMIT}`}</span>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 flex flex-row justify-end">
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm enabled:hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm disabled:bg-gray-300 disabled:text-gray-500 mx-2 enabled:focus:ring-primary-500"
                    onClick={handleCloseInternal}
                  >
                    {i18n.t('general.cancel')}
                  </button>
                  <button
                    type="button"
                    disabled={episodeName.length === 0}
                    className="inline-flex justify-center rounded-md border border-transparent disabled:bg-gray-300 disabled:text-gray-500 bg-primary-500 px-4 py-2 text-base font-medium text-white shadow-sm enabled:hover:bg-primary-400 focus:outline-none focus:ring-2 enabled:focus:ring-primary-500 focus:ring-offset-2 sm:text-sm"
                    onClick={() => updateShow()}
                  >
                    {i18n.t('general.save')}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
        {showBackdrop && (
        <div className="fixed w-full h-full flex justify-center items-center inset-0 bg-black opacity-70 z-50">
          <Spinner size={6} color="primary-500" />
        </div>
        )}
      </Dialog>
    </Transition.Root>
  );
}
