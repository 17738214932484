import React, {
  Fragment, useEffect, useRef, useState,
} from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';

import i18n from '../../../i18n';
import EpisodeAPI from '../../../api/EpisodeApi';
import { defaultErrorHandler } from '../../../errors/ApplicationError';
import useAlertNotification from '../../../hooks/useAlertNotification';
import EpisodeStructureList from './EpisodeStructureList';
import useWebSocketApi from '../../../hooks/useWebSocketApi';
import createLogger from '../../../util/Logger';

const episodeApi = new EpisodeAPI();
const logger = createLogger('EpisodeStructureProposeModal');

export default function EpisodeStructureProposeModal({
  showDialog, handleClose, episodeId, markdown, setShowBackdrop,
}) {
  const [structure, setStructure] = useState(null);
  const [isError, setIsError] = useState(false);
  const [requestActive, setRequestActive] = useState(false);
  const { addError } = useAlertNotification();
  const { sendJsonMessage, lastJsonMessage } = useWebSocketApi();
  const websocketTimeoutRef = useRef(null);

  const webSocketTimeoutError = () => {
    logger.error('WebSocket ran into timeout');
    setIsError(true);
    setRequestActive(false);
    websocketTimeoutRef.current = null;
  };

  useEffect(() => {
    setIsError(false);
    setStructure(null);
    if (!showDialog || requestActive) {
      return;
    }

    setRequestActive(true);
    try {
      sendJsonMessage({ action: 'episode-structure', episodeId, researchMarkdown: markdown });
      websocketTimeoutRef.current = setTimeout(webSocketTimeoutError, 90000);
    } catch (e) {
      defaultErrorHandler(e, addError);
      setIsError(true);
      setRequestActive(false);
    }
  }, [episodeId, markdown, showDialog]);

  useEffect(() => {
    if (lastJsonMessage != null && lastJsonMessage.type === 'episode-structure' && lastJsonMessage.data != null) {
      setStructure(lastJsonMessage.data);
      setRequestActive(false);
      clearTimeout(websocketTimeoutRef.current);
    }
  }, [lastJsonMessage]);

  useEffect(() => () => {
    if (websocketTimeoutRef.current != null) {
      clearTimeout(websocketTimeoutRef.current);
    }
  }, []);

  const handleSave = async () => {
    if (structure == null || structure.length === 0) {
      return;
    }

    setShowBackdrop(true);
    try {
      await episodeApi.createEpisodeStructures(episodeId, structure);
      handleClose(true);
    } catch (e) {
      setShowBackdrop(false);
      defaultErrorHandler(e, addError);
    }
  };

  return (
    <Transition.Root show={showDialog} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-70 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0 focus:ring-offset-2"
                    onClick={handleClose}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div>
                  <div>
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 mb-3">
                      {i18n.t('episodeDetails.preparation.proposedStructure')}
                    </Dialog.Title>
                    {structure == null && !isError && <span className="mt-5 italic">{i18n.t('episodeDetails.preparation.aiGenerating')}</span>}
                    {structure != null && <span>{i18n.t('episodeDetails.preparation.aiGenerationFinished')}</span>}
                    {!isError && (
                    <div>
                      <EpisodeStructureList
                        canEdit={false}
                        structure={structure}
                      />
                    </div>
                    )}
                    {isError && <span className="text-red-600">{i18n.t('episodeDetails.preparation.aiError')}</span>}
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 flex flex-row justify-end">
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm enabled:hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm disabled:bg-gray-300 disabled:text-gray-500 mx-2 enabled:focus:ring-primary-500"
                    onClick={handleClose}
                  >
                    {i18n.t('general.cancel')}
                  </button>
                  <button
                    type="button"
                    disabled={structure == null}
                    className="inline-flex justify-center rounded-md border border-transparent disabled:bg-gray-300 disabled:text-gray-500 bg-primary-500 px-4 py-2 text-base font-medium text-white shadow-sm enabled:hover:bg-primary-400 focus:outline-none focus:ring-2 enabled:focus:ring-primary-500 focus:ring-offset-2 sm:text-sm"
                    onClick={handleSave}
                  >
                    {i18n.t('general.save')}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
