/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';

import i18n from '../../../i18n';
import EpisodeAPI from '../../../api/EpisodeApi';
import { defaultErrorHandler } from '../../../errors/ApplicationError';
import useAlertNotification from '../../../hooks/useAlertNotification';

const episodeApi = new EpisodeAPI();

const BLOCK_TITLE_MAX_LENGTH = 100;
const BLOCK_CONTENT_MAX_LENGTH = 1000;

export default function EpisodeStructureBlockModal({
  showBlockEditDialog, handleClose, episodeId, block, add, afterBlockId = null, setShowBackdrop,
}) {
  const [blockTitle, setBlockTitle] = useState(block.title || '');
  const [blockContent, setBlockContent] = useState(block.content || '');
  const [blockLength, setBlockLength] = useState(block.timeIndicationInSeconds || 0);
  const [allowError, setAllowError] = useState(false);
  const { addError } = useAlertNotification();

  useEffect(() => {
    setBlockTitle(block.title || '');
    setBlockContent(block.content || '');
    setBlockLength(block.timeIndicationInSeconds || 0);
    setAllowError(false);
  }, [block]);

  const handleSave = async () => {
    if (blockTitle.length === 0) {
      return;
    }

    setShowBackdrop(true);
    try {
      if (add) {
        await episodeApi.addEpisodeStructure(episodeId, blockTitle, blockContent, +blockLength, afterBlockId);
      } else {
        await episodeApi.updateEpisodeStructure(episodeId, block.blockId, blockTitle, blockContent, +blockLength); // the + converts to number if it's a string and does nothing if it's already a number
      }
      handleClose(true);
    } catch (e) {
      setShowBackdrop(false);
      defaultErrorHandler(e, addError);
    }
  };

  return (
    <Transition.Root show={showBlockEditDialog} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-70 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0 focus:ring-offset-2"
                    onClick={handleClose}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div>
                  <div>
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                      {add ? i18n.t('episodeDetails.preparation.addBlock') : i18n.t('episodeDetails.preparation.editBlock')}
                    </Dialog.Title>
                    <div className="relative mt-5">
                      <label
                        htmlFor="blockTitle"
                        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs text-gray-600"
                      >
                        {i18n.t('episodeDetails.preparation.blockTitle')}
                      </label>
                      <input
                        type="text"
                        name="blockTitle"
                        id="blockTitle"
                        className={classNames(
                          'block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-500 text-sm sm:leading-6',
                          blockTitle.length === 0 && allowError && 'focus:ring-red-500',
                        )}
                        value={blockTitle}
                        maxLength={BLOCK_TITLE_MAX_LENGTH}
                        onChange={(e) => {
                          setBlockTitle(e.target.value);
                          setAllowError(true);
                        }}
                        autoComplete="off"
                      />
                      <div>
                        <span className={classNames('text-xs ml-1', blockTitle.length === 0 && allowError ? 'text-red-600' : 'text-gray-700')}>{`${blockTitle.length}/${BLOCK_TITLE_MAX_LENGTH}`}</span>
                        {blockTitle.length === 0 && allowError && <span className="text-xs ml-1 text-red-600">{i18n.t('episodeDetails.preparation.blockTitleEmpty')}</span>}
                      </div>
                    </div>
                    <div className="relative mt-6">
                      <label htmlFor="length" className="z-10 absolute -top-2 left-2 inline-block bg-white px-1 text-xs text-gray-600">
                        {i18n.t('episodeDetails.preparation.blockLength')}
                      </label>
                      <div className="relative mt-2 rounded-md shadow-sm">
                        <input
                          type="number"
                          name="length"
                          id="length"
                          className="block w-full rounded-md border-0 py-2 pr-12 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-500 sm:text-sm sm:leading-6"
                          min="1"
                          placeholder="1"
                          autoComplete="off"
                          value={blockLength}
                          onChange={(e) => setBlockLength(e.target.value)}
                        />
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                          <span className="text-gray-500 sm:text-sm" id="length-unit">
                            {i18n.t('episodeDetails.preparation.minutes')}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="relative mt-6">
                      <label htmlFor="blockContent" className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs text-gray-600">
                        {i18n.t('episodeDetails.preparation.blockContent')}
                      </label>
                      <div className="mt-2">
                        <textarea
                          rows={7}
                          name="blockContent"
                          id="blockContent"
                          className="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-500 sm:py-1.5 sm:text-sm sm:leading-6"
                          maxLength={BLOCK_CONTENT_MAX_LENGTH}
                          value={blockContent}
                          onChange={(e) => setBlockContent(e.target.value)}
                        />
                      </div>
                      <span className="text-gray-700 text-xs ml-1">{`${blockContent.length}/${BLOCK_CONTENT_MAX_LENGTH}`}</span>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 flex flex-row justify-end">
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm enabled:hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm disabled:bg-gray-300 disabled:text-gray-500 mx-2 enabled:focus:ring-primary-500"
                    onClick={handleClose}
                  >
                    {i18n.t('general.cancel')}
                  </button>
                  <button
                    type="button"
                    disabled={blockTitle.length === 0}
                    className="inline-flex justify-center rounded-md border border-transparent disabled:bg-gray-300 disabled:text-gray-500 bg-primary-500 px-4 py-2 text-base font-medium text-white shadow-sm enabled:hover:bg-primary-400 focus:outline-none focus:ring-2 enabled:focus:ring-primary-500 focus:ring-offset-2 sm:text-sm"
                    onClick={handleSave}
                  >
                    {i18n.t('general.save')}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
