import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import './index.css';

import App from './App';
import * as serviceWorker from './serviceWorker';
import { LayoutProvider } from './context/LayoutContext';
import { Provider as AuthProvider } from './context/AuthContext';
import AlertNotificationProvider from './context/AlertNotificationProvider';
import store from './store';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <LayoutProvider>
        <AuthProvider>
          <AlertNotificationProvider>
            <App />
          </AlertNotificationProvider>
        </AuthProvider>
      </LayoutProvider>
    </Provider>
  </React.StrictMode>,
);

if (process.env.REACT_APP_ENV === 'prod') {
  let headElements = document.getElementsByTagName('head');
  if (headElements.length > 0) {
    let scriptEl = document.createElement('script');
    scriptEl.setAttribute('src', 'https://plausible.io/js/script.js');
    scriptEl.setAttribute('defer', '');
    scriptEl.setAttribute('data-domain', 'app.sicross.com');
    headElements[0].appendChild(scriptEl);
  }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
