import React, { useState } from 'react';

import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import {
  HeadingNode, QuoteNode,
} from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import {
  ListItemNode,
  ListNode,
} from '@lexical/list';
import {
  CodeHighlightNode,
  CodeNode,
} from '@lexical/code';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
import { TRANSFORMERS } from '@lexical/markdown';
import classNames from 'classnames';
import { Prompt, Link } from 'react-router-dom';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { $getRoot } from 'lexical';

import ToolbarPlugin, { SAVE_STATES } from './plugins/ToolbarPlugin';
import AutoLinkPlugin from './plugins/AutoLinkPlugin';
import ClickableLinkPlugin from './plugins/ClickableLinkPlugin';
import RegisterTabPlugin from './plugins/RegisterTabPlugin';
import SavePlugin from './plugins/SavePlugin';
import ReloadEditorContentPlugin from './plugins/ReloadEditorContentPlugin';
import Spinner from '../Basic/Spinner';
import i18n from '../../i18n';

function Placeholder({ stateRecovered, editable, episodeId }) {
  return (
    <>
      {stateRecovered && !editable && (
      <div className="absolute top-0 left-0 max-w-[80%] my-2 pointer-events-none flex flex-row items-center">
        <p>
          {i18n.t('editor.emptyNotEditable1')}
          {' '}
          <Link className="text-primary-500 pointer-events-auto" to={`/app/episode/${episodeId}/preparation`}>{i18n.t('editor.emptyNotEditable2')}</Link>
          {' '}
          {i18n.t('editor.emptyNotEditable3')}
        </p>
      </div>
      )}
      <div className="absolute top-1 left-1 p-5 my-2 text-gray-600 pointer-events-none flex flex-row items-center">
        {stateRecovered && editable && i18n.t('editor.placeholder')}
        {!stateRecovered && (
        <>
          <Spinner className="border-primary-500" size={5} />
          <span className="ml-1 italic">{i18n.t('editor.reloadChanges')}</span>
        </>
        )}
      </div>

    </>
  );
}

const themeCss = {
  ltr: 'ltr',
  rtl: 'rtl',
  paragraph: 'leading-1 my-2',
  quote: 'border-l-[6px] pl-2 border-gray-300 block p-1 leading-5 my-2',
  heading: {
    h1: 'text-3xl font-bold pb-3',
    h2: 'text-2xl font-bold pb-2',
    h3: 'text-xl font-bold pb-2',
    h4: 'editor-heading-h4',
    h5: 'editor-heading-h5',
  },
  list: {
    nested: {
      listitem: 'list-none pl-4',
    },
    ol: 'list-decimal list-inside font-bold pl-1',
    ul: 'list-disc list-inside pl-2',
    listitem: 'font-normal leading-7',
  },
  image: 'editor-image',
  link: 'text-primary-500 hover:text-primary-700 underline cursor-pointer',
  text: {
    bold: 'font-bold',
    italic: 'italic',
    overflowed: 'editor-text-overflowed',
    hashtag: 'editor-text-hashtag',
    underline: 'underline',
    strikethrough: 'line-through',
    underlineStrikethrough: 'underline line-through',
    code: 'bg-gray-200 rounded',
  },
  code: 'bg-gray-200 rounded block p-1',
  codeHighlight: {
    atrule: 'editor-tokenAttr',
    attr: 'editor-tokenAttr',
    boolean: 'editor-tokenProperty',
    builtin: 'editor-tokenSelector',
    cdata: 'editor-tokenComment',
    char: 'editor-tokenSelector',
    class: 'editor-tokenFunction',
    'class-name': 'editor-tokenFunction',
    comment: 'editor-tokenComment',
    constant: 'editor-tokenProperty',
    deleted: 'editor-tokenProperty',
    doctype: 'editor-tokenComment',
    entity: 'editor-tokenOperator',
    function: 'editor-tokenFunction',
    important: 'editor-tokenVariable',
    inserted: 'editor-tokenSelector',
    keyword: 'editor-tokenAttr',
    namespace: 'editor-tokenVariable',
    number: 'editor-tokenProperty',
    operator: 'editor-tokenOperator',
    prolog: 'editor-tokenComment',
    property: 'editor-tokenProperty',
    punctuation: 'editor-tokenPunctuation',
    regex: 'editor-tokenVariable',
    selector: 'editor-tokenSelector',
    string: 'editor-tokenSelector',
    symbol: 'editor-tokenProperty',
    tag: 'editor-tokenProperty',
    url: 'editor-tokenOperator',
    variable: 'editor-tokenVariable',
  },
};

const editorConfig = {
  // The editor theme
  theme: themeCss,
  editable: false,
  // Handling of errors during update
  onError(error) {
    throw error;
  },
  // Any custom nodes go here
  nodes: [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    CodeNode,
    CodeHighlightNode,
    TableNode,
    TableCellNode,
    TableRowNode,
    AutoLinkNode,
    LinkNode,
  ],
};

export default function Editor({
  className, episodeId, setMarkdown, editable = true,
}) {
  const [saveState, setSaveState] = useState(SAVE_STATES.HIDE);
  const [isDirty, setIsDirty] = useState(false);
  const [stateRecovered, setStateRecovered] = useState(false);
  const [contentNotEmpty, setContentNotEmpty] = useState(false);

  const onChange = (editorState) => {
    editorState.read(() => {
      // Read the contents of the EditorState here.
      const content = $getRoot().getTextContent();
      if (content && content.length > 0) {
        setContentNotEmpty(true);
      } else {
        setContentNotEmpty(false);
      }
    });
  };

  return (
    <>
      <LexicalComposer initialConfig={editorConfig}>
        <div className={classNames('flex flex-col', className)}>
          {editable && <ToolbarPlugin showSaveState={saveState} />}
          <div className="relative">
            <RichTextPlugin
              contentEditable={<ContentEditable className={classNames('min-h-[450px] outline-none p-5 resize-none overflow-hidden text-ellipsis rounded-b text-black text-base', editable && 'border border-gray-300')} />}
              placeholder={<Placeholder stateRecovered={stateRecovered} episodeId={episodeId} editable={editable} />}
              ErrorBoundary={LexicalErrorBoundary}
            />
            <OnChangePlugin onChange={onChange} />
            <HistoryPlugin />
            <AutoFocusPlugin />
            <ListPlugin />
            <LinkPlugin />
            <ClickableLinkPlugin />
            <AutoLinkPlugin />
            <RegisterTabPlugin />
            <ReloadEditorContentPlugin episodeId={episodeId} setStateRecovered={setStateRecovered} editable={editable} />
            {editable && <SavePlugin setSaveState={setSaveState} setIsDirty={setIsDirty} stateRecovered={stateRecovered} episodeId={episodeId} setMarkdown={setMarkdown} setContentNotEmpty={setContentNotEmpty} />}
            <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
          </div>
        </div>
      </LexicalComposer>
      {contentNotEmpty && isDirty && <Prompt message="Unsaved changes are lost when you leave this page" />}
    </>
  );
}
