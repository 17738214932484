import React, {
  useState, useMemo, Fragment,
} from 'react';
import clsx from 'clsx';
import {
  useParams,
  Link,
} from 'react-router-dom';
import AudioProvider, { useAudioPlayer } from './components/AudioProvider';
import PlayerContainer from './components/PlayerContainer';
import FormattedDate from './components/FormattedDate';
import { AudioPlayer } from './components/player/AudioPlayer';
import defaultImage from '../../../../assets/img/image_placeholder.jpg';
import useShowDetails from '../../../../hooks/useShowDetails';
import i18n from '../../../../i18n';

function PlayPauseIcon({ playing, ...props }) {
  return (
    <svg aria-hidden="true" viewBox="0 0 10 10" fill="none" {...props}>
      {playing ? (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.496 0a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5H2.68a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5H1.496Zm5.82 0a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5H8.5a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5H7.316Z"
        />
      ) : (
        <path d="M8.25 4.567a.5.5 0 0 1 0 .866l-7.5 4.33A.5.5 0 0 1 0 9.33V.67A.5.5 0 0 1 .75.237l7.5 4.33Z" />
      )}
    </svg>
  );
}

function TinyWaveFormIcon({ colors = [], ...props }) {
  return (
    <svg aria-hidden="true" viewBox="0 0 10 10" {...props}>
      <path
        d="M0 5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V5Z"
        className={colors[0]}
      />
      <path
        d="M6 1a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1V1Z"
        className={colors[1]}
      />
    </svg>
  );
}

function AboutSection(props) {
  let [isExpanded, setIsExpanded] = useState(false);

  return (
    <section {...props}>
      <h2 className="flex items-center font-mono text-sm font-medium leading-7 text-slate-900">
        <TinyWaveFormIcon
          colors={['fill-primary-300', 'fill-gray-300']}
          className="h-2.5 w-2.5"
        />
        <span className="ml-2.5">{i18n.t('sharedShow.about')}</span>
      </h2>
      <p
        className={clsx(
          'mt-2 text-base leading-7 text-slate-700',
          !isExpanded && 'line-clamp-4',
        )}
      >
        {props.show.description}
      </p>
      {!isExpanded && (
        <button
          type="button"
          className="mt-2 text-sm font-bold leading-6 text-primary-500 hover:text-primary-700 active:text-primary-900 lg:inline-block"
          onClick={() => setIsExpanded(true)}
        >
          {i18n.t('sharedShow.showMore')}
        </button>
      )}
    </section>
  );
}

export default function SharedShow() {
  let { showId } = useParams();

  const [
    show,
    pagination,
  ] = useShowDetails(showId);

  let sharedShow = null;
  if (show == null) {
    sharedShow = (
      <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden opacity-75 flex flex-col items-center justify-center">
        <svg
          aria-hidden="true"
          className="w-10 h-10 mr-2 fill-primary-600 animate-spin"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentcolor"
            className="fill-primary-200"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="fill-primary-600"
          />
        </svg>
        <span className="sr-only">{i18n.t('sharedShow.loading')}</span>
      </div>
    );
  } else {
    sharedShow = (
      <>
        <AudioProvider>
          <div className="relative pb-10 bg-white flex h-screen min-h-screen flex-col overflow-y-auto">
            {/* 3 column wrapper */}

            <div className="mx-auto w-full max-w-7xl flex-grow lg:flex ">
              {/* Left sidebar & main wrapper */}
              <div className="bg-vivid-50 min-w-0 flex-1 lg:flex">
                <div className="border-b border-gray-200 bg-white lg:w-96 lg:flex-shrink-0 lg:border-b-0 lg:border-r lg:border-gray-200">
                  <div className="h-full py-6 pl-4 pr-6 sm:pl-6 xl:pl-10">
                    {/* Start left column area */}
                    <div className="relative h-full" style={{ minHeight: '20rem' }}>
                      <div className="relative mx-auto px-4 pb-4 pt-10 sm:px-6 md:max-w-2xl md:px-4 xl:px-8">
                        <Link
                          to={`/show/${show.showId}`}
                          className="relative mx-auto block w-48 overflow-hidden rounded-lg bg-slate-200 shadow-xl shadow-slate-200 sm:w-64 sm:rounded-xl"
                          aria-label="Homepage"
                        >
                          <img
                            className="w-full"
                            src={show.imagePath || defaultImage}
                            alt=""
                            sizes="(min-width: 1024px) 204rem, (min-width: 640px) 16rem, 12rem"
                          />
                          <div className="absolute inset-0 rounded-lg ring-1 ring-inset ring-black/10 sm:rounded-xl" />
                        </Link>
                        <div className="mt-10 text-center">
                          {show.channel && (
                            <span className="inline-flex items-center rounded-md bg-primary-600/10 px-2.5 py-0.5 mb-3 text-sm font-medium text-primary-600">
                              {show.channel.name}
                            </span>
                          )}
                          <p className="text-xl font-bold text-slate-900">
                            {show.name}
                          </p>
                        </div>
                        <AboutSection show={show} className="mt-12 lg:block" />
                        <section className="mt-10 lg:mt-12">
                          <h2 className="flex items-center font-mono text-sm font-medium leading-7 text-slate-900 lg:not-sr-only">
                            <TinyWaveFormIcon
                              colors={['fill-gray-300', 'fill-primary-300']}
                              className="h-2.5 w-2.5"
                            />
                            <span className="ml-2.5">{i18n.t('sharedShow.producedBy')}</span>
                          </h2>
                          <div className="mt-2">
                            <div className="h-px bg-gradient-to-r from-slate-200/0 via-slate-200 to-slate-200/0 lg:hidden" />
                            {show.organizationName && (
                              <div className="mb-1">
                                {show.organizationName}
                              </div>
                            )}
                            {show.owners
                              && show.owners.map((host) => (
                                <Fragment key={host.id}>
                                  <div className="flex flex-col mb-1">
                                    {host.firstName}
                                    {' '}
                                    {host.lastName}
                                  </div>

                                </Fragment>
                              ))}
                            {show.producers && show.producers.map((host) => (
                              <Fragment key={host.id}>
                                <div className="flex flex-col mb-1">
                                  {host.firstName}
                                  {' '}
                                  {host.lastName}
                                </div>
                              </Fragment>
                            ))}
                          </div>
                        </section>
                      </div>
                    </div>
                    {/* End left column area */}
                  </div>
                </div>
                <div className="bg-white lg:min-w-0 lg:flex-1">
                  <div className="h-full py-6 px-4 sm:px-6 lg:px-8">
                    {/* Start main area */}
                    <div className="relative h-full" style={{ minHeight: '36rem' }}>
                      <div className="pt-16 pb-12 sm:pb-4 lg:pt-12">
                        <PlayerContainer>
                          <h1 className="text-2xl font-bold leading-7 text-slate-900">
                            {i18n.t('sharedShow.episodes')}
                          </h1>
                        </PlayerContainer>
                        <div className="divide-y divide-slate-100 sm:mt-4 lg:mt-8 lg:border-t lg:border-slate-100">
                          {pagination.pageEpisodes.filter((episode) => episode.status === 'published').map((episode) => (
                            <EpisodeEntry key={episode.episodeId} episode={episode} />
                          ))}
                        </div>
                      </div>
                    </div>
                    {/* End main area */}
                  </div>
                </div>
              </div>
            </div>
            <div className="fixed inset-x-0 bottom-0 z-10">
              <AudioPlayer />
            </div>
          </div>
        </AudioProvider>
      </>
    );
  }

  return (
    <>
      {sharedShow}
    </>
  );
}

function EpisodeEntry({ episode }) {
  let date = new Date(episode.publishDate);

  let audioPlayerData = useMemo(
    () => ({
      episodeId: episode.episodeId,
      title: episode.name,
      audio: {
        src: episode.podcastPath,
        type: 'audio/mpeg',
      },
    }),
    [episode],
  );
  let player = useAudioPlayer(audioPlayerData);

  return (
    <article
      aria-labelledby={`episode-${episode.episodeId}-title`}
      className="py-10 sm:py-12"
    >
      <PlayerContainer>
        <div className="flex flex-col items-start">

          <Link to={`/episode/${episode.episodeId}`}>
            <h2
              id={`episode-${episode.episodeId}-title`}
              className="mt-2 text-lg font-bold text-slate-900"
            >
              {episode.name}
            </h2>
          </Link>

          <FormattedDate
            date={date}
            className="order-first font-mono text-sm leading-7 text-slate-500"
          />
          <p className="mt-1 text-base leading-7 text-slate-700 line-clamp-4">
            {episode.description}
          </p>
          <div className="mt-4 flex items-center gap-4">
            <button
              type="button"
              onClick={() => player.toggle()}
              className="flex items-center text-sm font-bold leading-6 text-primary-500 hover:text-primary-700 active:text-primary-900"
              aria-label={`${player.playing ? 'Pause' : 'Play'} episode ${episode.name}`}
            >
              <PlayPauseIcon
                playing={player.playing}
                className="h-2.5 w-2.5 fill-current"
              />
              <span className="ml-3" aria-hidden="true">
                {i18n.t('sharedShow.listen')}
              </span>
            </button>
            <span
              aria-hidden="true"
              className="text-sm font-bold text-slate-400"
            >
              /
            </span>
            <Link
              to={`/episode/${episode.episodeId}`}
              className="flex items-center text-sm font-bold leading-6 text-primary-500 hover:text-primary-700 active:text-primary-900"
              aria-label={`Show notes for episode ${episode.title}`}
            >
              {i18n.t('sharedShow.showDescription')}
            </Link>
          </div>
        </div>
      </PlayerContainer>
    </article>
  );
}
