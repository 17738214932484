import Amplify, { Auth, Hub, Cache } from 'aws-amplify';
import createLogger from './Logger';
import Config from '../config';

let logger = createLogger('AuthHelper');

const configObject = {
  Auth: {
    region: 'eu-central-1',
    userPoolId: Config.USER_POOL_ID,
    userPoolWebClientId: Config.USER_POOL_CLIENT,
    oauth: {
      domain: Config.USER_POOL_DOMAIN,
      scope: ['email', 'profile', 'openid'],
      redirectSignIn: Config.LOGIN_REDIRECT_URI,
      redirectSignOut: Config.LOGOUT_REDIRECT_URI,
      responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
};

export const AUTH_PROVIDER_NAME_CACHE_KEY = 'AUTH_PROVIDER_NAME';

Amplify.configure(configObject);
Auth.configure();

Hub.listen('auth', async (data) => {
  logger.debug('AUTH EVENT', data);
  if (data.payload.event === 'signIn_failure'
    && data.payload.data.message.includes('Already+found+an+entry+for+username')) {
    logger.debug('Another round of fed sign in');
    // second round of fed sign in
    let providerName = await Cache.getItem(AUTH_PROVIDER_NAME_CACHE_KEY);
    Auth.federatedSignIn({
      provider: providerName,
    });
  }
});

export default Auth;
