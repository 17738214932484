/* eslint-disable jsx-a11y/label-has-associated-control */
import { Dialog, Switch, Transition } from '@headlessui/react';
import {
  ChevronRightIcon,
  MagnifyingGlassIcon,
  TrashIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import classNames from 'classnames';
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ChannelAPI from '../../api/ChannelApi';
import Spinner from '../../components/Basic/Spinner';
import WarningModal from '../../components/WarningModal/WarningModal';
import { defaultErrorHandler } from '../../errors/ApplicationError';
import useAlertNotification from '../../hooks/useAlertNotification';
import i18n from '../../i18n';
import UserSearch from '../Shows/components/UserSearch';

const GROUP_NAME_MAX_LENGTH = 100;

const groupsApi = new ChannelAPI();

function CreateGroupModal({
  showCreateGroupDialog, handleClose, handleSave,
}) {
  const [groupName, setGroupName] = useState('');
  const [allowError, setAllowError] = useState(false);

  useEffect(() => {
    setGroupName('');
    setAllowError(false);
  }, [showCreateGroupDialog]);

  return (
    <Transition.Root show={showCreateGroupDialog} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-70 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0 focus:ring-offset-2"
                    onClick={handleClose}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div>
                  <div>
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                      {i18n.t('groups.createDialog.title')}
                    </Dialog.Title>
                    <div className="relative mt-5">
                      <label
                        htmlFor="groupName"
                        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs text-gray-600"
                      >
                        {i18n.t('groups.createDialog.groupName')}
                      </label>
                      <input
                        type="text"
                        name="groupName"
                        id="groupName"
                        className={classNames(
                          'block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-500 text-sm sm:leading-6',
                          groupName.length === 0 && allowError && 'focus:ring-red-500',
                        )}
                        value={groupName}
                        maxLength={GROUP_NAME_MAX_LENGTH}
                        onChange={(e) => {
                          setGroupName(e.target.value);
                          setAllowError(true);
                        }}
                        autoComplete="off"
                      />
                      <div>
                        <span className={classNames('text-xs ml-1', groupName.length === 0 && allowError ? 'text-red-600' : 'text-gray-700')}>{`${groupName.length}/${GROUP_NAME_MAX_LENGTH}`}</span>
                        {groupName.length === 0 && allowError && <span className="text-xs ml-1 text-red-600">{i18n.t('groups.createDialog.groupNameNotEmpty')}</span>}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 flex flex-row justify-end">
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm enabled:hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm disabled:bg-gray-300 disabled:text-gray-500 mx-2 enabled:focus:ring-primary-500"
                    onClick={handleClose}
                  >
                    {i18n.t('general.cancel')}
                  </button>
                  <button
                    type="button"
                    disabled={groupName.length === 0}
                    className="inline-flex justify-center rounded-md border border-transparent disabled:bg-gray-300 disabled:text-gray-500 bg-primary-500 px-4 py-2 text-base font-medium text-white shadow-sm enabled:hover:bg-primary-400 focus:outline-none focus:ring-2 enabled:focus:ring-primary-500 focus:ring-offset-2 sm:text-sm"
                    onClick={() => handleSave(groupName)}
                  >
                    {i18n.t('general.save')}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

const GROUP_ROLE_ADMIN = 1;
const GROUP_ROLE_MEMBER = 2;

function SlideOver({
  open, setOpen, selectedGroup: selectedGroupIn, setShowBackdrop, refreshGroups,
}) {
  const { addError } = useAlertNotification();
  const [selectedGroup, setSelectedGroup] = useState(selectedGroupIn);

  useEffect(() => {
    setSelectedGroup(selectedGroupIn);
  }, [selectedGroupIn]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-30" onClose={setOpen}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="p-6">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-lg font-medium text-gray-900">{selectedGroup?.channelName}</Dialog.Title>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-primary-500"
                            onClick={() => setOpen(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col justify-between py-4 px-2 w-full">
                      <UserSearch
                        placeholder={i18n.t('groups.slideOver.addMember')}
                        onUserSelection={async (user) => {
                          let newMembers = [
                            ...selectedGroup.members,
                            {
                              id: user.objectID,
                              firstName: user.firstName,
                              lastName: user.lastName,
                              role: GROUP_ROLE_MEMBER,
                            }];
                          try {
                            setShowBackdrop(true);
                            await groupsApi.updateChannel(
                              selectedGroup.channelId,
                              selectedGroup.channelName,
                              '',
                              newMembers,
                            );
                            let refreshedGroup = await groupsApi.getChannel(selectedGroup.channelId);
                            setSelectedGroup({
                              ...selectedGroup,
                              members: refreshedGroup.members,
                            });
                          } catch (e) {
                            defaultErrorHandler(e, addError);
                          } finally {
                            setShowBackdrop(false);
                          }
                          refreshGroups();
                        }}
                      />
                    </div>
                    <ul className="flex-1 divide-y divide-gray-200 overflow-y-auto">
                      {selectedGroup?.members.map((member) => (
                        <li key={member.id}>
                          <div className="relative flex items-center py-6 px-5">
                            <div className="relative flex min-w-0 flex-1 items-center">
                              <span className="relative inline-block flex-shrink-0">
                                <div key={member.id} className="inline-flex h-10 w-10 rounded-full bg-gray-400 justify-center items-center">
                                  <span className="text-base text-white">{`${member.firstName.charAt(0)}${member.lastName.charAt(0)}`}</span>
                                </div>
                              </span>
                              <p className="ml-4 truncate text-sm font-medium text-gray-900">{`${member.firstName} ${member.lastName}`}</p>
                            </div>
                            <Switch
                              checked={member.role === GROUP_ROLE_ADMIN}
                              onChange={async () => {
                                let newMemberRole = member.role === GROUP_ROLE_ADMIN ? GROUP_ROLE_MEMBER : GROUP_ROLE_ADMIN;
                                let memberIdx = selectedGroup.members.findIndex((m) => m.id === member.id);
                                if (memberIdx === -1) {
                                  return;
                                }
                                let newMembers = [...selectedGroup.members];
                                newMembers[memberIdx].role = newMemberRole;

                                // sanity check, that we have at least one admin, else ignore the action
                                let hasAdmin = newMembers.some((m) => m.role === GROUP_ROLE_ADMIN);
                                if (!hasAdmin) {
                                  return;
                                }
                                try {
                                  setShowBackdrop(true);
                                  await groupsApi.updateChannel(
                                    selectedGroup.channelId,
                                    selectedGroup.channelName,
                                    '',
                                    newMembers,
                                  );
                                  let refreshedGroup = await groupsApi.getChannel(selectedGroup.channelId);
                                  setSelectedGroup({
                                    ...selectedGroup,
                                    members: refreshedGroup.members,
                                  });
                                } catch (e) {
                                  defaultErrorHandler(e, addError);
                                } finally {
                                  setShowBackdrop(false);
                                }
                                refreshGroups();
                              }}
                              className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                            >
                              <span className="sr-only">Admin</span>
                              <span aria-hidden="true" className="pointer-events-none absolute h-full w-full rounded-md bg-white" />
                              <span
                                aria-hidden="true"
                                className={classNames(
                                  member.role === GROUP_ROLE_ADMIN ? 'bg-primary-500' : 'bg-gray-200',
                                  'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out',
                                )}
                              />
                              <span
                                aria-hidden="true"
                                className={classNames(
                                  member.role === GROUP_ROLE_ADMIN ? 'translate-x-5' : 'translate-x-0',
                                  'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out',
                                )}
                              />
                            </Switch>
                            <span className="text-gray-500 ml-1 mr-2">{i18n.t('groups.slideOver.admin')}</span>
                            <button
                              type="button"
                              className="p-2 rounded-full hover:bg-gray-200 active:bg-gray-300"
                              onClick={async () => {
                                let newMembers = selectedGroup.members.filter((m) => m.id !== member.id);
                                try {
                                  setShowBackdrop(true);
                                  await groupsApi.updateChannel(
                                    selectedGroup.channelId,
                                    selectedGroup.channelName,
                                    '',
                                    newMembers,
                                  );
                                  let refreshedGroup = await groupsApi.getChannel(selectedGroup.channelId);
                                  setSelectedGroup({
                                    ...selectedGroup,
                                    members: refreshedGroup.members,
                                  });
                                } catch (e) {
                                  defaultErrorHandler(e, addError);
                                } finally {
                                  setShowBackdrop(false);
                                }
                                refreshGroups();
                              }}
                            >
                              <span className="sr-only">Remove user from Channel</span>
                              <TrashIcon className="h-5 w-5 text-primary-500" />
                            </button>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default function GroupsPage() {
  const [showDeleteWarningDialog, setShowDeleteWarningDialog] = useState(false);
  const [slideOverOpen, setSlideOverOpen] = useState(false);
  const [showCreateGroupDialog, setShowCreateGroupDialog] = useState(false);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [groupToDelete, setGroupToDelete] = useState(null);
  const [groups, setGroups] = useState(null);
  const [searchValue, setSearchValue] = useState('');

  const user = useSelector((state) => state.user.user);

  const { addError, addSuccess } = useAlertNotification();

  const refreshGroups = async () => {
    setGroups(null);
    groupsApi.getChannels(GROUP_ROLE_ADMIN)
      .then((g) => {
        setGroups(g);
      }).catch((e) => defaultErrorHandler(e, addError));
  };

  useEffect(() => {
    refreshGroups();
  }, []);

  return (
    <div className="py-6 h-full flex">
      <div className="flex-1 ml-10 overflow-hidden relative">
        <div className="absolute inset-0 overflow-auto pl-1 pr-4">
          <h3 className="text-xl font-bold">{i18n.t('showDetails.audience.groups')}</h3>
          <div className="mt-8 flex flex-col items-center">
            <div className="flex justify-between items-baseline pr-4 max-w-2xl w-full">
              <div className="relative mt-1 rounded-md shadow-sm w-1/3">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
                <input
                  type="text"
                  name="searchUser"
                  id="searchUser"
                  className="block w-full rounded-md hover:border-gray-400 border-gray-300 pl-10 focus:border-primary-500 focus:ring-primary-500 text-sm"
                  placeholder={i18n.t('groups.searchGroups')}
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                  }}
                />
              </div>
              <button
                type="button"
                // disabled={show == null || show.channel == null}
                onClick={() => setShowCreateGroupDialog(true)}
                className="px-3 py-1 disabled:bg-gray-300 disabled:text-gray-500 rounded bg-primary-500 hover:bg-primary-400 active:bg-primary-600 text-white text-base"
              >
                {i18n.t('groups.create')}
              </button>
            </div>
            <div className="overflow-visible inline-block max-w-2xl py-2 align-middle pr-4 w-full">
              <div className="overflow-visible shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="w-full divide-y divide-gray-300 table-auto">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                      >
                        {i18n.t('groups.group')}
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                      >
                        <span className="sr-only">Member List</span>
                      </th>
                      <th
                        scope="col"
                        className="text-right w-0 text-xs font-medium uppercase tracking-wide text-gray-500"
                      >
                        <span className="sr-only">Delete</span>
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 w-0"
                      >
                        <span className="sr-only">Open Member List</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white pointer-events-auto">
                    {groups == null && <tr><td colSpan="5"><div className="flex justify-center my-4"><Spinner size={6} color="primary-500" className="self-center" /></div></td></tr>}
                    {groups && groups.filter((group) => group.channelName.toLowerCase().includes(searchValue.toLowerCase())).map((group) => (
                      <tr
                        key={group.channelId}
                        className="hover:bg-gray-100 cursor-pointer pointer-events-auto"
                        onClick={() => {
                          setSelectedGroup(group);
                          setSlideOverOpen(true);
                        }}
                      >
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900 font-medium truncate max-w-[20rem] min-w-[5rem]" title={group.channelName}>{group.channelName}</td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right flex justify-end items-center min-w-max">
                          <div className="isolate flex -space-x-1 overflow-hidden shrink-0">
                            {group.members.slice(0, 5).map((member, i, a) => (
                              <div key={member.id} className={`relative z-${a.length * 10 - i * 10} inline-flex h-6 w-6 rounded-full ring-2 ring-white bg-gray-400 justify-center items-center`}>
                                <span className="text-xs text-white">{`${member.firstName.charAt(0)}${member.lastName.charAt(0)}`}</span>
                              </div>
                            ))}
                          </div>
                          {group.members.length > 5 && (<span className="tracking-tight pl-2">{i18n.t('groups.moreMembers', { moreCount: group.members.length - 5 })}</span>)}
                        </td>
                        <td className="py-1 pl-10 text-right">
                          <button
                            type="button"
                            className="p-2 rounded-full hover:bg-gray-200 active:bg-gray-300"
                            onClick={(e) => {
                              e.stopPropagation();
                              setGroupToDelete(group.channelId);
                              setShowDeleteWarningDialog(true);
                            }}
                          >
                            <span className="sr-only">Delete Block</span>
                            <TrashIcon className="h-4 w-4 text-primary-500" />
                          </button>
                        </td>
                        <td className="px-3 py-1 pl-4 text-right">
                          <ChevronRightIcon className="h-4 w-4 text-gray-400" />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <SlideOver
            open={slideOverOpen}
            setOpen={setSlideOverOpen}
            selectedGroup={selectedGroup}
            setShowBackdrop={setShowBackdrop}
            refreshGroups={refreshGroups}
          />
          <CreateGroupModal
            showCreateGroupDialog={showCreateGroupDialog}
            handleClose={() => setShowCreateGroupDialog(false)}
            handleSave={async (groupName) => {
              if (groupName.length === 0 || groupName.length > GROUP_NAME_MAX_LENGTH) {
                return;
              }

              try {
                setShowBackdrop(true);
                let createdGroup = await groupsApi.createChannel(groupName, '', []);
                setSelectedGroup({
                  ...createdGroup,
                  members: [{
                    id: createdGroup.user,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    role: GROUP_ROLE_ADMIN,
                  }],
                });
                setSlideOverOpen(true);
                refreshGroups();
                setShowCreateGroupDialog(false);
              } catch (e) {
                defaultErrorHandler(e, addError);
              } finally {
                setShowBackdrop(false);
              }
            }}
          />
          <WarningModal
            closeWarningModal={() => setShowDeleteWarningDialog(false)}
            handleWarningModal={async () => {
              setShowDeleteWarningDialog(false);
              setShowBackdrop(true);
              try {
                await groupsApi.deleteChannel(groupToDelete);
                addSuccess(i18n.t('notifications.deletedChannelSuccessfully'));
                refreshGroups();
              } catch (e) {
                defaultErrorHandler(e, addError);
              } finally {
                setShowBackdrop(false);
              }
            }}
            openWarningModal={showDeleteWarningDialog}
            title={i18n.t('groups.deleteDialog.title')}
            contentText={i18n.t('groups.deleteDialog.content')}
            positiveButtonText={i18n.t('groups.deleteDialog.buttonText')}
            colorScheme="red"
            buttonColorScheme="red"
          />
          {showBackdrop && (
          <div className="fixed w-full h-full flex justify-center items-center inset-0 bg-black opacity-70 z-50">
            <Spinner size={6} className="border-primary-500" />
          </div>
          )}
        </div>
      </div>
    </div>
  );
}
