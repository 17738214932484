import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import ApplicationRoles from '../../util/ApplicationRoles';
import Logo from '../../components/Basic/Logo';
import UserButton from '../../layouts/components/UserButton';
import i18n from '../../i18n';

export default function ListenerFallback() {
  let userRoles = useSelector((s) => s.user.roles);
  const history = useHistory();

  // redirect user to dashboard screen if he got the broadcast all permission
  if (userRoles !== null && (userRoles[ApplicationRoles.BROADCAST_ALL] || userRoles[ApplicationRoles.USER_ADMIN])) {
    history.replace('/app/dashboard');
  }

  return (
    <div className="h-[100vh]">
      <div className="h-full flex bg-white flex-col sm:flex-row">
        <div className="flex flex-row sm:flex-col">
          <div className="flex flex-grow flex-row sm:flex-col overflow-y-visible bg-white pt-5 justify-between items-center mt-4 sm:mt-0">
            <div className="flex flex-shrink-0 items-center px-4 mb-6">
              <div className="flex flex-1">
                <div className="flex-1"><Logo /></div>
              </div>
            </div>
            <div className="mb-4 ml-4 sm:mt-8">
              <UserButton />
            </div>
          </div>
        </div>
        <div className="flex flex-1 flex-col overflow-auto">
          <main className="flex-1 flex flex-col items-center px-10">
            <h3 className="text-4xl font-bold sm:pt-44 pt-16">{i18n.t('listenerFallback.title')}</h3>
            <p className="py-4 font-semibold">{i18n.t('listenerFallback.subtitle')}</p>
            <p className="max-w-xl py-4">
              {i18n.t('listenerFallback.resolution1')}
              {' '}
              <a href="mailto:support@sicross.com" target="_blank" rel="noreferrer" className="text-primary-700 underline">support@sicross.com</a>
              {' '}
              {i18n.t('listenerFallback.resolution2')}
            </p>
            <p className="max-w-xl">{i18n.t('listenerFallback.appInfo')}</p>
            <div className="flex flex-col sm:flex-row items-end pt-8 sm:pt-4">
              <a
                href="https://apps.apple.com/us/app/si-cross/id1510591844?itsct=apps_box&itscg=30200"
                style={{
                  display: 'inline-block',
                  overflow: 'hidden',
                  borderRadius: '13px',
                  width: '250px',
                  height: '83px',
                }}
              >
                <img
                  src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-US?size=250x83&releaseDate=1601337600&h=897620c50c8103744446d363eb8bd40d"
                  alt="Download on the App Store"
                  style={{
                    borderRadius: '13px',
                    width: '250px',
                    height: '68px',
                  }}
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.simobile&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
              >
                <img
                  alt="Get it on Google Play"
                  src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                  style={{
                    width: '250px',
                  }}
                />
              </a>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}
