import { Dialog, Transition } from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import React, { Fragment, useRef } from 'react';

import i18n from '../../i18n';

export default function WarningModal({
  openWarningModal,
  closeWarningModal,
  handleWarningModal,
  title,
  contentText,
  positiveButtonText,
  icon,
  colorScheme = 'yellow',
  buttonColorScheme = 'primary',
  showCancelButton = true,
}) {
  const cancelButtonRef = useRef(null);

  let RenderIcon = icon || ExclamationTriangleIcon;

  return (
    <Transition.Root show={openWarningModal} as={Fragment}>
      <Dialog as="div" className="relative z-50" initialFocus={cancelButtonRef} onClose={closeWarningModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-70 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className={classNames(
                    'mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full sm:mx-0 sm:h-10 sm:w-10',
                    colorScheme === 'primary' && 'bg-primary-100',
                    colorScheme === 'yellow' && 'bg-yellow-100',
                    colorScheme === 'red' && 'bg-red-100',
                    colorScheme === 'green' && 'bg-green-100',
                  )}
                  >
                    <RenderIcon
                      className={classNames(
                        'h-6 w-6',
                        colorScheme === 'primary' && 'text-primary-600',
                        colorScheme === 'yellow' && 'text-yellow-600',
                        colorScheme === 'red' && 'text-red-600',
                        colorScheme === 'green' && 'text-green-600',
                      )}
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                      {title}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        {contentText}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className={classNames(
                      'inline-flex w-full justify-center rounded-md border border-transparent px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm',
                      buttonColorScheme === 'primary' && 'focus:ring-primary-500 hover:bg-primary-700 bg-primary-500',
                      buttonColorScheme === 'yellow' && 'focus:ring-yellow-500 hover:bg-yellow-700 bg-yellow-500',
                      buttonColorScheme === 'red' && 'focus:ring-red-500 hover:bg-red-700 bg-red-600',
                    )}
                    onClick={() => handleWarningModal()}
                  >
                    {positiveButtonText || i18n.t('general.ok')}
                  </button>
                  {showCancelButton && (
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => closeWarningModal(false)}
                    ref={cancelButtonRef}
                  >
                    {i18n.t('general.cancel')}
                  </button>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
