import { API } from './api';

export default class TenantApi {
  constructor() {
    this.api = new API();
  }

  async acceptInvite(code) {
    let data = await this.api.create('space/invite/accept', {
      code,
    });

    return data.data;
  }

  async inviteUsers(emails) {
    await this.api.create('space/invite', { emails });
  }

  async removeInvite(email) {
    await this.api.delete('space/invite', { customParamMap: { email } });
  }

  async updateTenantAssignmentInactive(userId) {
    await this.api.delete(`space/assignment/${userId}`);
  }

  async getUserTenants() {
    let data = await this.api.read('space/assignment');

    return data.data;
  }

  async switchTenant(tenantId) {
    await this.api.create('space/switch', { tenantId });
  }

  async getTenantHome(home) {
    let data = await this.api.read('tenantHomeDiscovery', {
      customParamMap: {
        home,
      },
    });

    return data.data;
  }

  async getCanCreateChannel() {
    let data = await this.api.read('space/plan/channel');

    return data.data;
  }

  async getCanCreateShow(isExternal) {
    let data = await this.api.read(`space/plan/${isExternal ? 'extShow' : 'intShow'}`);

    return data.data;
  }

  async getCanCreateUser() {
    let data = await this.api.read('space/plan/user');

    return data.data;
  }

  async getPlan() {
    let data = await this.api.read('space/plan');

    return data.data;
  }

  async getIsTenantReady() {
    let data = await this.api.read('space/status');

    return data.data;
  }

  async stripeCheckout(plan) {
    let data = await this.api.create('billing/checkout', { plan });

    return data.data;
  }

  async stripeCustomerPortal() {
    let data = await this.api.create('billing/portal');

    return data.data;
  }

  async contactEnterprisePlan(message) {
    let data = await this.api.create('billing/contact', { message });

    return data.data;
  }
}
