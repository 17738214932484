import { API } from './api';

export default class AnalyticsApi {
  constructor() {
    this.api = new API();
  }

  async postAnalyticsQuery(queryType, filters) {
    let postBody = {
      queryType, filters,
    };
    let data = await this.api.create('analytics', postBody);
    return data.data;
  }
}
