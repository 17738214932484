/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect } from 'react';
import {
  Link,
  Redirect,
  Route,
  Switch,
  useHistory,
} from 'react-router-dom';
import { Cache } from 'aws-amplify';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { Menu, Transition } from '@headlessui/react';
import moment from 'moment';
import { ChevronRightIcon, HomeIcon, PlusIcon } from '@heroicons/react/24/outline';

import ApplicationRoles from '../util/ApplicationRoles';

// pages
import CreateShow from '../pages/Shows/components/CreateShow/CreateShow';
import ShowDetails from '../pages/Shows/components/ShowDetails/ShowDetails';
import Analytics from '../pages/Analytics/Analytics';
import UserManagement from '../pages/UserManagement/UserManagement';
// context
import NavBar from './NavBar';
import Notification from '../components/AlertNotification/Notification';
import EpisodeDetails from '../pages/Episodes/EpisodeDetails';
import GroupsPage from '../pages/Channels/GroupsPage';
import AcceptInvite from '../pages/AcceptInvite/AcceptInvite';
import useTenantSettings from '../hooks/useTenantSettings';
import i18n from '../i18n';
import Dashboard from '../pages/Dashboard/Dashboard';
import Billing from '../pages/Billing/Billing';

export default function Layout() {
  const { plan, planEndDate } = useTenantSettings();
  let userRoles = useSelector((s) => s.user.roles);
  let isBillingOwner = useSelector((s) => s.user.user.isBillingOwner);
  const navBarState = useSelector((s) => s.navbar);
  const history = useHistory();

  // redirect user to "listener" screen if he does not have the broadcast all permission
  if (userRoles !== null && !userRoles[ApplicationRoles.BROADCAST_ALL] && !userRoles[ApplicationRoles.USER_ADMIN]) {
    history.replace('/listener');
  }

  useEffect(() => {
    Cache.removeItem('loginRedirectUrl');
  }, []);

  return (
    <>
      <div className="h-[100vh]">
        {/* TODO remove this item and set html, body and app-div to h-full ??? */}
        <div className="h-full flex bg-white">
          <NavBar />
          {/* Top bar with Add Button */}
          <div className="flex flex-1 flex-col overflow-auto">
            <div className="sticky top-0 z-10 flex h-16 flex-shrink-0 bg-white">
              <div className="flex flex-1 justify-between px-4">
                <ol className="flex items-center space-x-4">
                  {navBarState.breadcrumbShow && (
                    <>
                      <li>
                        <div>
                          <Link to="/app/dashboard" className="text-gray-400 hover:text-gray-500">
                            <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
                            <span className="sr-only">Home</span>
                          </Link>
                        </div>
                      </li>
                      {[navBarState.breadcrumbShow, navBarState.breadcrumbEpisode].map((bc) => (
                        bc && (
                          <li key={bc.id}>
                            <div className="flex items-center">
                              <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                              <Link
                                to={bc.href}
                                className="ml-4 text-sm font-normal text-gray-500 hover:text-primary-500"
                              >
                                {bc.name}
                              </Link>
                            </div>
                          </li>
                        )
                      ))}
                    </>
                  )}
                </ol>
                <div className="relative ml-4 flex items-center md:ml-6">
                  {plan !== 2 && (
                  <div className="flex flex-row items-center">
                    {plan === 0 && (
                    <span className="py-1 px-2 rounded-lg bg-primary-200 text-primary-900 text-sm">
                      {moment(planEndDate).diff(moment(), 'days') < 0 ? i18n.t('plan.free') : i18n.t('plan.trial', { endDate: moment(planEndDate).format('MMM DD, YYYY') })}
                    </span>
                    )}
                    {isBillingOwner && (
                    <Link
                      to="/app/billing"
                      className="ml-2 px-2 py-1 disabled:bg-gray-300 disabled:text-gray-500 rounded bg-primary-500 hover:bg-primary-400 active:bg-primary-600 text-white text-sm"
                    >
                      {i18n.t('plan.upgrade')}
                    </Link>
                    )}
                  </div>
                  )}
                  <Menu as="div" className="relative ml-3">
                    <div>
                      <Menu.Button className="rounded-full bg-primary-500 p-2 text-white hover:bg-primary-400 active:bg-primary-600">
                        <span className="sr-only">Add</span>
                        <PlusIcon className="h-6 w-6" aria-hidden="true" />

                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/app/show/create"
                              className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700')}
                            >
                              Create Show
                            </Link>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>

            <main className="flex-1">
              <Switch>
                {isBillingOwner ? (
                  <Route path="/app/billing" component={Billing} />
                ) : null}
                <Route path="/app/dashboard" component={Dashboard} />
                <Route path="/app/show/create" component={CreateShow} />
                {userRoles[ApplicationRoles.READ_ANALYTICS]
                  ? <Route path="/app/analytics" component={Analytics} /> : null}
                <Route exact path="/app/show/:showId" render={(routeProps) => <Redirect to={`/app/show/${routeProps.match.params.showId}/overview`} />} />
                <Route path="/app/show/:showId" component={ShowDetails} />
                <Route exact path="/app/episode/:episodeId" render={(routeProps) => <Redirect to={`/app/episode/${routeProps.match.params.episodeId}/overview`} />} />
                <Route path="/app/episode/:episodeId" component={EpisodeDetails} />
                <Route path="/app/groups" component={GroupsPage} />
                <Route path="/app/acceptInvite" component={AcceptInvite} />
                {userRoles[ApplicationRoles.USER_ADMIN] ? (
                  <Route path="/app/users" component={UserManagement} />
                ) : null}
              </Switch>
            </main>
          </div>
        </div>
      </div>
      <Notification />
    </>
  );
}
