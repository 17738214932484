const {
  REACT_APP_APP_URL: APP_URL,
  REACT_APP_API_URL: API_URL,
  REACT_APP_API_WS_URL: API_WS_URL,
  REACT_APP_LOG_LEVEL: LOG_LEVEL,
  REACT_APP_USER_POOL_ID: USER_POOL_ID,
  REACT_APP_USER_POOL_CLIENT: USER_POOL_CLIENT,
  REACT_APP_USER_POOL_DOMAIN: USER_POOL_DOMAIN,
} = process.env;

const config = {
  API_URL,
  API_WS_URL,
  LOGIN_REDIRECT_URI: `${APP_URL}/loginCallback`,
  LOGOUT_REDIRECT_URI: APP_URL,
  LOG_LEVEL,
  USER_POOL_ID,
  USER_POOL_CLIENT,
  USER_POOL_DOMAIN,
};

export default {
  // Add common config values here
  ...config,
};
