import { useEffect, useState } from 'react';

import webSocketApi from '../util/WebSocketApi';

export default function useWebSocketApi() {
  const [lastJsonMessage, setLastJsonMessage] = useState(null);
  useEffect(() => {
    let handlerIdx = webSocketApi.addOnMessageHandler((data) => {
      setLastJsonMessage(data);
    });
    return () => webSocketApi.removeOnMessageHandler(handlerIdx);
  }, []);

  return { sendJsonMessage: (data) => webSocketApi.sendJson(data), lastJsonMessage };
}
