import { Listbox } from '@headlessui/react';
import {
  ArrowUturnLeftIcon,
  ArrowUturnRightIcon,
  Bars3BottomLeftIcon,
  CheckIcon,
  ChevronDownIcon,
} from '@heroicons/react/24/outline';
import { $createCodeNode } from '@lexical/code';
import {
  $isListNode,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  ListNode,
  REMOVE_LIST_COMMAND,
} from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $createHeadingNode, $createQuoteNode, $isHeadingNode } from '@lexical/rich-text';
import { $wrapNodes } from '@lexical/selection';
import { $getNearestNodeOfType, mergeRegister } from '@lexical/utils';
import classNames from 'classnames';
import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  COMMAND_PRIORITY_LOW,
  FORMAT_TEXT_COMMAND,
  REDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  UNDO_COMMAND,
} from 'lexical';
import React from 'react';
import i18n from '../../../i18n';
import Spinner from '../../Basic/Spinner';

const supportedBlockTypes = [
  'paragraph',
  'h1',
  'h2',
  'h3',
  'ul',
  'ol',
  'quote',
  'code',
];

const blockTypeToBlockName = {
  h1: i18n.t('editor.heading1'),
  h2: i18n.t('editor.heading2'),
  h3: i18n.t('editor.heading3'),
  h4: i18n.t('editor.heading4'),
  h5: i18n.t('editor.heading5'),
  ol: i18n.t('editor.ol'),
  ul: i18n.t('editor.ul'),
  paragraph: i18n.t('editor.text'),
  quote: i18n.t('editor.quote'),
  code: i18n.t('editor.code'),
};

const blockTypeToNode = {
  h1: () => $createHeadingNode('h1'),
  h2: () => $createHeadingNode('h2'),
  h3: () => $createHeadingNode('h3'),
  h4: () => $createHeadingNode('h4'),
  h5: () => $createHeadingNode('h5'),
  paragraph: $createParagraphNode,
  quote: $createQuoteNode,
  code: $createCodeNode,
};
export const SAVE_STATES = {
  HIDE: 0,
  SAVING: 1,
  SAVE_FINISHED: 2,
};

export default function ToolbarPlugin({ showSaveState }) {
  const [editor] = useLexicalComposerContext();
  const [isBold, setIsBold] = React.useState(false);
  const [isItalic, setIsItalic] = React.useState(false);
  const [isStrikethrough, setIsStrikethrough] = React.useState(false);
  const [isUnderline, setIsUnderline] = React.useState(false);
  const [canUndo, setCanUndo] = React.useState(false);
  const [canRedo, setCanRedo] = React.useState(false);
  const [blockType, setBlockType] = React.useState('paragraph');

  const updateToolbar = React.useCallback(() => {
    const selection = $getSelection();

    if ($isRangeSelection(selection)) {
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
      setIsStrikethrough(selection.hasFormat('strikethrough'));
      setIsUnderline(selection.hasFormat('underline'));

      const anchorNode = selection.anchor.getNode();
      const element = anchorNode.getKey() === 'root'
        ? anchorNode
        : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      if (elementDOM !== null) {
        // setSelectedElementKey(elementKey);
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();
          setBlockType(type);
          // if ($isCodeNode(element)) {
          //   setBlockType(element.getLanguage() || getDefaultCodeLanguage());
          // }
        }
      }
    }
  }, [editor]);

  React.useEffect(() => mergeRegister(
    editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        updateToolbar();
      });
    }),
    editor.registerCommand(
      SELECTION_CHANGE_COMMAND,
      () => {
        updateToolbar();
        return false;
      },
      COMMAND_PRIORITY_LOW,
    ),
    editor.registerCommand(
      CAN_UNDO_COMMAND,
      (payload) => {
        setCanUndo(payload);
        return false;
      },
      COMMAND_PRIORITY_LOW,
    ),
    editor.registerCommand(
      CAN_REDO_COMMAND,
      (payload) => {
        setCanRedo(payload);
        return false;
      },
      COMMAND_PRIORITY_LOW,
    ),
  ), [updateToolbar, editor]);

  const selectBlockType = (block) => {
    if (block !== 'ul' && block !== 'ol') {
      if (blockType !== block) {
        editor.update(() => {
          const selection = $getSelection();

          if ($isRangeSelection(selection)) {
            $wrapNodes(selection, () => blockTypeToNode[block]());
          }
        });
      }
    } else if (block === 'ul') {
      if (blockType !== 'ul') {
        editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND);
      } else {
        editor.dispatchCommand(REMOVE_LIST_COMMAND);
      }
    } else if (block === 'ol') {
      if (blockType !== 'ol') {
        editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND);
      } else {
        editor.dispatchCommand(REMOVE_LIST_COMMAND);
      }
    }
  };

  return (
    <div className="flex items-center rounded-t w-full border border-gray-300 border-b-0 h-9">
      <button
        type="button"
        disabled={!canUndo}
        className="group px-2 py-1 enabled:hover:bg-gray-200"
        onClick={() => {
          editor.dispatchCommand(UNDO_COMMAND);
        }}
      >
        <ArrowUturnLeftIcon
          className="text-gray-700 group-disabled:text-gray-300 w-5 h-5"
        />
      </button>
      <button
        type="button"
        disabled={!canRedo}
        className="group px-2 py-1 enabled:hover:bg-gray-200"
        onClick={() => {
          editor.dispatchCommand(REDO_COMMAND);
        }}
      >
        <ArrowUturnRightIcon
          className="text-gray-700 group-disabled:text-gray-300 w-5 h-5"
        />
      </button>
      <span className="w-[1px] bg-gray-300 block h-full mx-1" />
      <button
        type="button"
        className={classNames(
          'px-2 py-1 hover:bg-gray-200 text-black',
          isBold ? 'bg-gray-200' : 'bg-transparent',
        )}
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
        }}
      >
        <span className="font-bold text-lg">B</span>
      </button>
      <button
        type="button"
        className={classNames(
          'px-2 py-1 hover:bg-gray-200 text-black',
          isStrikethrough ? 'bg-gray-200' : 'bg-transparent',
        )}
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough');
        }}
      >
        <span className="line-through text-lg">S</span>
      </button>
      <button
        type="button"
        className={classNames(
          'px-2 py-1 hover:bg-gray-200 text-black',
          isItalic ? 'bg-gray-200' : 'bg-transparent',
        )}
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
        }}
      >
        <span className="italic text-lg">I</span>
      </button>
      <button
        type="button"
        className={classNames(
          'px-2 py-1 hover:bg-gray-200 text-black',
          isUnderline ? 'bg-gray-200' : 'bg-transparent',
        )}
        onClick={() => {
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');
        }}
      >
        <span className="underline text-lg">U</span>
      </button>
      <span className="w-[1px] bg-gray-300 block h-full mx-1" />
      {/* Combobox */}
      <Listbox as="div" value={blockType} onChange={selectBlockType} className="flex items-center h-full flex-col">
        <div className="relative flex flex-1">
          <Listbox.Button className="flex items-center rounded px-2 focus:outline-none hover:bg-gray-300">
            {blockTypeToBlockName[blockType]}
            <ChevronDownIcon className="h-4 w-4 text-gray-500 ml-1" />
          </Listbox.Button>

          <Listbox.Options className="absolute z-10 mt-9 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {supportedBlockTypes.map((type) => (
              <Listbox.Option
                key={type}
                value={type}
                className={({ active }) => classNames(
                  'relative cursor-default select-none py-2 pl-3 pr-9',
                  active ? 'bg-primary-500 text-white' : 'text-gray-900',
                )}
              >
                {({ active, selected }) => (
                  <>
                    <div className="flex items-center">
                      <Bars3BottomLeftIcon className="h-5 w-5 text-gray-400" />
                      <span className={classNames('ml-3', selected && 'font-semibold')}>{blockTypeToBlockName[type]}</span>
                    </div>

                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          active ? 'text-white' : 'text-primary-500',
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </div>
      </Listbox>
      {showSaveState === SAVE_STATES.SAVING && (
      <div className="flex flex-row flex-1 justify-end items-center mr-2">
        <Spinner className="border-gray-300" size={4} />
        <span className="ml-1 italic text-gray-400">Saving...</span>
      </div>
      )}
      {showSaveState === SAVE_STATES.SAVE_FINISHED && (
      <div className="flex flex-row flex-1 justify-end items-center mr-2">
        <CheckIcon className="h-4 w-4 text-gray-400" />
        <span className="ml-1 text-gray-400">Saved</span>
      </div>
      )}
    </div>
  );
}
