import { API } from './api';

export default class RSSFeedApi {
  constructor() {
    this.api = new API();
  }

  async getRSSFeedContents(url) {
    let data = await this.api.read('extract-rss', {
      customParamMap: { url },
    });

    return data.data;
  }

  async createExternalShow(feed) {
    let postBody = {
      extRssFeedUrl: feed.extRssFeedUrl,
    };
    let data = await this.api.create('external-show', postBody);
    return data.data;
  }
}
