import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import ShowAPI from '../../../../api/ShowApi';
import {
  clearBreadcrumbs,
  collapseNavBar,
  setBreadcrumbs,
  setEnableBreakpoint,
} from '../../../../slices/NavBarSlice';
import defaultImage from '../../../../assets/img/image_placeholder.jpg';
import MiniSidebarNav from '../../../../components/MiniSidebarNav';
import ShowOverview from './components/ShowOverview';
import ShowAudience from './components/ShowAudience';
import ShowAnalytics from './components/ShowAnalytics';
import ShowEpisodes from './components/ShowEpisodes';
import { defaultErrorHandler } from '../../../../errors/ApplicationError';
import useAlertNotification from '../../../../hooks/useAlertNotification';
import i18n from '../../../../i18n';

const showNavItems = [
  {
    id: 0,
    name: i18n.t('showDetails.nav.overview'),
    href: 'overview',
  },
  {
    id: 1,
    name: i18n.t('showDetails.nav.episodes'),
    href: 'episodes',
  },
  {
    id: 2,
    name: i18n.t('showDetails.nav.audience'),
    href: 'audience',
  },
  {
    id: 3,
    name: i18n.t('showDetails.nav.analytics'),
    href: 'analytics',
  },
  // {
  //   id: 4,
  //   name: i18n.t('showDetails.nav.costs'),
  //   href: 'costs',
  // },
];

const showApi = new ShowAPI();

export default function ShowDetails() {
  const dispatch = useDispatch();
  const { showId } = useParams();
  const { path } = useRouteMatch();
  const history = useHistory();
  const [show, setShow] = useState(null);
  const { addError } = useAlertNotification();

  const userState = useSelector((state) => state.user.user);
  const userId = userState.id;

  const refreshShow = async () => {
    let showData = await showApi.getShowWithEpisodes(showId, 0);
    setShow(showData);
    return showData;
  };

  useEffect(() => {
    // load show details
    let mounted = true;
    setShow(null);
    refreshShow()
      .then((showResp) => {
        if (!mounted) {
          return;
        }

        dispatch(setBreadcrumbs({ show: showResp }));
      })
      .catch((err) => {
        if (!mounted) {
          return;
        }

        defaultErrorHandler(err, addError);
        history.push('/app/dashboard');
      });
    return () => {
      mounted = false;
      dispatch(clearBreadcrumbs());
    };
  }, [showId]);

  useEffect(() => {
    dispatch(setEnableBreakpoint(false));
    dispatch(collapseNavBar());
    return () => dispatch(setEnableBreakpoint(true));
  }, [showId]);

  return (
    <div className="py-6 h-full">
      <div className="flex flex-row h-full">
        <MiniSidebarNav showImage={(show && show.imagePath) || defaultImage} navItems={showNavItems} showId={show?.showId} refreshShow={refreshShow} imageEditable={show != null && show.owners != null && show.owners.find((owner) => owner.id === userId) != null} />
        {/* main content */}
        <div className="flex-1 ml-10 overflow-hidden relative">
          <div className="absolute inset-0 overflow-auto pl-1 pr-4">
            <Switch>
              <Route exact path={`${path}/overview`}>
                <ShowOverview show={show} refreshShow={refreshShow} />
              </Route>
              <Route exact path={`${path}/episodes`}>
                <ShowEpisodes show={show} />
              </Route>
              <Route exact path={`${path}/audience`}>
                <ShowAudience show={show} refreshShow={refreshShow} />
              </Route>
              <Route exact path={`${path}/analytics`}>
                <ShowAnalytics show={show} />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
}
