/* eslint-disable jsx-a11y/label-has-associated-control */
import classNames from 'classnames';
import React, { useState } from 'react';
import EpisodeAPI from '../../../api/EpisodeApi';
import Spinner from '../../../components/Basic/Spinner';
import { defaultErrorHandler } from '../../../errors/ApplicationError';
import useAlertNotification from '../../../hooks/useAlertNotification';
import i18n from '../../../i18n';
import Utils from '../../../util/Utils';

const episodeApi = new EpisodeAPI();

export default function UpdatePodcastFileButton({ episodeId, disabled, onBeforeUpdateFinished }) {
  const [isUploading, setIsUploading] = useState(false);
  const [uploadingPercentage, setUploadingPercentage] = useState(0);
  const { addError, addSuccess } = useAlertNotification();

  const updatePodcastFile = async (f) => {
    if (isUploading) {
      return;
    }

    setIsUploading(true);
    try {
      let tempName = await Utils.uploadPodcast({
        file: f,
        onProgress: (progress) => setUploadingPercentage(progress),
        episodeApi,
      });
      await episodeApi.updateEpisodePodcastFile(episodeId, tempName);
      if (onBeforeUpdateFinished) {
        await onBeforeUpdateFinished();
      }
      addSuccess(i18n.t('notifications.podcastUploadSuccessfully'));
    } catch (e) {
      defaultErrorHandler(e, addError);
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <label
      title={disabled ? i18n.t('episodeDetails.production.alreadyPublished') : null}
      disabled={disabled}
      className={classNames('flex flex-row text-sm rounded px-2 py-1 justify-center items-center', disabled ? 'bg-gray-300 text-gray-500' : 'bg-primary-500 text-white hover:bg-primary-400 active:bg-primary-600 cursor-pointer')}
    >
      {isUploading && <Spinner size={4} className="border-gray-200 mr-2" />}
      <span>{isUploading ? `${uploadingPercentage}%` : i18n.t('episodeDetails.production.upload')}</span>
      <input
        disabled={disabled}
        type="file"
        className="hidden"
        accept=".asf,.wma,.wmv,.ts,.m2ts,.mp3,.mp4,.m4a,.aifc,.mka,.oga,.mov,.wav"
        onChange={async (e) => {
          await updatePodcastFile(e.target.files[0]);
          e.target.value = null;
        }}
      />
    </label>
  );
}
