import React, { useState, useCallback } from 'react';

export const AlertNotificationContext = React.createContext({
  error: null,
  success: null,
  addError: () => {},
  removeError: () => {},
  addSuccess: () => {},
  removeSuccess: () => {},
});

export default function AlertNotificationProvider({ children }) {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const removeError = () => setError(null);

  const addError = (message) => setError({ message });

  const removeSuccess = () => setSuccess(null);

  const addSuccess = (message) => setSuccess({ message });

  const contextValue = {
    error,
    success,
    addError: useCallback((message) => addError(message), []),
    removeError: useCallback(() => removeError(), []),
    addSuccess: useCallback((message) => addSuccess(message), []),
    removeSuccess: useCallback(() => removeSuccess(), []),
  };

  return (
    <AlertNotificationContext.Provider value={contextValue}>
      {children}
    </AlertNotificationContext.Provider>
  );
}
