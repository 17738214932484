import { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';
import ShowApi from '../api/ShowApi';
import EpisodeApi from '../api/EpisodeApi';
import ChannelApi from '../api/ChannelApi';

import { defaultErrorHandler } from '../errors/ApplicationError';

import i18n from '../i18n';

import createLogger from '../util/Logger';
import Constants from '../util/Constants';

import useAlertNotification from './useAlertNotification';

const logger = createLogger('ShowDetailsScreen');

const showApi = new ShowApi();
const episodeApi = new EpisodeApi();
const channelApi = new ChannelApi();

const PAGINATION_INITIALIZE = {
  pageEpisodes: [],
  retrievedEpisodes: [],
  pageNo: 0,
  total: 0,
  lastEvaluated: null,
};

export default function useShowDetails(showId) {
  const { addError, addSuccess } = useAlertNotification();

  const [show, setShow] = useState(null);
  const [pagination, setPagination] = useState(PAGINATION_INITIALIZE);
  const [isDeleting, setIsDeleting] = useState(false);
  const [selectedChannelId, setSelectedChannelId] = useState(null);
  const [channels, setChannels] = useState(null);
  const [isAssigning, setIsAssigning] = useState(false);
  const [producers, setProducers] = useState(null);
  const [owners, setOwners] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isLoadingNewEpisodes, setIsLoadingNewEpisodes] = useState(false); // used during pagination server load
  const [isUploadingImage, setIsUploadingImage] = useState(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);

  const user = useSelector((s) => s.user.user);

  let history = useHistory();

  const deleteEpisode = async (episodeId) => {
    logger.debug('doing delete episodes API request', episodeId);
    setIsDeleting(true);
    episodeApi
      .deleteEpisode(episodeId, showId)
      .then((deleteData) => {
        logger.debug('Server responded with success status', { deleteData });
        episodeApi.getEpisodeList(showId, null, null)
          .then((epData) => {
            logger.debug('Server responded with success status', { epData });
            let { episodes, lastEvaluated } = epData;
            setPagination(
              {
                pageEpisodes: episodes,
                retrievedEpisodes: epData.episodes,
                pageNo: 0, // for simplicity any episode deletion will reset the pagination to first page
                total: epData.episodes.length,
                lastEvaluated,
              },
            );
            addSuccess(i18n.t('notifications.deletedEpisodeSuccessfully'));
            setIsDeleting(false);

            if (history.location.pathname !== `/app/show/${showId}`) {
              history.push(`/app/show/${showId}`);
            }
          })
          .catch((err) => {
            defaultErrorHandler(err, (msg) => {
              logger.debug('Received error', { msg });
              addError(msg);
            });
            setIsDeleting(false);
          });
      })
      .catch((err) => {
        defaultErrorHandler(err, (msg) => {
          logger.debug('Received error', { msg });
          addError(msg);
          setIsDeleting(false);
        });
      });
  };

  /*
   * Client side pagination
   * @param {boolean} next, flag for next page
   */
  const getNextEpisodes = async (next = true) => {
    let newPageNo = next ? pagination.pageNo + 1 : pagination.pageNo - 1;
    let startIndex = newPageNo * Constants.EPISODES_PAGE_SIZE;
    let endIndex = startIndex + Constants.EPISODES_PAGE_SIZE;
    let pageEpisodes = pagination.retrievedEpisodes.slice(startIndex, endIndex);
    let paginationData = {
      ...pagination,
      pageEpisodes,
      pageNo: newPageNo,
    };
    setPagination(paginationData);
  };

  /*
   * Server side episode reload to check the publish status(regular shows)
   * This will reset the pagination if any;
   */
  const reloadEpisodes = async () => {
    logger.debug('Reloading Server responded with success status', { showId });
    let size = Constants.EPISODES_PAGE_SIZE;

    episodeApi.getEpisodeList(showId, size, null)
      .then((data) => {
        logger.debug('Server responded with success status', { data });
        let { episodes, lastEvaluated } = data;
        let paginationData = {
          pageEpisodes: episodes,
          retrievedEpisodes: episodes,
          pageNo: 0,
          total: episodes.length,
          lastEvaluated,
        };
        logger.debug('Reload pagination', { paginationData });
        setPagination(paginationData);
      })
      .catch((err) => {
        defaultErrorHandler(err, (msg) => {
          logger.debug('Received error', { msg });
          addError(msg);
        });
      });
  };

  /*
   * Server side episode load for pagination with lastEvaluated, otherwise client side pagination
   */
  const getEpisodes = async () => {
    let size = Constants.EPISODES_PAGE_SIZE;
    let {
      lastEvaluated, pageNo, total, retrievedEpisodes,
    } = pagination;

    let nextPageNo = pageNo + 1;
    let nextPageEndIndex = nextPageNo * size + size;

    if (lastEvaluated && nextPageEndIndex > total) {
      setIsLoadingNewEpisodes(true);

      episodeApi.getEpisodeList(showId, size + 1, lastEvaluated)
        .then((data) => {
          logger.debug('getEpisodes : Server responded with success status', { data });

          let { episodes } = data;
          let totalEpisodes = [];

          // lastEvaluated query bug, episode is repeated sometimes
          let last = retrievedEpisodes.pop();

          if (last.episodeId === episodes[0].episodeId) {
            logger.warn('episode is repeated', { episode: last.name });
            totalEpisodes = [...retrievedEpisodes, ...episodes];
          } else {
            totalEpisodes = [...retrievedEpisodes, last, ...episodes];
          }

          let nextPageEpisodes = totalEpisodes.slice(nextPageNo * size, (nextPageNo * size) + size);
          logger.debug('next page episodes ', { nextPageEpisodes: nextPageEpisodes.length });

          let paginationData = {
            pageEpisodes: nextPageEpisodes,
            retrievedEpisodes: totalEpisodes,
            pageNo: nextPageNo,
            total: totalEpisodes.length,
            lastEvaluated: data.lastEvaluated,
          };
          setPagination(paginationData);
        })
        .catch((err) => {
          defaultErrorHandler(err, (msg) => {
            logger.debug('Received error during server pagination', { msg });
            addError(msg);
          });
        })
        .finally(() => {
          setIsLoadingNewEpisodes(false);
        });
    } else {
      logger.debug('load next page episodes from retrieved data');
      await getNextEpisodes(true);
    }
  };

  const getChannels = async (channelId) => {
    logger.debug('Fetch channels of user where he is member off.');
    channelApi.getChannels(null)
      .then((data) => {
        logger.debug('Server responded with success status', { data });
        if (selectedChannelId) {
          let filteredChannels = data.filter((c) => c.channelId !== channelId);
          setChannels(filteredChannels);
        } else {
          setChannels(data);
        }
      })
      .catch((err) => {
        defaultErrorHandler(err, (msg) => {
          logger.debug('Received error', { msg });
          addError(msg);
        });
      });
  };

  const assignNewChannelOrMakePublic = async (channelId) => {
    logger.debug('Assign new Channel or made show public.');
    setIsAssigning(true);

    try {
      let channelData = await channelApi.assignNewChannel(showId, channelId);
      logger.debug('Server responded with success status', { channelData });

      if (channelId != null) {
        addSuccess(i18n.t('notifications.updatedChannelSuccessfully'));
      } else {
        addSuccess(i18n.t('notifications.madeShowSuccessfullyPublic'));
        setSelectedChannelId(null);
      }
      setIsAssigning(false);
    } catch (err) {
      defaultErrorHandler(err, (msg) => {
        logger.debug('Received error', { msg });
        addError(msg);
        setIsAssigning(false);
      });
    }

    try {
      let showData = await showApi.getShowWithEpisodes(showId, 0);
      setShow(showData);
      setOwners(showData.owners);
      logger.debug('Received show from the server', { showData });
    } catch (err) {
      defaultErrorHandler(err, (msg) => {
        logger.debug('Received error', { msg });
        addError(msg);
        history.goBack();
      });
    }
  };

  const setProducersToChannelMembers = (members, producersToCheck) => {
    let producersOfChannel = members
      .filter((member) => member.id !== user.id) // exlude the current logged in user
      .map((filteredMember) => {
        let checkedProducer = null;
        checkedProducer = producersToCheck.find((p) => p.id === filteredMember.id);

        checkedProducer = {
          id: filteredMember.id,
          firstName: filteredMember.firstName,
          lastName: filteredMember.lastName,
          isProducer: !!checkedProducer,
        };

        return checkedProducer;
      });

    setProducers(producersOfChannel);
  };

  const getProducers = async () => {
    logger.debug('Fetch channels of user where he is member off.');
    setProducers(null);
    try {
      let producerData = await showApi.getProducers(showId);
      logger.debug('Server responded with success status', { producerData });

      if (!show.channel) {
        setProducers(producerData);
      } else {
        setProducersToChannelMembers(show.channel.members, producerData);
      }
    } catch (err) {
      defaultErrorHandler(err, (msg) => {
        logger.debug('Received error', { msg });
        addError(msg);
      });
    }
  };

  const updateProducers = async () => {
    logger.debug('Update Producers.');

    // get only id and role of the member
    let tmpProducers = producers;
    if (show.channel) {
      tmpProducers = producers.filter((producer) => producer.isProducer);
    }

    let producersArray = tmpProducers.map((p) => (p.id));

    try {
      setIsUpdating(true);
      let updatedProducers = await showApi.updateProducers(showId, producersArray);
      logger.debug('Server responded with success status', { updatedProducers });
      addSuccess(i18n.t('notifications.updatedProducersSuccessfully'));
      setIsUpdating(false);
    } catch (err) {
      defaultErrorHandler(err, (msg) => {
        logger.debug('Received error', { msg });
        addError(msg);
        setIsUpdating(false);
      });
    }

    try {
      let showData = await showApi.getShowWithEpisodes(showId, 0);
      setShow(showData);
      setOwners(showData.owners);
      logger.debug('Received show from the server', { showData });
    } catch (err) {
      defaultErrorHandler(err, (msg) => {
        logger.debug('Received error', { msg });
        addError(msg);
        history.goBack();
      });
    }
  };

  const updateOwners = async () => {
    logger.debug('Updating owners');

    try {
      setIsUpdating(true);
      await showApi.updateOwners(showId, owners.map((owner) => ({ userId: owner.id, isLead: owner.isLead })));
      logger.debug('Successfully updated the show owners');
      addSuccess(i18n.t('notifications.updatedOwnersSuccessfully'));
      setIsUpdating(false);
    } catch (err) {
      defaultErrorHandler(err, (msg) => {
        logger.debug('Received error', { msg });
        addError(msg);
        setIsUpdating(false);
      });
    }

    try {
      let showData = await showApi.getShowWithEpisodes(showId, 0);
      setShow(showData);
      setOwners(showData.owners);
      logger.debug('Received show from the server', { showData });
    } catch (err) {
      defaultErrorHandler(err, (msg) => {
        logger.debug('Received error', { msg });
        addError(msg);
        history.goBack();
      });
    }
  };

  const updateShowDetails = async (name, description, organization) => {
    logger.debug('Updating show details');

    try {
      setIsUpdating(true);
      await showApi.updateShowDetails(showId, name, description, organization);
      logger.debug('Successfully updated the show details');
      addSuccess(i18n.t('notifications.updatedShowDetailsSuccessfully'));
      setIsUpdating(false);
    } catch (err) {
      defaultErrorHandler(err, (msg) => {
        logger.debug('Received error', { msg });
        addError(msg);
        setIsUpdating(false);
      });
    }

    try {
      let showData = await showApi.getShowWithEpisodes(showId, 0);
      setShow(showData);
      logger.debug('Received show from the server', { showData });
    } catch (err) {
      defaultErrorHandler(err, (msg) => {
        logger.debug('Received error', { msg });
        addError(msg);
        history.goBack();
      });
    }
  };

  const updateShowImage = async (file) => {
    try {
      logger.debug('Upload show image.', file);
      setIsUploadingImage(true);
      let tempFilename = null;
      let data = await showApi.getPresignedUrlForShowImage(file.name);
      logger.debug('Server responded with success status', { data });

      // save fileName for later signed image request
      tempFilename = data.fields.fileName;

      // create form and header data
      let form = new FormData();
      let headerObject = {};

      let keys = Object.keys(data.fields);
      keys.forEach((key) => {
        headerObject[key] = data.fields[key];
        form.append(key, data.fields[key]);
      });

      headerObject['Content-Type'] = 'image';
      form.append('Content-Type', 'image');
      form.append('file', file);

      let upload = await axios({
        method: 'post',
        headers: { headerObject },
        data: form,
        url: data.url,
      });

      logger.log(`file upload was successfully ${upload}`);

      let newImageSignedUrl = await showApi.updateShowImage(tempFilename, showId);
      setImagePreviewUrl(newImageSignedUrl);
    } catch (e) {
      defaultErrorHandler(e, (msg) => {
        logger.error('Received error', { msg });
        addError(i18n.t('createShow.imageUploadError'));
      });
      setIsUploadingImage(false);
    } finally {
      setIsUploadingImage(false);
      addSuccess(i18n.t('notifications.updatedShowImageSuccessfully'));
    }
  };

  useEffect(() => {
    let isActive = true;
    setShow(null);
    showApi
      .getShowWithEpisodes(showId, Constants.EPISODES_PAGE_SIZE)
      .then((data) => {
        if (isActive) {
          let showData = { ...data };
          delete showData.episodeList;
          setShow(showData);
          if (showData.channel) {
            setSelectedChannelId(showData.channel.id);
          }
          if (showData.owners != null) {
            setOwners(showData.owners);
          }

          // Initial load setting pagination to first page
          let { episodes, lastEvaluated } = data.episodeList;
          let paginationData = {
            pageEpisodes: episodes,
            retrievedEpisodes: episodes,
            pageNo: 0,
            total: episodes.length,
            lastEvaluated,
          };

          setPagination(paginationData);

          logger.debug('Received show from the server', { data, paginationData });
        }
      })
      .catch((err) => {
        if (isActive) {
          defaultErrorHandler(err, (msg) => {
            logger.debug('Received error', { msg });
            addError(msg);
            history.goBack();
          });
        }
      });
    return () => {
      isActive = false;
    };
  }, [showId]);

  return [
    show,
    pagination,
    deleteEpisode,
    getEpisodes,
    isDeleting,
    getChannels,
    channels,
    selectedChannelId,
    setSelectedChannelId,
    assignNewChannelOrMakePublic,
    isAssigning,
    producers,
    setProducers,
    updateProducers,
    isUpdating,
    getProducers,
    getNextEpisodes,
    isLoadingNewEpisodes,
    reloadEpisodes,
    owners,
    setOwners,
    updateOwners,
    updateShowDetails,
    updateShowImage,
    isUploadingImage,
    imagePreviewUrl,
  ];
}
