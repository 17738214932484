import React from 'react';

export default function Button({
  children,
  disabled,
  onClick,
}) {
  return (
    <button
      type="button"
      disabled={disabled}
      onClick={onClick}
      // eslint-disable-next-line max-len
      className="font-medium disabled:bg-gray-300 disabled:text-gray-500 rounded bg-primary-500 hover:bg-primary-400 active:bg-primary-600 p-1 text-white text-lg"
    >
      {children}
    </button>
  );
}
