import React, { useState } from 'react';
import {
  CalendarDaysIcon,
  PencilIcon,
  PlusCircleIcon,
  ShareIcon,
  TrashIcon,
  UsersIcon,
} from '@heroicons/react/24/outline';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';

import ShowAPI from '../../../../../api/ShowApi';
import WarningModal from '../../../../../components/WarningModal/WarningModal';
import ShowTeamModal from './ShowTeamModal';
import i18n from '../../../../../i18n';
import ShowEditModal from './ShowEditModal';
import Spinner from '../../../../../components/Basic/Spinner';
import { defaultErrorHandler } from '../../../../../errors/ApplicationError';
import useAlertNotification from '../../../../../hooks/useAlertNotification';
import EpisodeStatusTag from '../../../../../components/EpisodeStatusTag';
import CreateEpisodeDialog from '../../../../../components/CreateEpisodeDialog';

const showApi = new ShowAPI();

function EpisodeCard({ episode, to }) {
  return (
    <Link
      to={`/app/episode/${episode.episodeId}${to != null && to !== '' ? '/' : ''}${to || ''}`}
      className="xl:w-[80%] xl:mr-0 w-full mr-4 min-h-[8rem] rounded-lg shadow-md flex flex-col mb-4 cursor-pointer hover:bg-gray-100"
    >
      <p className="w-[80%] text-base font-bold ml-2 mt-4 line-clamp-2">{episode.name}</p>
      <p className="text-sm ml-2 text-gray-400">{episode.userDisplayName}</p>
      <div className="ml-1 mt-1 flex-1">
        <EpisodeStatusTag status={episode.status} className="text-sm" />
      </div>
      <div className="flex items-center mb-2 ml-2">
        <CalendarDaysIcon className="h-5 w-5 text-gray-500" />
        <span className="ml-1 mr-3 text-sm text-gray-500">{episode.publishDate != null ? moment(episode.publishDate).format('MMM DD, YYYY') : '-'}</span>
      </div>
    </Link>
  );
}

function ColumnLoading() {
  return (
    <div className="space-y-2">
      <div className="w-[80%] h-32 rounded-lg bg-gray-300 animate-pulse" />
      <div className="w-[80%] h-32 rounded-lg bg-gray-300 animate-pulse" />
      <div className="w-[80%] h-32 rounded-lg bg-gray-300 animate-pulse" />
    </div>
  );
}

export default function ShowOverview({ show, refreshShow }) {
  const [showDeleteWarningDialog, setShowDeleteWarningDialog] = useState(false);
  const [showTeamDialog, setShowTeamDialog] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [showCreateEpisodeDialog, setShowCreateEpisodeDialog] = useState(false);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const userState = useSelector((state) => state.user.user);
  const history = useHistory();
  const { addError } = useAlertNotification();

  const userId = userState.id;

  const team = [];
  if (show != null && show.owners != null) {
    team.push(...show.owners);
    team.sort((a, b) => {
      let result = 0;
      if (a.isLead && !b.isLead) {
        result = -1;
      } else if (!a.isLead && b.isLead) {
        result = 1;
      }

      return result;
    });
  }
  if (show != null && show.producers != null) {
    team.push(...show.producers);
  }

  const onDeleteShow = async () => {
    setShowBackdrop(true);
    setShowDeleteWarningDialog(false);
    try {
      await showApi.deleteShow(show.showId);
      history.push('/app/dashboard');
    } catch (e) {
      defaultErrorHandler(e, addError);
    } finally {
      setShowBackdrop(false);
    }
  };

  let episodesInTopic = [];
  let episodesInResearch = [];
  let episodesInRecording = [];
  let episodesInScheduled = [];
  show?.episodeList?.episodes?.forEach((episode) => {
    if (episode.status === 'scheduled') {
      episodesInScheduled.push(episode);
    } else if (episode.status !== 'published' && !episode.hasResearch && !episode.hasStructure) {
      episodesInTopic.push(episode);
    } else if (episode.status !== 'published' && episode.hasResearch && !episode.hasStructure) {
      episodesInResearch.push(episode);
    } else if (episode.status !== 'published' && episode.hasStructure) {
      episodesInRecording.push(episode);
    }
  });

  return (
    <>
      {show
        ? (
          <div className="flex justify-between xl:max-w-[80%]">
            <h3 className="text-xl font-bold mb-2">{show.name}</h3>
            <div className="flex items-center">
              <button
                type="button"
                className="isolate flex -space-x-1 overflow-hidden shrink-0 group rounded-lg items-center px-2 enabled:cursor-pointer select-none focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-primary-500"
                disabled={show == null || show.owners == null}
                onClick={() => setShowTeamDialog(true)}
              >
                {team.map((teamMember, i) => (
                  <div className={`relative z-${team.length * 10 - i * 10}  inline-flex h-6 w-6 rounded-full ring-2 ring-white bg-gray-400 group-enabled:group-hover:bg-gray-500 group-enabled:group-active:bg-gray-700 justify-center items-center`}>
                    <span className="text-xs text-white">{`${teamMember.firstName.charAt(0)}${teamMember.lastName.charAt(0)}`}</span>
                  </div>
                ))}
              </button>
              <div className="flex flex-row space-x-1">
                <div>
                  <button
                    type="button"
                    className="group hover:bg-gray-100 active:bg-gray-300 border border-gray-300 hover:border-gray-400 rounded text-sm py-0.5 pr-2 pl-1 inline-flex flex-row items-center"
                    onClick={() => setShowTeamDialog(true)}
                  >
                    <UsersIcon className="h-4 w-4 text-gray-500 group-hover:text-gray-700" />
                    <span className="pl-1">{i18n.t('showDetails.overview.team')}</span>
                  </button>
                </div>
                <div>
                  <Link to={`/show/${show.showId}`} target="_blank" className="group hover:bg-gray-100 active:bg-gray-300 border border-gray-300 hover:border-gray-400 rounded text-sm py-0.5 pr-2 pl-1 inline-flex flex-row items-center">
                    <ShareIcon className="h-4 w-4 text-gray-500 group-hover:text-gray-700" />
                    <span className="pl-1">{i18n.t('showDetails.overview.share')}</span>
                  </Link>
                </div>
                <div>
                  <button
                    type="button"
                    className="group hover:bg-gray-100 active:bg-gray-300 border border-gray-300 hover:border-gray-400 rounded text-sm py-0.5 pr-2 pl-1 inline-flex flex-row items-center"
                    onClick={() => setShowEditDialog(true)}
                  >
                    <PencilIcon className="h-4 w-4 text-gray-500 group-hover:text-gray-700" />
                    <span className="pl-1">{i18n.t('showDetails.overview.edit')}</span>
                  </button>
                </div>
                <div>
                  <button
                    type="button"
                    disabled={show == null || show.owners == null || show.owners.find((owner) => owner.id === userId) == null}
                    className="group enabled:hover:bg-red-100 enabled:active:bg-red-200 border border-red-700 enabled:hover:border-red-900 disabled:border-gray-300 rounded text-sm py-0.5 pr-2 pl-1 inline-flex flex-row items-center"
                    onClick={() => setShowDeleteWarningDialog(true)}
                  >
                    <TrashIcon className="h-4 w-4 text-red-700 group-enabled:group-hover:text-red-900 group-disabled:text-gray-400" />
                    <span className="pl-1 text-red-700 group-disabled:text-gray-500">{i18n.t('showDetails.overview.delete')}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : <div className="h-5 xl:max-w-[80%] bg-gray-300 animate-pulse rounded-xl" />}
      {show && show.organizationName && <h4 className="text-lg leading-tight font-normal text-gray-500">{show.organizationName}</h4>}
      {show && show.channel && <h4 className="text-lg leading-tight font-normal text-gray-500">{show.channel.name}</h4>}
      {show ? <p className="mt-5 text-gray-500 xl:max-w-[80%] line-clamp-6 min-h-[4rem] text-base">{show.description}</p> : <div className="h-40 xl:max-w-[80%] mt-5 bg-gray-300 animate-pulse rounded-xl" />}
      {show && (
      <button
        type="button"
        className="mt-10 px-2 py-1 disabled:bg-gray-300 disabled:text-gray-500 rounded bg-primary-500 hover:bg-primary-400 active:bg-primary-600 text-white text-sm"
        onClick={() => setShowCreateEpisodeDialog(true)}
      >
        {i18n.t('showDetails.overview.createEpisode')}
      </button>
      )}
      <div className="grid grid-cols-4 mt-2">
        <div className="flex flex-col my-4 xl:mr-0 mr-4 min-w-fit">
          <div className="flex flex-row mb-6 items-center">
            <h3 className="font-bold text-lg">{i18n.t('showDetails.overview.topics')}</h3>
            <span className="rounded-full bg-primary-500 text-white ml-2 h-5 w-5 text-center text-sm font-semibold">{episodesInTopic.length}</span>
          </div>
          {show?.episodeList?.episodes != null && (
          <button
            type="button"
            className="xl:w-[80%] xl:mr-0 w-full mr-4 h-32 rounded-lg flex flex-col items-center mb-4 hover:bg-gray-100 active:bg-gray-200 border-gray-300 border-dashed border-2"
            onClick={() => setShowCreateEpisodeDialog(true)}
          >
            <PlusCircleIcon className="h-8 w-8 text-gray-400 mt-6" />
            <p className="mt-2 text-gray-500">{i18n.t('showDetails.overview.createNewEpisode')}</p>
          </button>
          )}
          {episodesInTopic.map((episode) => (
            <EpisodeCard episode={episode} />
          ))}
          {show?.episodeList?.episodes == null && <ColumnLoading />}
        </div>
        <div className="flex flex-col py-4">
          <div className="flex flex-row mb-6 items-center">
            <h3 className="font-bold text-lg">{i18n.t('showDetails.overview.research')}</h3>
            <span className="rounded-full bg-primary-500 text-white ml-2 h-5 w-5 text-center text-sm font-semibold">{episodesInResearch.length}</span>
          </div>
          {episodesInResearch.map((episode) => (
            <EpisodeCard episode={episode} to="preparation" />
          ))}
          {show?.episodeList?.episodes == null && <ColumnLoading />}
        </div>
        <div className="flex flex-col py-4">
          <div className="flex flex-row mb-6 items-center">
            <h3 className="font-bold text-lg">{i18n.t('showDetails.overview.production')}</h3>
            <span className="rounded-full bg-primary-500 text-white ml-2 h-5 w-5 text-center text-sm font-semibold">{episodesInRecording.length}</span>
          </div>
          {episodesInRecording.map((episode) => (
            <EpisodeCard episode={episode} to="production" />
          ))}
          {show?.episodeList?.episodes == null && <ColumnLoading />}
        </div>
        <div className="flex flex-col py-4">
          <div className="flex flex-row mb-6 items-center">
            <h3 className="font-bold text-lg">{i18n.t('showDetails.overview.scheduled')}</h3>
            <span className="rounded-full bg-primary-500 text-white ml-2 h-5 w-5 text-center text-sm font-semibold">{episodesInScheduled.length}</span>
          </div>
          {episodesInScheduled.map((episode) => (
            <EpisodeCard episode={episode} />
          ))}
          {show?.episodeList?.episodes == null && <ColumnLoading />}
        </div>
      </div>
      <WarningModal
        closeWarningModal={() => setShowDeleteWarningDialog(false)}
        handleWarningModal={() => onDeleteShow()}
        openWarningModal={showDeleteWarningDialog}
        title={i18n.t('showDetails.delete.dialogTitle')}
        contentText={i18n.t('showDetails.delete.dialogText')}
        positiveButtonText={i18n.t('showDetails.delete.dialogButton')}
        colorScheme="red"
        buttonColorScheme="red"
      />
      <ShowTeamModal
        showDialog={showTeamDialog}
        refreshShow={refreshShow}
        handleClose={() => setShowTeamDialog(false)}
        owners={show && show.owners}
        producers={show && show.producers}
        show={show}
        editable={show != null && show.owners != null && show.owners.find((owner) => owner.id === userId) != null}
      />
      <ShowEditModal
        showDialog={showEditDialog}
        refreshShow={refreshShow}
        handleClose={() => setShowEditDialog(false)}
        show={show}
      />
      <CreateEpisodeDialog
        showDialog={showCreateEpisodeDialog}
        handleClose={() => setShowCreateEpisodeDialog(false)}
        handleSave={(episode) => {
          setShowCreateEpisodeDialog(false);
          history.push(`/app/episode/${episode.episodeId}`);
        }}
        show={show}
      />
      {showBackdrop && (
      <div className="fixed w-full h-full flex justify-center items-center inset-0 bg-black opacity-70 z-50">
        <Spinner size={6} className="border-primary-500" />
      </div>
      )}
    </>
  );
}
