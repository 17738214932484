import React from 'react';

export default function ClapsIcon(props) {
  return (
    <svg
      {...props}
      viewBox="0 0 512.000000 512.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,512.000000) scale(0.120000,-0.120000)"
        fill="none"
        stroke="currentColor"
        strokeWidth="70"
      >
        <path d="M3016 4138 c-28 -43 -176 -400 -176 -424 0 -14 10 -30 26 -40 45 -30
66 -2 159 216 47 107 84 204 85 216 0 49 -68 72 -94 32z"
        />
        <path d="M2642 4094 c-21 -15 -22 -20 -22 -209 l0 -195 24 -15 c28 -19 58 -9
76 25 6 10 10 92 10 190 0 168 0 171 -25 195 -28 29 -33 30 -63 9z"
        />
        <path d="M1362 3978 c-24 -24 -10 -63 70 -197 95 -159 104 -171 133 -171 26 0
55 29 55 54 0 17 -148 273 -178 309 -17 20 -62 23 -80 5z"
        />
        <path d="M977 3922 c-10 -10 -17 -27 -17 -38 0 -27 359 -384 386 -384 26 0 54
30 54 56 0 27 -359 384 -387 384 -11 0 -27 -8 -36 -18z"
        />
        <path d="M3304 3932 c-6 -4 -63 -73 -127 -153 -82 -103 -117 -154 -117 -172 0
-33 38 -61 68 -50 21 8 236 270 255 310 20 44 -39 93 -79 65z"
        />
        <path d="M982 3484 c-26 -18 -29 -55 -4 -76 26 -24 239 -128 260 -128 27 0 54
34 50 62 -2 18 -29 36 -133 90 -71 37 -135 67 -140 68 -6 0 -21 -7 -33 -16z"
        />
        <path d="M1645 3281 c-131 -61 -175 -229 -90 -346 38 -53 38 -53 -3 -74 -76
-39 -122 -117 -122 -208 0 -73 19 -113 90 -187 33 -34 60 -68 60 -74 0 -6 -10
-14 -22 -18 -39 -12 -79 -48 -106 -94 -38 -64 -38 -166 0 -231 35 -59 835
-853 918 -911 84 -59 125 -79 240 -118 89 -30 109 -33 310 -43 402 -20 500
-13 658 50 200 80 382 245 478 435 132 263 139 564 19 824 -63 137 -114 200
-382 465 -234 233 -262 257 -299 264 -71 11 -113 -3 -166 -56 -33 -33 -51 -62
-60 -93 -7 -25 -18 -46 -24 -46 -7 0 -106 94 -221 209 -131 131 -224 217 -252
231 -111 56 -248 9 -308 -105 -8 -16 -17 -32 -19 -34 -2 -2 -35 26 -73 63 -38
36 -87 74 -108 82 -60 26 -144 20 -202 -12 l-48 -28 -29 23 c-68 54 -165 66
-239 32z m175 -126 c24 -23 27 -33 23 -65 -8 -57 3 -100 43 -166 19 -32 31
-60 25 -62 -6 -2 -27 -15 -47 -29 l-37 -25 -98 100 c-81 83 -98 106 -104 140
-19 114 112 186 195 107z m281 14 c24 -8 163 -140 489 -465 251 -250 462 -454
471 -454 28 0 59 30 59 58 0 21 -64 91 -336 363 l-337 338 5 50 c6 57 26 87
74 107 72 30 69 33 604 -502 307 -306 503 -494 514 -494 27 0 56 29 56 56 0
17 -56 81 -202 229 -223 226 -246 259 -234 337 8 53 57 118 89 118 16 0 98
-76 278 -257 202 -203 265 -274 299 -331 114 -194 148 -418 95 -625 -81 -317
-322 -547 -642 -613 -94 -20 -255 -14 -353 11 -106 28 -237 96 -325 168 -44
36 -249 236 -456 444 -394 396 -400 402 -384 472 3 15 20 41 36 57 25 24 39
29 79 29 l49 0 248 -246 c137 -136 257 -251 268 -257 33 -16 85 17 85 54 0 5
-166 175 -369 379 -204 203 -375 383 -380 398 -6 16 -11 40 -11 55 1 78 94
136 163 101 12 -6 189 -177 393 -380 203 -203 378 -369 387 -369 24 0 57 36
57 62 0 16 -128 151 -454 478 -484 485 -480 481 -456 554 13 40 37 64 76 76
16 4 29 9 30 9 1 1 16 -4 35 -10z m-370 -422 c24 -17 27 -27 31 -96 5 -92 21
-129 94 -209 30 -33 54 -62 54 -65 0 -6 -78 -47 -90 -47 -5 0 -68 59 -139 131
-86 88 -132 142 -136 161 -26 116 92 195 186 125z m-3 -494 c31 -23 33 -27 26
-68 -4 -28 0 -63 11 -102 16 -57 24 -65 418 -462 221 -222 429 -426 462 -454
l60 -50 -39 6 c-46 7 -167 63 -233 108 -69 46 -872 847 -889 886 -20 46 -17
75 9 114 25 37 77 61 117 52 14 -3 40 -17 58 -30z"
        />
      </g>
    </svg>
  );
}
