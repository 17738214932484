import { API } from './api';

export default class SearchApi {
  constructor() {
    this.api = new API();
  }

  async getSearchKey() {
    let data = await this.api.read('getSearchKey');

    return data.data;
  }

  async search(requests, type) {
    let data = await this.api.create('search', {
      requests,
      type,
    });

    return data.data;
  }
}
