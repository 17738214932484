import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
} from 'react';
import classNames from 'classnames';
import { Popover, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/24/outline';
import { useDispatch, useSelector } from 'react-redux';

import { Context as AuthContext } from '../../context/AuthContext';
import i18n from '../../i18n';
import TenantApi from '../../api/TenantApi';
import Auth from '../../util/AuthHelper';
import useAlertNotification from '../../hooks/useAlertNotification';

const tenantApi = new TenantApi();

export default function UserButton({
  hideName,
}) {
  const [spaces, setSpaces] = useState(null);
  const [spaceName, setSpaceName] = useState('');
  const { addError } = useAlertNotification();
  const { signout } = useContext(AuthContext);
  const dispatch = useDispatch();
  let { user: userState } = useSelector((s) => s.user);

  useEffect(() => {
    tenantApi.getUserTenants().then((s) => {
      setSpaces(s);
      setSpaceName(s.find((sp) => sp.active)?.name || '');
    });
  }, []);

  const switchSpace = async (space) => {
    if (space.active) {
      return;
    }
    try {
      await tenantApi.switchTenant(space.tenantId);
      let user = await Auth.currentAuthenticatedUser();
      let session = await Auth.currentSession();
      user.refreshSession(session.getRefreshToken(), (err) => {
        if (err) {
          addError(i18n.t('tenant.errorSwitchTenant'));
        } else {
          window.location.reload();
        }
      });
    } catch (err) {
      addError(i18n.t('tenant.errorSwitchTenant'));
    }
  };

  const signOut = () => {
    signout(dispatch);
  };

  return (
    <Popover as="div" className="relative">
      <Popover.Button
        className="flex max-w-xs items-center rounded-full bg-white text-sm pr-2 hover:ring-0 active:ring-0 focus:ring-0 focus:ring-offset-0 ring-0 ring-offset-0 border-0"
      >
        <span className="sr-only">Open user menu</span>
        <div className="flex">
          <div className="rounded-full h-12 w-12 bg-gray-400 text-white flex justify-center items-center">
            <span className="text-lg">{`${userState.firstName.substring(0, 1)}${userState.lastName.substring(0, 1)}`}</span>
          </div>
          <div className={classNames(hideName ? 'hidden' : 'flex flex-col items-start pl-3')}>
            <span className="font-bold text-lg text-black truncate">{`${userState.firstName} ${userState.lastName}`}</span>
            <span className="text-base text-gray-500 leading-3 truncate">{spaceName || ''}</span>
          </div>
        </div>
      </Popover.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Popover.Panel className={classNames(
          'absolute right-0 z-10 mt-2 w-48 origin-bottom-left sm:-translate-y-full rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
          hideName ? 'translate-x-full' : '-translate-x-1',
        )}
        >
          <div className="overflow-hidden rounded-lg">
            <div className="relative flex flex-col gap-4 bg-white p-5">
              <div className="flex flex-col border-b border-gray-300 pb-1">
                <p className="font-bold text-gray-500 mb-2">{i18n.t('general.space')}</p>
                {spaces != null && spaces.map((space) => (
                  <button key={space.tenantId} type="button" className="flex p-2 hover:bg-gray-100 rounded-lg" onClick={() => switchSpace(space)}>
                    {space.active && <CheckIcon className="text-black w-5 h-5 mr-2" />}
                    {!space.active && <div className="w-5 h-5 mr-2" />}
                    <p className="text-sm">{space.name}</p>
                  </button>
                ))}
                {spaces == null && (
                <div className="space-y-1">
                  <div className="w-[80%] rounded-xl mx-2 h-3 bg-gray-300 animate-pulse" />
                  <div className="w-[80%] rounded-xl mx-2 h-3 bg-gray-300 animate-pulse" />
                  <div className="w-[80%] rounded-xl mx-2 h-3 bg-gray-300 animate-pulse" />
                </div>
                )}
              </div>
              <a
                href="https://sicross.com/terms/"
                rel="noreferrer"
                target="_blank"
                className="text-primary-500 font-semibold hover:text-primary-400 text-sm"
              >
                {i18n.t('general.termsOfUse')}
              </a>
              <a
                href="https://sicross.com/privacy-policy/"
                target="_blank"
                rel="noreferrer"
                className="text-primary-500 font-semibold hover:text-primary-400 text-sm"
              >
                {i18n.t('general.privacyPolicy')}
              </a>
              <button type="button" className="bg-primary-500 hover:bg-primary-400 text-white p-1 rounded text-sm" onClick={signOut}>{i18n.t('general.signOut')}</button>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
