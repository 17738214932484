import React, { useState } from 'react';
import { connectAutoComplete, connectStateResults } from 'react-instantsearch-dom';
import { Combobox } from '@headlessui/react';
import { GlobeAltIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';

import Spinner from '../Basic/Spinner';

const ORG_NAME_CHARACTER_LIMIT = 50;

const SearchBar = ({
  hits, refine, onSelection, searching, onNameChange, initValue, isOrganization, placeholder,
}) => {
  const [selectedOrg, setSelectedOrg] = useState(initValue);
  const [currentText, setCurrentText] = useState(selectedOrg != null ? selectedOrg.name : '');

  const onSelectionChange = (org) => {
    onSelection(org);
    setSelectedOrg(org);
  };

  return (
    <Combobox as="div" className="flex-1" disabled={!isOrganization} onChange={onSelectionChange} value={selectedOrg}>
      <div className="relative ml-2">
        <div className="relative flex flex-grow items-center focus-within:z-10">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <GlobeAltIcon className={classNames('h-5 w-5', isOrganization ? 'text-gray-400' : 'text-gray-300')} aria-hidden="true" />
          </div>
          <Combobox.Input
            type="text"
            name="organization"
            id="organization"
            disabled={!isOrganization}
            className="block w-full text-black rounded-md border-gray-300 disabled:border-gray-200 placeholder:text-gray-400 disabled:placeholder:text-gray-300 pl-10 enabled:focus:border-primary-500 enabled:focus:ring-primary-500 sm:text-sm"
            placeholder={placeholder}
            displayValue={(org) => (isOrganization ? (org && org.name) || '' : '')}
            maxLength={ORG_NAME_CHARACTER_LIMIT}
            autoComplete="off"
            onChange={(event) => {
              refine(event.target.value);
              if (onNameChange != null) {
                onNameChange(event.target.value);
              }
              setCurrentText(event.target.value.trim());
            }}
          />
          {searching && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <Spinner size={4} className="border-primary-600" />
          </div>
          )}
        </div>
        {(hits.length > 0 || !searching) && (
        <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          {[...(hits.find((o) => o.orgName === currentText) == null && currentText !== '' ? [{ id: null, name: currentText }] : []), ...hits.map((org) => ({ id: org.objectID, name: org.orgName }))].map((org) => (
            <Combobox.Option
              key={org.id}
              value={org}
              className={({ active }) => classNames(
                'relative select-none py-2 pl-3 pr-9 text-black cursor-pointer',
                active && 'bg-gray-200',
              )}
            >
              {() => (
                <>
                  <span className={classNames('block truncate')}>{org.name}</span>
                </>
              )}
            </Combobox.Option>
          ))}
        </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
};

export default connectAutoComplete(connectStateResults(SearchBar));
