import React, { useState } from 'react';

import i18n from '../../../i18n';
import createLogger from '../../../util/Logger';
import Auth from '../../../util/AuthHelper';
import Logo from '../../../components/Basic/Logo';
import LabelInput from '../../../components/Basic/LabelInput';
import RepeatPassword from '../components/RepeatPassword';
import Spinner from '../../../components/Basic/Spinner';
import Alert from '../../../components/Basic/Alert';
import Button from '../../../components/Basic/Button';

const logger = createLogger('OneUserCard');

export default function NewPasswordCard({
  title, subtitle, warningText = null, email: emailIn,
}) {
  const [error, setError] = useState(warningText);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState(emailIn);
  const [password, setPassword] = useState('');
  const [code, setCode] = useState('');

  const isButtonDisabled = () => {
    let result = (email == null || email.length === 0
      || password == null || password.length === 0
      || code == null || code.length === 0);

    return result;
  };

  const confirmClick = async () => {
    if (!isButtonDisabled()) {
      try {
        setIsLoading(true);
        await Auth.forgotPasswordSubmit(email, code, password);
        logger.debug('Change password success');
        await Auth.signIn({
          username: email,
          password,
        });
      } catch (err) {
        logger.error('Error setting new password', err);
        if (err.code === 'ExpiredCodeException') {
          setError(i18n.t('oneuser.errors.ExpiredCodeException'));
          await Auth.forgotPassword(email);
        } else if (err.code === 'CodeMismatchException') {
          setError(i18n.t('oneuser.errors.CodeMismatchException'));
        } else {
          setError(i18n.t('oneuser.errors.Unknown', { errCode: err.code }));
        }
        setIsLoading(false);
      }
    }
  };

  const submit = () => {
    if (!isButtonDisabled()) {
      confirmClick();
    }
  };

  return (
    <div className="w-[470px] min-h-[300px] flex bg-white rounded-2xl drop-shadow-2xl flex-col p-12 pt-10">
      <Logo />
      <h1 className="text-4xl font-semibold text-black mt-6">{title}</h1>
      <p className="text-black font-light text-base">{subtitle}</p>
      <LabelInput
        className="mt-3"
        type="email"
        label={i18n.t('oneuser.inputs.email')}
        value={emailIn}
        disabled={emailIn != null}
        onChange={(data, isValid) => {
          if (isValid) {
            setEmail(data);
          } else {
            setEmail('');
          }
        }}
      />
      <RepeatPassword onChange={(data, isValid) => {
        if (isValid) {
          setPassword(data);
        } else {
          setPassword('');
        }
      }}
      />
      <LabelInput
        className="mb-3 mt-3"
        type="text"
        label={i18n.t('oneuser.inputs.code')}
        disabled={isLoading}
        onChange={(data) => {
          setCode(data);
        }}
        onSubmit={submit}
      />
      {error && (<Alert severity="warning" className="mb-3" text={error} />)}
      <Button disabled={isButtonDisabled()} onClick={confirmClick}>
        {!isLoading && i18n.t('oneuser.newPasswordCard.confirm')}
        {isLoading && <div className="flex justify-center"><Spinner /></div>}
      </Button>
    </div>
  );
}
