import { configureStore } from '@reduxjs/toolkit';
import showReducer from './slices/CreateShowSlice';
import userReducer from './slices/UserSlice';
import extShowReducer from './slices/CreateExternalSlice';
import analyticsReducer from './slices/AnalyticsSlice';
import tenantReducer from './slices/TenantSlice';
import projectReducer from './slices/CreateProjectSlice';
import notificationReducer from './slices/NotificationSlice';
import navbarReducer from './slices/NavBarSlice';

export default configureStore({
  reducer: {
    createShow: showReducer,
    user: userReducer,
    createExternal: extShowReducer,
    analytics: analyticsReducer,
    tenant: tenantReducer,
    createProject: projectReducer,
    notification: notificationReducer,
    navbar: navbarReducer,
  },
});
