import React from 'react';

export default function Logo() {
  return (
    <p className="text-4xl">
      <span className="text-black font-normal font-['Roboto', 'Helvetica', 'Arial', sans-serif]">si</span>
      <span className="text-primary-500 font-bold font-['Roboto', 'Helvetica', 'Arial', sans-serif]">:cross</span>
    </p>
  );
}
