import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import {
  Chart as ChartJS, registerables,
} from 'chart.js';
import 'chartjs-adapter-moment';

import getPlugins from '../../util/AnalyticsUtil';

ChartJS.register(...registerables);

// Shades of primary color. Make a input prop if necessary
const DATASET_COLORS = [
  '#457995',
  '#94BBCC',
  '#DDEAF0',
  '#F2F8F9',
];

export default function DonutChart({
  labels, data, isError,
}) {
  return (
    <Doughnut
      data={{
        labels,
        datasets: [{
          data,
          backgroundColor: DATASET_COLORS,
          error: isError,
        }],
      }}
      options={{
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: { display: true, position: 'left', align: 'end' },
        },
        layout: {
          padding: {
            top: 15,
            right: 15,
            left: 10,
            bottom: 5,
          },
        },
      }}
      plugins={getPlugins()}
    />
  );
}
