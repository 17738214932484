/* eslint-disable max-len */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import i18n from '../../../i18n';
import createLogger from '../../../util/Logger';
import Auth from '../../../util/AuthHelper';
import Button from '../../../components/Basic/Button';
import LabelInput from '../../../components/Basic/LabelInput';
import Logo from '../../../components/Basic/Logo';
import Spinner from '../../../components/Basic/Spinner';
import Alert from '../../../components/Basic/Alert';
import RepeatPassword from '../components/RepeatPassword';

const logger = createLogger('SignUpCard');

const PROGRESS_STATES = {
  DATA: 0,
  CODE: 1,
};

export default function SignUpCard() {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [code, setCode] = useState('');
  const [progressState, setProgressState] = useState(PROGRESS_STATES.DATA);

  const nextClick = async () => {
    setError(null);
    let resp = null;
    switch (progressState) {
      case PROGRESS_STATES.DATA:
        // check user and IdP
        setIsLoading(true);
        try {
          await Auth.signUp({
            username: email,
            password,
            attributes: {
              email,
              given_name: firstName,
              family_name: lastName,
            },
          });
          setProgressState(PROGRESS_STATES.CODE);
        } catch (err) {
          logger.debug(err);
          if (err.code === 'UsernameExistsException') {
            setError(i18n.t('oneuser.errors.UsernameExistsException'));
          } else {
            setError(i18n.t('oneuser.errors.Unknown', { errCode: err.code }));
          }
        } finally {
          setIsLoading(false);
        }
        break;
      case PROGRESS_STATES.CODE:
        // do the login
        setIsLoading(true);
        try {
          await Auth.confirmSignUp(email, code);
          await Auth.signIn({
            username: email,
            password,
          });
        } catch (err) {
          logger.error(err);
          if (err.code === 'ExpiredCodeException') {
            setError(i18n.t('oneuser.errors.ExpiredCodeException'));
            await Auth.resendSignUp(email);
          } else if (err.code === 'CodeMismatchException') {
            setError(i18n.t('oneuser.errors.CodeMismatchException'));
          } else {
            setError(i18n.t('oneuser.errors.Unknown', { errCode: err.code }));
          }
          setIsLoading(false);
        }
        logger.debug('SignIn result', { resp });
        break;
      default:
        break;
    }
  };

  const isButtonDisabled = () => {
    let result = false;
    switch (progressState) {
      case PROGRESS_STATES.DATA:
        result = email.length === 0
          || password.length === 0
          || firstName.length === 0
          || lastName.length === 0
          || !termsAccepted;
        break;
      case PROGRESS_STATES.CODE:
        result = code.length === 0;
        break;
      default:
        break;
    }

    return result;
  };

  const submit = () => {
    if (!isButtonDisabled()) {
      nextClick();
    }
  };

  return (
    <div className="w-[470px] min-h-[300px] flex bg-white rounded-2xl drop-shadow-2xl flex-col p-12 pt-10">
      <Logo />
      <h1 className="text-4xl font-semibold text-black mt-6">{i18n.t('oneuser.signUpCard.title')}</h1>
      <p className="text-black font-light text-base">{i18n.t('oneuser.signUpCard.subtitle')}</p>
      <LabelInput
        className="mt-3"
        type="email"
        label={i18n.t('oneuser.inputs.email')}
        disabled={isLoading || progressState === PROGRESS_STATES.CODE}
        onChange={(data, isValid) => {
          if (isValid) {
            setEmail(data);
          } else {
            setEmail('');
          }
        }}
      />
      <LabelInput
        className="mt-3"
        type="text"
        label={i18n.t('oneuser.inputs.firstName')}
        disabled={isLoading || progressState === PROGRESS_STATES.CODE}
        onChange={(data, isValid) => {
          if (isValid) {
            setFirstName(data);
          } else {
            setFirstName('');
          }
        }}
      />
      <LabelInput
        className="mt-3"
        type="text"
        label={i18n.t('oneuser.inputs.lastName')}
        disabled={isLoading || progressState === PROGRESS_STATES.CODE}
        onChange={(data, isValid) => {
          if (isValid) {
            setLastName(data);
          } else {
            setLastName('');
          }
        }}
      />
      <RepeatPassword onChange={(data, isValid) => {
        if (isValid) {
          setPassword(data);
        } else {
          setPassword('');
        }
      }}
      />
      <div className="flex mb-3 mt-4 items-center">
        <input
          type="checkbox"
          className="rounded border-gray-300 text-primary-500 focus:ring-primary-500"
          onChange={(e) => {
            setTermsAccepted(e.target.checked);
          }}
        />
        <p className="pl-3">
          {i18n.t('oneuser.inputs.acceptTermsPrefix')}
          <a
            className="text-primary-500 hover:text-primary-400"
            href="https://sicross.com/terms"
            target="_blank"
            rel="noreferrer"
          >
            {i18n.t('oneuser.inputs.termsText')}
          </a>
          {i18n.t('oneuser.inputs.acceptTermsSuffix')}
          <a
            className="text-primary-500 hover:text-primary-400"
            href="https://sicross.com/privacy-policy/"
            target="_blank"
            rel="noreferrer"
          >
            {i18n.t('oneuser.inputs.privacyPolicyText')}
          </a>
          {i18n.t('oneuser.inputs.acceptPrivacySuffix')}
        </p>
      </div>
      {progressState === PROGRESS_STATES.CODE && (
        <>
          <Alert severity="info" text={i18n.t('oneuser.signUpCard.enterCode')} />
          <LabelInput
            className="mt-3 mb-3"
            type="text"
            label={i18n.t('oneuser.inputs.code')}
            disabled={isLoading}
            onChange={(data) => {
              setCode(data);
            }}
            onSubmit={submit}
          />
        </>
      )}
      {error && (<Alert severity="warning" className="mb-3" text={error} />)}
      <Button disabled={isButtonDisabled()} onClick={nextClick}>
        {!isLoading && (progressState === PROGRESS_STATES.DATA
          ? i18n.t('oneuser.signUpCard.signUp') : i18n.t('oneuser.signUpCard.confirm'))}
        {isLoading && <div className="flex justify-center"><Spinner /></div>}
      </Button>
      <div className="flex items-baseline mt-4">
        <p className="text-sm text-black pr-1 font-light">{i18n.t('oneuser.alreadyHaveUser')}</p>
        <Link
          to="/login"
          className="text-primary-500 hover:text-primary-400 text-sm font-medium"
        >
          {i18n.t('oneuser.links.signIn')}
        </Link>
      </div>
    </div>
  );
}
