import React, { useEffect, useState } from 'react';
import {
  CalendarDaysIcon,
  ChevronRightIcon,
  SpeakerWaveIcon,
} from '@heroicons/react/24/outline';
import moment from 'moment';

import { Link, useHistory } from 'react-router-dom';
import i18n from '../../../../../i18n';
import useAlertNotification from '../../../../../hooks/useAlertNotification';
import { defaultErrorHandler } from '../../../../../errors/ApplicationError';

import defaultImage from '../../../../../assets/img/image_placeholder.jpg';
import ClapsIcon from '../../../../../assets/svgIcons/ClapsIcon';
import EpisodeAPI from '../../../../../api/EpisodeApi';
import Utils from '../../../../../util/Utils';
import DurationFormatter from '../../../../../formatter/DurationFormatter';
import EpisodeStatusTag from '../../../../../components/EpisodeStatusTag';
import CreateEpisodeDialog from '../../../../../components/CreateEpisodeDialog';

const episodeApi = new EpisodeAPI();

export default function ShowEpisodes({ show }) {
  const [lastEvaluated, setLastEvaluated] = useState(null);
  const [episodes, setEpisodes] = useState(null);
  const [showCreateEpisodeDialog, setShowCreateEpisodeDialog] = useState(false);
  const { addError } = useAlertNotification();
  const history = useHistory();

  const loadMoreEpisodes = () => {
    if (lastEvaluated != null) {
      episodeApi.getEpisodeList(show.showId, 25, lastEvaluated)
        .then((data) => {
          setEpisodes([...episodes, ...data.episodes]);
          setLastEvaluated(data.lastEvaluated);
        }).catch((err) => {
          defaultErrorHandler(err, addError);
        });
    }
  };

  useEffect(() => {
    if (show != null) {
      episodeApi.getEpisodeList(show.showId, 25, null)
        .then((data) => {
          setEpisodes(data.episodes);
          setLastEvaluated(data.lastEvaluated);
        }).catch((err) => {
          defaultErrorHandler(err, addError);
        });
    }
  }, [show]);

  return (
    <>
      <h3 className="text-xl font-bold">{i18n.t('showDetails.episodes.episodes')}</h3>
      <div className="block">
        <button
          type="button"
          disabled={episodes == null}
          className="mt-10 px-2 py-1 disabled:bg-gray-300 disabled:text-gray-500 rounded bg-primary-500 hover:bg-primary-400 active:bg-primary-600 text-white text-sm"
          onClick={() => setShowCreateEpisodeDialog(true)}
        >
          Create Episode
        </button>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="flex-1 flex justify-center">
          <ol className="max-w-2xl flex-grow divide-y divide-gray-200">
            {/* Loading indicator */}
            {episodes == null && (
            <>
              <li className="p-2">
                <div className="w-full h-20 animate-pulse bg-gray-300 rounded-xl" />
              </li>
              <li className="p-2">
                <div className="w-full h-20 animate-pulse bg-gray-300 rounded-xl" />
              </li>
              <li className="p-2">
                <div className="w-full h-20 animate-pulse bg-gray-300 rounded-xl" />
              </li>
              <li className="p-2">
                <div className="w-full h-20 animate-pulse bg-gray-300 rounded-xl" />
              </li>
            </>
            )}
            {episodes && episodes.map((episode) => (
              <li
                key={episode.episodeId}
                className="cursor-pointer hover:bg-gray-100 rounded px-4 py-5"
              >
                <Link to={`/app/episode/${episode.episodeId}`}>
                  <div className="flex flex-row flex-1">
                    <img src={(show && show.imagePath) || defaultImage} alt="text" className="h-20 w-20 rounded-lg" />
                    <div className="flex-1 flex flex-col ml-3">
                      <span className="text-lg">{episode.name}</span>
                      <span className="text-base text-gray-500">{episode.userDisplayName}</span>
                      <EpisodeStatusTag status={episode.status} className="text-xs" />
                      <div className="flex justify-between mt-1">
                        <div className="flex items-center">
                          <CalendarDaysIcon className="h-5 w-5 text-gray-500" />
                          <span className="ml-1 mr-3 text-sm text-gray-500">{episode.publishDate != null ? moment(episode.publishDate).format('MMM DD, YYYY') : '-'}</span>
                          <span className="text-sm text-gray-500">{i18n.t('showDetails.episodes.length', { len: episode.duration != null ? DurationFormatter.formatDuration(episode.duration) : '-' })}</span>
                        </div>
                        <div className="flex items-center mr-2">
                          <SpeakerWaveIcon className="h-5 w-5 text-gray-500" />
                          <span className="ml-1 mr-1 text-sm text-gray-500">{episode.listenCount != null ? Utils.formatNumberUnitPrefix(episode.listenCount) : '-'}</span>
                          <ClapsIcon className="h-8 w-8 text-gray-500" />
                          <span className="ml-1 text-sm text-gray-500">{episode.clapCount != null ? Utils.formatNumberUnitPrefix(episode.clapCount) : '-'}</span>
                        </div>
                      </div>
                    </div>
                    <div className="self-center">
                      <ChevronRightIcon className="h-6 w-6 text-gray-400" />
                    </div>
                  </div>
                </Link>
              </li>
            ))}
          </ol>
        </div>
        {lastEvaluated != null
        && (
        <div className="flex justify-center">
          <button
            type="button"
            onClick={loadMoreEpisodes}
            className="px-3 py-1 disabled:bg-gray-300 disabled:text-gray-500 rounded hover:text-primary-600 active:text-primary-600 text-primary-500 text-sm"
          >
            {i18n.t('showDetails.episodes.loadMore')}
          </button>
        </div>
        )}
      </div>
      <CreateEpisodeDialog
        showDialog={showCreateEpisodeDialog}
        handleClose={() => setShowCreateEpisodeDialog(false)}
        handleSave={(episode) => {
          setShowCreateEpisodeDialog(false);
          history.push(`/app/episode/${episode.episodeId}`);
        }}
        show={show}
      />
    </>
  );
}
