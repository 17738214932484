/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import moment from 'moment-timezone';

import ChannelApi from '../api/ChannelApi';
import ProjectApi from '../api/ProjectApi';

import Constants from '../util/Constants';

import createLogger from '../util/Logger';

const logger = createLogger('CreateProjectSlice');

const channelApi = new ChannelApi();
const projectApi = new ProjectApi();

const setProducersToChannelMembers = (members, currentUserId) => members
  .filter((user) => user.id !== currentUserId) // exlude the current logged in user
  .map((user) => ({
    id: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    isProducer: false,
  }));

const setMeetingFromConfig = (meetingType, channelId, producers) => {
  let meetingSettings = [];
  let meeting = {
    meetingTypeId: meetingType.meetingTypeId,
    channelId,
    producer: [],
  };

  for (let i = 0; i < meetingType.meetingSettings.length; i++) {
    let { meetingSettingId, config } = meetingType.meetingSettings[i];

    if (meetingSettingId === Constants.TEMPLATES.MEETING_SETTINGS.PUBLISHER) {
      if (config.preselectAll) {
        meeting.producer = producers.map((p) => p.id);
      }
    } else {
      let { type, ...rest } = config;
      meetingSettings.push({
        meetingSettingId,
        config: {
          type,
          tz: moment.tz.guess(),
          selectedDays: rest.preselectedDays,
          time: rest.preselectedTime,
        },
      });
    }
  }
  meeting.meetingSettings = meetingSettings;
  return meeting;
};

export const createProjectSlice = createSlice({
  name: 'createProject',
  initialState: {
    activeStep: 0,
    templates: null,
    selectedTemplateId: null,
    selectedTemplateName: null,
    project: {
      projectName: '',
      projectDescription: '',
    },
    channels: null,
    selectedChannel: null,
    producers: [],
    meetingTypes: null,
    meetings: [],
    isSetupConfiguration: false,
    activeMeetingTypeStep: 0,
    isCreating: false,
    noPublicAuth: true,
    isPublic: false,
  },
  reducers: {
    setActiveStep: (state, action) => {
      logger.debug('setActiveStep: ', action.payload);
      state.activeStep = action.payload;
    },
    setTemplates: (state, action) => {
      state.templates = action.payload;
    },
    setSelectedTemplate: (state, action) => {
      // clearing template specific data
      state.meetingTypes = null;
      state.meetings = [];

      const { templateId, name } = action.payload;
      state.selectedTemplateId = templateId;
      state.selectedTemplateName = name;
    },
    setProjectName: (state, action) => {
      state.project.projectName = action.payload;
    },
    setProjectDescription: (state, action) => {
      state.project.projectDescription = action.payload;
    },
    setChannels: (state, action) => {
      state.channels = action.payload.channels;
      if (!state.channels) {
        // clear selected channel and producers
        state.selectedChannel = null;
        state.producers = [];
      } else if (state.selectedChannel != null) {
        let selectedChannel = state.channels.find((channel) => channel.channelId === state.selectedChannel);
        let producers = setProducersToChannelMembers(
          selectedChannel.members,
          action.payload.currentUserId,
        ).map((producer) => {
          let currentProducer = state.producers.find((p) => p.id === producer.id);
          return {
            id: producer.id,
            firstName: producer.firstName,
            lastName: producer.lastName,
            isProducer: currentProducer != null ? currentProducer.isProducer : false,
          };
        });
        state.producers = producers;
      }
    },
    setSelectedChannel: (state, action) => {
      const { channelId, created, currentUserId } = action.payload;
      state.selectedChannel = channelId;
      logger.debug('Create PROJECT: setSelectedChannel:', { channelId, created });

      if (created) {
        // clear the existing channels, so that it reloads again
        state.channels = null;
        state.producers = [];
        logger.debug('Create PROJECT: clear out channels & producers');
      } else if (state.selectedChannel != null && state.channels != null) {
        let selectedChannel = state.channels.find((channel) => channel.channelId === state.selectedChannel);
        if (selectedChannel != null) {
          state.producers = setProducersToChannelMembers(selectedChannel.members, currentUserId);
        } else {
          logger.warn('Create PROJECT: selectedChannel is not found: ');
        }
      } else {
        state.producers = [];
      }
    },
    setInitialMeetingTypes: (state, action) => {
      let { meetingTypes } = action.payload;
      state.meetingTypes = meetingTypes;

      // set the meetings also based on defaultActiveStatus
      let meetings = meetingTypes.filter((mt) => mt.defaultActiveStatus).map((mt) => setMeetingFromConfig(mt, state.selectedChannel, state.producers));
      logger.debug('meetings: ', meetings);
      state.meetings = meetings;
    },
    setMeetingTypes: (state, action) => {
      let selectedMeetingTypeId = action.payload;
      logger.debug('meetingTypeId: ', selectedMeetingTypeId);

      const newMeetingType = state.meetingTypes.map((mtg) => {
        if (mtg.meetingTypeId === selectedMeetingTypeId) {
          return { ...mtg, defaultActiveStatus: !mtg.defaultActiveStatus };
        }
        return mtg;
      });
      state.meetingTypes = newMeetingType;

      let clone = state.meetings;
      let existingIndex = state.meetings.findIndex((m) => m.meetingTypeId === selectedMeetingTypeId);
      if (existingIndex !== -1) {
        clone.splice(existingIndex, 1);
      } else {
        let meetingType = state.meetingTypes.find((mtg) => mtg.meetingTypeId === selectedMeetingTypeId);
        clone.push(setMeetingFromConfig(meetingType, state.selectedChannel, state.producers));
      }
      state.meetings = clone;
      logger.debug('state.meetings', JSON.stringify(clone, null, 2));
    },
    setSetupConfiguration: (state, action) => {
      state.isSetupConfiguration = action.payload;
    },
    setActiveMeetingTypeStep: (state, action) => {
      logger.debug('setActiveMeetingTypeStep', action.payload);
      state.activeMeetingTypeStep = action.payload;
    },
    updateMeetingSetting: (state, action) => {
      logger.debug('Updating meeting data for meeting type:', JSON.stringify(action.payload));
      let { updated, meetingTypeId } = action.payload;
      let mtgIdx = state.meetings.findIndex((m) => m.meetingTypeId === meetingTypeId);

      logger.debug('meetingType: index', mtgIdx);

      let clone = state.meetings;
      let mtg = clone[mtgIdx];
      logger.debug('meetingType', JSON.stringify(mtg));
      mtg = { ...mtg, producer: updated.producers };

      let cadence = mtg.meetingSettings.find((ms) => ms.meetingSettingId === Constants.TEMPLATES.MEETING_SETTINGS.RECURRENCE);

      if (cadence) {
        let config = {
          ...cadence.config, selectedDays: updated.settings.days, time: updated.settings.time, tz: updated.settings.tz,
        };

        cadence = { ...cadence, config };
        logger.debug('cadence updated', JSON.stringify(cadence, null, 2));
        mtg.meetingSettings = [cadence];
      } else {
        mtg.meetingSettings = [];
      }

      clone.splice(mtgIdx, 1, mtg);
      logger.debug('Updating meeting data for meeting type:', JSON.stringify(clone));
      state.meetings = clone;
    },
    setNoPublicAuth: (state, action) => {
      state.noPublicAuth = action.payload;
    },
    clear: (state) => {
      state.activeStep = 0;
      state.templates = null;
      state.selectedTemplateId = null;
      state.selectedTemplateName = null;
      state.project = { projectName: '', projectDescription: '' };
      state.channels = null;
      state.selectedChannel = null;
      state.producers = [];
      state.meetingTypes = null;
      state.meetings = [];
      state.isSetupConfiguration = false;
      state.activeMeetingTypeStep = 0;
      state.isCreating = false;
      state.noPublicAuth = true;
    },
    setIsCreating: (state, action) => {
      state.isCreating = action.payload;
    },
  },
});

export const {
  setActiveStep,
  setTemplates,
  setSelectedTemplate,
  setProjectName,
  setProjectDescription,
  setInitialMeetingTypes,
  setMeetingTypes,
  setSetupConfiguration,
  setActiveMeetingTypeStep,
  updateMeetingSetting,
  clear,
  setIsCreating,
  setNoPublicAuth,
} = createProjectSlice.actions;

// Thunks
export const getTemplates = () => async (dispatch) => {
  let data = await projectApi.getTemplates();
  dispatch(setTemplates(data.templates));
};

export const setChannels = (channels) => async (dispatch, getState) => {
  let currentUserId = getState().user.user.id;
  dispatch(createProjectSlice.actions.setChannels({ channels, currentUserId }));
};

export const loadChannels = () => async (dispatch, getState) => {
  const existingChannels = getState().createProject.channels;

  logger.debug('existingChannels', { existingChannels });

  if (!existingChannels || existingChannels.length === 0) {
    channelApi.getChannels(null).then((data) => {
      logger.debug('Server responded with success status', { data });
      dispatch(setChannels(data));
    });
  }
};

export const setSelectedChannel = (channelId, created) => async (dispatch, getState) => {
  let currentUserId = getState().user.user.id;
  dispatch(createProjectSlice.actions.setSelectedChannel({ channelId, currentUserId, created }));
};

export const getMeetingTypes = (templateId) => async (dispatch, getState) => {
  const { meetingTypes } = getState().createProject;

  logger.debug('already loaded meetingTypes', { meetingTypes });

  if (!meetingTypes) {
    let data = await projectApi.getMeetingTypes(templateId);
    logger.debug('Got meeting types', data);
    dispatch(setInitialMeetingTypes(data));
  }
};

// Export Reducer
export default createProjectSlice.reducer;
