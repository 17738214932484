import moment from 'moment';
import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS, registerables,
} from 'chart.js';
import 'chartjs-adapter-moment';

import i18n from '../../i18n';
import getPlugins from '../../util/AnalyticsUtil';

ChartJS.register(...registerables);

export default function HourlyLineChart({
  labels, data, isError,
}) {
  return (
    <Line
      data={{
        labels,
        datasets: [
          {
            data,
            error: isError,
            borderColor: '#457995',
            tension: 0.4,
            pointRadius: 0,
            pointBackgroundColor: '#94BBCC',
          },
        ],
      }}
      options={{
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
          mode: 'nearest',
          axis: 'x',
          intersect: false,
          includeInvisible: true,
        },
        plugins: {
          legend: { display: false },
          tooltip: {
            time: {
              unit: 'hour',
              displayFormats: {
                hour: 'HH',
              },
            },
            callbacks: {
              title(context) {
                return moment(context[0].label).format('HH:00');
              },
            },
          },
        },
        layout: {
          padding: {
            top: 15,
            right: 15,
            left: 10,
            bottom: 5,
          },
        },
        scales: {
          x: {
            type: 'time',
            title: {
              display: true,
              text: i18n.t('analytics.hour'),
            },
            time: {
              unit: 'hour',
              displayFormats: {
                hour: 'HH',
              },
            },
            grid: {
              display: false,
            },
          },
          y: {
            title: {
              display: true,
              text: i18n.t('analytics.listener'),
            },
            ticks: {
              precision: 0,
              display: true,
            },
          },
        },
      }}
      plugins={getPlugins()}
    />
  );
}
