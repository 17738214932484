/* eslint-disable max-len */
import React, { useState } from 'react';
import { Cache } from 'aws-amplify';
import { Link, useLocation } from 'react-router-dom';

import i18n from '../../../i18n';
import createLogger from '../../../util/Logger';
import AuthApi from '../../../api/AuthApi';
import Auth, { AUTH_PROVIDER_NAME_CACHE_KEY } from '../../../util/AuthHelper';
import LabelInput from '../../../components/Basic/LabelInput';
import Logo from '../../../components/Basic/Logo';
import Button from '../../../components/Basic/Button';
import Spinner from '../../../components/Basic/Spinner';
import Alert from '../../../components/Basic/Alert';

const logger = createLogger('OneUserCard');
const authApi = new AuthApi();

const PROGRESS_STATES = {
  EMAIL: 0,
  PASSWORD: 1,
};

export default function LoginCard({ resetRequired, needConfirmEmail }) {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [progressState, setProgressState] = useState(PROGRESS_STATES.EMAIL);
  const location = useLocation();

  const nextClick = async () => {
    let resp = null;
    switch (progressState) {
      case PROGRESS_STATES.EMAIL:
        // check user and IdP
        setIsLoading(true);
        resp = await authApi.checkUser(email);
        setIsLoading(false);

        if (resp.providerName != null && resp.providerName.length > 0) {
          // federated sign in
          logger.debug('redirect to IDP federated sign-in', { providerName: resp.providerName });
          await Cache.setItem(AUTH_PROVIDER_NAME_CACHE_KEY, resp.providerName);
          if (location.state?.from?.pathname != null) {
            await Cache.setItem('loginRedirectUrl', location.state.from.pathname);
          }
          await Auth.federatedSignIn({ provider: resp.providerName });
        } else if (resp.userResetRequired) {
          await Auth.forgotPassword(email);
          resetRequired(email);
        } else {
          setProgressState(PROGRESS_STATES.PASSWORD);
        }
        break;
      case PROGRESS_STATES.PASSWORD:
        // do the login
        setIsLoading(true);
        try {
          resp = await Auth.signIn({
            username: email,
            password,
          });
        } catch (err) {
          logger.error(err);
          if (err.code === 'UserNotConfirmedException') {
            needConfirmEmail(email, password);
          } else if (err.code === 'NotAuthorizedException') {
            setError(i18n.t('oneuser.errors.NotAuthorizedException'));
          } else {
            setError(i18n.t('oneuser.errors.Unknown', { errCode: err.code }));
          }
          setIsLoading(false);
        }
        logger.debug('SignIn result', { resp });
        break;
      default:
        break;
    }
  };

  const isButtonDisabled = () => {
    let result = false;
    switch (progressState) {
      case PROGRESS_STATES.EMAIL:
        result = email.length === 0;
        break;
      case PROGRESS_STATES.PASSWORD:
        result = password.length === 0;
        break;
      default:
        break;
    }

    return result;
  };

  const submit = () => {
    if (!isButtonDisabled()) {
      nextClick();
    }
  };

  const signInWithGoogle = async () => {
    await Cache.setItem(AUTH_PROVIDER_NAME_CACHE_KEY, 'Google');
    if (location.state?.from?.pathname != null) {
      await Cache.setItem('loginRedirectUrl', location.state.from.pathname);
    }
    Auth.federatedSignIn({
      provider: 'Google',
    });
  };

  return (
    <div className="w-[470px] min-h-[300px] flex bg-white rounded-2xl drop-shadow-2xl flex-col p-12 pt-10">
      <Logo />
      <h1 className="text-4xl font-semibold text-black mt-6">{i18n.t('oneuser.loginCard.title')}</h1>
      <LabelInput
        className="mt-8"
        type="email"
        label={i18n.t('oneuser.inputs.email')}
        disabled={isLoading || progressState === PROGRESS_STATES.PASSWORD}
        onChange={(data, isValid) => {
          if (isValid) {
            setEmail(data);
          } else {
            setEmail('');
          }
        }}
        onSubmit={submit}
      />
      {progressState === PROGRESS_STATES.PASSWORD && (
      <LabelInput
        className="mt-8"
        type="password"
        label={i18n.t('oneuser.inputs.password')}
        onChange={(data, isValid) => {
          if (isValid) {
            setPassword(data);
          } else {
            setPassword('');
          }
        }}
        onSubmit={submit}
      />
      )}
      <div className="self-end mt-2 mb-5">
        <Link
          to="/forgotPassword"
          className="text-primary-500 hover:text-primary-400 text-base font-medium"
        >
          {i18n.t('oneuser.links.forgotPassword')}
        </Link>
      </div>
      {error && (
        <Alert severity="warning" className="mb-3" text={error} />
      )}
      <Button disabled={isButtonDisabled()} onClick={nextClick}>
        {!isLoading && (progressState === PROGRESS_STATES.EMAIL
          ? i18n.t('oneuser.loginCard.next') : i18n.t('oneuser.loginCard.login'))}
        {isLoading && <div className="flex justify-center"><Spinner /></div>}
      </Button>
      <div className="flex items-baseline mt-4">
        <p className="text-sm text-black pr-1 font-light">{i18n.t('oneuser.loginCard.footer')}</p>
        <Link
          to="/signup"
          className="text-primary-500 hover:text-primary-400 text-sm font-medium"
        >
          {i18n.t('oneuser.links.signUp')}
        </Link>
      </div>
      <div className="flex items-center my-4">
        <span className="h-[1px] bg-neutral-300 flex-1" />
        <span className="px-2">{i18n.t('oneuser.loginCard.or')}</span>
        <span className="h-[1px] bg-neutral-300 flex-1" />
      </div>
      <div className="self-center">
        <button
          className="
            text-black
            rounded
            border-primary-500
            hover:border-primary-300
            active:bg-primary-50
            border
            py-2
            px-4
            flex
            flex-row
            items-center"
          type="button"
          onClick={signInWithGoogle}
        >
          <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
            <path d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z" fill="#4285F4" />
            <path d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z" fill="#34A853" />
            <path d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z" fill="#FBBC05" />
            <path d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z" fill="#EA4335" />
            <path d="M1 1h22v22H1z" fill="none" />
          </svg>
          <span className="ml-4 text-base">{i18n.t('oneuser.loginCard.continueWithGoogle')}</span>
        </button>
      </div>
    </div>
  );
}
