/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const tenantSlice = createSlice({
  name: 'tenant',
  initialState: {
    plan: null,
    planEndDate: null,
    spaceName: '',
    restrictions: {},
    aiDisabled: false,
  },
  reducers: {
    setSpaceName: (state, action) => {
      state.spaceName = action.payload;
    },
    setPlan: (state, action) => {
      state.plan = action.payload;
    },
    setPlanEndDate: (state, action) => {
      state.planEndDate = action.payload;
    },
    setRestrictions: (state, action) => {
      state.restrictions = action.payload;
    },
    setAiDisabled: (state, action) => {
      state.aiDisabled = action.payload;
    },
    clear: (state) => {
      state.plan = null;
      state.planEndDate = null;
      state.spaceName = '';
      state.restrictions = {};
      state.aiDisabled = false;
    },
  },
});

export const {
  setPlan,
  setPlanEndDate,
  setSpaceName,
  setRestrictions,
  setAiDisabled,
  clear,
} = tenantSlice.actions;

// Export Reducer
export default tenantSlice.reducer;
