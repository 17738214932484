import React, { useEffect, Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';
import i18n from '../../../../../i18n';

const TITLE_MAX_LENGTH = 100;
const DESCRIPTION_MAX_LENGTH = 4000;

export default function EpisodeDialog({
  open, onClose, episode, onEpisodeEvent, isAdd,
}) {
  const [title, setTitle] = useState(
    isAdd ? '' : episode.title,
  );
  const [description, setDescription] = useState(
    isAdd ? '' : episode.description,
  );
  const [allowError, setAllowError] = useState(false);

  // const [description, setDescription] = React.useState(episode.description || '');

  useEffect(() => {
    if (open) {
      setTitle(isAdd ? '' : episode.title);
      setDescription(isAdd ? '' : episode.description);
    }
  }, [open, isAdd, episode]);

  const handleEpisodeEvent = () => {
    const newEpisode = { title, description, selected: true };
    onEpisodeEvent(newEpisode);
    setAllowError(false);
  };

  const handleClose = () => {
    onClose();
    setTitle('');
    setDescription('');
    setAllowError(false);
  };

  return (
    <Transition show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-30 overflow-y-auto"
        onClose={onClose}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-2xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                {isAdd ? 'Add Episode' : 'Update Episode'}
              </Dialog.Title>
              <div className="mt-2">
                <div className="relative mt-5">
                  <label
                    htmlFor="title"
                    className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs text-gray-600"
                  >
                    {i18n.t('createShow.episodeStep.dialog.episodeTitle')}
                  </label>
                  <input
                    type="text"
                    name="title"
                    id="title"
                    className={classNames(
                      'block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-500 text-sm sm:leading-6',
                      title.length === 0 && allowError && 'focus:ring-red-500',
                    )}
                    value={title}
                    maxLength={TITLE_MAX_LENGTH}
                    onChange={(e) => {
                      setTitle(e.target.value);
                      setAllowError(true);
                    }}
                    autoComplete="off"
                  />
                  <div>
                    <span className={classNames('text-xs ml-1', title.length === 0 && allowError ? 'text-red-600' : 'text-gray-700')}>{`${title.length}/${TITLE_MAX_LENGTH}`}</span>
                    {title.length === 0 && allowError && <span className="text-xs ml-1 text-red-600">{i18n.t('episodeDetails.preparation.blockTitleEmpty')}</span>}
                  </div>
                </div>
                <div className="relative mt-6">
                  <label htmlFor="description" className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs text-gray-600">
                    {i18n.t('createShow.episodeStep.dialog.episodeDescription')}
                  </label>
                  <div className="mt-2">
                    <textarea
                      rows={7}
                      name="description"
                      id="description"
                      className="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-500 sm:py-1.5 sm:text-sm sm:leading-6"
                      maxLength={DESCRIPTION_MAX_LENGTH}
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </div>
                  <span className="text-gray-700 text-xs ml-1">{`${description.length}/${DESCRIPTION_MAX_LENGTH}`}</span>
                </div>
              </div>

              <div className="mt-4">
                <button
                  type="button"
                  disabled={title.length === 0}
                  className="inline-flex justify-center rounded-md border border-transparent disabled:bg-gray-300 disabled:text-gray-500 bg-primary-500 px-4 py-2 text-base font-medium text-white shadow-sm enabled:hover:bg-primary-400 focus:outline-none focus:ring-2 enabled:focus:ring-primary-500 focus:ring-offset-2 sm:text-sm"
                  onClick={handleEpisodeEvent}
                >
                  {isAdd ? 'Add Episode' : 'Update Episode'}
                </button>
                <button
                  type="button"
                  className="ml-2 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm enabled:hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm disabled:bg-gray-300 disabled:text-gray-500 mx-2 enabled:focus:ring-primary-500"
                  onClick={handleClose}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
