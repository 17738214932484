import { API } from './api';

export default class UserApi {
  constructor() {
    this.api = new API();
  }

  async joinWaitlist() {
    await this.api.create('waitlist');
  }

  async updateBillingOwner(userId) {
    await this.api.update(`user/${userId}/billingOwner`);
  }
}
