import React, { useState } from 'react';

import i18n from '../../../i18n';
import Auth from '../../../util/AuthHelper';
import Logo from '../../../components/Basic/Logo';
import LabelInput from '../../../components/Basic/LabelInput';
import Spinner from '../../../components/Basic/Spinner';
import Alert from '../../../components/Basic/Alert';
import Button from '../../../components/Basic/Button';

export default function ConfirmEmailCard({ email, password }) {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [code, setCode] = useState('');

  const nextClick = async () => {
    try {
      setIsLoading(true);
      await Auth.confirmSignUp(email, code);
      await Auth.signIn({
        username: email,
        password,
      });
    } catch (err) {
      if (err.code === 'ExpiredCodeException') {
        setError(i18n.t('oneuser.errors.ExpiredCodeException'));
        await Auth.resendSignUp(email);
      } else if (err.code === 'CodeMismatchException') {
        setError(i18n.t('oneuser.errors.CodeMismatchException'));
      } else {
        setError(i18n.t('oneuser.errors.Unknown', { errCode: err.code }));
      }
      setIsLoading(false);
    }
  };

  const isButtonDisabled = () => code.length === 0;

  const submit = () => {
    if (!isButtonDisabled()) {
      nextClick();
    }
  };

  return (
    <div className="w-[470px] min-h-[300px] flex bg-white rounded-2xl drop-shadow-2xl flex-col p-12 pt-10">
      <Logo />
      <h1 className="text-4xl font-semibold text-black mt-6">{i18n.t('oneuser.confirmEmailCard.title')}</h1>
      <p className="text-black font-light text-base">{i18n.t('oneuser.confirmEmailCard.subtitle')}</p>
      <LabelInput
        className="mt-7 mb-4"
        type="text"
        label={i18n.t('oneuser.inputs.code')}
        disabled={isLoading}
        onChange={(data, isValid) => {
          if (isValid) {
            setCode(data);
          } else {
            setCode('');
          }
        }}
        onSubmit={submit}
      />
      {error && (<Alert severity="warning" className="mb-3" text={error} />)}
      <Button disabled={isButtonDisabled()} onClick={nextClick}>
        {!isLoading && i18n.t('oneuser.confirmEmailCard.confirm')}
        {isLoading && <div className="flex justify-center"><Spinner /></div>}
      </Button>
    </div>
  );
}
