import React, { useEffect, useState } from 'react';
import ShowAPI from '../../../../../api/ShowApi';
import ChartLoadingIndicator from '../../../../../components/Analytics/ChartLoadingIndicator';
import DonutChart from '../../../../../components/Analytics/DonutChart';
import MonthlyBarChart from '../../../../../components/Analytics/MonthlyBarChart';
import { defaultErrorHandler } from '../../../../../errors/ApplicationError';
import useAlertNotification from '../../../../../hooks/useAlertNotification';
import i18n from '../../../../../i18n';
import Utils from '../../../../../util/Utils';

const showApi = new ShowAPI();

export default function ShowAnalytics({ show }) {
  const { addError } = useAlertNotification();

  const [totalPlays, setTotalPlays] = useState(null);
  const [averagePlays, setAveragePlays] = useState(null);
  const [topEpisodes, setTopEpisodes] = useState(null);
  const [dropOffData, setDropOffData] = useState(null);
  const [monthlyLabels, setMonthlyLabels] = useState(null);
  const [monthlyListeningData, setMonthlyListeningData] = useState(null);
  const [monthlyListenerData, setMonthlyListenerData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (show == null) {
      return;
    }

    showApi.getShowAnalytics(show.showId).then((data) => {
      setTotalPlays(data.totalListeners);
      setAveragePlays(Math.round(data.avgListeners));
      setTopEpisodes(data.top3);
      setDropOffData([data.dropOff.finishedListeningCount, data.dropOff.listenerCount - data.dropOff.finishedListeningCount]);

      let monthlyListeningCompute = [[], []];
      let monthlyLabelsCompute = [];
      data.monthlyListening.forEach((listeningData) => {
        monthlyLabelsCompute.push(listeningData.month);
        monthlyListeningCompute[0].push(Math.round(listeningData.value / 1000 / 60));
        monthlyListeningCompute[1].push(Math.round(listeningData.delta / 1000 / 60));
      });
      setMonthlyLabels(monthlyLabelsCompute);
      setMonthlyListeningData(monthlyListeningCompute);

      let monthlyListenerCompute = [];
      data.monthlyListeners.forEach((listenerData) => {
        monthlyListenerCompute.push(listenerData.value);
      });
      setMonthlyListenerData(monthlyListenerCompute);

      setIsLoading(false);
    }).catch((err) => {
      defaultErrorHandler(err, addError);
    });
  }, [show]);

  return (
    <>
      <h3 className="text-xl font-bold">{i18n.t('showDetails.analytics.analytics')}</h3>
      <div className="mt-8 flex flex-col">
        <dl className="mt-5 grid grid-cols-2 gap-5 lg:grid-cols-4">
          <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6 flex flex-col">
            <dt className="truncate text-sm font-medium text-gray-500">{i18n.t('showDetails.analytics.totalListener')}</dt>
            <div className="flex flex-1 items-center justify-center">
              {isLoading ? <ChartLoadingIndicator /> : <dd className="mt-2 text-5xl text-center font-semibold tracking-tight text-primary-500 align-middle">{Utils.formatNumberUnitPrefix(totalPlays)}</dd>}
            </div>
          </div>
          <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6 flex flex-col">
            <dt className="truncate text-sm font-medium text-gray-500">{i18n.t('showDetails.analytics.averageListener')}</dt>
            <div className="flex flex-1 items-center justify-center">
              {isLoading ? <ChartLoadingIndicator /> : <dd className="mt-2 text-5xl text-center font-semibold tracking-tight text-primary-500">{Utils.formatNumberUnitPrefix(averagePlays)}</dd>}
            </div>
          </div>
          <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6 col-span-2">
            <dt className="truncate text-sm font-medium text-gray-500">{i18n.t('showDetails.analytics.topEpisodes')}</dt>
            <table className="min-w-full table-auto mt-2">
              <thead className="text-gray-400 uppercase text-xs">
                <tr>
                  <th className="text-left font-medium text-gray-400">#</th>
                  <th className="text-left font-medium text-gray-400">{i18n.t('showDetails.analytics.episode')}</th>
                  <th className="text-right font-medium text-gray-400">{i18n.t('showDetails.analytics.listener')}</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {isLoading ? <ChartLoadingIndicator /> : topEpisodes.map((topEpisode, i) => (
                  <tr key={topEpisode.episodeId}>
                    <td className="py-1.5">{i + 1}</td>
                    <td>{topEpisode.name}</td>
                    <td className="text-right">{Utils.formatNumberUnitPrefix(topEpisode.listeners)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </dl>
        <dl className="mt-5 grid grid-cols-1 gap-5 xl:grid-cols-2">
          <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
            <dt className="truncate text-sm font-medium text-gray-500">{i18n.t('showDetails.analytics.listeningOverTime')}</dt>
            <div className="relative h-80">
              {isLoading ? <ChartLoadingIndicator /> : <MonthlyBarChart stacked labels={monthlyLabels} data={monthlyListeningData} isError={null} />}
            </div>
          </div>
          <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
            <dt className="truncate text-sm font-medium text-gray-500">{i18n.t('showDetails.analytics.dropOffRate')}</dt>
            <div className="relative h-80">
              {isLoading ? <ChartLoadingIndicator /> : <DonutChart labels={[i18n.t('showDetails.analytics.listenedToEnd'), i18n.t('showDetails.analytics.droppedOff')]} data={dropOffData} isError={null} />}
            </div>
          </div>
        </dl>
        <dl className="mt-5 grid grid-cols-1 gap-5">
          <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
            <dt className="truncate text-sm font-medium text-gray-500">{i18n.t('showDetails.analytics.listenerOverTime')}</dt>
            <div className="relative h-80">
              {isLoading ? <ChartLoadingIndicator /> : <MonthlyBarChart labels={monthlyLabels} data={monthlyListenerData} isError={null} />}
            </div>
          </div>
        </dl>
      </div>
    </>
  );
}
