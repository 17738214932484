import ApplicationError from './ApplicationError';
import i18n from '../i18n';

export default class ApplicationAPIError extends ApplicationError {
  constructor(status, backendErrorCode) {
    super(ApplicationAPIError.getBackendMessage(backendErrorCode));
    this.requestStatusCode = status;
    this.backendErrorCode = backendErrorCode;
  }

  static getBackendMessage(backendErrorCode) {
    return Number.isInteger(backendErrorCode)
      ? i18n.t(`backendErrors.${backendErrorCode}`) : i18n.t('backendErrors.unknown');
  }
}
