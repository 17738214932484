/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useRef, useState } from 'react';
import {
  ChatBubbleOvalLeftEllipsisIcon,
  CloudArrowDownIcon,
  DocumentIcon,
  PauseCircleIcon,
  PlayCircleIcon,
} from '@heroicons/react/24/solid';
import { useSelector } from 'react-redux';
import moment from 'moment';

import Slider from '../../Shows/components/SharedShow/components/player/Slider';
import useAlertNotification from '../../../hooks/useAlertNotification';
import EpisodeAPI from '../../../api/EpisodeApi';
import i18n from '../../../i18n';
import { defaultErrorHandler } from '../../../errors/ApplicationError';
import Utils from '../../../util/Utils';
import UpdatePodcastFileButton from './UpdatePodcastFileButton';
import Spinner from '../../../components/Basic/Spinner';

function parseTime(seconds) {
  let hours = Math.floor(seconds / 3600);
  let minutes = Math.floor((seconds - hours * 3600) / 60);
  let sec = seconds - hours * 3600 - minutes * 60;
  return [hours, minutes, sec];
}

function formatHumanTime(seconds) {
  let [h, m, s] = parseTime(seconds);
  return `${h} hour${h === 1 ? '' : 's'}, ${m} minute${m === 1 ? '' : 's'
  }, ${s} second${s === 1 ? '' : 's'}`;
}

const episodeApi = new EpisodeAPI();

export default function EpisodeProductionUpload({ episode, refreshEpisode, setForceRecordScreen }) {
  const user = useSelector((s) => s.user.user);
  const [commentText, setCommentText] = useState('');
  const [isAddingComment, setIsAddingComment] = useState('');
  const [isPlaying, setIsPlaying] = useState(false);
  const [isSeeking, setIsSeeking] = useState(false);
  const [playbackCurrentTime, setPlaybackCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [feed, setFeed] = useState(null);

  const textAreaRef = useRef(null);
  const audioRef = useRef(null);
  const { addError } = useAlertNotification();

  useEffect(() => {
    if (episode == null) {
      return;
    }

    episodeApi.getEpisodeProductionFeed(episode.episodeId)
      .then((feedResp) => {
        setFeed(feedResp.feed);
      })
      .catch((e) => {
        defaultErrorHandler(e, addError);
        setFeed([]);
      });
  }, [episode]);

  useEffect(() => {
    if (textAreaRef != null && textAreaRef.current != null) {
      textAreaRef.current.scrollTop = textAreaRef.current.scrollHeight;
    }
  }, [commentText]);

  const addComment = async () => {
    if (isAddingComment || commentText.trim() === '') {
      return;
    }

    setIsAddingComment(true);
    try {
      await episodeApi.addEpisodeProductionComment(episode.episodeId, commentText);
      setFeed([{
        type: 2, user: { firstName: user.firstName, lastName: user.lastName }, content: commentText, commentId: new Date().toString(), timestamp: new Date(),
      }, ...feed]);
      setCommentText('');
    } catch (e) {
      defaultErrorHandler(e, addError);
    } finally {
      setIsAddingComment(false);
    }
  };

  const commentTimestamp = () => {
    let newText = commentText;
    if (commentText.length > 0) {
      newText += '\n';
    }
    newText += `${Utils.formatTime(Math.trunc(playbackCurrentTime))}: `;
    setCommentText(newText);
    if (textAreaRef != null && textAreaRef.current != null) {
      textAreaRef.current.focus();
      textAreaRef.current.scrollTop = textAreaRef.current.scrollHeight;
    }
  };

  const lastUploaded = feed?.find((f) => f.type === 1);
  const isPublished = episode?.status === 'published';

  return (
    <div className="w-full flex flex-col items-center">
      <div className="mt-4 flex flex-col items-center max-w-xl lg:w-120">
        <div className="flex flex-col w-full rounded-lg border border-gray-300 px-4 py-2">
          <div className="flex-1 flex flex-col items-center w-full">
            <div className="flex flex-col">
              <span className="text-black">{`${lastUploaded == null ? 'Unknown' : `${lastUploaded?.user.firstName} ${lastUploaded?.user.lastName}`} - ${lastUploaded != null ? moment(lastUploaded.timestamp).fromNow() : 'Unknown'}`}</span>
              <span className="text-gray-500">{`Length: ${Utils.formatTime(duration || Math.trunc(episode.duration / 1000))}`}</span>
            </div>
            <div className="flex flex-row gap-1 mt-3">
              <audio
                className="hidden"
                src={episode.podcastPath}
                onError={() => addError(i18n.t('episodeDetails.production.failedLoadingPodcast'))}
                onPlay={() => setIsPlaying(true)}
                onPause={() => setIsPlaying(false)}
                onDurationChange={(e) => setDuration(Math.trunc(e.target.duration))}
                onTimeUpdate={(e) => {
                  if (!isSeeking) {
                    setPlaybackCurrentTime(e.target.currentTime);
                  }
                }}
                ref={audioRef}
              />
              <button
                type="button"
                className="flex flex-row rounded-full text-primary-500 hover:text-primary-400 active:text-primary-600 justify-center"
                onClick={() => {
                  if (audioRef == null || audioRef.current == null) {
                    return;
                  }

                  if (audioRef.current.paused) {
                    audioRef.current.play();
                  } else {
                    audioRef.current.pause();
                  }
                }}
              >
                {isPlaying ? <PauseCircleIcon className="w-8 h-8" /> : <PlayCircleIcon className="w-8 h-8" /> }
              </button>
              <a
                title={i18n.t('episodeDetails.production.download')}
                type="button"
                className="flex flex-row rounded-full text-primary-500 hover:text-primary-400 active:text-primary-600 justify-center"
                href={episode.podcastPath}
              >
                <CloudArrowDownIcon className="w-8 h-8" />
              </a>
              <button
                title={i18n.t('episodeDetails.production.addToComment')}
                type="button"
                className="flex flex-row rounded-full text-primary-500 hover:text-primary-400 active:text-primary-600 justify-center"
                onClick={commentTimestamp}
              >
                <ChatBubbleOvalLeftEllipsisIcon className="w-8 h-8" />
              </button>
            </div>
            <div className="flex flex-row gap-1 w-48 mt-3">
              <Slider
                vertical
                label={i18n.t('episodeDetails.production.currentTime')}
                maxValue={duration || Math.trunc(episode.duration / 1000)}
                step={1}
                value={Math.trunc(playbackCurrentTime)}
                numberFormatter={{ format: formatHumanTime }}
                onChange={(time) => {
                  setIsSeeking(true);
                  setPlaybackCurrentTime(time);
                }}
                onChangeEnd={(time) => {
                  audioRef.current.currentTime = time;
                  setIsSeeking(false);
                }}
              />
            </div>
          </div>
          <div className="flex items-center gap-2 mt-4">
            <span className="uppercase text-gray-500 text-xs font-medium tracking-tight">{i18n.t('episodeDetails.production.replace')}</span>
            <UpdatePodcastFileButton
              disabled={isPublished}
              episodeId={episode.episodeId}
              onBeforeUpdateFinished={async () => {
                await refreshEpisode();
              }}
            />
            <button
              type="button"
              disabled={isPublished}
              title={isPublished ? i18n.t('episodeDetails.production.alreadyPublished') : null}
              className="flex flex-row bg-primary-500 rounded px-2 py-1 text-white text-sm hover:bg-primary-400 active:bg-primary-600 disabled:bg-gray-300 disabled:text-gray-500 justify-center"
              onClick={() => setForceRecordScreen(true)}
            >
              {i18n.t('episodeDetails.production.record')}
            </button>
          </div>
        </div>
        <div className="flex w-full mt-2">
          <div className="flex-1 min-w-0 overflow-hidden rounded-lg shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-primary-500">
            <textarea
              ref={textAreaRef}
              rows={4}
              placeholder={i18n.t('episodeDetails.production.addCommentPlaceholder')}
              value={commentText}
              onChange={(e) => {
                setCommentText(e.target.value);
              }}
              className="block w-full resize-none border-0 bg-transparent text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:py-1.5 sm:text-sm sm:leading-6"
            />
            <div className="flex flex-row justify-between pb-2 px-2 hover:cursor-text" onClick={() => textAreaRef?.current?.focus()}>
              <button
                type="button"
                className="flex flex-row text-sm rounded-lg px-2 border border-gray-300 hover:bg-gray-100 active:bg-gray-200 justify-center items-center"
                onClick={(e) => {
                  e.stopPropagation();
                  commentTimestamp();
                }}
              >
                {'+ '}
                {Utils.formatTime(Math.trunc(playbackCurrentTime))}
              </button>
              <button
                type="button"
                className="flex flex-row text-sm bg-primary-500 rounded px-2 py-1 text-white hover:bg-primary-400 active:bg-primary-600 justify-center items-center"
                onClick={(e) => {
                  e.stopPropagation();
                  addComment();
                }}
              >
                {isAddingComment && <Spinner size={4} className="border-gray-200" />}
                {i18n.t('episodeDetails.production.comment')}
              </button>
            </div>
          </div>
        </div>
        <div className="flow-root mt-8">
          <ul className="-mb-8">
            {feed && feed.map((feedItem, activityItemIdx) => (
              <li key={feedItem.timestamp}>
                <div className="relative pb-8">
                  {activityItemIdx !== feed.length - 1 ? (
                    <span className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                  ) : null}
                  <div className="relative flex items-start space-x-3">
                    { /* eslint-disable-next-line no-nested-ternary */ }
                    {feedItem.type === 2 ? (
                      <>
                        <div className="relative">
                          <span className="relative inline-block flex-shrink-0">
                            <div className="inline-flex h-10 w-10 rounded-full bg-gray-400 justify-center items-center">
                              <span className="text-base text-white">{`${feedItem.user.firstName.charAt(0)}${feedItem.user.lastName.charAt(0)}`}</span>
                            </div>
                          </span>
                          {/* <span className="absolute -bottom-0.5 -right-1 rounded-tl bg-white px-0.5 py-px">
                            <ChatBubbleLeftEllipsisIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                          </span> */}
                        </div>
                        <div className="min-w-0 flex-1">
                          <div>
                            <span className="text-sm">
                              {`${feedItem.user.firstName} ${feedItem.user.lastName}`}
                            </span>
                            <p className="mt-0.5 text-sm text-gray-500">
                              {i18n.t('episodeDetails.production.commented')}
                              {' '}
                              {moment(feedItem.timestamp).fromNow()}
                            </p>
                          </div>
                          <div className="mt-2 text-sm text-gray-700 whitespace-pre-line">
                            <p>{feedItem.content}</p>
                          </div>
                        </div>
                      </>
                    // eslint-disable-next-line no-nested-ternary
                    ) : feedItem.type === 1 ? (
                      <>
                        <div>
                          <div className="relative px-1">
                            <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-100 ring-8 ring-white">
                              <DocumentIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                            </div>
                          </div>
                        </div>
                        <div className="min-w-0 flex-1 py-1.5">
                          <div className="text-sm text-gray-500">
                            {`${feedItem.user.firstName} ${feedItem.user.lastName}`}
                            {' '}
                            {i18n.t('episodeDetails.production.uploadedNewAudio')}
                            {' '}
                            <span className="whitespace-nowrap">{moment(feedItem.timestamp).fromNow()}</span>
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
