import React, { Fragment, useEffect, useState } from 'react';
import {
  TrashIcon, XMarkIcon,
} from '@heroicons/react/24/outline';

import { Dialog, Transition } from '@headlessui/react';
import i18n from '../../../../../i18n';
import { defaultErrorHandler } from '../../../../../errors/ApplicationError';
import useAlertNotification from '../../../../../hooks/useAlertNotification';
import Spinner from '../../../../../components/Basic/Spinner';
import UserSearch from '../../UserSearch';
import ShowAPI from '../../../../../api/ShowApi';

const showApi = new ShowAPI();

export default function ShowTeamModal({
  showDialog,
  show,
  refreshShow,
  owners: initOwners,
  producers: initProducers,
  handleClose,
  editable,
}) {
  const [showBackdrop, setShowbackdrop] = useState(false);
  const [owners, setOwners] = useState(initOwners);
  const [producers, setProducers] = useState(initProducers);
  const { addError } = useAlertNotification();

  useEffect(() => {
    setOwners(initOwners);
  }, [initOwners]);

  useEffect(() => {
    setProducers(initProducers);
  }, [initProducers]);

  const handleCloseInternal = () => {
    handleClose(false);
  };

  const updateOwners = async (updatedOwners) => {
    let ownersBefore = [...owners];
    setOwners(updatedOwners);

    setShowbackdrop(true);
    try {
      await showApi.updateOwners(show.showId, updatedOwners.map((owner) => ({ userId: owner.id, isLead: owner.isLead })));
    } catch (e) {
      defaultErrorHandler(e, addError);
      setOwners(ownersBefore);
    } finally {
      setShowbackdrop(false);
    }

    await refreshShow();
  };

  const changeOwner = async (user, add) => {
    let userIdx = owners.findIndex((o) => o.id === user.id);
    if ((add && userIdx !== -1) || (!add && userIdx === -1) || (!add && owners.length === 1)) {
      return;
    }

    let updatedOwners = [...owners];
    if (add) {
      updatedOwners.push({
        id: user.id, firstName: user.firstName, lastName: user.lastName, isLead: false,
      });
    } else {
      if (updatedOwners[userIdx].isLead) {
        updatedOwners[0].isLead = true;
      }
      updatedOwners.splice(userIdx, 1);
    }

    await updateOwners(updatedOwners);
  };

  const onLeadChanged = async (owner) => {
    let updatedOwners = owners.map((o) => ({
      ...o,
      isLead: o.id === owner.id,
    }));

    await updateOwners(updatedOwners);
  };

  const updateProducers = async (updatedProducers) => {
    let producersBefore = [...producers];
    setProducers(updatedProducers);

    setShowbackdrop(true);
    try {
      await showApi.updateProducers(show.showId, updatedProducers.map((producer) => (producer.id)));
    } catch (e) {
      defaultErrorHandler(e, addError);
      setProducers(producersBefore);
    } finally {
      setShowbackdrop(false);
    }

    await refreshShow();
  };

  const changeProducer = async (user, add) => {
    let userIdx = producers.findIndex((o) => o.id === user.id);
    if ((add && userIdx !== -1) || (!add && userIdx === -1)) {
      return;
    }

    let updatedProducers = [...producers];
    if (add) {
      updatedProducers.push({
        id: user.id, firstName: user.firstName, lastName: user.lastName,
      });
    } else {
      updatedProducers.splice(userIdx, 1);
    }

    await updateProducers(updatedProducers);
  };

  return (
    <Transition.Root show={showDialog} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleCloseInternal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-70 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg lg:max-w-3xl sm:p-6"
              >
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0 focus:ring-offset-2"
                    onClick={() => handleClose(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div>
                  <div>
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                      {i18n.t('showDetails.team.dialogTitle')}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        {i18n.t('showDetails.team.dialogText')}
                      </p>
                    </div>
                    <div className="h-[1px] w-full bg-gray-300 rounded my-4" />
                    <div className="grid grid-cols-1 lg:grid-cols-2 lg:divide-x divide-gray-200 lg:divide-y-0 divide-y">
                      <div className="pb-4 lg:pb-0 lg:pr-4">
                        <h3 className="text-lg font-medium leading-6 text-black">{i18n.t('showDetails.team.owners')}</h3>
                        {editable && <UserSearch placeholder={i18n.t('showDetails.team.addOwner')} onUserSelection={(user) => changeOwner({ id: user.objectID, firstName: user.firstName, lastName: user.lastName }, true)} />}
                        <fieldset>
                          <legend className="sr-only">Show Owner Lead</legend>
                          <div className="flex-1 divide-y divide-gray-200 overflow-y-auto">
                            {owners && owners.map((owner) => (
                              <div key={owner.id}>
                                <div className="relative flex items-center py-3">
                                  <div className="relative flex min-w-0 flex-1 items-center">
                                    <span className="relative inline-block flex-shrink-0">
                                      <div className="inline-flex h-10 w-10 rounded-full bg-gray-400 justify-center items-center">
                                        <span className="text-base text-white">{`${owner.firstName.charAt(0)}${owner.lastName.charAt(0)}`}</span>
                                      </div>
                                    </span>
                                    <p className="ml-4 truncate text-sm font-medium text-gray-900">{`${owner.firstName} ${owner.lastName}`}</p>
                                  </div>
                                  <div className="flex items-center mr-1">
                                    <label htmlFor={`lead-${owner.id}`} className="space-x-1.5 flex items-center">
                                      <span className="text-sm font-medium text-black">{i18n.t('showDetails.team.lead')}</span>
                                      <input
                                        id={`lead-${owner.id}`}
                                        name="lead"
                                        type="radio"
                                        checked={owner.isLead}
                                        disabled={!editable}
                                        onChange={() => onLeadChanged(owner)}
                                        className="h-4 w-4 border-gray-300 text-primary-600 focus:ring-primary-500 focus:ring-2 focus:ring-offset-1"
                                      />
                                    </label>
                                    {editable && (
                                    <button
                                      type="button"
                                      className="ml-3 rounded-full p-2 text-primary-500 enabled:hover:bg-gray-300 enabled:active:bg-primary-500 enabled:active:text-white disabled:text-gray-300"
                                      onClick={() => changeOwner(owner, false)}
                                    >
                                      <TrashIcon className="h-5 w-5 text-primary-500" />
                                    </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </fieldset>
                      </div>
                      <div className="pt-4 lg:pt-0 lg:pl-4">
                        <h3 className="text-lg font-medium leading-6 text-black">{i18n.t('showDetails.team.producers')}</h3>
                        {editable && <UserSearch placeholder={i18n.t('showDetails.team.addProducer')} onUserSelection={(user) => changeProducer({ id: user.objectID, firstName: user.firstName, lastName: user.lastName }, true)} />}
                        <div className="flex-1 divide-y divide-gray-200 overflow-y-auto">
                          {producers && producers.map((producer) => (
                            <div key={producer.id}>
                              <div className="relative flex items-center py-3">
                                <div className="relative flex min-w-0 flex-1 items-center">
                                  <span className="relative inline-block flex-shrink-0">
                                    <div className="inline-flex h-10 w-10 rounded-full bg-gray-400 justify-center items-center">
                                      <span className="text-base text-white">{`${producer.firstName.charAt(0)}${producer.lastName.charAt(0)}`}</span>
                                    </div>
                                  </span>
                                  <p className="ml-4 truncate text-sm font-medium text-gray-900">{`${producer.firstName} ${producer.lastName}`}</p>
                                </div>
                                <div className="flex items-center mr-1">
                                  {editable && (
                                  <button
                                    type="button"
                                    className="ml-3 rounded-full p-2 text-primary-500 enabled:hover:bg-gray-300 enabled:active:bg-primary-500 enabled:active:text-white disabled:text-gray-300"
                                    onClick={() => changeProducer(producer, false)}
                                  >
                                    <TrashIcon className="h-5 w-5 text-primary-500" />
                                  </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
        {showBackdrop && (
        <div className="fixed w-full h-full flex justify-center items-center inset-0 bg-black opacity-70 z-50">
          <Spinner size={6} color="primary-500" />
        </div>
        )}
      </Dialog>
    </Transition.Root>
  );
}
