// TODO: need to be implemented for web and react
import createDataContext from './createDataContext';
import AuthApi from '../api/AuthApi';
import TenantApi from '../api/TenantApi';
import Auth from '../util/AuthHelper';

import {
  setUser as userSliceSetUser,
  setTenant as userSliceSetTenant,
  setRoles as userSliceSetRoles,
  clear as userSliceClear,
} from '../slices/UserSlice';

import {
  setPlan,
  setPlanEndDate,
  setSpaceName,
  setRestrictions,
  setAiDisabled,
} from '../slices/TenantSlice';

const authApi = new AuthApi();
const tenantApi = new TenantApi();

const ACTION_TYPES = {
  SIGNIN: 'signin',
  SIGNOUT: 'signout',
  LOADING: 'loading',
};

let authProvider = '';

const authReducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.SIGNIN:
      return {
        isSignout: false,
        isLoading: false,
        isLoggedIn: true,
        user: action.payload.user,
        tenant: action.payload.tenant,
      };
    case ACTION_TYPES.SIGNOUT:
      return {
        isSignout: true, isLoggedIn: false, user: null, tenant: null,
      };
    case ACTION_TYPES.LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    default:
      return state;
  }
};

const signin = (dispatch) => async () => {
  try {
    dispatch({ type: ACTION_TYPES.LOADING, payload: true });
    await Auth.federatedSignIn({ provider: authProvider });
  } catch (err) {
    // TODO error handler
    console.log('Auth Error', err);
  }
};

const getAccessToken = (dispatch) => async (reduxDispatch) => {
  let token = null;

  try {
    let cognitoUser = await Auth.currentAuthenticatedUser();
    token = cognitoUser.signInUserSession.accessToken.jwtToken;

    const user = await authApi.getUser();
    let roles = {};
    user.user.roles.forEach((role) => { roles[role] = true; });
    reduxDispatch(userSliceSetUser(user.user));
    reduxDispatch(userSliceSetTenant(user.tenant));
    reduxDispatch(setSpaceName(user.tenant));
    reduxDispatch(userSliceSetRoles(roles));

    try {
      const tenantPlan = await tenantApi.getPlan();
      reduxDispatch(setPlan(tenantPlan.plan));
      reduxDispatch(setPlanEndDate(tenantPlan.endDate));
      reduxDispatch(setRestrictions(tenantPlan.restrictions));
      reduxDispatch(setAiDisabled(tenantPlan.aiDisabled));
    } catch (e) {
      // ignore
    }

    dispatch({ type: ACTION_TYPES.SIGNIN, payload: user });
  } catch (e) {
    // TODO proper logging
    dispatch({ type: ACTION_TYPES.SIGNOUT });
  }

  dispatch({ type: ACTION_TYPES.LOADING, payload: false });
  return token;
};

const signout = (dispatch) => async (reduxDispatch) => {
  Auth.signOut()
    .then(async () => {
      reduxDispatch(userSliceClear());
    })
    // TODO error handling
    .catch((err) => console.log(err));

  dispatch({ type: ACTION_TYPES.SIGNOUT });
};

export const { Provider, Context } = createDataContext(
  authReducer,
  {
    signin,
    getAccessToken,
    signout,
  },
  {
    isLoading: true, isSignout: false, isLoggedIn: false, user: null, tenant: null,
  },
);
