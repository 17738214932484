import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import {
  clearBreadcrumbs,
  collapseNavBar,
  setBreadcrumbs,
  setEnableBreakpoint,
} from '../../slices/NavBarSlice';
import defaultImage from '../../assets/img/image_placeholder.jpg';
import MiniSidebarNav from '../../components/MiniSidebarNav';
import { defaultErrorHandler } from '../../errors/ApplicationError';
import useAlertNotification from '../../hooks/useAlertNotification';
import i18n from '../../i18n';
import EpisodeAPI from '../../api/EpisodeApi';
import EpisodePreparation from './components/EpisodePreparation';
import EpisodeProduction from './components/EpisodeProduction';
import EpisodeOverview from './components/EpisodeOverview';
import EpisodePublishing from './components/EpisodePublishing';

const episodeNavItems = [
  {
    id: 0,
    name: i18n.t('episodeDetails.nav.overview'),
    href: 'overview',
  },
  {
    id: 1,
    name: i18n.t('episodeDetails.nav.preparation'),
    href: 'preparation',
  },
  {
    id: 2,
    name: i18n.t('episodeDetails.nav.production'),
    href: 'production',
  },
  {
    id: 3,
    name: i18n.t('episodeDetails.nav.publishing'),
    href: 'publishing',
  },
];

const episodeApi = new EpisodeAPI();

export default function EpisodeDetails() {
  const dispatch = useDispatch();
  const { episodeId } = useParams();
  const { path } = useRouteMatch();
  const history = useHistory();
  const { addError } = useAlertNotification();

  const [episode, setEpisode] = useState(null);

  const refreshEpisode = async () => {
    let episodeData = await episodeApi.getEpisode(episodeId);
    setEpisode(episodeData);
    return episodeData;
  };

  useEffect(() => {
    // load show details
    let mounted = true;
    setEpisode(null);
    refreshEpisode()
      .then((episodeResp) => {
        if (!mounted) {
          return;
        }

        dispatch(setBreadcrumbs({
          show: { showId: episodeResp.showId, name: episodeResp.showName },
          episode: { episodeId: episodeResp.episodeId, name: episodeResp.name },
        }));
      })
      .catch((err) => {
        if (!mounted) {
          return;
        }

        defaultErrorHandler(err, addError);
        history.push('/app/dashboard');
      });
    return () => {
      mounted = false;
      dispatch(clearBreadcrumbs());
    };
  }, [episodeId]);

  useEffect(() => {
    dispatch(setEnableBreakpoint(false));
    dispatch(collapseNavBar());
    return () => dispatch(setEnableBreakpoint(true));
  }, [episodeId]);

  return (
    <div className="py-6 h-full">
      <div className="flex flex-row h-full">
        <MiniSidebarNav showImage={(episode && episode.showImage) || defaultImage} navItems={episodeNavItems} />
        {/* main content */}
        <div className="flex-1 ml-10 overflow-hidden relative">
          <div className="absolute inset-0 overflow-auto pl-1 pr-4">
            <Switch>
              <Route exact path={`${path}/overview`}>
                <EpisodeOverview episode={episode} refreshEpisode={refreshEpisode} />
              </Route>
              <Route exact path={`${path}/preparation`}>
                <EpisodePreparation episodeId={episodeId} episode={episode} />
              </Route>
              <Route exact path={`${path}/production`}>
                <EpisodeProduction episodeId={episodeId} episode={episode} refreshEpisode={refreshEpisode} />
              </Route>
              <Route exact path={`${path}/publishing`}>
                <EpisodePublishing episodeId={episodeId} episode={episode} refreshEpisode={refreshEpisode} />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
}
