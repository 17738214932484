import React, {
  Fragment,
  useEffect,
  useState,
} from 'react';
import classNames from 'classnames';
import {
  Bars3Icon,
  ChartPieIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  CreditCardIcon,
  HomeIcon,
  UserGroupIcon,
  UsersIcon,
} from '@heroicons/react/24/outline';
import { Transition } from '@headlessui/react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import Logo from '../components/Basic/Logo';
import UserButton from './components/UserButton';
import i18n from '../i18n';
import ApplicationRoles from '../util/ApplicationRoles';
import useSidebarContent from '../hooks/useSidebarContent';
import {
  collapseNavBar,
  expandNavBar,
  setCollapsed,
  setExpanded,
} from '../slices/NavBarSlice';

const navigation = [
  {
    id: 0,
    name: i18n.t('sidebar.dashboard'),
    href: '/app/dashboard',
    icon: HomeIcon,
  },
  {
    id: 1,
    name: i18n.t('sidebar.groups'),
    href: '/app/groups',
    icon: UserGroupIcon,
  },
  {
    id: 2,
    name: i18n.t('sidebar.userManagement'),
    href: '/app/users',
    icon: UsersIcon,
    authorization: ApplicationRoles.USER_ADMIN,
  },
  {
    id: 3,
    name: i18n.t('sidebar.analytics'),
    href: '/app/analytics',
    icon: ChartPieIcon,
    authorization: ApplicationRoles.READ_ANALYTICS,
  },
  {
    id: 4,
    name: i18n.t('sidebar.billing'),
    href: '/app/billing',
    icon: CreditCardIcon,
    authorization: ApplicationRoles.USER_ADMIN,
    isBillingOwner: true,
  },
];

function authorizationFilter(navEntry, userRoles, isBillingOwner) {
  return (navEntry.authorization == null || userRoles[navEntry.authorization]) && (!navEntry.isBillingOwner || isBillingOwner);
}

function CollapsedNav({
  className, onBurgerClick, userRoles, isBillingOwner,
}) {
  let location = useLocation();

  return (
    <div className={classNames(className, 'sticky inset-y-0 flex flex-col bg-gray-50')}>
      <nav aria-label="Sidebar" className="block flex-shrink-0 overflow-y-auto h-full flex-1">
        <div className="relative flex flex-col space-y-1 p-3">
          <button onClick={onBurgerClick} type="button" className="text-gray-500 hover:bg-gray-200 flex-shrink-0 inline-flex items-center justify-center rounded-lg py-2">
            <Bars3Icon className="h-6 w-6" />
          </button>
          {navigation.filter((navEntry) => authorizationFilter(navEntry, userRoles, isBillingOwner)).map((item) => (
            <div key={item.id} className="flex flex-row h-10 items-center">
              <div className={classNames('w-0.5 h-full', item.href.startsWith(location.pathname) ? 'bg-primary-500' : 'bg-transparent')} />
              <Link
                to={item.href}
                className={classNames(
                  item.href.startsWith(location.pathname) ? 'text-primary-500' : 'text-gray-500 hover:bg-gray-200',
                  'flex-shrink-0 inline-flex items-center justify-center rounded-lg px-2 h-10',
                )}
              >
                <span className="sr-only">{item.name}</span>
                <item.icon className="h-6 w-6" aria-hidden="true" />
              </Link>
            </div>
          ))}
        </div>
      </nav>
      <div className="self-center mb-4 mt-4 mx-2">
        <UserButton hideName />
      </div>
    </div>
  );
}

function ExpandableSection({ sectionTitle, children }) {
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <div className="pt-4 pl-4 flex flex-col">
      <div className="flex flex-row justify-between items-center">
        <span className="text-black font-bold pl-2">{sectionTitle}</span>
        <button className="self-center rounded-full p-2 hover:bg-gray-100" type="button" onClick={() => setIsExpanded(!isExpanded)}>
          {isExpanded ? <ChevronDownIcon className="h-4 w-4" /> : <ChevronLeftIcon className="h-4 w-4" />}
        </button>
      </div>
      <Transition
        as={Fragment}
        show={isExpanded}
        enter="transition ease-in-out duration-300 transform origin-top"
        enterFrom="scale-y-0"
        enterTo="scale-y-1"
        leave="transition ease-in-out duration-300 transform origin-top"
        leaveFrom="scale-y-1"
        leaveTo="scale-y-0"
      >
        {/* Wrap below div around to make sure the immediate child accepts innerRef */}
        <div>
          {children}
        </div>
      </Transition>
    </div>
  );
}

function ExpandedNav({
  className, onCollapseClick, userRoles, isBillingOwner,
}) {
  let location = useLocation();
  const {
    shows: {
      shows, loadMore: loadMoreShows, moreAvailable: moreShowsAvailable,
    },
  } = useSidebarContent();

  return (
    <div className={classNames(className, 'flex w-64 flex-col')}>
      <div className="flex flex-grow flex-col overflow-y-auto bg-white pt-5">
        <div className="flex flex-shrink-0 items-center px-4 mb-6">
          <div className="flex flex-1">
            <div className="flex-1"><Logo /></div>
            <button className="self-center rounded-full p-2 hover:bg-gray-100" type="button" onClick={onCollapseClick}>
              <span className="sr-only">Collapse Navigation</span>
              <ChevronLeftIcon className="h-6 w-6" />
            </button>
          </div>
        </div>
        <div className="mt-5 flex flex-grow flex-col flex-1">
          <nav className="space-y-1 px-2 pb-4">
            {navigation.filter((navEntry) => authorizationFilter(navEntry, userRoles, isBillingOwner)).map((item) => (
              <Link
                key={item.name}
                to={item.href}
                className={classNames(
                  item.href.startsWith(location.pathname) ? 'bg-gray-100 border-l-primary-600' : 'hover:bg-gray-50 border-l-transparent',
                  'group flex items-center px-2 py-1.5 text-sm rounded-sm border-l-2 text-primary-500',
                )}
              >
                <item.icon
                  className="text-primary-500 mr-3 flex-shrink-0 h-6 w-6"
                  aria-hidden="true"
                />
                {item.name}
              </Link>
            ))}
          </nav>
          {/* <ExpandableSection sectionTitle="Recents">
            <Link
              to="#?"
              className="flex rounded-md px-2 py-1 items-baseline text-primary-500 hover:bg-gray-50 hover:text-primary-700"
            >
              <div className="h-5 w-5 rounded border-primary-400 border flex justify-center items-center text-sm"><span>E</span></div>
              <p className="pl-2 text-sm truncate">Fostering a Positive Environment</p>
            </Link>
            <Link
              to="#?"
              className="flex rounded-md px-2 py-1 items-baseline text-primary-500 hover:bg-gray-50 hover:text-primary-700"
            >
              <div className="h-5 w-5 rounded border-primary-400 border flex justify-center items-center text-sm"><span>S</span></div>
              <span className="pl-2 text-sm truncate">Company Culture</span>
            </Link>
          </ExpandableSection> */}
          <ExpandableSection sectionTitle="Shows">
            {shows == null && (
            <div className="space-y-1">
              <div className="w-[80%] rounded-xl mx-2 h-3 bg-gray-300 animate-pulse" />
              <div className="w-[80%] rounded-xl mx-2 h-3 bg-gray-300 animate-pulse" />
              <div className="w-[80%] rounded-xl mx-2 h-3 bg-gray-300 animate-pulse" />
            </div>
            )}
            {shows != null && shows.length > 0 && (
            <div className="flex flex-col">
              {shows.map((show) => (
                <Link
                  to={`/app/show/${show.showId}/overview`}
                  className="flex rounded-md px-2 py-1 items-center text-primary-500 hover:bg-gray-50 hover:text-primary-700"
                  title={show.name}
                  key={show.showId}
                >
                  {/* <div className="h-5 w-5 rounded border-primary-400 border flex justify-center items-center text-sm"><span>S</span></div> */}
                  {/* <img className="h-6 w-6 rounded" src={show.showImage} alt="" /> */}
                  <span className="pl-2 text-sm truncate">{show.name}</span>
                </Link>
              ))}
              {moreShowsAvailable && (
              <button type="button" onClick={loadMoreShows}>
                <span className="text-primary-500 hover:text-primary-700 hover:bg-gray-50 px-3 py-1 mt-4">{i18n.t('myShows.loadMore')}</span>
              </button>
              )}
            </div>
            )}
          </ExpandableSection>
          {/* <ExpandableSection sectionTitle="Drafts">
            <div className="space-y-1">
              <div className="w-[80%] rounded-xl mx-2 h-3 bg-gray-300 animate-pulse" />
              <div className="w-[80%] rounded-xl mx-2 h-3 bg-gray-300 animate-pulse" />
              <div className="w-[80%] rounded-xl mx-2 h-3 bg-gray-300 animate-pulse" />
            </div>
          </ExpandableSection> */}
        </div>
        <div className="mb-4 ml-4 mt-8">
          <UserButton />
        </div>
      </div>
    </div>
  );
}

export default function NavBar() {
  const dispatch = useDispatch();
  const [matchesBreakpoint, setMatchesBreakpoint] = useState(window.matchMedia('(min-width: 768px)').matches);
  const navBarState = useSelector((s) => s.navbar);
  let userRoles = useSelector((s) => s.user.roles);
  let isBillingOwner = useSelector((s) => s.user.user.isBillingOwner);

  useEffect(() => {
    // ensure the listener is only registered once
    window.matchMedia('(min-width: 768px)').addEventListener('change', (e) => {
      setMatchesBreakpoint(e.matches);
    });
  }, []);

  useEffect(() => {
    // breakpoint changed
    if (!navBarState.enableBreakpoint) {
      return;
    }

    if (!matchesBreakpoint && navBarState.expanded) {
      dispatch(collapseNavBar());
    } else if (matchesBreakpoint && navBarState.collapsed) {
      dispatch(expandNavBar());
    }
  }, [matchesBreakpoint]);

  return (
    <>
      <Transition
        as={Fragment}
        show={navBarState.collapsed}
        enter="transition ease-in-out duration-300 transform"
        enterFrom="-translate-x-full"
        enterTo="translate-x-0"
        leave="transition ease-in-out duration-300 transform"
        leaveFrom="translate-x-0"
        leaveTo="-translate-x-full"
        afterLeave={() => dispatch(setExpanded(true))}
      >
        <div className="flex"><CollapsedNav onBurgerClick={() => dispatch(setCollapsed(false))} userRoles={userRoles} isBillingOwner={isBillingOwner} /></div>
      </Transition>
      <Transition
        as={Fragment}
        show={navBarState.expanded}
        enter="transition ease-in-out duration-300 transform"
        enterFrom="-translate-x-full"
        enterTo="translate-x-0"
        leave="transition ease-in-out duration-300 transform"
        leaveFrom="translate-x-0"
        leaveTo="-translate-x-full"
        afterLeave={() => dispatch(setCollapsed(true))}
      >
        <div className="flex"><ExpandedNav className="flex" onCollapseClick={() => dispatch(setExpanded(false))} userRoles={userRoles} isBillingOwner={isBillingOwner} /></div>
      </Transition>
    </>
  );
}
