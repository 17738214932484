/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: null,
    tenant: null,
    roles: {},
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setTenant: (state, action) => {
      state.tenant = action.payload;
    },
    setRoles: (state, action) => {
      state.roles = action.payload;
    },
    clear: (state) => {
      state.user = null;
      state.tenant = null;
      state.roles = {};
    },
  },
});

export const {
  setUser,
  setTenant,
  setRoles,
  clear,
} = userSlice.actions;

// Export Reducer
export default userSlice.reducer;
