import { API } from './api';

export default class ChannelAPI {
  constructor() {
    this.api = new API();
  }

  async createChannel(name, description, users) {
    let data = await this.api.create('channel', {
      name,
      description,
      users,
    });
    return data.data;
  }

  async getChannels(role) {
    let data = await this.api.read('channels', {
      customParamMap: {
        role,
      },
    });

    return data.data;
  }

  async getChannel(id) {
    let data = await this.api.read('channel', {
      key: id,
    });

    return data.data;
  }

  async updateChannel(channelId, name, description, users) {
    let data = await this.api.update('channel', {
      channelId,
      name,
      description,
      users,
    });

    return data.data;
  }

  async assignNewChannel(showId, channelId) {
    let data = await this.api.update('channel/assign', {
      showId,
      channelId,
    });

    return data.data;
  }

  async deleteChannel(channelId) {
    let data = await this.api.delete('channel', {
      key: channelId,
    });

    return data.data;
  }
}
