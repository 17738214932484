import React, { useEffect, useRef, useState } from 'react';
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/outline';

import moment from 'moment';
import classNames from 'classnames';
import WarningModal from '../../../components/WarningModal/WarningModal';
import i18n from '../../../i18n';
import Spinner from '../../../components/Basic/Spinner';
import { defaultErrorHandler } from '../../../errors/ApplicationError';
import useAlertNotification from '../../../hooks/useAlertNotification';
import EpisodeAPI from '../../../api/EpisodeApi';
import UserApi from '../../../api/UserApi';
import createLogger from '../../../util/Logger';
import ApplicationAPIError from '../../../errors/ApplicationAPIError';
import Utils from '../../../util/Utils';
import ShowAPI from '../../../api/ShowApi';

const episodeApi = new EpisodeAPI();
const userApi = new UserApi();
const showApi = new ShowAPI();
const logger = createLogger('EpisodePublishing');

function TodoItem({
  fulfilled, text, mandatory = false, className,
}) {
  let Icon = CheckCircleIcon;
  if (!fulfilled && mandatory) {
    Icon = ExclamationCircleIcon;
  } else if (!fulfilled && !mandatory) {
    Icon = ExclamationTriangleIcon;
  }
  return (
    <div className={classNames('flex items-center', fulfilled && 'text-green-700', !fulfilled && !mandatory && 'text-yellow-700', !fulfilled && mandatory && 'text-red-700', className)}>
      <Icon className="w-5 h-5 mr-1" />
      <p className={classNames(fulfilled && 'line-through')}>{text}</p>
    </div>
  );
}

export default function EpisodePublishing({ episode, refreshEpisode }) {
  const [showPublishImmediatelyDialog, setShowPublishImmediatelyDialog] = useState(false);
  const [showWaitlistDialog, setShowWaitlistDialog] = useState(false);
  const [showUploadImageDialog, setShowUploadImageDialog] = useState(false);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [dateValid, setDateValid] = useState(true);
  const [scheduleDate, setScheduleDate] = useState('');
  const [wasScheduling, setWasScheduling] = useState(false);
  const { addError, addSuccess } = useAlertNotification();
  const fileInputRef = useRef();

  let minDate = moment().add(1, 'day').startOf('day');

  useEffect(() => {
    refreshEpisode();
  }, []);

  useEffect(() => {
    if (episode?.publishDate != null && episode?.publishDate !== '') {
      setScheduleDate(moment(episode.publishDate).local().format('YYYY-MM-DDTkk:mm'));
    }
  }, [episode]);

  const hasDescription = episode?.description !== '';
  const hasResearch = episode?.hasResearch;
  const hasStructure = episode?.hasStructure;
  const hasAudio = !!episode?.podcastPath;

  async function scheduleEpisode() {
    let scheduleDateObject = new Date(scheduleDate);
    if (!dateValid || scheduleDate === '' || Number.isNaN(scheduleDateObject) || minDate.toDate() > scheduleDateObject) {
      logger.error('Invalid Date', { scheduleDate });
      return;
    }

    try {
      setShowBackdrop(true);
      await episodeApi.scheduleEpisode(episode.episodeId, scheduleDateObject);
      addSuccess(i18n.t('episodeDetails.publishing.scheduleSuccessTitle'), i18n.t('episodeDetails.publishing.scheduleSuccessContent', { scheduleDate: moment(scheduleDate).format('MMM DD, YYYY') }));
      refreshEpisode();
    } catch (e) {
      if (e instanceof ApplicationAPIError && e.backendErrorCode === 1011) { // 1011 = error show has no image
        setWasScheduling(true);
        setShowUploadImageDialog(true);
      } else {
        defaultErrorHandler(e, addError);
      }
    } finally {
      setShowBackdrop(false);
    }
  }

  const updateShowImage = async (image) => {
    setShowBackdrop(true);
    try {
      let tempFilename = await Utils.uploadImage({
        file: image,
        showApi,
        onProgress: () => {},
      });
      await showApi.updateShowImage(tempFilename, episode.showId); // note: this method also returns a valid url to the new image, however, we will refresh the whole show object
      await refreshEpisode();
      addSuccess(i18n.t('notifications.updatedShowImageSuccessfullyTitle'), i18n.t('notifications.updatedShowImageSuccessfullyContent'));
      if (!wasScheduling) {
        setShowPublishImmediatelyDialog(true);
      } else {
        await scheduleEpisode();
      }
    } catch (e) {
      defaultErrorHandler(e, addError);
    } finally {
      setShowBackdrop(false);
    }
  };

  return (
    <>
      {episode
        ? (
          <div className="flex flex-col items-center justify-center xl:max-w-[80%]">
            <div className="block">
              {episode.status === 'published' && (
              <>
                <p className="text-center text-xl font-semibold">{i18n.t('episodeDetails.publishing.episodePublished')}</p>
                <p className="text-center text-7xl mt-3">🎉</p>
                <p className="text-center mt-4 mb-6 text-base">{`Published on ${moment(episode.publishDate).format('MMM DD, YYYY')}`}</p>
              </>
              )}
              {episode.status !== 'published' && (
              <>
                <h3 className="text-base font-semibold mb-2">{i18n.t('episodeDetails.publishing.openTodos')}</h3>
                <TodoItem className="mb-1" fulfilled={hasDescription} text={i18n.t('episodeDetails.publishing.todoDescription')} />
                <TodoItem className="mb-1" fulfilled={hasResearch} text={i18n.t('episodeDetails.publishing.todoResearch')} />
                <TodoItem className="mb-1" fulfilled={hasStructure} text={i18n.t('episodeDetails.publishing.todoStructure')} />
                <TodoItem className="mb-1" mandatory fulfilled={hasAudio} text={i18n.t('episodeDetails.publishing.todoUploadEpisode')} />
                <p className="mt-3">{i18n.t('episodeDetails.publishing.scheduledFor')}</p>
                <input
                  className={classNames('rounded w-full border border-gray-300 focus:border-primary-500 ring-0 focus:ring-0', !dateValid && 'border-red-500 focus:border-red-500')}
                  type="datetime-local"
                  id="scheduleDate"
                  name="scheduleDate"
                  value={scheduleDate}
                  onChange={(e) => {
                    setScheduleDate(e.target.value);
                    if (e.target.value !== '') {
                      let newDate = moment(e.target.value);
                      if (newDate < minDate) {
                        setDateValid(false);
                      } else {
                        setDateValid(true);
                      }
                    } else {
                      setDateValid(true);
                    }
                  }}
                  min={minDate.format('YYYY-MM-DDThh:mm')}
                  step="600"
                />
                {!dateValid && <p className="text-red-500 text-xs">{i18n.t('episodeDetails.publishing.dateInvalid')}</p>}
                <div className="flex flex-col space-y-2 mt-1">
                  <button
                    type="button"
                    disabled={!dateValid || scheduleDate === '' || !hasAudio}
                    className="bg-primary-500 rounded px-2 py-1 text-white hover:bg-primary-400 active:bg-primary-600 disabled:bg-gray-300 disabled:text-gray-500 justify-center"
                    onClick={() => scheduleEpisode()}
                  >
                    {i18n.t('episodeDetails.publishing.schedule')}
                  </button>
                  <button
                    type="button"
                    disabled={!hasAudio}
                    className="bg-primary-500 rounded px-2 py-1 text-white hover:bg-primary-400 active:bg-primary-600 disabled:bg-gray-300 disabled:text-gray-500 justify-center"
                    onClick={() => setShowPublishImmediatelyDialog(true)}
                  >
                    {i18n.t('episodeDetails.publishing.publishImmediately')}
                  </button>
                </div>
              </>
              )}
            </div>
            <div className="mt-4 max-w-[80%]">
              <p>{i18n.t('episodeDetails.publishing.waitlistNote')}</p>
              <button
                type="button"
                className="self-start mt-1 bg-primary-500 rounded px-2 py-1 text-white hover:bg-primary-400 active:bg-primary-600 disabled:bg-gray-300 disabled:text-gray-500 justify-center"
                onClick={() => setShowWaitlistDialog(true)}
              >
                {i18n.t('episodeDetails.publishing.joinWaitlist')}
              </button>
            </div>
          </div>
        ) : (
          <>
            {/* Loading state */}
            <div className="h-5 xl:max-w-[80%] bg-gray-300 animate-pulse rounded-xl" />
            <div className="h-40 xl:max-w-[80%] mt-5 bg-gray-300 animate-pulse rounded-xl" />
          </>
        )}
      <WarningModal
        closeWarningModal={() => setShowPublishImmediatelyDialog(false)}
        openWarningModal={showPublishImmediatelyDialog}
        handleWarningModal={async () => {
          try {
            setShowBackdrop(true);
            setShowPublishImmediatelyDialog(false);
            await episodeApi.publishEpisode(episode.episodeId);
            await refreshEpisode();
            addSuccess(i18n.t('episodeDetails.publishing.publishSuccessful'));
          } catch (e) {
            if (e instanceof ApplicationAPIError && e.backendErrorCode === 1011) { // 1011 = error show has no image
              setWasScheduling(false);
              setShowUploadImageDialog(true);
            } else {
              defaultErrorHandler(e, addError);
            }
          } finally {
            setShowBackdrop(false);
          }
        }}
        title={i18n.t('episodeDetails.publishing.publishDialogTitle')}
        contentText={i18n.t('episodeDetails.publishing.publishDialogContent')}
        positiveButtonText={i18n.t('episodeDetails.publishing.publishDialogButton')}
        colorScheme="primary"
        buttonColorScheme="primary"
        icon={InformationCircleIcon}
      />
      <WarningModal
        closeWarningModal={() => setShowWaitlistDialog(false)}
        openWarningModal={showWaitlistDialog}
        handleWarningModal={async () => {
          try {
            setShowWaitlistDialog(false);
            setShowBackdrop(true);
            await userApi.joinWaitlist();
            addSuccess(i18n.t('episodeDetails.publishing.joinWaitlistSuccessTitle'), i18n.t('episodeDetails.publishing.joinWaitlistSuccessContent'));
          } catch (e) {
            defaultErrorHandler(e, addError);
          } finally {
            setShowBackdrop(false);
          }
        }}
        title={i18n.t('episodeDetails.publishing.waitlistDialogTitle')}
        contentText={i18n.t('episodeDetails.publishing.waitlistDialogContent')}
        positiveButtonText={i18n.t('episodeDetails.publishing.waitlistDialogButton')}
        colorScheme="primary"
        buttonColorScheme="primary"
        icon={InformationCircleIcon}
      />
      <WarningModal
        closeWarningModal={() => setShowUploadImageDialog(false)}
        openWarningModal={showUploadImageDialog}
        handleWarningModal={async () => {
          if (fileInputRef.current == null) {
            return;
          }

          setShowUploadImageDialog(false);
          fileInputRef.current.click();
        }}
        title="Error publishing Episode"
        contentText="The episode could not be published because the Show does not have an image attached yet. Do you want to upload an image now?"
        positiveButtonText="Upload"
        colorScheme="red"
        buttonColorScheme="primary"
      />
      {showBackdrop && (
      <div className="fixed w-full h-full flex justify-center items-center inset-0 bg-black opacity-70 z-50">
        <Spinner size={6} className="border-primary-500" />
      </div>
      )}
      <input
        ref={fileInputRef}
        type="file"
        className="hidden"
        accept="image/*"
        onChange={async (e) => {
          await updateShowImage(e.target.files[0]);
          e.target.value = null;
        }}
      />
    </>
  );
}
