import classNames from 'classnames';
import React from 'react';
import i18n from '../i18n';

export default function EpisodeStatusTag({ status, className }) {
  let colorClasses = '';
  let text = '';
  let tooltip = '';

  // eslint-disable-next-line default-case
  switch (status) {
    case 'draft':
      colorClasses = 'border-primary-500 text-primary-500';
      text = i18n.t('episodeStatusTag.draft');
      tooltip = text;
      break;
    case 'published':
      colorClasses = 'border-green-700 text-green-700';
      text = i18n.t('episodeStatusTag.published');
      tooltip = text;
      break;
    case 'progressing':
      colorClasses = 'border-yellow-700 text-yellow-700';
      text = i18n.t('episodeStatusTag.progressing');
      tooltip = text;
      break;
    case 'scheduled':
      colorClasses = 'border-yellow-700 text-yellow-700';
      text = i18n.t('episodeStatusTag.scheduled');
      tooltip = text;
      break;
    case 'error':
      colorClasses = 'border-red-700 text-red-700';
      text = i18n.t('episodeStatusTag.error');
      tooltip = i18n.t('episodeStatusTag.errorTooltip');
      break;
  }

  return (
    <div>
      <span title={tooltip} className={classNames('rounded-xl pl-2 pr-2 border text-sm', colorClasses, className)}>{text}</span>
    </div>
  );
}
