/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  useState, useEffect, Fragment, useRef,
} from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';

import i18n from '../i18n';
import EpisodeAPI from '../api/EpisodeApi';
import { defaultErrorHandler } from '../errors/ApplicationError';
import useAlertNotification from '../hooks/useAlertNotification';
import Spinner from './Basic/Spinner';
import useTenantSettings from '../hooks/useTenantSettings';
import useWebSocketApi from '../hooks/useWebSocketApi';
import createLogger from '../util/Logger';

const EPISODE_NAME_MAX_LENGTH = 150;
const EPISODE_DESCRIPTION_MAX_LENGTH = 4000;

const episodeApi = new EpisodeAPI();
const logger = createLogger('CreateEpisodeDialog');

export default function CreateEpisodeDialog({
  showDialog, handleClose, handleSave, show,
}) {
  const { aiDisabled } = useTenantSettings();
  const [episodeName, setEpisodeName] = useState('');
  const [episodeDescription, setEpisodeDescription] = useState('');
  const [allowError, setAllowError] = useState(false);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [isLoadingProposal, setIsLoadingProposal] = useState(false);
  const { addError } = useAlertNotification();
  const websocketTimeoutRef = useRef(null);
  const { sendJsonMessage, lastJsonMessage } = useWebSocketApi();

  useEffect(() => {
    setEpisodeName('');
    setAllowError(false);
    return () => {
      if (websocketTimeoutRef.current != null) {
        clearTimeout(websocketTimeoutRef.current);
      }
    };
  }, [showDialog]);

  const webSocketTimeoutError = () => {
    logger.error('WebSocket ran into timeout');
    setIsLoadingProposal(false);
    addError(i18n.t('backendErrors.-1'));
    websocketTimeoutRef.current = null;
  };

  const createEpisode = async () => {
    setShowBackdrop(true);
    try {
      let episode = await episodeApi.createEpisode(episodeName, episodeDescription, show.showId);
      handleSave(episode);
    } catch (err) {
      defaultErrorHandler(err, addError);
    } finally {
      setShowBackdrop(false);
    }
  };

  const proposeEpisode = async () => {
    if (isLoadingProposal) {
      return;
    }

    setIsLoadingProposal(true);
    try {
      await sendJsonMessage({ action: 'episodes', amount: 1, showId: show.showId });
      websocketTimeoutRef.current = setTimeout(webSocketTimeoutError, 90000);
    } catch (e) {
      defaultErrorHandler(e, addError);
      setIsLoadingProposal(false);
    }
  };

  useEffect(() => {
    if (lastJsonMessage != null && lastJsonMessage.type === 'episodes' && lastJsonMessage.data.length > 0) {
      setEpisodeName(lastJsonMessage.data[0].title);
      setEpisodeDescription(lastJsonMessage.data[0].description);
      setIsLoadingProposal(false);
      if (websocketTimeoutRef.current != null) {
        clearTimeout(websocketTimeoutRef.current);
      }
    }
  }, [lastJsonMessage]);

  return (
    <Transition.Root show={showDialog} as={Fragment}>
      <Dialog as="div" className="relative z-20" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-70 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-20 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0 focus:ring-offset-2"
                    onClick={handleClose}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div>
                  <div>
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                      {i18n.t('createEpisodeDialog.title')}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        {i18n.t('createEpisodeDialog.content')}
                      </p>
                    </div>
                    <button
                      type="button"
                      disabled={aiDisabled}
                      className="mt-2 text-sm bg-primary-500 rounded px-2 py-1 text-white hover:bg-primary-400 active:bg-primary-600 disabled:bg-gray-300 disabled:text-gray-500"
                      onClick={() => proposeEpisode()}
                    >
                      {i18n.t('createEpisodeDialog.propose')}
                    </button>
                    <div className="relative mt-5">
                      {isLoadingProposal && <div className="w-full h-6 animate-pulse bg-gray-300 rounded-xl" />}
                      {!isLoadingProposal && (
                      <>
                        <label
                          htmlFor="episodeName"
                          className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs text-gray-600"
                        >
                          {i18n.t('createEpisodeDialog.episodeName')}
                        </label>
                        <input
                          type="text"
                          name="groupName"
                          id="groupName"
                          className={classNames(
                            'block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-500 text-sm sm:leading-6',
                            episodeName.length === 0 && allowError && 'focus:ring-red-500',
                          )}
                          value={episodeName}
                          maxLength={EPISODE_NAME_MAX_LENGTH}
                          onChange={(e) => {
                            setEpisodeName(e.target.value);
                            setAllowError(true);
                          }}
                          autoComplete="off"
                        />
                        <div>
                          <span className={classNames('text-xs ml-1', episodeName.length === 0 && allowError ? 'text-red-600' : 'text-gray-700')}>{`${episodeName.length}/${EPISODE_NAME_MAX_LENGTH}`}</span>
                          {episodeName.length === 0 && allowError && <span className="text-xs ml-1 text-red-600">{i18n.t('createEpisodeDialog.nameEmpty')}</span>}
                        </div>
                      </>
                      )}
                    </div>
                    <div className="relative mt-6">
                      {isLoadingProposal && <div className="w-full h-16 animate-pulse bg-gray-300 rounded-xl" />}
                      {!isLoadingProposal && (
                      <>
                        <label htmlFor="episodeDescription" className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs text-gray-600">
                          {i18n.t('createEpisodeDialog.episodeDescription')}
                        </label>
                        <div className="mt-2">
                          <textarea
                            rows={5}
                            name="episodeDescription"
                            id="episodeDescription"
                            className="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-500 sm:py-1.5 sm:text-sm sm:leading-6"
                            value={episodeDescription}
                            maxLength={EPISODE_DESCRIPTION_MAX_LENGTH}
                            onChange={(e) => setEpisodeDescription(e.target.value)}
                          />
                        </div>
                        <span className="text-gray-700 text-xs ml-1">{`${episodeDescription.length}/${EPISODE_DESCRIPTION_MAX_LENGTH}`}</span>
                      </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 flex flex-row justify-end">
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm enabled:hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm disabled:bg-gray-300 disabled:text-gray-500 mx-2 enabled:focus:ring-primary-500"
                    onClick={handleClose}
                  >
                    {i18n.t('general.cancel')}
                  </button>
                  <button
                    type="button"
                    disabled={episodeName.length === 0}
                    className="inline-flex justify-center rounded-md border border-transparent disabled:bg-gray-300 disabled:text-gray-500 bg-primary-500 px-4 py-2 text-base font-medium text-white shadow-sm enabled:hover:bg-primary-400 focus:outline-none focus:ring-2 enabled:focus:ring-primary-500 focus:ring-offset-2 sm:text-sm"
                    onClick={() => createEpisode()}
                  >
                    {i18n.t('general.save')}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
        {showBackdrop && (
        <div className="fixed w-full h-full flex justify-center items-center inset-0 bg-black opacity-70 z-50">
          <Spinner size={6} color="primary-500" />
        </div>
        )}
      </Dialog>
    </Transition.Root>
  );
}
