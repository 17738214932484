import React from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';

import EpisodeAPI from '../../../api/EpisodeApi';
import { defaultErrorHandler } from '../../../errors/ApplicationError';
import useAlertNotification from '../../../hooks/useAlertNotification';

const episodeApi = new EpisodeAPI();

export default function ReloadEditorContentPlugin({ episodeId, setStateRecovered, editable = true }) {
  const [editor] = useLexicalComposerContext();
  const { addError } = useAlertNotification();

  React.useEffect(() => {
    if (episodeId == null) {
      return;
    }

    let isActive = true;

    episodeApi.getEpisodeResearch(episodeId)
      .then((researchJson) => {
        if (isActive) {
          if (researchJson != null && researchJson !== '') {
            editor.setEditorState(editor.parseEditorState(researchJson));
          }
          setStateRecovered(true);
          if (editable) {
            editor.setEditable(true);
          }
        }
      })
      .catch((err) => {
        defaultErrorHandler(err, addError);
      });
    // eslint-disable-next-line consistent-return
    return () => {
      isActive = false;
      setStateRecovered(false);
    };
  }, [episodeId]);
}
