import EpisodeApi from '../api/EpisodeApi';
import createLogger from '../util/Logger';

const logger = createLogger('SyncPlaytime');

const episodeApi = new EpisodeApi();

class SyncPlaytime {
  constructor() {
    this.episodeId = null;
    this.playtime = 0;
    this.lastSynctime = 0;
    this.intervalId = null;
    this.audioElement = null;
  }

  setAudioElement(audioElement) {
    this.audioElement = audioElement;
  }

  start(episodeId) {
    // Update the episodeId even if the intervalId is not null
    if (this.episodeId !== episodeId) {
      this.episodeId = episodeId;
    }

    if (this.intervalId == null) {
      this.intervalId = setInterval(() => {
        // pass the isPlaying parameter to the sync() method
        this.sync(this.audioElement && !this.audioElement.paused);
      }, 3000);
    }
  }

  stop() {
    if (this.intervalId != null) {
      clearInterval(this.intervalId);
    }
    this.intervalId = null;
    this.sync(); // sync open changes
  }

  setPlaytime(playtime) {
    this.playtime = Math.trunc(playtime);
  }

  /** @private */
  async sync(isPlaying) {
    if (
      isPlaying
      && this.playtime !== this.lastSynctime
      && this.episodeId != null
    ) {
      this.lastSynctime = this.playtime;
      try {
        await episodeApi.syncPlaytime(this.episodeId, this.playtime);
      } catch (error) {
        logger.error('Error syncing playtime:', error);
      }
    }
  }

  reset() {
    this.stop();
    this.episodeId = null;
    this.playtime = 0;
    this.lastSynctime = 0;
  }
}

const instance = new SyncPlaytime();
export default instance;
