import React, { useState, Fragment } from 'react';
import {
  CheckIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  EnvelopeIcon,
  MagnifyingGlassIcon,
  TrashIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import classNames from 'classnames';

import i18n from '../../i18n';
import useManageUser from '../../hooks/useManageUser';
import { useUserRestriction } from '../../hooks/useTenantSettings';

import RoleSelect from './components/RoleSelect';
import UserManagementAPI, { TENANT_ASSIGNMENT_STATUS } from '../../api/UserManagementApi';
import Constants from '../../util/Constants';
import InviteModal from './components/InviteModal';
import TenantApi from '../../api/TenantApi';
import useAlertNotification from '../../hooks/useAlertNotification';
import { defaultErrorHandler } from '../../errors/ApplicationError';
import WarningModal from '../../components/WarningModal/WarningModal';
import Spinner from '../../components/Basic/Spinner';

const tenantApi = new TenantApi();
const userApi = new UserManagementAPI();

function TenantAssignmentStatus({ status }) {
  const STATUS_STYLES = {
    [TENANT_ASSIGNMENT_STATUS.ACTIVE]: 'border-green-700 text-green-700',
    [TENANT_ASSIGNMENT_STATUS.INACTIVE]: 'border-red-700 text-red-700',
    [TENANT_ASSIGNMENT_STATUS.INVITED]: 'border-yellow-700 text-yellow-700',
  };

  let text = 'Active';
  let Icon = CheckIcon;
  if (status === TENANT_ASSIGNMENT_STATUS.INACTIVE) {
    text = 'Inactive';
    Icon = XMarkIcon;
  } else if (status === TENANT_ASSIGNMENT_STATUS.INVITED) {
    text = 'Invited';
    Icon = EnvelopeIcon;
  }

  return (
    <div className={classNames('inline-flex rounded-xl pl-1 pr-2 border', STATUS_STYLES[status])}>
      <Icon className="h-5 w-5 mr-1" />
      <span>{text}</span>
    </div>
  );
}

function UserManagement() {
  const [
    usersLoading,
    fetchUsers,
    userTableData,
    setSearchValue,
  ] = useManageUser();
  const [, userRestriction] = useUserRestriction();
  const [showInviteDialog, setShowInviteDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [userToDelete, setUserToDelete] = useState(false);
  const [showDeleteWarningDialog, setShowDeleteWarningDialog] = useState(false);
  const [savingRoleUserId, setSavingRoleUserId] = useState(null);
  const { addSuccess, addError } = useAlertNotification();

  const onRoleChange = async (user, roleId) => {
    try {
      setSavingRoleUserId(user.userId);
      await userApi.updateUserRole(user.userId, roleId);
    } catch (e) {
      defaultErrorHandler(e, addError);
      // addError(i18n.t('notifications.updateUserRoleError'), i18n.t('general.errorOccured'));
      fetchUsers(userTableData.pageNo);
    } finally {
      setSavingRoleUserId(null);
    }
  };

  const onDeleteUser = async (user) => {
    if (user.tenantAssignmentStatus === TENANT_ASSIGNMENT_STATUS.INACTIVE) {
      return;
    }

    try {
      setIsDeleting(true);
      if (user.tenantAssignmentStatus === TENANT_ASSIGNMENT_STATUS.ACTIVE) {
        await tenantApi.updateTenantAssignmentInactive(user.userId);
      } else if (user.tenantAssignmentStatus === TENANT_ASSIGNMENT_STATUS.INVITED) {
        await tenantApi.removeInvite(user.email);
      }
      addSuccess(i18n.t('userManagement.successRemove', { email: user.email }));
      fetchUsers(userTableData.pageNo);
    } catch (err) {
      defaultErrorHandler(err, addError);
    } finally {
      setIsDeleting(false);
      setUserToDelete(null);
      setShowDeleteWarningDialog(false);
    }
  };

  const onPrevPage = () => {
    fetchUsers(userTableData.pageNo - 1);
  };

  const onNextPage = () => {
    fetchUsers(userTableData.pageNo + 1);
  };

  return (
    <>
      <div className="py-1 px-4">
        <div className="md:px-6 lg:px-8 max-w-7xl">
          <h1 className="text-2xl font-semibold text-gray-900">{i18n.t('userManagement.title')}</h1>
        </div>
        <div className="mt-8 flex flex-col">
          <div className="flex justify-between items-baseline md:px-6 lg:px-8">
            <div className="relative mt-1 rounded-md shadow-sm w-1/3 min-w-[10rem]">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>
              <input
                type="text"
                name="searchUser"
                id="searchUser"
                className="block w-full rounded-md hover:border-gray-400 border-gray-300 pl-10 focus:border-primary-500 focus:ring-primary-500 text-sm"
                placeholder={i18n.t('userManagement.searchUser')}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
              />
            </div>
            <button
              type="button"
              className="px-3 py-1 disabled:bg-gray-300 disabled:text-gray-500 rounded bg-primary-500 hover:bg-primary-400 active:bg-primary-600 text-white text-base"
              onClick={() => setShowInviteDialog(true)}
            >
              {i18n.t('userManagement.invite')}
            </button>
          </div>
          <div className="overflow-visible inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-visible shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-6"
                    >
                      {i18n.t('userManagement.table.name')}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      {i18n.t('userManagement.table.email')}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      {i18n.t('userManagement.table.status')}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                      {i18n.t('userManagement.table.role')}
                    </th>
                    <th scope="col" className="relative py-3 pl-3 pr-4 sm:pr-6">
                      <span className="sr-only">Delete</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {(usersLoading || isDeleting) && <tr><td colSpan="5"><div className="flex justify-center my-4"><Spinner size={6} color="primary-500" className="self-center" /></div></td></tr>}
                  {!(usersLoading || isDeleting) && userTableData.pageItems.map((user) => (
                    <tr key={user.userId}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        {`${user.firstName} ${user.lastName}`}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{user.email}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        <TenantAssignmentStatus status={user.tenantAssignmentStatus} />
                      </td>
                      <td className="whitespace-nowrap px-3 py-1 text-sm text-gray-500">
                        <RoleSelect disabled={user.tenantAssignmentStatus !== TENANT_ASSIGNMENT_STATUS.ACTIVE} activeRoleId={user.singleRoleId} isSaving={savingRoleUserId === user.userId} onChange={(roleId) => onRoleChange(user, roleId)} />
                      </td>
                      <td className="relative whitespace-nowrap pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        <button
                          className="rounded-full p-2 text-primary-500 enabled:hover:bg-gray-300 enabled:active:bg-primary-500 enabled:active:text-white disabled:text-gray-300"
                          type="button"
                          onClick={() => {
                            setUserToDelete(user);
                            setShowDeleteWarningDialog(true);
                          }}
                          disabled={user.tenantAssignmentStatus === TENANT_ASSIGNMENT_STATUS.INACTIVE}
                        >
                          <span className="sr-only">
                            Delete,
                            {`${user.firstName} ${user.lastName}`}
                          </span>
                          <TrashIcon className="h-6 w-6" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan="5">
                      <div className="flex flex-1 justify-end py-1 self-end pr-4 space-x-2 items-center">
                        <span>
                          {/* eslint-disable-next-line max-len */}
                          {`${userTableData.pageNo * Constants.USERS_PAGE_SIZE + 1} - ${userTableData.pageNo * Constants.USERS_PAGE_SIZE + userTableData.pageItems.length} of ${userTableData.count}`}
                        </span>
                        <button
                          type="button"
                          className="rounded-full p-2 text-primary-500 enabled:hover:bg-gray-300 enabled:active:bg-primary-500 active:text-white disabled:text-gray-400"
                          onClick={onPrevPage}
                          disabled={userTableData.pageNo === 0}
                        >
                          <span className="sr-only">
                            Table Page before
                          </span>
                          <ChevronLeftIcon className="h-5 w-5" />
                        </button>
                        <button
                          type="button"
                          className="rounded-full p-2 text-primary-500 enabled:hover:bg-gray-300 enabled:active:bg-primary-500 active:text-white disabled:text-gray-400"
                          onClick={onNextPage}
                          disabled={userTableData.count !== 0 && ((userTableData.pageNo + 1) * Constants.USERS_PAGE_SIZE >= userTableData.count)}
                        >
                          <span className="sr-only">
                            Next Table Page
                          </span>
                          <ChevronRightIcon className="h-5 w-5" />
                        </button>
                      </div>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
      <InviteModal
        show={showInviteDialog}
        fetchUsers={fetchUsers}
        handleClose={() => setShowInviteDialog(false)}
        userCount={userTableData.count}
        userRestriction={userRestriction}
      />
      <WarningModal
        closeWarningModal={() => setShowDeleteWarningDialog(false)}
        handleWarningModal={() => onDeleteUser(userToDelete)}
        openWarningModal={showDeleteWarningDialog}
        title={i18n.t('manageUser.deleteDialogTitle')}
        contentText={i18n.t('manageUser.deleteDialogContent', { email: userToDelete != null ? userToDelete.email : '' })}
        positiveButtonText={i18n.t('manageUser.delete')}
      />
    </>
  );
}

export default UserManagement;
