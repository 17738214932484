import React, { Fragment, useEffect, useState } from 'react';

import Spinner from '../../../components/Basic/Spinner';
import { defaultErrorHandler } from '../../../errors/ApplicationError';
import useAlertNotification from '../../../hooks/useAlertNotification';
import Editor from '../../../components/Editor/Editor';
import EpisodeAPI from '../../../api/EpisodeApi';
import EpisodeStructureBlockModal from './EpisodeStructureBlockModal';
import EpisodeStructureList from './EpisodeStructureList';
import EpisodeStructureProposeModal from './EpisodeStructureProposeModal';
import WarningModal from '../../../components/WarningModal/WarningModal';
import i18n from '../../../i18n';
import useTenantSettings from '../../../hooks/useTenantSettings';

const episodeApi = new EpisodeAPI();

export default function EpisodePreparation({ episodeId, episode }) {
  const { aiDisabled } = useTenantSettings();
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [structure, setStructure] = useState(null);
  const [showBlockEditDialog, setShowBlockEditDialog] = useState(false);
  const [editBlock, setEditBlock] = useState({});
  const [isAdd, setIsAdd] = useState(false);
  const [addAfterBlockId, setAddAfterBlockId] = useState(null);
  const [showStructureProposeModal, setShowStructureProposeModal] = useState(false);
  const [markdown, setMarkdown] = useState('');
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const [blockIdToDelete, setBlockIdToDelete] = useState(null);
  const { addError } = useAlertNotification();

  useEffect(() => {
    if (episodeId == null) {
      return;
    }

    episodeApi.getEpisodeStructure(episodeId)
      .then((s) => setStructure(s))
      .catch((e) => defaultErrorHandler(e, addError));
  }, [episodeId]);

  return (
    <>
      {episode?.description && <h3 className="text-xl font-bold mb-2">{i18n.t('episodeDetails.preparation.episodeDescription')}</h3>}
      {episode?.description && <p className="text-base mb-5 text-gray-600 lg:max-w-[80%]">{episode.description}</p>}
      <div className="grid grid-cols-1 xl:grid-cols-2 xl:space-x-4">
        <div>
          <h3 className="text-xl font-bold">{i18n.t('episodeDetails.preparation.researchNotes')}</h3>
          <Editor className="mt-2" episodeId={episodeId} setMarkdown={setMarkdown} />
        </div>
        <div className="mt-4 2xl:mt-0">
          <h3 className="text-xl font-bold">{i18n.t('episodeDetails.preparation.structure')}</h3>
          <div className="mt-2">
            <button
              type="button"
              disabled={structure == null || aiDisabled}
              className="bg-primary-500 text-sm rounded px-2 py-1 text-white hover:bg-primary-400 active:bg-primary-600"
              onClick={() => setShowStructureProposeModal(true)}
            >
              {i18n.t('episodeDetails.preparation.propose')}
            </button>
            <EpisodeStructureList
              canEdit
              structure={structure}
              onEditClick={(block, btnIsAdd, btnAddAfterBlockId) => {
                setEditBlock(block);
                setIsAdd(btnIsAdd);
                setAddAfterBlockId(btnAddAfterBlockId);
                setShowBlockEditDialog(true);
              }}
              onDeleteClick={(blockId) => {
                setBlockIdToDelete(blockId);
                setShowDeleteWarning(true);
              }}
            />
          </div>
        </div>
      </div>
      {showBackdrop && (
      <div className="fixed w-full h-full flex justify-center items-center inset-0 bg-black opacity-70 z-50">
        <Spinner size={6} className="border-primary-500" />
      </div>
      )}
      <EpisodeStructureBlockModal
        showBlockEditDialog={showBlockEditDialog}
        handleClose={async (didModify) => {
          setShowBlockEditDialog(false);
          if (didModify) {
            try {
              let s = await episodeApi.getEpisodeStructure(episodeId);
              setStructure(s);
            } catch (e) {
              defaultErrorHandler(e, addError);
            }
          }
          setShowBackdrop(false);
        }}
        add={isAdd}
        episodeId={episodeId}
        block={editBlock}
        afterBlockId={addAfterBlockId}
        setShowBackdrop={setShowBackdrop}
      />
      <EpisodeStructureProposeModal
        showDialog={showStructureProposeModal}
        handleClose={async (didModify) => {
          setShowStructureProposeModal(false);
          if (didModify) {
            try {
              let s = await episodeApi.getEpisodeStructure(episodeId);
              setStructure(s);
            } catch (e) {
              defaultErrorHandler(e, addError);
            }
          }
          setShowBackdrop(false);
        }}
        markdown={markdown}
        episodeId={episodeId}
        setShowBackdrop={setShowBackdrop}
      />
      <WarningModal
        openWarningModal={showDeleteWarning}
        closeWarningModal={() => setShowDeleteWarning(false)}
        handleWarningModal={async () => {
          setShowDeleteWarning(false);
          setShowBackdrop(true);
          try {
            await episodeApi.deleteEpisodeStructure(episodeId, blockIdToDelete);
            let s = await episodeApi.getEpisodeStructure(episodeId);
            setStructure(s);
          } catch (e) {
            defaultErrorHandler(e, addError);
          } finally {
            setShowBackdrop(false);
          }
        }}
        title={i18n.t('episodeDetails.preparation.deleteBlockTitle')}
        contentText={i18n.t('episodeDetails.preparation.deleteBlockContent')}
        positiveButtonText={i18n.t('episodeDetails.preparation.deleteBlockButton')}
        buttonColorScheme="red"
        colorScheme="red"
      />
    </>
  );
}
