import { useState, useEffect } from 'react';
import EpisodeApi from '../api/EpisodeApi';
import ShowApi from '../api/ShowApi';
import { defaultErrorHandler } from '../errors/ApplicationError';
import createLogger from '../util/Logger';
import i18n from '../i18n';

import useAlertNotification from './useAlertNotification';

const logger = createLogger('EpisodeDetails');

const episodeApi = new EpisodeApi();
const showApi = new ShowApi();

export default function useEpisodeDetails() {
  const { addError, addSuccess } = useAlertNotification();

  const [episodeDetails, setEpisodeDetails] = useState(null);
  const [show, setShow] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [episodeName, setEpisodeName] = useState('');
  const [episodeDescription, setEpisodeDescription] = useState('');

  const getEpisode = async (episodeId) => {
    logger.debug('getting episodes API request', episodeId);

    if (!isLoading) {
      setIsLoading(true);
      episodeApi.getEpisode(episodeId).then((details) => {
        logger.debug('Server responded with success status', { details });
        setEpisodeDetails(details);
        setIsError(false);
      }).catch((err) => {
        setEpisodeDetails(null);
        setIsError(true);
        defaultErrorHandler(err, (msg) => {
          logger.debug('Received error', { msg });
        });
      }).finally(() => setIsLoading(false));
    }
  };

  const getEpisodeWithShow = async (episodeId) => {
    if (!isLoading) {
      try {
        logger.debug('getting episodes API request', episodeId);

        setIsLoading(true);

        const episode = await episodeApi.getEpisode(episodeId);

        logger.debug('Server responded with success status', { episode });
        setEpisodeDetails(episode);

        const showDetails = await showApi.getShow(episode.showId);

        logger.debug('Server responded with success status', { showDetails });
        setShow(showDetails);
        setIsError(false);
      } catch (err) {
        setEpisodeDetails(null);
        setIsError(true);

        defaultErrorHandler(err, (msg) => {
          logger.debug('Received error', { msg });
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const getEpisodeNameAndDescription = (episodeId) => {
    logger.debug('getting episodes API request', episodeId);

    episodeApi.getEpisode(episodeId).then((details) => {
      logger.debug('Server responded with success status', { details });
      setEpisodeName(details.name);
      setEpisodeDescription(details.description);
      setIsError(false);
    }).catch((err) => {
      setEpisodeName('');
      setEpisodeDescription('');
      setIsError(true);
      defaultErrorHandler(err, (msg) => {
        logger.debug('Received error', { msg });
      });
    });
  };

  const updateEpisodeDetails = (episodeId, getEpisodes) => {
    logger.debug('updating episode API request', episodeId);

    if (!isLoading) {
      setIsLoading(true);
      episodeApi.updateEpisode(episodeId, episodeName, episodeDescription).then((details) => {
        logger.debug('Server responded with success status', { details });
        addSuccess(i18n.t('notifications.updatedEpisodeSuccessfully'));
        getEpisodes();
      }).catch((err) => {
        defaultErrorHandler(err, (msg) => {
          logger.debug('Received error', { msg });
          addError(msg);
        });
      }).finally(() => setIsLoading(false));
    }
  };

  return [
    episodeDetails,
    getEpisode,
    isLoading,
    isError,
    getEpisodeNameAndDescription,
    episodeName,
    setEpisodeName,
    episodeDescription,
    setEpisodeDescription,
    updateEpisodeDetails,
    getEpisodeWithShow,
    show,
  ];
}
