import { useDispatch } from 'react-redux';
import { showNotification } from '../slices/NotificationSlice';
import i18n from '../i18n';

export default function useAlertNotification() {
  const dispatch = useDispatch();

  // use text, header instead of header text for backwards compatibility
  const addError = (text, header) => {
    dispatch(showNotification('error', header || i18n.t('general.errorOccured'), text));
  };

  const addSuccess = (header, text) => {
    dispatch(showNotification('success', header, text));
  };

  const showNotificationInt = (type, header, text, autoHide) => {
    dispatch(showNotification(type, header, text, autoHide));
  };

  return {
    addError,
    addSuccess,
    showNotification: showNotificationInt,
  };
}
