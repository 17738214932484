import { API } from './api';

export const TENANT_ASSIGNMENT_STATUS = {
  INVITED: 0,
  ACTIVE: 1,
  INACTIVE: 2,
};

export default class UserManagementAPI {
  constructor() {
    this.api = new API();
  }

  /**
   * List users inside the cognito user pool
   *
   * @param {string} searchTerm Term to search for (might be either a name or email address)
   * @param {number} skip Amount of users to skip for pagination
   * @param {number} limit Maximum number of entries to load
   * @returns A list of users
   */
  async getUsers(searchTerm, skip, limit) {
    let data = await this.api.read('users', {
      limit,
      customParamMap: {
        searchTerm,
        skip,
      },
    });

    return data.data;
  }

  async getUser(userId) {
    let data = await this.api.read(`user/${userId}`);

    return data.data;
  }

  /**
   *
   * @property {string} userId ID of the user to change
   * @property {string} roleId The single role id to set
   * @returns {Object} object of the user created
   */
  async updateUserRole(userId, roleId) {
    await this.api.update(`user/${userId}/role`, {
      singleRoleId: roleId,
    });
  }
}
