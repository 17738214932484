/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import axios from 'axios';

import ShowApi from '../api/ShowApi';
import ChannelApi from '../api/ChannelApi';

import createLogger from '../util/Logger';

import defaultImage from '../assets/img/image_placeholder.jpg';
import ApplicationError from '../errors/ApplicationError';

import i18n from '../i18n';

const logger = createLogger('CreateShowSlice');

const showApi = new ShowApi();
const channelApi = new ChannelApi();

const setProducersToChannelMembers = (members, currentUserId) => members
  .filter((user) => user.id !== currentUserId) // exlude the current logged in user
  .map((user) => ({
    id: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    isProducer: false,
  }));

export const createShowSlice = createSlice({
  name: 'createShow',
  initialState: {
    show: {
      showName: '',
      showDescription: '',
    },
    organization: {
      id: null,
      name: '',
    },
    imagePreviewUrl: defaultImage,
    isUploadingImage: false,
    uploadProgress: 0,
    filename: null,
    isCreating: false,
    channels: null,
    selectedChannel: null,
    producers: [],
    isPublic: true,
    activeStep: 0,
    isOrganization: false,
    noPublicAuth: true,
  },
  reducers: {
    setImagePreviewUrl: (state, action) => {
      state.imagePreviewUrl = action.payload;
    },
    setIsUploadingImage: (state, action) => {
      state.isUploadingImage = action.payload;
    },
    updateUploadProgress: (state, action) => {
      state.uploadProgress = action.payload;
    },
    setShowName: (state, action) => {
      state.show.showName = action.payload;
    },
    setShowDescription: (state, action) => {
      state.show.showDescription = action.payload;
    },
    setOrganization: (state, action) => {
      state.organization.id = action.payload.id;
      state.organization.name = action.payload.name;
    },
    setFilename: (state, action) => {
      state.filename = action.payload;
    },
    setIsCreating: (state, action) => {
      state.isCreating = action.payload;
    },
    setChannels: (state, action) => {
      state.channels = action.payload.channels;
      if (state.selectedChannel != null) {
        let selectedChannel = state.channels.find((channel) => channel.channelId === state.selectedChannel);
        let producers = setProducersToChannelMembers(
          selectedChannel.members,
          action.payload.currentUserId,
        ).map((producer) => {
          let currentProducer = state.producers.find((p) => p.id === producer.id);
          return {
            id: producer.id,
            firstName: producer.firstName,
            lastName: producer.lastName,
            isProducer: currentProducer != null ? currentProducer.isProducer : false,
          };
        });
        state.producers = producers;
      }
    },
    setSelectedChannel: (state, action) => {
      const { channelId, created, currentUserId } = action.payload;
      state.selectedChannel = channelId;
      logger.debug('Create SHOW: setSelectedChannel:', { channelId, created });
      if (created) {
        // clear the existing channels
        state.channels = null;
        state.producers = [];
      } else if (state.selectedChannel != null && state.channels != null) {
        let selectedChannel = state.channels.find((channel) => channel.channelId === state.selectedChannel);
        state.isPublic = false;
        if (selectedChannel != null) {
          state.producers = setProducersToChannelMembers(selectedChannel.members, currentUserId);
        }
      } else {
        state.producers = [];
      }
    },
    addProducer: (state, action) => {
      if (state.producers.find((el) => el.id === action.payload.id) == null) {
        state.producers.push(action.payload);
      }
    },
    removeProducer: (state, action) => {
      let index = state.producers.findIndex((producer) => producer.id === action.payload);
      if (index !== -1) {
        state.producers.splice(index, 1);
      }
    },
    updateProducer: {
      prepare: (id, isProducer) => ({
        payload: {
          id, isProducer,
        },
      }),
      reducer: (state, action) => {
        let producer = state.producers.find((p) => p.id === action.payload.id);
        if (producer) {
          producer.isProducer = action.payload.isProducer;
        }
      },
    },
    setIsPublic: (state, action) => {
      state.isPublic = action.payload;
      if (state.isPublic) {
        state.producers = [];
        state.selectedChannel = null;
      }
    },
    setActiveStep: (state, action) => {
      state.activeStep = action.payload;
    },
    setIsOrganization: (state, action) => {
      state.isOrganization = action.payload;
    },
    setNoPublicAuth: (state, action) => {
      state.noPublicAuth = action.payload;
      state.isPublic = state.isPublic && !action.payload;
    },
    clear: (state) => {
      state.show.showName = '';
      state.show.showDescription = '';
      state.organization.id = null;
      state.organization.name = '';
      state.imagePreviewUrl = defaultImage;
      state.isUploadingImage = false;
      state.uploadProgress = 0;
      state.filename = null;
      state.isCreating = false;
      state.channels = null;
      state.selectedChannel = null;
      state.producers = [];
      state.isPublic = false;
      state.activeStep = 0;
      state.isOrganization = false;
      state.noPublicAuth = true;
    },
  },
});

export const {
  setImagePreviewUrl,
  setIsUploadingImage,
  updateUploadProgress,
  setFilename,
  setIsCreating,
  setShowName,
  setShowDescription,
  setOrganization,
  clear,
  addProducer,
  removeProducer,
  updateProducer,
  setIsPublic,
  setActiveStep,
  setIsOrganization,
  setNoPublicAuth,
} = createShowSlice.actions;

// Thunks
export const setSelectedChannel = (channelId, isCreated = false) => async (dispatch, getState) => {
  let currentUserId = getState().user.user.id;
  dispatch(createShowSlice.actions.setSelectedChannel({ channelId, currentUserId, created: isCreated }));
};

export const setChannels = (channels) => async (dispatch, getState) => {
  let currentUserId = getState().user.user.id;
  dispatch(createShowSlice.actions.setChannels({ channels, currentUserId }));
};

export const createShow = () => async (dispatch, getState) => {
  const state = getState().createShow;
  if (state.isCreating) {
    throw new ApplicationError(i18n.t('createShow.alreadyCreating'));
  }
  try {
    logger.debug('Create show.');
    dispatch(setIsCreating(true));
    let result = await showApi.createShow(
      state.show.showName,
      state.show.showDescription,
      state.filename,
      state.selectedChannel,
      state.producers
        .filter((producer) => producer.isProducer)
        .map((producer) => producer.id),
      state.organization,
    );
    dispatch(setIsCreating(false));

    return result;
  } finally {
    dispatch(setIsCreating(false));
  }
};

export const uploadShowImage = (file) => async (dispatch) => {
  try {
    logger.debug('Upload show image.');
    dispatch(setIsUploadingImage(true));
    let tempFilename = null;
    let data = await showApi.getPresignedUrlForShowImage(file.name);
    logger.debug('Server responded with success status', { data });

    // save fileName for later signed image request
    tempFilename = data.fields.fileName;
    dispatch(setFilename(tempFilename));

    // create form and header data
    let form = new FormData();
    let headerObject = {};

    let keys = Object.keys(data.fields);
    keys.forEach((key) => {
      headerObject[key] = data.fields[key];
      form.append(key, data.fields[key]);
    });

    headerObject['Content-Type'] = 'image';
    form.append('Content-Type', 'image');
    form.append('file', file);

    let upload = await axios({
      method: 'post',
      headers: { headerObject },
      data: form,
      url: data.url,
      onUploadProgress: (progressEvent) => {
        let percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        );
        dispatch(updateUploadProgress(percentCompleted));
      },
    });

    logger.log(`file upload was successfully ${upload}`);
    let filenameKey = await showApi.getSignedTempFileUrl(tempFilename);
    dispatch(setImagePreviewUrl(filenameKey));
  } finally {
    dispatch(setIsUploadingImage(false));
  }
};

export const loadChannels = () => async (dispatch) => {
  channelApi.getChannels(null).then((data) => {
    logger.debug('Server responded with success status', { data });
    dispatch(setChannels(data));
  });
};

// Export Reducer
export default createShowSlice.reducer;
