import { useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import {
  $getSelection,
  $isRangeSelection,
  COMMAND_PRIORITY_EDITOR,
  INDENT_CONTENT_COMMAND,
  OUTDENT_CONTENT_COMMAND,
  KEY_TAB_COMMAND,
} from 'lexical';

export default function RegisterTabPlugin() {
  const [editor] = useLexicalComposerContext();

  useEffect(() => editor.registerCommand(
    KEY_TAB_COMMAND,
    (event) => {
      const selection = $getSelection();

      if (!$isRangeSelection(selection)) {
        return false;
      }

      event.preventDefault();

      return editor.dispatchCommand(event.shiftKey ? OUTDENT_CONTENT_COMMAND : INDENT_CONTENT_COMMAND);
    },
    COMMAND_PRIORITY_EDITOR,
  ), [editor]);
}
