/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const navbarSlice = createSlice({
  name: 'navbar',
  initialState: {
    expanded: window.matchMedia('(min-width: 768px)').matches,
    collapsed: !window.matchMedia('(min-width: 768px)').matches,
    enableBreakpoint: true,
    breadcrumbShow: null,
    breadcrumbEpisode: null,
  },
  reducers: {
    setExpanded: (state, action) => {
      state.expanded = action.payload;
    },
    setCollapsed: (state, action) => {
      state.collapsed = action.payload;
    },
    setEnableBreakpoint: (state, action) => {
      state.enableBreakpoint = action.payload;
    },
    setBreadcrumbs: (state, action) => {
      state.breadcrumbShow = action.payload.show;
      state.breadcrumbEpisode = action.payload.episode;

      if (state.breadcrumbShow != null) {
        state.breadcrumbShow.href = `/app/show/${state.breadcrumbShow.showId}`;
        state.breadcrumbShow.id = state.breadcrumbShow.showId;
      }
      if (state.breadcrumbEpisode != null) {
        state.breadcrumbEpisode.href = `/app/episode/${state.breadcrumbEpisode.episodeId}`;
        state.breadcrumbEpisode.id = state.breadcrumbEpisode.episodeId;
      }
    },
    clearBreadcrumbs: (state) => {
      state.breadcrumbShow = null;
      state.breadcrumbEpisode = null;
    },
  },
});

export const {
  setExpanded,
  setCollapsed,
  setEnableBreakpoint,
  clearBreadcrumbs,
} = navbarSlice.actions;

// advanced reducer
export const expandNavBar = () => (dispatch, getState) => {
  const state = getState().navbar;

  if (!state.expanded && state.collapsed) {
    dispatch(setCollapsed(false));
    // the following timeout is required if the navbar is exchanged before fully mounted
    // in this case the afterLeave callback of the transition is not called
    // set to trigger after 400 ms as transition is set to 300 ms
    setTimeout(() => dispatch(setExpanded(true)), 400);
  }
};

export const collapseNavBar = () => (dispatch, getState) => {
  const state = getState().navbar;

  if (!state.collapsed && state.expanded) {
    dispatch(setExpanded(false));
    // the following timeout is required if the navbar is exchanged before fully mounted
    // in this case the afterLeave callback of the transition is not called
    // set to trigger after 400 ms as transition is set to 300 ms
    setTimeout(() => dispatch(setCollapsed(true)), 400);
  }
};

export const setBreadcrumbs = ({ show, episode }) => (dispatch, getState) => {
  const state = getState().navbar;
  let payload = {
    show: show != null ? { name: show.name, showId: show.showId } : state.breadcrumbShow,
    episode: episode != null ? { name: episode.name, episodeId: episode.episodeId } : state.breadcrumbEpisode,
  };

  dispatch(navbarSlice.actions.setBreadcrumbs(payload));
};

// Export Reducer
export default navbarSlice.reducer;
