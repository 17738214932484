import { useState, useEffect } from 'react';

import UserManagementAPI from '../api/UserManagementApi';
import { defaultErrorHandler } from '../errors/ApplicationError';
import createLogger from '../util/Logger';
import Constants from '../util/Constants';

import useAlertNotification from './useAlertNotification';
import useDebounce from './useDebounce';

const logger = createLogger('ManageUserHook');
const userManagementAPI = new UserManagementAPI();

const PAGINATION_INITIALIZE = {
  pageItems: [],
  pageNo: 0,
  count: 0,
};

export default function useManageUser() {
  const { addError } = useAlertNotification();

  const [searchValue, setSearchValue] = useState('');

  const [userTableData, setuserTableData] = useState(PAGINATION_INITIALIZE);
  const [usersLoading, setUsersLoading] = useState(false);
  const debouncedSearchValue = useDebounce(searchValue, 500);

  const fetchUsers = async (pageNo) => {
    if (usersLoading) {
      return;
    }
    setUsersLoading(true);
    try {
      let requestData = await userManagementAPI.getUsers(
        searchValue,
        pageNo * Constants.USERS_PAGE_SIZE,
        Constants.USERS_PAGE_SIZE,
      );
      logger.debug('Server responded with success status', { requestData });

      let data = {
        pageItems: requestData.users,
        pageNo,
        count: requestData.count,
      };
      setuserTableData(data);
    } catch (err) {
      defaultErrorHandler(err, (msg) => {
        logger.debug('Received error', { msg });
        addError(msg);
      });
    } finally {
      setUsersLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers(0);
  }, [debouncedSearchValue]);

  return [
    usersLoading,
    fetchUsers,
    userTableData,
    setSearchValue,
    searchValue,
  ];
}
