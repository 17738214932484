import i18n from '../i18n';

export default function getPlugins(text = {
  noDataText: i18n.t('analytics.noDataText'),
  errorText: i18n.t('analytics.analyticsError'),
}) {
  const plugins = [
    {
      afterDraw(chart) {
        if (chart.data.datasets[0].error) {
          let { ctx } = chart;
          let { width } = chart;
          let { height } = chart;
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.font = 'italic normal 1em Roboto';
          ctx.fillStyle = '#ef5350';
          ctx.fillText(text.errorText, width / 2, height / 2);
          ctx.restore();
        } else if (chart.data.datasets[0].data.length < 1) {
          let { ctx } = chart;
          let { width } = chart;
          let { height } = chart;
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.font = 'italic normal 1em Roboto';
          ctx.fillStyle = '#ef5350';
          ctx.fillText(text.noDataText, width / 2, height / 2);
          ctx.restore();
        }
      },
    },
  ];
  return plugins;
}
