import React from 'react';
import classNames from 'classnames';

export default function Spinner({ color = 'gray-100', size = 7, className }) {
  return (
    <div className={classNames(
      'm-1 rounded-full animate-spin border-[3px] border-solid border-t-transparent',
      `border-${color}`,
      `w-${size} h-${size}`,
      className,
    )}
    />
  );
}
