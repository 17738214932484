import { useEffect, useState } from 'react';
import useAlertNotification from './useAlertNotification';
import { defaultErrorHandler } from '../errors/ApplicationError';
import i18n from '../i18n';
import ShowAPI from '../api/ShowApi';

const showApi = new ShowAPI();

export default function useSidebarContent() {
  const [shows, setShows] = useState(null);
  const [lastEvaluatedShow, setLastEvaluatedShow] = useState(null);
  const { addError } = useAlertNotification();

  async function loadShowsOfUser() {
    if (lastEvaluatedShow == null && shows != null) {
      return;
    }
    let result = await showApi.getShowsOfUser(10, lastEvaluatedShow);
    setShows([...(shows || []), ...result.shows]);
    setLastEvaluatedShow(result.lastEvaluated);
  }

  const catchHandler = (err) => {
    defaultErrorHandler(err, (msg) => {
      addError(msg, i18n.t('general.errorOccured'));
    });
  };

  useEffect(() => {
    loadShowsOfUser(shows, lastEvaluatedShow)
      .catch(catchHandler);
  }, []);

  return {
    shows: {
      shows,
      loadMore: loadShowsOfUser,
      moreAvailable: lastEvaluatedShow != null,
    },
  };
}
