/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const notificationSlice = createSlice({
  name: 'notification',
  initialState: {
    type: null,
    header: null,
    text: null,
    show: false,
  },
  reducers: {
    showNotification: (state, action) => {
      state.type = action.payload.type;
      state.header = action.payload.header;
      state.text = action.payload.text;
      state.show = true;
    },
    hideNotification: (state) => {
      state.show = false;
    },
  },
});

export const {
  hideNotification,
} = notificationSlice.actions;

/**
 * Show a notification
 *
 * @param {'success' | 'error'} type Notification type. Either success or error
 * @param {string} header Header Text
 * @param {string} text Contents text
 * @param {number} [autoHide] Automatically hide the text after x ms. Set to 0 to do not hide automatically. Defaults to 6 seconds
 */
export const showNotification = (type, header, text, autoHide = 6000) => async (dispatch) => {
  dispatch(notificationSlice.actions.showNotification({ type, header, text }));
  if (autoHide > 0) {
    setTimeout(() => dispatch(hideNotification()), autoHide);
  }
};

// Export Reducer
export default notificationSlice.reducer;
