/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Fragment, useEffect, useState } from 'react';
import {
  XMarkIcon,
} from '@heroicons/react/24/outline';

import {
  Dialog, Switch, Transition,
} from '@headlessui/react';
import classNames from 'classnames';
import i18n from '../../../../../i18n';
import { defaultErrorHandler } from '../../../../../errors/ApplicationError';
import useAlertNotification from '../../../../../hooks/useAlertNotification';
import Spinner from '../../../../../components/Basic/Spinner';
import ShowAPI from '../../../../../api/ShowApi';
import UpdateOrganizationSearch from './UpdateOrganizationSearch';

const showApi = new ShowAPI();

const NAME_CHARACTER_LIMIT = 150;
const DESCRIPTION_CHARACTER_LIMIT = 1000;
const ORG_NAME_CHARACTER_LIMIT = 50;

export default function ShowEditModal({
  showDialog,
  show,
  refreshShow,
  handleClose,
}) {
  const [showBackdrop, setShowbackdrop] = useState(false);
  const [publishAsOrg, setPublishAsOrg] = useState(show && show.organization != null);
  const [showName, setShowName] = useState((show && show.name) || '');
  const [showDescription, setShowDescription] = useState((show && show.description) || '');
  const [showOrganization, setShowOrganization] = useState(show && show.organization);
  const [orgNameText, setOrgNameText] = useState((show && show.organization && show.organization.name) || '');
  const { addError } = useAlertNotification();

  useEffect(() => {
    setShowName((show && show.name) || '');
    setShowDescription((show && show.description) || '');
    setShowOrganization(show && show.organization);
    setPublishAsOrg(show && show.organization != null);
    setOrgNameText((show && show.organization && show.organization.name) || '');
  }, [show]);

  useEffect(() => {
    if (showDialog) {
      setShowName(show.name);
      setShowDescription(show.description);
      setShowOrganization(show.organization);
      setPublishAsOrg(show.organization != null);
      setOrgNameText((show.organization && show.organization.name) || '');
    }
  }, [showDialog]);

  const updateShow = async () => {
    if (showName.length === 0 || (publishAsOrg && showOrganization == null)) {
      return;
    }

    setShowbackdrop(true);
    try {
      await showApi.updateShowDetails(show.showId, showName, showDescription, publishAsOrg ? showOrganization : { id: null, name: '' });
      await refreshShow();
      handleClose(false);
    } catch (e) {
      defaultErrorHandler(e, addError);
    } finally {
      setShowbackdrop(false);
    }
  };

  const handleCloseInternal = () => {
    handleClose(false);
  };

  return (
    <Transition.Root show={showDialog} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleCloseInternal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-70 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg lg:max-w-xl sm:p-6"
              >
                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0 focus:ring-offset-2"
                    onClick={() => handleClose(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div>
                  <div>
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                      {i18n.t('showDetails.edit.dialogTitle')}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        {i18n.t('showDetails.edit.dialogText')}
                      </p>
                    </div>
                    <div className="h-[1px] w-full bg-gray-300 rounded my-4" />
                    <div className="relative">
                      <label
                        htmlFor="showName"
                        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs text-gray-600"
                      >
                        {i18n.t('showDetails.edit.showName')}
                      </label>
                      <input
                        type="text"
                        name="showName"
                        id="showName"
                        className={classNames(
                          'block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-500 text-sm sm:leading-6',
                          showName.length === 0 && 'focus:ring-red-500',
                        )}
                        autoComplete="off"
                        value={showName}
                        maxLength={NAME_CHARACTER_LIMIT}
                        onChange={(e) => setShowName(e.target.value)}
                      />
                      <div>
                        <span className={classNames('text-xs ml-1', showName.length === 0 ? 'text-red-600' : 'text-gray-700')}>{`${showName.length}/${NAME_CHARACTER_LIMIT}`}</span>
                        {showName.length === 0 && <span className="text-xs ml-1 text-red-600">{i18n.t('showDetails.edit.showEmpty')}</span>}
                      </div>
                    </div>
                    <div className="flex flex-row items-center mt-3">
                      <Switch
                        checked={publishAsOrg}
                        onChange={setPublishAsOrg}
                        className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                      >
                        <span className="sr-only">{i18n.t('showDetails.edit.publishAsOrg')}</span>
                        <span aria-hidden="true" className="pointer-events-none absolute h-full w-full rounded-md bg-white" />
                        <span
                          aria-hidden="true"
                          className={classNames(
                            publishAsOrg ? 'bg-primary-500' : 'bg-gray-200',
                            'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out',
                          )}
                        />
                        <span
                          aria-hidden="true"
                          className={classNames(
                            publishAsOrg ? 'translate-x-5' : 'translate-x-0',
                            'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out',
                          )}
                        />
                      </Switch>
                      <UpdateOrganizationSearch
                        placeholder={i18n.t('showDetails.edit.publishAsOrg')}
                        initValue={show && show.organization}
                        isOrganization={publishAsOrg}
                        onOrgSelection={(org) => {
                          setShowOrganization(org);
                          setOrgNameText(org.name);
                        }}
                        onOrganizationNameChange={setOrgNameText}
                      />
                    </div>
                    <div>
                      <span className={classNames('text-xs ml-1', publishAsOrg && showOrganization == null ? 'text-red-600' : 'text-gray-700')}>{`${publishAsOrg ? orgNameText.length : 0}/${ORG_NAME_CHARACTER_LIMIT}`}</span>
                      {publishAsOrg && showOrganization == null && <span className="text-xs ml-1 text-red-600">{i18n.t('showDetails.edit.orgEmpty')}</span>}
                    </div>
                    <div className="relative mt-6">
                      <label htmlFor="showDescription" className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs text-gray-600">
                        {i18n.t('showDetails.edit.showDescription')}
                      </label>
                      <div className="mt-2">
                        <textarea
                          rows={10}
                          name="showDescription"
                          id="showDescription"
                          className="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-500 sm:py-1.5 sm:text-sm sm:leading-6"
                          value={showDescription}
                          maxLength={DESCRIPTION_CHARACTER_LIMIT}
                          onChange={(e) => setShowDescription(e.target.value)}
                        />
                      </div>
                      <span className="text-gray-700 text-xs ml-1">{`${showDescription.length}/${DESCRIPTION_CHARACTER_LIMIT}`}</span>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 flex flex-row justify-end">
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm enabled:hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm disabled:bg-gray-300 disabled:text-gray-500 mx-2 enabled:focus:ring-primary-500"
                    onClick={handleCloseInternal}
                  >
                    {i18n.t('general.cancel')}
                  </button>
                  <button
                    type="button"
                    disabled={showName.length === 0 || (publishAsOrg && showOrganization == null)}
                    className="inline-flex justify-center rounded-md border border-transparent disabled:bg-gray-300 disabled:text-gray-500 bg-primary-500 px-4 py-2 text-base font-medium text-white shadow-sm enabled:hover:bg-primary-400 focus:outline-none focus:ring-2 enabled:focus:ring-primary-500 focus:ring-offset-2 sm:text-sm"
                    onClick={() => updateShow()}
                  >
                    {i18n.t('general.save')}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
        {showBackdrop && (
        <div className="fixed w-full h-full flex justify-center items-center inset-0 bg-black opacity-70 z-50">
          <Spinner size={6} color="primary-500" />
        </div>
        )}
      </Dialog>
    </Transition.Root>
  );
}
