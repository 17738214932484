import React from 'react';
import {
  InstantSearch,
} from 'react-instantsearch-dom';

import SearchBar from '../../../../../components/SearchBars/UpdateOrgSearchBar';
import Constants from '../../../../../util/Constants';
import { Context as AuthContext } from '../../../../../context/AuthContext';
import useSearch from '../../../../../hooks/useSearch';
import createLogger from '../../../../../util/Logger';

const logger = createLogger('UserSearch');

export default function UpdateOrganizationSearch({
  onOrgSelection, onOrganizationNameChange, validation, initValue, isOrganization, placeholder,
}) {
  const { state } = React.useContext(AuthContext);
  const [searchRequest] = useSearch();

  let content = null;
  const conditionalQuery = {
    search(requests) {
      if (
        requests.every(({ params }) => !params.query)
        || requests.every(({ params }) => params.query.trim() === '')
      ) {
        // Here we have to do something else
        logger.debug('Empty Query');
        return Promise.resolve({
          results: requests.map(() => ({
            hits: [],
            nbHits: 0,
            nbPages: 0,
            processingTimeMS: 0,
          })),
        });
      }
      return searchRequest(requests, Constants.SEARCH_TYPES.ORGANIZATION);
    },
  };

  content = (
    <InstantSearch searchClient={conditionalQuery} indexName={state.tenant}>
      <SearchBar
        onSelection={onOrgSelection}
        onNameChange={onOrganizationNameChange}
        validation={validation}
        initValue={initValue}
        isOrganization={isOrganization}
        placeholder={placeholder}
      />
    </InstantSearch>
  );

  return content;
}
