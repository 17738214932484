import React, { useState } from 'react';
import {
  CalendarDaysIcon,
  PencilIcon,
  ShareIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';

import WarningModal from '../../../components/WarningModal/WarningModal';
import i18n from '../../../i18n';
import Spinner from '../../../components/Basic/Spinner';
import { defaultErrorHandler } from '../../../errors/ApplicationError';
import useAlertNotification from '../../../hooks/useAlertNotification';
import EpisodeAPI from '../../../api/EpisodeApi';
import EpisodeEditModal from './EpisodeEditModal';
import EpisodeStatusTag from '../../../components/EpisodeStatusTag';

const episodeApi = new EpisodeAPI();

export default function EpisodeOverview({ episode, refreshEpisode }) {
  const [showDeleteWarningDialog, setShowDeleteWarningDialog] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [showBackdrop, setShowBackdrop] = useState(false);
  const userState = useSelector((state) => state.user.user);
  const history = useHistory();
  const { addError } = useAlertNotification();

  const userId = userState.id;

  const onDeleteEpisode = async () => {
    setShowBackdrop(true);
    setShowDeleteWarningDialog(false);
    try {
      await episodeApi.deleteEpisode(episode.episodeId, episode.showId);
      history.push('/app/dashboard');
    } catch (e) {
      defaultErrorHandler(e, addError);
    } finally {
      setShowBackdrop(false);
    }
  };

  return (
    <>
      {episode
        ? (
          <div className="flex justify-between xl:max-w-[80%]">
            <h3 className="text-xl font-bold mb-2">{episode.name}</h3>
            <div className="flex items-center">
              <div className="flex flex-row space-x-1">
                <div>
                  <Link
                    to={`/episode/${episode.episodeId}`}
                    target="_blank"
                    className={`group hover:bg-gray-100 active:bg-gray-300 border border-gray-300 hover:border-gray-400 rounded text-sm py-0.5 pr-2 pl-1 inline-flex flex-row items-center ${episode.status !== 'published' ? 'pointer-events-none opacity-50' : ''}`}
                  >
                    <ShareIcon className="h-4 w-4 text-gray-500 group-hover:text-gray-700" />
                    <span className="pl-1">
                      {i18n.t('episodeDetails.overview.share')}
                    </span>
                  </Link>
                </div>
                <div>
                  <button
                    type="button"
                    className="group hover:bg-gray-100 active:bg-gray-300 border border-gray-300 hover:border-gray-400 rounded text-sm py-0.5 pr-2 pl-1 inline-flex flex-row items-center"
                    onClick={() => setShowEditDialog(true)}
                  >
                    <PencilIcon className="h-4 w-4 text-gray-500 group-hover:text-gray-700" />
                    <span className="pl-1">{i18n.t('episodeDetails.overview.edit')}</span>
                  </button>
                </div>
                <div>
                  <button
                    type="button"
                    // TODO disabled={show == null || show.owners == null || show.owners.find((owner) => owner.id === userId) == null}
                    className="group enabled:hover:bg-red-100 enabled:active:bg-red-200 border border-red-700 enabled:hover:border-red-900 disabled:border-gray-300 rounded text-sm py-0.5 pr-2 pl-1 inline-flex flex-row items-center"
                    onClick={() => setShowDeleteWarningDialog(true)}
                  >
                    <TrashIcon className="h-4 w-4 text-red-700 group-enabled:group-hover:text-red-900 group-disabled:text-gray-400" />
                    <span className="pl-1 text-red-700 group-disabled:text-gray-500">{i18n.t('episodeDetails.overview.delete')}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : <div className="h-5 xl:max-w-[80%] bg-gray-300 animate-pulse rounded-xl" />}
      {episode
        ? (
          <>
            <div className="flex flex-row items-center">
              <EpisodeStatusTag status={episode.status} />
              {(episode.status === 'published' || episode.status === 'scheduled') && (
                <div className="flex items-center ml-2">
                  <CalendarDaysIcon className="h-5 w-5 text-gray-500" />
                  <span className="ml-1 mr-3 text-sm text-gray-500">{episode.publishDate != null ? moment(episode.publishDate).format('MMM DD, YYYY LT') : '-'}</span>
                </div>
              )}
            </div>
            <p className="mt-5 text-gray-500 xl:max-w-[80%] line-clamp-6 min-h-[4rem] text-base">{episode.description}</p>
          </>
        ) : <div className="h-40 xl:max-w-[80%] mt-5 bg-gray-300 animate-pulse rounded-xl" />}
      <WarningModal
        closeWarningModal={() => setShowDeleteWarningDialog(false)}
        handleWarningModal={() => onDeleteEpisode()}
        openWarningModal={showDeleteWarningDialog}
        title={i18n.t('episodeDetails.delete.dialogTitle')}
        contentText={i18n.t('episodeDetails.delete.dialogText')}
        positiveButtonText={i18n.t('episodeDetails.delete.dialogButton')}
        colorScheme="red"
        buttonColorScheme="red"
      />
      <EpisodeEditModal
        showDialog={showEditDialog}
        refreshEpisode={refreshEpisode}
        handleClose={() => setShowEditDialog(false)}
        episode={episode}
      />
      {showBackdrop && (
        <div className="fixed w-full h-full flex justify-center items-center inset-0 bg-black opacity-70 z-50">
          <Spinner size={6} className="border-primary-500" />
        </div>
      )}
    </>
  );
}
