/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import {
  CalendarDaysIcon, MicrophoneIcon,
} from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import moment from 'moment';

import EpisodeStatusTag from '../../components/EpisodeStatusTag';
import useAlertNotification from '../../hooks/useAlertNotification';
import EpisodeAPI from '../../api/EpisodeApi';
import { defaultErrorHandler } from '../../errors/ApplicationError';
import i18n from '../../i18n';
import ChartLoadingIndicator from '../../components/Analytics/ChartLoadingIndicator';
import ShowAPI from '../../api/ShowApi';
import MonthlyLineChart from '../../components/Analytics/MonthlyLineChart';
import useSidebarContent from '../../hooks/useSidebarContent';

function EpisodeCard({ episode, to }) {
  return (
    <Link
      to={`/app/episode/${episode.episodeId}${to != null && to !== '' ? '/' : ''}${to || ''}`}
      className="xl:w-[80%] xl:mr-0 w-full mr-4 min-h-[8rem] rounded-lg shadow-md flex flex-col mb-4 cursor-pointer hover:bg-gray-100"
    >
      <p className="w-[80%] text-base font-bold ml-2 mt-4 line-clamp-2">{episode.name}</p>
      <p className="text-sm ml-2 text-gray-700">{episode.showName}</p>
      <p className="text-sm ml-2 text-gray-400">{episode.userDisplayName}</p>
      <div className="mt-1 mb-2 flex-1 flex flex-row items-start justify-between flex-wrap-reverse">
        <div className="flex items-center ml-2">
          <CalendarDaysIcon className="h-5 w-5 text-gray-500" />
          <span className="ml-1 mr-3 text-sm text-gray-500">{episode.publishDate != null ? moment(episode.publishDate).format('MMM DD, YYYY') : '-'}</span>
        </div>
        <EpisodeStatusTag status={episode.status} className="mr-4 ml-2 text-sm" />
      </div>
    </Link>
  );
}

function ColumnLoading() {
  return (
    <div className="space-y-2">
      <div className="w-[80%] h-32 rounded-lg bg-gray-300 animate-pulse" />
      <div className="w-[80%] h-32 rounded-lg bg-gray-300 animate-pulse" />
      <div className="w-[80%] h-32 rounded-lg bg-gray-300 animate-pulse" />
    </div>
  );
}

const episodeApi = new EpisodeAPI();
const showApi = new ShowAPI();

export default function Dashboard() {
  const [episodes, setEpisodes] = useState(null);
  const [monthlyLabels, setMonthlyLabels] = useState(null);
  const [monthlyListenerData, setMonthlyListenerData] = useState(null);
  const { addError } = useAlertNotification();

  const { shows: { shows } } = useSidebarContent();

  useEffect(() => {
    episodeApi.getUnpublishedUserEpisodes()
      .then((unpublishedEpisodes) => setEpisodes(unpublishedEpisodes))
      .catch((e) => {
        defaultErrorHandler(e, addError);
        setEpisodes([]);
      });
  }, []);

  useEffect(() => {
    showApi.getUserShowsAnalytics().then((data) => {
      let monthlyLabelsCompute = [];
      let monthlyListenerCompute = [];
      data.forEach((listenerData) => {
        monthlyLabelsCompute.push(listenerData.month);
        monthlyListenerCompute.push(listenerData.value);
      });
      setMonthlyListenerData(monthlyListenerCompute);
      setMonthlyLabels(monthlyLabelsCompute);
    }).catch((err) => {
      defaultErrorHandler(err, addError);
      setMonthlyLabels([]);
    });
  }, []);

  let episodesInTopic = [];
  let episodesInResearch = [];
  let episodesInRecording = [];
  let episodesInScheduled = [];
  episodes?.forEach((episode) => {
    if (episode.status === 'scheduled') {
      episodesInScheduled.push(episode);
    } else if (episode.status !== 'published' && !episode.hasResearch && !episode.hasStructure) {
      episodesInTopic.push(episode);
    } else if (episode.status !== 'published' && episode.hasResearch && !episode.hasStructure) {
      episodesInResearch.push(episode);
    } else if (episode.status !== 'published' && episode.hasStructure) {
      episodesInRecording.push(episode);
    }
  });

  return (
    <>
      <div className="py-6 h-full">
        <div className="flex flex-col h-full">
          <div className="flex flex-col px-4">
            <h3 className="text-xl font-bold mb-4">{i18n.t('dashboard.title')}</h3>
            {(shows == null || shows.length !== 0) && (
            <>
              <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                <dt className="truncate text-sm font-medium text-gray-500">{i18n.t('showDetails.analytics.listenerOverTime')}</dt>
                <div className="relative h-40">
                  {monthlyLabels == null ? <ChartLoadingIndicator /> : <MonthlyLineChart labels={monthlyLabels} data={monthlyListenerData} isError={null} />}
                </div>
              </div>
              <div className="block px-4 pt-4">
                <Link
                  to="/app/show/create"
                  className="px-2 py-1 disabled:bg-gray-300 disabled:text-gray-500 rounded bg-primary-500 hover:bg-primary-400 active:bg-primary-600 text-white text-sm"
                >
                  {i18n.t('dashboard.createShow')}
                </Link>
              </div>

            </>
            )}
          </div>
          {(shows == null || shows.length !== 0) && (
            <div className="flex justify-between xl:max-w-[90%] self-center">
              <div className="grid grid-cols-4 mt-2">
                <div className="flex flex-col my-4 xl:mr-0 mr-4 min-w-fit">
                  <div className="flex flex-row mb-6 items-center">
                    <h3 className="font-bold text-lg">{i18n.t('dashboard.topics')}</h3>
                    <span className="rounded-full bg-primary-500 text-white ml-2 h-5 w-5 text-center text-sm font-semibold">{episodesInTopic.length}</span>
                  </div>
                  {episodesInTopic.map((episode) => (
                    <EpisodeCard key={episode.episodeId} episode={episode} />
                  ))}
                  {episodes == null && <ColumnLoading />}
                </div>
                <div className="flex flex-col py-4">
                  <div className="flex flex-row mb-6 items-center">
                    <h3 className="font-bold text-lg">{i18n.t('dashboard.research')}</h3>
                    <span className="rounded-full bg-primary-500 text-white ml-2 h-5 w-5 text-center text-sm font-semibold">{episodesInResearch.length}</span>
                  </div>
                  {episodesInResearch.map((episode) => (
                    <EpisodeCard key={episode.episodeId} episode={episode} to="preparation" />
                  ))}
                  {episodes == null && <ColumnLoading />}
                </div>
                <div className="flex flex-col py-4">
                  <div className="flex flex-row mb-6 items-center">
                    <h3 className="font-bold text-lg">{i18n.t('dashboard.production')}</h3>
                    <span className="rounded-full bg-primary-500 text-white ml-2 h-5 w-5 text-center text-sm font-semibold">{episodesInRecording.length}</span>
                  </div>
                  {episodesInRecording.map((episode) => (
                    <EpisodeCard key={episode.episodeId} episode={episode} to="production" />
                  ))}
                  {episodes == null && <ColumnLoading />}
                </div>
                <div className="flex flex-col py-4">
                  <div className="flex flex-row mb-6 items-center">
                    <h3 className="font-bold text-lg">{i18n.t('dashboard.scheduled')}</h3>
                    <span className="rounded-full bg-primary-500 text-white ml-2 h-5 w-5 text-center text-sm font-semibold">{episodesInScheduled.length}</span>
                  </div>
                  {episodesInScheduled.map((episode) => (
                    <EpisodeCard key={episode.episodeId} episode={episode} />
                  ))}
                  {episodes == null && <ColumnLoading />}
                </div>
              </div>
            </div>
          )}
          {shows != null && shows.length === 0 && (
            <div className="flex flex-1 items-center justify-center -translate-y-1/4">
              <div className="mx-auto max-w-lg flex items-center flex-col">
                <MicrophoneIcon className="w-8 h-8 text-primary-500 my-3" />
                <h2 className="text-base font-medium text-gray-700">{i18n.t('dashboard.emptyState.title')}</h2>
                <p className="mt-1 text-sm text-gray-500 text-center">
                  {i18n.t('dashboard.emptyState.content')}
                </p>
                <div className="mt-6 flex justify-center">
                  <Link to="/app/show/create" className="text-sm font-medium disabled:bg-gray-300 disabled:text-gray-500 bg-primary-500 hover:bg-primary-400 active:bg-primary-600 rounded-md text-white py-2 px-3">
                    {i18n.t('dashboard.createShow')}
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
