/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import Spinner from './Basic/Spinner';
import ShowAPI from '../api/ShowApi';
import { defaultErrorHandler } from '../errors/ApplicationError';
import useAlertNotification from '../hooks/useAlertNotification';
import Utils from '../util/Utils';
import i18n from '../i18n';

const showApi = new ShowAPI();

export default function MiniSidebarNav({
  showImage, navItems, imageEditable, showId, refreshShow,
}) {
  const location = useLocation();
  const fileInputRef = useRef();
  const [showBackdrop, setShowBackdrop] = useState(false);
  const { addError, addSuccess } = useAlertNotification();

  const onImageClick = () => {
    if (!imageEditable || showId == null || fileInputRef.current == null) {
      return;
    }

    fileInputRef.current.click();
  };

  const updateShowImage = async (image) => {
    setShowBackdrop(true);
    try {
      let tempFilename = await Utils.uploadImage({
        file: image,
        showApi,
        onProgress: () => {},
      });
      await showApi.updateShowImage(tempFilename, showId); // note: this method also returns a valid url to the new image, however, we will refresh the whole show object
      await refreshShow();
      addSuccess(i18n.t('notifications.updatedShowImageSuccessfullyTitle'), i18n.t('notifications.updatedShowImageSuccessfullyContent'));
    } catch (e) {
      defaultErrorHandler(e, addError);
    } finally {
      setShowBackdrop(false);
    }
  };

  return (
    <>
      <div className="flex flex-col pl-10">
        <div className="relative group">
          <img src={showImage} alt="text" className={classNames('h-32 w-32 rounded-lg', imageEditable && 'hover:brightness-50 active:brightness-0 cursor-pointer')} onClick={onImageClick} />
          {imageEditable && <p className="hidden group-hover:block absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-white font-semibold text-center pointer-events-none">{i18n.t('showDetails.nav.changeImage')}</p>}
          <input
            ref={fileInputRef}
            type="file"
            className="hidden"
            accept="image/*"
            onChange={async (e) => {
              await updateShowImage(e.target.files[0]);
              e.target.value = null;
            }}
          />
        </div>
        <div className="mt-5 flex flex-col text-gray-500 text-base space-y-2">
          {navItems.map((item) => (
            <Link key={item.id} to={item.href} className={classNames(location.pathname.endsWith(item.href) ? 'text-primary-500 bg-gray-100 ' : 'hover:bg-gray-100', 'px-2 py-1 rounded-md')}>{item.name}</Link>
          ))}
        </div>
      </div>
      {showBackdrop && (
      <div className="fixed w-full h-full flex justify-center items-center inset-0 bg-black opacity-70 z-50">
        <Spinner size={6} className="border-primary-500" />
      </div>
      )}
    </>
  );
}
