import Auth from './AuthHelper';
import envConfig from '../config';
import createLogger from './Logger';

const logger = createLogger('WebSocketApi');

class WebSocketApi {
  constructor() {
    this.webSocket = null;
    this.onMessageHandlers = [];
    this.messageQueue = [];
  }

  async initConnection() {
    if (this.webSocket == null || this.webSocket.readyState === WebSocket.CLOSING || this.webSocket.readyState === WebSocket.CLOSED) {
      const token = (await Auth.currentAuthenticatedUser()).signInUserSession.accessToken.jwtToken;
      this.webSocket = new WebSocket(`${envConfig.API_WS_URL}?Auth=Bearer ${token}`);
      this.webSocket.onmessage = this.onMessage.bind(this);
      this.webSocket.onopen = this.onOpen.bind(this);
      this.webSocket.onerror = this.onError.bind(this);
      this.webSocket.onclose = this.onClose.bind(this);
    }
  }

  addOnMessageHandler(handler) {
    this.onMessageHandlers.push(handler);
    return this.onMessageHandlers.length - 1;
  }

  removeOnMessageHandler(index) {
    this.onMessageHandlers.splice(index, 1);
  }

  onMessage(e) {
    // sending messages will return an empty response. Ignore those
    if (e.data === '') {
      return;
    }
    logger.debug('Received websocket message', e);
    try {
      let json = JSON.parse(e.data);
      this.onMessageHandlers.forEach((handler) => handler(json));
    } catch (err) {
      logger.error('Message could not be parsed as JSON', e, err);
    }
  }

  onOpen() {
    logger.debug('Websocket successfully connected');
    this.messageQueue.forEach((msg) => this.webSocket.send(JSON.stringify(msg)));
    logger.debug(`Successfully send ${this.messageQueue.length} queued messages!`);
    this.messageQueue = [];
  }

  // eslint-disable-next-line class-methods-use-this
  onError(e) {
    logger.error('Error in WebSocket connection', e);
  }

  // eslint-disable-next-line class-methods-use-this
  onClose(e) {
    logger.error('Error in WebSocket connection', e);
  }

  async sendJson(data) {
    await this.initConnection();
    if (this.webSocket.readyState !== WebSocket.OPEN) {
      this.messageQueue.push(data);
    } else {
      this.webSocket.send(JSON.stringify(data));
      logger.debug('Send websocket message', data);
    }
  }
}

export default new WebSocketApi();
