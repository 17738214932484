import React, { useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';
import i18n from '../../../i18n';
import LabelInput from '../../../components/Basic/LabelInput';

export default function RepeatPassword({ disabled, onChange }) {
  const [pw1, setPw1] = useState('');
  const [pw2, setPw2] = useState('');
  const [pw1Error, setPw1Error] = useState(null);
  const [pw2Error, setPw2Error] = useState(null);

  const checkPw1 = (value) => {
    const blankRegexp = new RegExp(/^[\S]+.*[\S]+$/g);
    let error = null;
    if (!value.match(blankRegexp)) {
      error = i18n.t('oneuser.validationErrors.blank');
    }
    const lowerCaseRegexp = new RegExp(/([a-z]+)/g);
    const lengthRegexp = new RegExp(/(?=.{8,}$)/g);
    const upperCaseRegexp = new RegExp(/([A-Z]+)/g);
    const digitsRegexp = new RegExp(/([0-9]+)/g);

    const specialRegexp = new RegExp(
      '^(?=.*[!@#$%^&*"\\[\\]\\{\\}<>/\\(\\)=\\\\\\-_´+`~\\:;,\\.€\\|])',
    );

    // ^ $ * . [ ] { } ( ) ? " ! @ # % & / \ , > < ' : ; | _ ~ ` = + -

    let message = null;
    if (!lengthRegexp.test(value)) {
      message = i18n.t('oneuser.validationErrors.long');
    } else if (error) {
      message = error;
    } else if (!lowerCaseRegexp.test(value)) {
      message = i18n.t('oneuser.validationErrors.lowercase');
    } else if (!upperCaseRegexp.test(value)) {
      message = i18n.t('oneuser.validationErrors.uppercase');
    } else if (!digitsRegexp.test(value)) {
      message = i18n.t('oneuser.validationErrors.digits');
    } else if (!specialRegexp.test(value)) {
      message = i18n.t('oneuser.validationErrors.symbol');
    }
    return message;
  };

  const checkPw2 = (p1, p2) => (p1 === p2 ? null : i18n.t('oneuser.validationErrors.repeatPassword'));

  const validateRepeatPassword = (p1, p2) => {
    let err = checkPw2(p1, p2);
    setPw2Error(err);
  };

  const validatePassword = (val) => {
    let err = checkPw1(val);
    setPw1Error(err);
  };

  const checkValidityDebounce = useRef(
    debounce(async (val) => {
      validatePassword(val);
    }, 800),
  ).current;

  useEffect(() => () => {
    checkValidityDebounce.cancel();
  }, [checkValidityDebounce]);

  return (
    <>
      <LabelInput
        className="mt-3"
        type="password"
        label={i18n.t('oneuser.inputs.password')}
        disabled={disabled}
        onChange={(data) => {
          setPw1(data);
          checkValidityDebounce(data);
          if (pw2 !== '') {
            validateRepeatPassword(data, pw2);
          }
          let isValid = checkPw1(data) == null && checkPw2(data, pw2) == null;
          onChange(data, isValid);
        }}
        errorText={pw1Error}
      />
      <LabelInput
        className="mt-3"
        type="password"
        label={i18n.t('oneuser.inputs.repeatPassword')}
        disabled={disabled}
        onChange={(data) => {
          setPw2(data);
          validateRepeatPassword(pw1, data);
          let isValid = pw1Error == null && checkPw2(pw1, data) == null;
          onChange(pw1, isValid); // isValid might change
        }}
        errorText={pw2Error}
      />
    </>
  );
}
