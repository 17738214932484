import React, {
  useContext, useEffect, useState,
} from 'react';
import {
  Route, Switch, Redirect, BrowserRouter as Router, useLocation,
} from 'react-router-dom';

import { Hub, Cache } from 'aws-amplify';
import { useDispatch } from 'react-redux';

// components
import Layout from './layouts/Layout';
import OneUser, { CARDS } from './pages/Auth/OneUser';
import createLogger from './util/Logger';

import { Context as AuthContext } from './context/AuthContext';
import TenantApi from './api/TenantApi';
import SharedShow from './pages/Shows/components/SharedShow/SharedShow';
import SharedEpisode from './pages/Shows/components/SharedShow/SharedEpisode';
import ListenerFallback from './pages/Fallback/ListenerFallback';

const logger = createLogger('App');
const tenantApi = new TenantApi();

function LoadingIndicator() {
  return (
    <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden opacity-75 flex flex-col items-center justify-center">
      <svg
        aria-hidden="true"
        className="w-10 h-10 mr-2 fill-primary-600 animate-spin"
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="currentcolor"
          className="fill-primary-200"
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="fill-primary-600"
        />
      </svg>
    </div>
  );
}

export default function App() {
  // global
  const { state, getAccessToken } = useContext(AuthContext);
  const { isLoading } = state;
  const dispatch = useDispatch();
  const [loadingTenant, setLoadingTenant] = useState(false);

  useEffect(() => {
    let tenantIsReady = true;
    getAccessToken(dispatch);
    Hub.listen('auth', async (data) => {
      logger.debug('auth event triggered', { payload: data.payload });
      switch (data.payload.event) {
        case 'signIn':
          logger.debug('Sign In Event', { isTenantReady: tenantIsReady });
          setLoadingTenant(true);
          logger.debug('Set loading tenant true');
          if (data.payload.data.username.startsWith('google')) {
            // if the user signed in with google we cannot know whether the tenant is ready, so assume no and check
            tenantIsReady = false;
          }
          if (tenantIsReady) {
            getAccessToken(dispatch);
            setLoadingTenant(false);
          } else {
            // wait for the tenant to get ready
            // wait until tenant is created
            let isReady = false;
            try {
              isReady = (await tenantApi.getIsTenantReady()).isReady;
            } catch (err) {
              // sometimes the authorizer is not ready yet to take requests, therefore ignore error
              logger.debug('Tenant status errored, which might be ok', err);
            }
            while (!isReady) {
              // retry each second until tenant is ready
              // eslint-disable-next-line no-await-in-loop
              await new Promise((resolve) => setTimeout(resolve, 1000));
              try {
                // eslint-disable-next-line no-await-in-loop
                isReady = (await tenantApi.getIsTenantReady()).isReady;
              } catch (err) {
                // sometimes the authorizer is not ready yet to take requests, therefore ignore error
                logger.debug('Tenant status errored, which might be ok', err);
              }
            }
            getAccessToken(dispatch);
            setLoadingTenant(false);
          }
          break;
        case 'signUp':
          tenantIsReady = false;
          break;
        case 'signIn_failure':
          break;
        default:
          break;
      }
    });
  }, []);

  // #######################################################################

  function LoginRoute() {
    const location = useLocation();
    let redirectUrl = Cache.getItem('loginRedirectUrl');

    if (isLoading || loadingTenant) {
      return <LoadingIndicator />;
    }
    return (
      <Route
        exact
        path="/loginCallback"
        render={() => (
          <Redirect to={{
            pathname: redirectUrl || location.state?.from?.pathname || '/',
            search: location.state?.from?.search || '',
          }}
          />
        )}
      />
    );
  }

  function PrivateRoute({ component, ...rest }) {
    if (isLoading || loadingTenant) {
      return <LoadingIndicator />;
    }
    return (
      <Route
        {...rest}
        render={(props) => (state.isLoggedIn ? (
          React.createElement(component, props)
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: {
                from: props.location,
              },
            }}
          />
        ))}
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
    const location = useLocation();
    let redirectUrl = Cache.getItem('loginRedirectUrl');

    if (isLoading || loadingTenant) {
      return <LoadingIndicator />;
    }

    return (
      <Route
        {...rest}
        render={(props) => (state.isLoggedIn ? (
          <Redirect
            to={{
              pathname: redirectUrl || location.state?.from?.pathname || '/app/dashboard',
              search: location.state?.from?.search || '',
            }}
          />
        ) : (
          React.createElement(component, { ...props, ...rest })
        ))}
      />
    );
  }

  return (
    <Router>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/app/dashboard" />} />
        <Route
          exact
          path="/app"
          render={() => <Redirect to="/app/dashboard" />}
        />
        <PrivateRoute path="/app" component={Layout} />
        <PrivateRoute path="/episode/:episodeId" component={SharedEpisode} />
        <PrivateRoute path="/show/:showId" component={SharedShow} />
        <PrivateRoute path="/listener" component={ListenerFallback} />
        <PublicRoute path="/login" component={OneUser} />
        <PublicRoute path="/forgotPassword" component={OneUser} initialCard={CARDS.FORGOT_PASSWORD} />
        <PublicRoute path="/signup" component={OneUser} initialCard={CARDS.SIGNUP} />
        <LoginRoute />
        <Route component={Error} />
      </Switch>
    </Router>
  );
}
