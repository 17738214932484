import React, {
  useEffect, useState,
} from 'react';

import { defaultErrorHandler } from '../../../errors/ApplicationError';
import useAlertNotification from '../../../hooks/useAlertNotification';
import EpisodeAPI from '../../../api/EpisodeApi';
import EpisodeProductionRecord from './EpisodeProductionRecord';
import EpisodeProductionUpload from './EpisodeProductionUpload';

const episodeApi = new EpisodeAPI();

export default function EpisodeProduction({ episodeId, episode, refreshEpisode: refreshEpisodeOuter }) {
  const [structure, setStructure] = useState(null);
  const [forceRecordScreen, setForceRecordScreen] = useState(false);
  const { addError } = useAlertNotification();

  useEffect(() => {
    if (episodeId == null) {
      return;
    }

    episodeApi.getEpisodeStructure(episodeId)
      .then((s) => setStructure(s))
      .catch((e) => defaultErrorHandler(e, addError));
  }, [episodeId]);

  const refreshEpisode = async () => {
    await refreshEpisodeOuter();
    setForceRecordScreen(false);
  };

  return (
    <>
      {episode == null && <div className="w-full py-4 mb-4 bg-gray-200 rounded h-48 animate-pulse" />}
      {((episode && !episode.podcastPath) || forceRecordScreen) && <EpisodeProductionRecord structure={structure} episodeId={episodeId} refreshEpisode={refreshEpisode} />}
      {episode && episode.podcastPath && !forceRecordScreen && <EpisodeProductionUpload episode={episode} refreshEpisode={refreshEpisode} setForceRecordScreen={setForceRecordScreen} />}
    </>
  );
}
