// import i18n from '../i18n';
import createLogger from '../util/Logger';
import i18n from '../i18n';

const logger = createLogger('DefaultErrorHandler');

export default class ApplicationError extends Error { }

export function defaultErrorHandler(exception, setMessage) {
  logger.warn('An error occurred', { exception });
  if (exception instanceof ApplicationError) {
    if (setMessage != null && typeof setMessage === 'function') {
      setMessage(exception.message);
    }
  } else {
    setMessage(i18n.t('backendErrors.unknown'));
  }
}
