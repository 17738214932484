/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  useHistory,
} from 'react-router-dom';
import { RadioGroup } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import {
  PencilIcon,
  XMarkIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/24/outline';
import ShowAPI from '../../../../api/ShowApi';
import EpisodeApi from '../../../../api/EpisodeApi';
import {
  collapseNavBar,
  setEnableBreakpoint,
} from '../../../../slices/NavBarSlice';

import { defaultErrorHandler } from '../../../../errors/ApplicationError';
import useAlertNotification from '../../../../hooks/useAlertNotification';
import i18n from '../../../../i18n';
import EpisodeDialog from './components/EpisodeDialog';
import createLogger from '../../../../util/Logger';
import useTenantSettings from '../../../../hooks/useTenantSettings';
import ApplicationAPIError from '../../../../errors/ApplicationAPIError';
import useWebSocketApi from '../../../../hooks/useWebSocketApi';

const logger = createLogger('CreateShow');

const showApi = new ShowAPI();
const episodeApi = new EpisodeApi();

const topics = [
  {
    id: 1, title: i18n.t('createShow.topicStep.yourTopic.title'), description: i18n.t('createShow.topicStep.yourTopic.description'), placeholder: i18n.t('createShow.topicStep.yourTopic.placeholder'),
  },
  {
    id: 2, title: i18n.t('createShow.topicStep.crisisComms.title'), description: i18n.t('createShow.topicStep.crisisComms.description'), placeholder: '',
  },
  {
    id: 3, title: i18n.t('createShow.topicStep.upToDateCustomerSuccess.title'), description: i18n.t('createShow.topicStep.upToDateCustomerSuccess.description'), placeholder: '',
  },
  {
    id: 4, title: i18n.t('createShow.topicStep.ipo.title'), description: i18n.t('createShow.topicStep.ipo.description'), placeholder: '',
  },
  {
    id: 5, title: i18n.t('createShow.topicStep.topOfMind.title'), description: i18n.t('createShow.topicStep.topOfMind.description'), placeholder: '',
  },
  {
    id: 6, title: i18n.t('createShow.topicStep.knowYourLeaders.title'), description: i18n.t('createShow.topicStep.knowYourLeaders.description'), placeholder: '',
  },
  {
    id: 7, title: i18n.t('createShow.topicStep.allHands.title'), description: i18n.t('createShow.topicStep.allHands.description'), placeholder: '',
  },
  {
    id: 8, title: i18n.t('createShow.topicStep.newsAndInsights.title'), description: i18n.t('createShow.topicStep.newsAndInsights.description'), placeholder: '',
  },
];

const steps = [
  {
    id: 0, title: i18n.t('createShow.topicStep.title'), description: i18n.t('createShow.topicStep.description'), progress: '33.33%',
  },
  {
    id: 1, title: i18n.t('createShow.motivationStep.title'), description: i18n.t('createShow.motivationStep.description'), progress: '66.66%',
  },
  {
    id: 2, title: i18n.t('createShow.episodeStep.title'), description: i18n.t('createShow.episodeStep.description'), progress: '100%',
  },
];

const SHOW_MAX_LENGTH = 100;
const GOAL_MAX_LENGTH = 1000;

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function CreateShow() {
  const dispatch = useDispatch();
  const { aiDisabled } = useTenantSettings();

  const history = useHistory();
  const { addError, addSuccess } = useAlertNotification();
  const [currentStep, setCurrentStep] = useState(0);
  const [activatedSteps, setActivatedSteps] = useState(Array(steps.length).fill(false));

  const [selectedTopic, setSelectedTopic] = useState(topics[0]);
  const [topicComment, setTopicComment] = useState('');
  const [showName, setShowName] = useState('');
  const [goal, setGoal] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [episodeList, setEpisodeList] = useState([]);
  const [episodeListLeft, setEpisodeListLeft] = useState([]);
  const [episodeListRight, setEpisodeListRight] = useState([]);

  const [episodeDialog, setEpisodeDialog] = useState(false);
  const [selectedEpisodeIndex, setSelectedEpisodeIndex] = useState(-1);
  const [allowError, setAllowError] = useState(false);
  const [show, setShow] = useState(null);
  const { sendJsonMessage, lastJsonMessage } = useWebSocketApi();
  const websocketTimeoutRef = useRef(null);

  useEffect(() => {
    dispatch(setEnableBreakpoint(false));
    dispatch(collapseNavBar());
    setAllowError(false);
    return () => dispatch(setEnableBreakpoint(true));
  }, []);

  const webSocketTimeoutError = () => {
    logger.error('WebSocket ran into timeout');
    setIsLoading(false);
    if (currentStep === 1) {
      setAllowError(true);
    }
    websocketTimeoutRef.current = null;
  };

  const setStep = (step) => {
    setCurrentStep(step);
    setActivatedSteps((prevActivatedSteps) => {
      const newActivatedSteps = [...prevActivatedSteps];
      for (let i = 0; i <= step; i++) {
        newActivatedSteps[i] = true;
      }
      return newActivatedSteps;
    });
  };

  useEffect(() => () => {
    if (websocketTimeoutRef.current != null) {
      clearTimeout(websocketTimeoutRef.current);
    }
  }, []);

  useEffect(() => {
    if (lastJsonMessage != null && websocketTimeoutRef.current != null) {
      clearTimeout(websocketTimeoutRef.current);
      websocketTimeoutRef.current = null;
    }

    if (currentStep === 1 && lastJsonMessage != null && lastJsonMessage.type === 'motivation' && lastJsonMessage.data != null) {
      setGoal(lastJsonMessage.data);
      setIsLoading(false);
      setAllowError(true);
    } else if (currentStep === 2 && lastJsonMessage != null && lastJsonMessage.type === 'episodes' && lastJsonMessage.data != null) {
      setEpisodeList(lastJsonMessage.data);
      setIsLoading(false);
    }
  }, [lastJsonMessage]);

  const nextStep = async (step) => {
    if (step === 1) {
      setStep(step);
      try {
        if (!aiDisabled) {
          setIsLoading(true);
          await sendJsonMessage({ action: 'motivation', topicDescription: topicComment });
          websocketTimeoutRef.current = setTimeout(webSocketTimeoutError, 90000);
        }
      } catch (err) {
        defaultErrorHandler(err, (msg) => {
          logger.debug('Received error', { msg });
          addError(msg);
        });
        setIsLoading(false);
        setAllowError(true);
      }
    } else if (step === 2) {
      setStep(step);
      try {
        setIsLoading(true);
        let organization = { id: null, name: '' };
        let showResponse = await showApi.createShow(showName, goal, null, null, [], organization, goal, selectedTopic.title, topicComment);
        addSuccess(i18n.t('notifications.createShowSuccessfully'));
        setShow(showResponse);
        if (!aiDisabled) {
          await sendJsonMessage({ action: 'episodes', amount: 6, showId: showResponse.showId });
          websocketTimeoutRef.current = setTimeout(webSocketTimeoutError, 90000);
        } else {
          setIsLoading(false);
        }
      } catch (err) {
        defaultErrorHandler(err, (msg) => {
          logger.debug('Received error', { msg });
          addError(msg);
        });
        if (err instanceof ApplicationAPIError && err.backendErrorCode === 1003) {
          setStep(step - 1);
        }
        setIsLoading(false);
      }
    } else if (step === 3) {
      try {
        setIsCreating(true);
        if (episodeList.length > 0) {
          await episodeApi.createProposedEpisodes(episodeList, show.showId);
        }
      } catch (err) {
        defaultErrorHandler(err, (msg) => {
          logger.debug('Received error', { msg });
          addError(msg);
        });
      } finally {
        setIsCreating(false);
        history.push(`/app/show/${show.showId}`);
      }
    }
  };

  useEffect(() => {
    if (episodeList.length > 0) {
      setEpisodeListLeft(episodeList.slice(0, Math.ceil(episodeList.length / 2)));
      setEpisodeListRight(episodeList.slice(Math.ceil(episodeList.length / 2)));
    } else {
      setEpisodeListLeft([]);
      setEpisodeListRight([]);
    }
  }, [episodeList]);

  const addEpisode = (episode) => {
    setEpisodeList([...episodeList, episode]);
    setEpisodeDialog(false);
  };

  const updateEpisode = (episode) => {
    const newEpisodeList = [...episodeList];
    newEpisodeList[selectedEpisodeIndex] = episode;
    setEpisodeList(newEpisodeList);
    setSelectedEpisodeIndex(-1);
    setEpisodeDialog(false);
  };

  const removeEpisode = (index) => {
    const newEpisodes = [...episodeList];
    newEpisodes.splice(index, 1);
    setEpisodeList(newEpisodes);
  };

  const onCloseEpisodeDialog = () => {
    setSelectedEpisodeIndex(-1);
    setEpisodeDialog(false);
  };

  const isAddMode = selectedEpisodeIndex === -1;

  const handleTopicChange = (topic) => {
    setSelectedTopic(topic);
    if (topic.id === 1) {
      setTopicComment('');
    } else {
      setTopicComment(topic.description);
    }
  };

  const handleProposeEpisodeRetry = async () => {
    try {
      setEpisodeList([]);
      if (!aiDisabled) {
        setIsLoading(true);
        await sendJsonMessage({ action: 'episodes', amount: 6, showId: show.showId });
        websocketTimeoutRef.current = setTimeout(webSocketTimeoutError, 90000);
      }
    } catch (err) {
      defaultErrorHandler(err, (msg) => {
        logger.debug('Received error', { msg });
        addError(msg);
      });
      setIsLoading(false);
    }
  };

  const EmptyState = () => (
    <div className="flex flex-1 items-center justify-center">
      <div className="mx-auto max-w-lg flex items-center flex-col">
        <ExclamationCircleIcon className="w-8 h-8 text-primary-500 my-3" />
        <h2 className="text-base font-medium text-gray-700">{aiDisabled ? i18n.t('createShow.episodeStep.empty.titleAiDisabled') : i18n.t('createShow.episodeStep.empty.title')}</h2>
        <p className="mt-1 text-sm text-gray-500 text-center">{aiDisabled ? i18n.t('createShow.episodeStep.empty.descriptionAiDisabled') : i18n.t('createShow.episodeStep.empty.description')}</p>
        <div className="mt-6 flex justify-center">
          <button
            type="button"
            disabled={isLoading}
            onClick={() => {
              if (aiDisabled) {
                history.push('/app/billing');
              } else {
                handleProposeEpisodeRetry();
              }
            }}
            className="bg-primary-600 hover:bg-primary-700 text-white font-bold py-2 px-4 rounded disabled:bg-gray-300 disabled:text-gray-500 text-sm"
          >
            {aiDisabled ? i18n.t('createShow.episodeStep.empty.upgrade') : i18n.t('createShow.episodeStep.retry')}
          </button>
        </div>
      </div>
    </div>
  );

  let content = null;
  if (currentStep === 0) {
    content = (
      <div>
        <RadioGroup value={selectedTopic} onChange={handleTopicChange}>
          <div className="mx-2 mt-4 grid grid-cols-1 gap-y-6 md:grid-cols-2 lg:grid-cols-3 sm:gap-x-4">
            {topics.map((topic) => (
              <div
                key={topic.id}
                className={classNames(
                  'relative min-h-48 flex flex-1 flex-col cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none',
                  selectedTopic?.id === topic.id
                    ? 'border-transparent border-primary-600 ring-2 ring-primary-600'
                    : 'border-gray-300',
                )}
              >
                <RadioGroup.Option value={topic}>
                  {({ checked }) => (
                    <>
                      <label
                        htmlFor={`radio-option-${topic.id}`}
                        className="absolute inset-0 cursor-pointer"
                      />
                      <input
                        type="radio"
                        id={`radio-option-${topic.id}`}
                        className="hidden"
                        checked={checked}
                        onChange={() => setSelectedTopic(topic)}
                      />
                      <span className="flex flex-1 flex-col">
                        <span className="flex flex-col h-full justify-between">
                          <RadioGroup.Label
                            as="span"
                            className="block text-sm font-medium text-gray-900"
                          >
                            {topic.title}
                          </RadioGroup.Label>
                          <RadioGroup.Description
                            as="span"
                            className="mt-4 flex-grow w-full items-center text-sm text-gray-500"
                          >
                            {topic.description}
                          </RadioGroup.Description>
                        </span>
                        {checked && (
                          <div className="flex flex-col text-sm justify-end font-medium text-gray-900">
                            {topic.id === 1 ? (
                              <>
                                <div className="mt-2 flex flex-col">
                                  <textarea
                                    rows={2}
                                    name="comment"
                                    id={`comment-${topic.id}`}
                                    placeholder={topic.placeholder}
                                    className="block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:py-1.5 sm:text-sm sm:leading-6 z-10"
                                    defaultValue={topicComment || ''}
                                    onKeyDown={(e) => {
                                      // Prevent the `RadioGroup` itself from eating your characters
                                      e.stopPropagation();
                                    }}
                                    onFocus={() => {
                                      // When the textarea is focused, make the current topic the selected one
                                      setSelectedTopic(topic);
                                    }}
                                    onChange={(e) => setTopicComment(e.target.value)}
                                  />
                                </div>
                                <div className="flex justify-end mt-2 space-x-2 md:space-x-0 md:flex-col md:items-end">
                                  <button
                                    type="button"
                                    disabled={topicComment.length === 0}
                                    className="mt-2 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 disabled:bg-gray-300 disabled:text-gray-500 disabled:shadow-none z-10"
                                    onClick={() => nextStep(1)}
                                  >
                                    {i18n.t('createShow.topicStep.start')}
                                  </button>
                                </div>
                              </>
                            ) : (
                              <div className="flex justify-end mt-2 space-x-2 md:space-x-0 md:flex-col md:items-end">
                                <button
                                  type="button"
                                  className="mt-2 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 z-10"
                                  onClick={() => nextStep(1)}
                                >
                                  {i18n.t('createShow.topicStep.start')}
                                </button>
                              </div>
                            )}
                          </div>
                        )}
                      </span>
                      <>
                        {checked ? (
                          <CheckCircleIcon
                            className="absolute top-3 right-3 h-6 w-6 text-primary-600"
                            aria-hidden="true"
                          />
                        ) : (
                          <CheckCircleIcon
                            className="absolute top-3 right-3 h-6 w-6 text-white border-[1px] rounded-full"
                            aria-hidden="true"
                          />
                        )}
                      </>
                    </>
                  )}
                </RadioGroup.Option>
              </div>
            ))}
          </div>
        </RadioGroup>
      </div>
    );
  } else if (currentStep === 1) {
    content = (
      <div className="mt-10 lg:flex lg:flex-col lg:justify-end">
        <div className="min-w-0 flex-1">
          <h3 className="text-lg font-bold leading-7 text-gray-900 sm:truncate sm:text-xl sm:tracking-tight">
            {i18n.t('createShow.motivationStep.showName')}
          </h3>
          <div className="relative mt-5">
            <input
              type="text"
              name="showName"
              id="showName"
              className={classNames(
                'block mt-5 w-full md:w-1/2 rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-500 text-sm sm:leading-6',
                showName.length === 0 && allowError && 'focus:ring-red-500',
              )}
              value={showName}
              placeholder={i18n.t('createShow.motivationStep.showName')}
              maxLength={SHOW_MAX_LENGTH}
              onChange={(e) => {
                setShowName(e.target.value);
              }}
              autoComplete="off"
            />
            <div>
              <span className={classNames('text-xs ml-1', showName.length === 0 && allowError ? 'text-red-600' : 'text-gray-700')}>{`${showName.length}/${SHOW_MAX_LENGTH}`}</span>
              {showName.length === 0 && allowError && <span className="text-xs ml-1 text-red-600">{i18n.t('createShow.motivationStep.empty.showName')}</span>}
            </div>
          </div>
          <h3 className="mt-10 text-lg font-bold leading-7 text-gray-900 sm:truncate sm:text-xl sm:tracking-tight">
            {i18n.t('createShow.motivationStep.motivation')}
          </h3>
          <p className="text-sm text-gray-500">
            {i18n.t('createShow.motivationStep.aiInfo')}
          </p>
          <div className="relative mt-5">
            {isLoading ? (
              <div className="block w-full md:w-1/2 mt-5 rounded-md border border-gray-300 p-2">
                <div className="h-3 bg-gray-300 animate-pulse mb-2 rounded-lg" />
                <div className="h-3 bg-gray-300 animate-pulse mb-2 rounded-lg" />
                <div className="h-3 bg-gray-300 animate-pulse rounded-lg" />
              </div>
            ) : (
              <textarea
                rows={5}
                name="comment"
                id="comment"
                disabled={isLoading}
                className={classNames(
                  'block w-full md:w-1/2 mt-5 rounded-md border border-gray-300 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:py-1.5 sm:text-sm sm:leading-6',
                  goal.length === 0 && allowError && !isLoading && 'focus:ring-red-500',
                )}
                maxLength={GOAL_MAX_LENGTH}
                placeholder={i18n.t('createShow.motivationStep.motivationPlaceholder')}
                onChange={(e) => {
                  setGoal(e.target.value);
                  setAllowError(true);
                }}
                value={goal}
              />
            )}

            <div>
              <span className={classNames('text-xs ml-1', goal.length === 0 && allowError ? 'text-red-600' : 'text-gray-700')}>{`${goal.length}/${GOAL_MAX_LENGTH}`}</span>
              {goal.length === 0 && allowError && !isLoading && <span className="text-xs ml-1 text-red-600">{i18n.t('createShow.motivationStep.empty.motivation')}</span>}
            </div>
          </div>
        </div>
        <button
          type="button"
          disabled={showName.length === 0 || goal.length === 0}
          className="mt-10 inline-flex self-start bottom items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 disabled:bg-gray-300 disabled:text-gray-500 disabled:shadow-none"
          onClick={() => nextStep(2)}
        >
          {i18n.t('createShow.motivationStep.start')}
        </button>
      </div>
    );
  } else if (currentStep === 2) {
    const renderEpisode = (episode, index, startIndex = 0) => (
      <div
        key={startIndex + index}
        className="relative min-h-[9rem] h-auto flex items-center mx-3 space-x-3 rounded-lg bg-white px-2 py-5 shadow-sm border border-gray-300"
      >
        <div className="flex-shrink-0 relative">
          <div className="h-7 w-7 rounded-full bg-primary-50">
            <span className="absolute inset-0 flex items-center justify-center text-primary-600">{startIndex + index + 1}</span>
          </div>
        </div>
        <div className="min-w-0 pr-5 flex-1">
          <span className="absolute inset-0" aria-hidden="true" />
          <p className="line-clamp-2 text-sm font-medium text-gray-900">{episode.title}</p>
          <p className="line-clamp-4 text-sm text-gray-500">{episode.description}</p>
        </div>
        <button
          type="button"
          className="flex-shrink-0 z-10 h-10 w-10 flex items-center justify-center rounded-full transition-colors duration-300  focus:outline-none hover:bg-primary-100"
          onClick={() => {
            setSelectedEpisodeIndex(index + startIndex);
            setEpisodeDialog(true);
          }}
        >
          <PencilIcon className="w-5 h-5 text-primary-600 hover:text-primary-900" />
          <span className="sr-only">{i18n.t('createShow.episodeStep.dialog.editEpisode')}</span>
        </button>
        <button
          type="button"
          className="flex-shrink-0 z-10 h-10 w-10 flex items-center justify-center rounded-full transition-colors duration-300  focus:outline-none hover:bg-red-100"
          onClick={() => removeEpisode(index + startIndex)}
        >
          <XMarkIcon className="w-5 h-5 text-red-600 hover:text-red-900" />
          <span className="sr-only">{i18n.t('createShow.episodeStep.removeEpisode')}</span>
        </button>
      </div>
    );

    content = (
      <>
        <EpisodeDialog
          open={episodeDialog}
          onClose={onCloseEpisodeDialog}
          onEpisodeEvent={isAddMode ? addEpisode : updateEpisode}
          isAdd={isAddMode}
          episode={isAddMode ? undefined : episodeList[selectedEpisodeIndex]}
        />
        <div>
          <div className="flex flex-col">
            <div className="flex justify-end mb-5">
              <button
                type="button"
                disabled={isLoading}
                onClick={() => setEpisodeDialog(true)}
                className="bg-primary-600 hover:bg-primary-700 text-white font-bold py-2 px-4 rounded disabled:bg-gray-300 disabled:text-gray-500 text-sm"
              >
                {i18n.t('createShow.episodeStep.addEpisode')}
              </button>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {isLoading ? (
                Array.from({ length: 4 }).map(() => (
                  <div
                    className="relative h-24 flex items-center mx-3 space-x-3 rounded-lg px-2 py-5 shadow-sm border border-gray-300 animate-pulse resize-none bg-gray-300"
                  />
                ))
              ) : (
                <>
                  {episodeList.length === 0 ? (
                    <div className="flex items-center justify-center col-span-2 mt-20">
                      <EmptyState />
                    </div>
                  ) : (
                    <>
                      <div className="space-y-4">
                        {episodeListLeft.map((episode, index) => renderEpisode(episode, index))}
                      </div>
                      <div className="space-y-4">
                        {episodeListRight.map((episode, index) => renderEpisode(episode, index, episodeListLeft.length))}
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
          <button
            type="button"
            disabled={isLoading}
            className="mt-10 inline-flex self-start bottom items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 disabled:bg-gray-300 disabled:text-gray-500 disabled:shadow-none"
            onClick={() => nextStep(3)}
          >
            {isCreating && (
            <svg aria-hidden="true" role="status" className="inline w-6 h-6 mr-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
            </svg>
            )}
            {i18n.t('createShow.episodeStep.save')}
          </button>
        </div>
      </>
    );
  }

  return (
    <div className="py-6 h-full">
      <div className="flex flex-row h-full">
        <div className="flex-1 mx-10 overflow-hidden relative">
          <div>
            <div className="mt-6" aria-hidden="true">
              <div className="mt-6" aria-hidden="true">
                <div className="overflow-hidden rounded-full bg-gray-200">
                  <div className="h-2 rounded-full bg-primary-600" style={{ width: steps[currentStep].progress }} />
                </div>
                <div className="mt-3 hidden grid-cols-3 text-sm font-medium text-gray-600 sm:grid">
                  <div className="text-primary-600">
                    {i18n.t('createShow.topicStep.stepperTitle')}
                  </div>
                  <div className="text-center text-primary-600">{i18n.t('createShow.motivationStep.stepperTitle')}</div>
                  <div className="text-center">{i18n.t('createShow.episodeStep.stepperTitle')}</div>
                </div>
                {/* <div className="mt-3 hidden grid-cols-3 text-sm font-medium text-gray-600 sm:grid">
                  <button
                    type="button"
                    className="text-center focus:outline-none text-primary-600"
                    onClick={() => setCurrentStep(0)}
                  >
                    {i18n.t('createShow.topicStep.stepperTitle')}
                  </button>
                  <button
                    type="button"
                    className={`text-center focus:outline-none ${activatedSteps[1] ? 'text-primary-600' : 'text-gray-400'}`}
                    onClick={() => setCurrentStep(1)}
                    disabled={!activatedSteps[1]}
                  >
                    {i18n.t('createShow.motivationStep.stepperTitle')}
                  </button>
                  <button
                    type="button"
                    className={`text-center focus:outline-none ${activatedSteps[2] ? 'text-primary-600' : 'text-gray-400'}`}
                    onClick={() => setCurrentStep(2)}
                    disabled={!activatedSteps[2]}
                  >
                    {i18n.t('createShow.episodeStep.stepperTitle')}
                  </button>
                </div> */}
              </div>

            </div>
            <div className="lg:flex lg:items-center lg:justify-between">
              <div className="mt-10 min-w-0 flex-1">
                <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                  {steps[currentStep].title}
                  {steps[currentStep].id === 1 && ` : ${selectedTopic?.title}`}
                </h2>
                <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
                  <div className="mt-4 w-full md:w-2/4 flex items-center text-md text-gray-500">
                    {steps[currentStep].description}
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-10" />
            {content}
          </div>
        </div>
      </div>
    </div>
  );
}
