import React from 'react';
import Spinner from '../Basic/Spinner';

export default function ChartLoadingIndicator() {
  return (
    <div className="h-full w-full flex items-center justify-center">
      <Spinner size={6} color="primary-500" />
    </div>
  );
}
