import React from 'react';
import { useSelector } from 'react-redux';

import TenantApi from '../api/TenantApi';

const tenantApi = new TenantApi();

export function useChannelRestriction(deps = []) {
  let tenantRestrictions = useSelector((state) => state.tenant.restrictions);
  const [canCreateChannel, setCanCreateChannel] = React.useState(tenantRestrictions.channels == null);

  React.useEffect(() => {
    if (tenantRestrictions.channels != null) {
      tenantApi.getCanCreateChannel()
        .then((resp) => setCanCreateChannel(resp.canCreateChannel));
    }
  }, [tenantRestrictions.channels, ...deps]);

  return [canCreateChannel, tenantRestrictions.channels];
}

export function useInternalShowRestriction(deps = []) {
  let tenantRestrictions = useSelector((state) => state.tenant.restrictions);
  const [canCreateShow, setCanCreateShow] = React.useState(tenantRestrictions.internalShows == null);

  React.useEffect(() => {
    if (tenantRestrictions.internalShows != null) {
      tenantApi.getCanCreateShow(false)
        .then((resp) => setCanCreateShow(resp.canCreateInternalShow));
    }
  }, [tenantRestrictions.internalShows, ...deps]);

  return [canCreateShow, tenantRestrictions.internalShows];
}

export function useExternalShowRestriction(deps = []) {
  let tenantRestrictions = useSelector((state) => state.tenant.restrictions);
  const [canCreateShow, setCanCreateShow] = React.useState(tenantRestrictions.externalShows == null);

  React.useEffect(() => {
    if (tenantRestrictions.externalShows != null) {
      tenantApi.getCanCreateShow(true)
        .then((resp) => setCanCreateShow(resp.canCreateExternalShow));
    }
  }, [tenantRestrictions.externalShows, ...deps]);

  return [canCreateShow, tenantRestrictions.externalShows];
}

export function useUserRestriction(deps = []) {
  let tenantRestrictions = useSelector((state) => state.tenant.restrictions);
  const [canCreateUser, setCanCreateUser] = React.useState(tenantRestrictions.users == null);
  const [userCount, setUserCount] = React.useState(tenantRestrictions.users == null);

  React.useEffect(() => {
    if (tenantRestrictions.users != null) {
      tenantApi.getCanCreateUser()
        .then((resp) => {
          setCanCreateUser(resp.canCreateUser);
          setUserCount(resp.userCount);
        });
    }
  }, [tenantRestrictions.users, ...deps]);

  return [canCreateUser, tenantRestrictions.users, userCount];
}

export default function useTenantSettings() {
  let tenantSettings = useSelector((state) => state.tenant);

  return tenantSettings;
}
