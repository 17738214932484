import i18n from '../i18n';

export default class DurationFormatter {
  /**
   * Formats a numerical duration value in milliseconds into "x h y min". If x = 0 then it will be omitted
   *
   * @param {number} duration The duration of the episode in milliseconds
   */
  static formatDuration(duration) {
    let hours = Math.trunc(duration / 1000 / 60 / 60);
    let mins = Math.max(Math.trunc(duration / 1000 / 60) % 60, 1);

    let result = '';

    if (hours > 0) {
      result = `${hours} ${i18n.t('formatter.duration.hours')} `;
    }

    result = `${result}${mins} ${i18n.t('formatter.duration.mins')}`;

    return result;
  }

  /**
   * Formats a hh:mm:ss string
   *
   * @param {string} duration The duration of the episode in hh:mm:ss format
   */
  static reformatDuration(duration) {
    let segments = duration.split(':').reverse();
    if (segments.length === 1 && segments[0] >= 60) {
      return DurationFormatter.formatDuration(duration * 1000);
    }

    let durationInMillis = parseInt(segments[0], 10) * 1000;

    if (segments.length > 1) {
      durationInMillis += parseInt(segments[1], 10) * 60 * 1000;
    }

    if (segments.length > 2) {
      durationInMillis += parseInt(segments[2], 10) * 60 * 60 * 1000;
    }

    return DurationFormatter.formatDuration(durationInMillis);
  }
}
