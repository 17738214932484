import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ExclamationTriangleIcon, SpeakerWaveIcon } from '@heroicons/react/24/outline';

import Constants from '../../util/Constants';
import { clear, postQuery } from '../../slices/AnalyticsSlice';
import { defaultErrorHandler } from '../../errors/ApplicationError';
import useAlertNotification from '../../hooks/useAlertNotification';
import i18n from '../../i18n';
import MonthlyBarChart from '../../components/Analytics/MonthlyBarChart';
import HourlyLineChart from '../../components/Analytics/HourlyLineChart';
import useEpisodeDetails from '../../hooks/useEpisodeDetails';
import ChartLoadingIndicator from '../../components/Analytics/ChartLoadingIndicator';
import Utils from '../../util/Utils';

const { ANALYTICS: { QUERY_TYPES, PERIOD_TYPES } } = Constants;
const {
  COUNT,
  POPULAR_EPISODE,
  PROGRESSIVE_COUNT,
  PROGRESSIVE_SUM,
  PROGRESSIVE_RECURRING_EVT_SUM,
  MONTHLY,
  HOURLY_RECURRING_EVT_COUNT,
} = QUERY_TYPES;

const countFilters = [
  {
    eventType: 'SHOW_CREATION',
    entity: 'SHOW',
  }, {
    eventType: 'CHANNEL_CREATION',
    entity: 'CHANNEL',
  },
];

const loginFilter = [{
  eventType: 'LOGIN_EVENT',
  entity: 'USER',
}];

const episodesFilter = [
  {
    eventType: 'EPISODE_CREATION',
    entity: 'EPISODE',
    exclude: 'gen2',
  }];

const durationFilter = [
  {
    eventType: 'EPISODE_CREATION',
    entity: 'EPISODE',
    column: 'gen1',
    exclude: 'gen2',
  }];

let periodType = PERIOD_TYPES.MONTHLY.name;

const listeningFilter = [
  {
    eventType: 'LISTENING',
    entity: 'EPISODE',
    column: 'gen1',
    periodType,
  },
];

const popularTimesFilter = [
  {
    eventType: 'LISTENING',
    entity: 'EPISODE',
  },
];

export default function Anaylitcs() {
  const dispatch = useDispatch();

  const { addError } = useAlertNotification();
  const [episodeDetails, getEpisode, isLoadingEpisode, isEpisodeError] = useEpisodeDetails();

  const showCount = useSelector((state) => state.analytics.showCount);
  const channelCount = useSelector((state) => state.analytics.channelCount);
  const totalListened = useSelector((state) => state.analytics.totalListened);
  const monthlyData = useSelector((state) => state.analytics.monthlyData);
  const progressiveMonthlyData = useSelector((state) => state.analytics.progressiveMonthlyData);
  const progressiveSumMonthlyData = useSelector((state) => state.analytics.progressiveSumMonthlyData);
  const listenedData = useSelector((state) => state.analytics.progressiveRecurringEventSumPeriodicData);
  const popularEpisode = useSelector((state) => state.analytics.popularEpisode);
  const hourlyData = useSelector((state) => state.analytics.hourlyData);

  useEffect(() => {
    dispatch(clear());
    dispatch(postQuery(COUNT, countFilters)).catch((err) => defaultErrorHandler(err, (msg) => addError(msg)));
    dispatch(postQuery(MONTHLY, loginFilter)).catch((err) => defaultErrorHandler(err, (msg) => addError(msg)));
    dispatch(postQuery(PROGRESSIVE_COUNT, episodesFilter))
      .catch((err) => defaultErrorHandler(err, (msg) => addError(msg)));
    dispatch(postQuery(PROGRESSIVE_SUM, durationFilter))
      .catch((err) => defaultErrorHandler(err, (msg) => addError(msg)));
    dispatch(postQuery(PROGRESSIVE_RECURRING_EVT_SUM, listeningFilter))
      .catch((err) => defaultErrorHandler(err, (msg) => addError(msg)));
    dispatch(postQuery(POPULAR_EPISODE, [])).catch((err) => defaultErrorHandler(err, (msg) => addError(msg)));
    dispatch(postQuery(HOURLY_RECURRING_EVT_COUNT, popularTimesFilter))
      .catch((err) => defaultErrorHandler(err, (msg) => addError(msg)));
  }, []);

  useEffect(() => {
    if (popularEpisode && popularEpisode.episode !== -1 && episodeDetails === null && !isLoadingEpisode) {
      getEpisode(popularEpisode.episode);
    }
  }, [popularEpisode]);

  let mostPopoularCard = null;
  if (isLoadingEpisode || (popularEpisode.episode == null && !popularEpisode.error)) {
    mostPopoularCard = <ChartLoadingIndicator />;
  } else if (episodeDetails == null || isEpisodeError) {
    mostPopoularCard = (
      <div className="h-full w-full flex items-center justify-center">
        <ExclamationTriangleIcon className="text-red-700 w-5 h-5 mr-1" />
        <p className="text-red-700">{i18n.t('analytics.popularEpisodeNoData')}</p>
      </div>
    );
  } else {
    mostPopoularCard = (
      <>
        <dd className="mt-1 text-lg font-semibold text-primary-500 truncate">{episodeDetails.showName}</dd>
        <div className="flex flex-row justify-between">
          <span className="-mt-1 text-base font-normal text-gray-600 truncate">{episodeDetails.name}</span>
          <div className="flex flex-row justify-center items-center">
            <SpeakerWaveIcon className="h-4 w-4 mr-2 text-gray-700" />
            <span className="text-gray-700 font-medium">{Utils.formatNumberUnitPrefix(popularEpisode.listeners)}</span>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="py-6">
      <div className="max-w-7xl px-4 sm:px-6 md:px-8">
        <h1 className="text-2xl font-semibold text-gray-900">{i18n.t('analytics.title')}</h1>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="overflow-visible inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div>
            <dl className="mt-5 grid grid-cols-3 gap-5 lg:grid-cols-5">
              <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                <dt className="truncate text-sm font-medium text-gray-500">{i18n.t('general.shows')}</dt>
                {showCount == null ? <ChartLoadingIndicator /> : <dd className="mt-2 text-5xl text-center font-semibold tracking-tight text-primary-500">{Utils.formatNumberUnitPrefix(showCount)}</dd>}
              </div>
              <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                <dt className="truncate text-sm font-medium text-gray-500">{i18n.t('general.channels')}</dt>
                {channelCount == null ? <ChartLoadingIndicator /> : <dd className="mt-2 text-5xl text-center font-semibold tracking-tight text-primary-500">{Utils.formatNumberUnitPrefix(channelCount)}</dd>}
              </div>
              <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                <dt className="truncate text-sm font-medium text-gray-500">{i18n.t('analytics.totalListened')}</dt>
                {totalListened == null ? <ChartLoadingIndicator /> : <dd className="mt-2 text-5xl text-center font-semibold tracking-tight text-primary-500">{Utils.formatNumberUnitPrefix(totalListened)}</dd>}
              </div>
              <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6 col-span-2">
                <dt className="truncate text-sm font-medium text-gray-500">{i18n.t('analytics.popularEpisode')}</dt>
                {mostPopoularCard}
              </div>
            </dl>
            <dl className="mt-5 grid grid-cols-1 gap-5 xl:grid-cols-2">
              <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                <dt className="truncate text-sm font-medium text-gray-500">{i18n.t('analytics.monthlyLogins')}</dt>
                <div className="relative h-80">
                  {monthlyData.loading ? <ChartLoadingIndicator /> : <MonthlyBarChart labels={monthlyData.labels} data={monthlyData.data} isError={monthlyData.error} />}
                </div>
              </div>
              <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                <dt className="truncate text-sm font-medium text-gray-500">{i18n.t('analytics.episodes.title')}</dt>
                <div className="relative h-80">
                  {progressiveMonthlyData.loading ? <ChartLoadingIndicator /> : <MonthlyBarChart stacked labels={progressiveMonthlyData.labels} data={[progressiveMonthlyData.data.main, progressiveMonthlyData.data.delta]} isError={progressiveMonthlyData.error} />}
                </div>
              </div>
              <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                <dt className="truncate text-sm font-medium text-gray-500">{i18n.t('analytics.uploaded.title')}</dt>
                <div className="relative h-80">
                  {progressiveSumMonthlyData.loading ? <ChartLoadingIndicator /> : <MonthlyBarChart stacked labels={progressiveSumMonthlyData.labels} data={[progressiveSumMonthlyData.data.main, progressiveSumMonthlyData.data.delta]} isError={progressiveSumMonthlyData.error} />}
                </div>
              </div>
              <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                <dt className="truncate text-sm font-medium text-gray-500">{i18n.t('analytics.listened.title')}</dt>
                <div className="relative h-80">
                  {listenedData.loading ? <ChartLoadingIndicator /> : <MonthlyBarChart stacked labels={listenedData.labels} data={[listenedData.data.main, listenedData.data.delta]} isError={listenedData.error} />}
                </div>
              </div>
              <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6 xl:col-span-2">
                <dt className="truncate text-sm font-medium text-gray-500">{i18n.t('analytics.frequentPlayTimes')}</dt>
                <div className="relative h-80">
                  {hourlyData.loading ? <ChartLoadingIndicator /> : <HourlyLineChart labels={hourlyData.labels} data={hourlyData.data} isError={monthlyData.error} />}
                </div>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
}
