import { API } from './api';

export default class ProjectApi {
  constructor() {
    this.api = new API();
  }

  async getProjects(limit, offset) {
    let data = await this.api.read('project', {
      limit,
      customParamMap: {
        offset,
      },
    });

    return data.data;
  }

  async getTemplates() {
    let data = await this.api.read('template', {});

    return data.data;
  }

  async getMeetingTypes(templateId) {
    let data = await this.api.read(`template/${templateId}/meetingType`, {
      includes: ['meetingSettings'],
    });

    return data.data;
  }

  async createProject(project) {
    await this.api.create('project', project);
  }
}
