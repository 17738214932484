import React, { useState } from 'react';

import { connectAutoComplete, connectStateResults } from 'react-instantsearch-dom';

import { Combobox } from '@headlessui/react';
import { UsersIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import Spinner from '../Basic/Spinner';

const SearchBar = ({
  hits, refine, onSelection, searching, placeholder, selectedValue,
}) => {
  const [value, setValue] = useState(selectedValue || '');
  return (
    <Combobox
      as="div"
      onChange={(person) => {
        onSelection(person);
        if (selectedValue != null) {
          setValue(`${person.firstName} ${person.lastName}`);
        }
      }}
    >
      <div className="relative mt-1">
        <div className="mt-2 relative flex flex-grow items-center focus-within:z-10">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <UsersIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
          <Combobox.Input
            type="text"
            name="owner"
            id="owner"
            autoComplete="off"
            className="block w-full rounded-md border-gray-300 pl-10 focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
            placeholder={placeholder}
            value={value}
            onChange={(event) => {
              refine(event.target.value);
              setValue(event.target.value);
            }}
          />
          {searching && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <Spinner size={4} className="border-primary-600" />
          </div>
          )}
        </div>
        {hits.length > 0 && (
        <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          {hits.map((person) => (
            <Combobox.Option
              key={person.id}
              value={person}
              className={({ active }) => classNames(
                'relative select-none py-2 pl-3 pr-9 text-black cursor-pointer',
                active && 'bg-gray-200',
              )}
            >
              {() => (
                <>
                  <span className={classNames('block truncate')}>{`${person.firstName} ${person.lastName}`}</span>
                </>
              )}
            </Combobox.Option>
          ))}
        </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
};

export default connectAutoComplete(connectStateResults(SearchBar));
