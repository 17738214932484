import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import i18n from '../../../i18n';
import Auth from '../../../util/AuthHelper';
import LabelInput from '../../../components/Basic/LabelInput';
import Logo from '../../../components/Basic/Logo';
import Spinner from '../../../components/Basic/Spinner';
import Button from '../../../components/Basic/Button';
import Alert from '../../../components/Basic/Alert';

export default function ForgotPasswordCard({ success }) {
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');

  const nextClick = async () => {
    try {
      setIsLoading(true);
      await Auth.forgotPassword(email);
      success(email);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const isButtonDisabled = () => email.length === 0;

  const submit = () => {
    if (!isButtonDisabled()) {
      nextClick();
    }
  };

  return (
    <div className="w-[470px] min-h-[300px] flex bg-white rounded-2xl drop-shadow-2xl flex-col p-12 pt-10">
      <Logo />
      <h1 className="text-4xl font-semibold text-black mt-6">{i18n.t('oneuser.forgotPasswordCard.title')}</h1>
      <p className="text-black font-light text-base">{i18n.t('oneuser.forgotPasswordCard.subtitle')}</p>
      <LabelInput
        className="mt-7 mb-4"
        type="email"
        label={i18n.t('oneuser.inputs.email')}
        disabled={isLoading}
        onChange={(data, isValid) => {
          if (isValid) {
            setEmail(data);
          } else {
            setEmail('');
          }
        }}
        onSubmit={submit}
      />
      {error && (<Alert severity="warning" className="mb-3" text={error} />)}
      <Button disabled={isButtonDisabled()} onClick={nextClick}>
        {!isLoading && i18n.t('oneuser.forgotPasswordCard.request')}
        {isLoading && <div className="flex justify-center"><Spinner /></div>}
      </Button>
      <div className="flex items-baseline mt-4">
        <p className="text-sm text-black pr-1 font-light">{i18n.t('oneuser.alreadyHaveUser')}</p>
        <Link
          to="/login"
          className="text-primary-500 hover:text-primary-400 text-sm font-medium"
        >
          {i18n.t('oneuser.links.signIn')}
        </Link>
      </div>
    </div>
  );
}
