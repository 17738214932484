import React, { Fragment, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import Spinner from '../../../components/Basic/Spinner';
import i18n from '../../../i18n';

const roles = [
  { roleId: 'ADMIN', title: i18n.t('userManagement.roles.admin.name'), description: i18n.t('userManagement.roles.admin.description') },
  { roleId: 'CREATOR', title: i18n.t('userManagement.roles.creator.name'), description: i18n.t('userManagement.roles.creator.description') },
  { roleId: 'LISTENER', title: i18n.t('userManagement.roles.listener.name'), description: i18n.t('userManagement.roles.listener.description') },
];

export default function RoleSelect({
  activeRoleId, disabled, onChange, isSaving,
}) {
  const [selected, setSelected] = useState(roles.find((role) => role.roleId === activeRoleId) || roles[0]);

  const onSelectionChange = (selectedOption) => {
    setSelected(selectedOption);
    if (onChange != null) {
      onChange(selectedOption.roleId);
    }
  };

  return (
    <Listbox value={selected} onChange={onSelectionChange} disabled={isSaving || disabled}>
      {({ open }) => (
        <>
          <Listbox.Label className="sr-only">Change user role</Listbox.Label>
          <div className="relative">
            <Listbox.Button
              className="
              inline-flex
              items-center
              rounded-md
              p-0.5
              text-sm
              font-medium
              text-black
              border
              border-transparent
              enabled:hover:border-gray-300
              focus:outline-none
              focus:ring-2
              focus:ring-primary-400
              focus:ring-offset-2
              focus:ring-offset-gray-50
              disabled:text-gray-500
              disabled:italic"
            >
              <span className="sr-only">Change user role</span>
              <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
              <div className="inline-flex items-center rounded-l-md border border-transparent pr-2">
                <p className="ml-1 text-sm font-normal">{selected.title}</p>
                {isSaving && <Spinner className="ml-2" size={4} color="gray-300" />}
              </div>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className="
                absolute
                right-0
                z-50
                mt-2
                w-72
                origin-top-right
                divide-y
                divide-gray-200
                overflow-hidden
                rounded-md
                bg-white
                shadow-lg
                ring-1
                ring-black
                ring-opacity-5
                focus:outline-none"
              >
                {roles.map((option) => (
                  <Listbox.Option
                    key={option.title}
                    className={({ active }) => classNames(
                      active ? 'text-white bg-primary-500' : 'text-gray-900',
                      'cursor-default select-none p-4 text-sm',
                    )}
                    value={option}
                  >
                    {({ selected: isSelected, active }) => (
                      <div className="flex flex-col">
                        <div className="flex justify-between">
                          <p className={isSelected ? 'font-semibold' : 'font-normal'}>{option.title}</p>
                          {isSelected ? (
                            <span className={active ? 'text-white' : 'text-primary-500'}>
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </div>
                        <p className={classNames(active ? 'text-primary-200' : 'text-gray-500', 'mt-2 whitespace-pre-wrap')}>
                          {option.description}
                        </p>
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
