import React, { useState } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $convertToMarkdownString, TRANSFORMERS } from '@lexical/markdown';

import useDebounce from '../../../hooks/useDebounce';
import { SAVE_STATES } from './ToolbarPlugin';
import EpisodeAPI from '../../../api/EpisodeApi';
import createLogger from '../../../util/Logger';
import useAlertNotification from '../../../hooks/useAlertNotification';
import i18n from '../../../i18n';

const episodeApi = new EpisodeAPI();
const logger = createLogger('SavePlugin');

export default function SavePlugin({
  setSaveState, setIsDirty, stateRecovered, episodeId, setMarkdown,
}) {
  const [editor] = useLexicalComposerContext();
  const [json, setJson] = useState('');
  let debouncedJson = useDebounce(json, 1500);
  const { addError } = useAlertNotification();

  React.useEffect(() => editor.registerUpdateListener(
    ({ editorState }) => {
      let newJson = JSON.stringify(editorState.toJSON());
      if (newJson !== json) {
        setIsDirty(true);
        setJson(newJson);
      }

      if (setMarkdown != null) {
        editorState.read(() => {
          const markdown = $convertToMarkdownString(TRANSFORMERS);
          setMarkdown(markdown);
        });
      }
    },
  ), [editor]);

  React.useEffect(() => {
    if (stateRecovered) {
      logger.debug('SAVE!');
      setSaveState(SAVE_STATES.SAVING);
      episodeApi.updateEpisodeResearch(episodeId, json.trim())
        .then(() => {
          setSaveState(SAVE_STATES.SAVE_FINISHED);
          setIsDirty(false);
        })
        .catch(() => {
          addError(i18n.t('episodeDetails.preparation.editorSaveFailed'));
          // eslint-disable-next-line prefer-template
          setJson(json + ' '); // trigger a retry (this is a bit dirty, but it works)
        });
    }
  }, [debouncedJson]);
}
