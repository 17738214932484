/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import RSSFeedApi from '../api/RSSFeedApi';

import createLogger from '../util/Logger';

const logger = createLogger('CreateExternalSlice');

const rssApi = new RSSFeedApi();

export const createExternalSlice = createSlice({
  name: 'createExternal',
  initialState: {
    activeStep: 0,
    feed: {},
    isLoading: false,
  },
  reducers: {
    setActiveStep: (state, action) => {
      state.activeStep = action.payload;
    },
    clear: (state) => {
      state.activeStep = 0;
      state.feed = {};
      state.isLoading = false;
    },
    loadFeed: (state, action) => {
      state.feed = action.payload.feed;
      state.isLoading = false;
      state.activeStep = 1;
    },
    startLoading: (state) => {
      state.isLoading = true;
    },
    stopLoading: (state) => {
      state.isLoading = false;
    },
  },
});

export const extractRSSFeed = (url) => async (dispatch) => {
  try {
    dispatch(createExternalSlice.actions.startLoading());
    logger.debug(`Extract data from RSS Feed URL : ${url}`);

    const feedDetails = await rssApi.getRSSFeedContents(url);
    dispatch(createExternalSlice.actions.loadFeed({ feed: feedDetails }));
  } catch (e) {
    logger.error(e.message);
    dispatch(createExternalSlice.actions.stopLoading());
    dispatch(createExternalSlice.actions.setActiveStep(0));
    throw e;
  }
};

export const createExtShow = () => async (dispatch, getState) => {
  try {
    logger.debug('Create external show.');
    const state = getState().createExternal;
    dispatch(createExternalSlice.actions.startLoading());
    await rssApi.createExternalShow(state.feed);
    dispatch(createExternalSlice.actions.stopLoading());
  } catch (e) {
    dispatch(createExternalSlice.actions.stopLoading());
    logger.error(e.message);
    throw e;
  }
};

export const {
  setActiveStep,
  clear,
} = createExternalSlice.actions;

// Export Reducer
export default createExternalSlice.reducer;
