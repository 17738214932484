import React from 'react';
import i18n from '../../i18n';

import useQuery from '../../hooks/useUrlQuery';
import TenantApi from '../../api/TenantApi';
import { defaultErrorHandler } from '../../errors/ApplicationError';
import Spinner from '../../components/Basic/Spinner';

const tenantApi = new TenantApi();

export default function AcceptInvite() {
  const [inProgress, setInProgress] = React.useState(true);
  const [error, setError] = React.useState(null);

  const query = useQuery();
  const code = query.get('code');

  React.useEffect(() => {
    if (code == null) {
      setError(i18n.t('tenant.noCode'));
      setInProgress(false);
    } else {
      tenantApi.acceptInvite(code)
        .catch((e) => {
          defaultErrorHandler(e, setError);
        })
        .finally(() => {
          setInProgress(false);
        });
    }
  }, [code]);

  let text = null;
  if (error != null) {
    text = error;
  } else if (!inProgress) {
    // if no error and not in progress assume success
    text = i18n.t('tenant.success');
  } else {
    text = i18n.t('tenant.activate');
  }

  return (
    <>
      <h3 className="text-lg font-bold">Joining Space</h3>
      <div className="mt-6 flex flex-1 flex-col justify-center">
        <p className="whitespace-pre-line">{text}</p>
        {inProgress && <Spinner size={6} className="border-primary-500" />}
      </div>

    </>
  );
}
