import React, { Fragment, useState } from 'react';
import { EnvelopeIcon } from '@heroicons/react/24/outline';

import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';
import i18n from '../../../i18n';
import Utils from '../../../util/Utils';
import { defaultErrorHandler } from '../../../errors/ApplicationError';
import useAlertNotification from '../../../hooks/useAlertNotification';
import TenantApi from '../../../api/TenantApi';
import Spinner from '../../../components/Basic/Spinner';

const tenantApi = new TenantApi();

function InviteModal({
  show,
  handleClose,
  fetchUsers,
  userCount,
  userRestriction,
}) {
  const [inviteUsersText, setInviteUsersText] = useState('');
  const [isInputError, setIsInputError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [isInvitingUsers, setIsInvitingUsers] = useState(false);
  const { addSuccess, addError } = useAlertNotification();

  const calculateError = (inp) => {
    let emails = inp.trim().split(',').map((e) => e.trim());
    let result = false;
    let actUserCount = userCount || 0;
    if (actUserCount + emails.length > userRestriction) {
      result = true;
      setErrorText(i18n.t('userManagement.createUserRestricted', { amount: userRestriction }));
    } else if (emails.some((val) => !Utils.isEmailValid(val))) {
      result = true;
      setErrorText(i18n.t('userManagement.errorEmails'));
    } else {
      setErrorText('');
    }

    setIsInputError(result);

    return [!result, emails];
  };

  const handleCloseInternal = () => {
    setErrorText('');
    setInviteUsersText('');
    handleClose(false);
  };

  const handleInviteUsersInternal = async () => {
    let [valid, emails] = calculateError(inviteUsersText);
    if (valid) {
      try {
        setIsInvitingUsers(true);
        await tenantApi.inviteUsers(emails);
        addSuccess(i18n.t('userManagement.sendInvitesSuccessfullyHeader'), i18n.t('userManagement.sendInvitesSuccessfully'));
        handleCloseInternal();
        fetchUsers(0);
      } catch (err) {
        defaultErrorHandler(err, addError);
      } finally {
        setIsInvitingUsers(false);
      }
    }
  };

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleCloseInternal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-70 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6"
              >
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-primary-100">
                    <EnvelopeIcon className="h-6 w-6 text-primary-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                      {i18n.t('userManagement.inviteDialog.title')}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        {i18n.t('userManagement.inviteDialog.formatInfo')}
                      </p>
                    </div>
                    <div className="mt-1">
                      <textarea
                        rows={3}
                        name="inviteUsers"
                        id="inviteUsers"
                        className={
                          classNames(
                            'block w-full rounded-md border-gray-300 shadow-sm sm:text-sm',
                            isInputError ? 'border-red-500 focus:border-red-500 focus:ring-red-500' : 'focus:border-primary-500 focus:ring-primary-500',
                          )
                        }
                        value={inviteUsersText}
                        placeholder="user@example.com, user2@example.com"
                        onChange={(e) => {
                          setInviteUsersText(e.target.value);
                          if (isInputError) {
                            calculateError(e.target.value);
                          }
                        }}
                        onBlur={() => {
                          calculateError(inviteUsersText);
                        }}
                      />
                    </div>
                    {errorText && <p className="text-sm text-red-600 text-start mt-1">{errorText}</p>}
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 flex flex-row justify-end">
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm enabled:hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm disabled:bg-gray-300 disabled:text-gray-500 mx-2 enabled:focus:ring-primary-500"
                    onClick={handleCloseInternal}
                    disabled={isInvitingUsers}
                  >
                    {i18n.t('general.cancel')}
                  </button>
                  <button
                    type="button"
                    className="inline-flex
                      justify-center
                      rounded-md
                      border
                      border-transparent
                      disabled:bg-gray-300
                      disabled:text-gray-500
                      bg-primary-500
                      px-4
                      py-2
                      text-base
                      font-medium
                      text-white
                      shadow-sm
                      enabled:hover:bg-primary-400
                      focus:outline-none
                      focus:ring-2
                      enabled:focus:ring-primary-500
                      focus:ring-offset-2
                      sm:text-sm"
                    onClick={() => handleInviteUsersInternal()}
                    disabled={isInvitingUsers}
                  >
                    {isInvitingUsers && <Spinner className="m-0 mr-1 border-primary-300" size={5} />}
                    {i18n.t('userManagement.invite')}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default InviteModal;
