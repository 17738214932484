/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

import Constants from '../util/Constants';
import AnalyticsApi from '../api/AnalyticsApi';

import createLogger from '../util/Logger';

const logger = createLogger('AnalyticsSlice');

const analyticsApi = new AnalyticsApi();

const { ANALYTICS } = Constants;

const getInitialLineState = () => ({
  label: '',
  labels: [],
  data: [],
  loading: true,
});

const getInitialProgressiveState = () => ({
  labels: [],
  data: {
    main: [],
    delta: [],
  },
  loading: true,
});

const {
  QUERY_TYPES: {
    COUNT,
    POPULAR_EPISODE,
    PROGRESSIVE_COUNT,
    PROGRESSIVE_SUM,
    PROGRESSIVE_RECURRING_EVT_SUM,
    MONTHLY,
    HOURLY_RECURRING_EVT_COUNT,
  },
  PERIOD_TYPES,
} = ANALYTICS;

export const analyticsSlice = createSlice({
  name: 'analytics',
  initialState: {
    showCount: null,
    channelCount: null,
    totalListened: null,
    monthlyData: getInitialLineState(),
    progressiveMonthlyData: getInitialProgressiveState(),
    progressiveSumMonthlyData: getInitialLineState(),
    progressiveRecurringEventSumPeriodicData: getInitialLineState(),
    popularEpisode: {
      episode: null,
      listeners: null,
      claps: null,
      score: null,
      error: false,
    },
    hourlyData: { labels: [], data: [], loading: true },
  },
  reducers: {
    clear: (state) => {
      state.showCount = null;
      state.channelCount = null;
    },
    setCounts: (state, action) => {
      let { data, error } = action.payload;
      let shows = error ? -1 : 0;
      let channels = error ? -1 : 0;

      if (data) {
        data.forEach((entityCount) => {
          if (ANALYTICS.ENTITY.SHOW === entityCount.entity) {
            shows = entityCount.count;
          }

          if (ANALYTICS.ENTITY.CHANNEL === entityCount.entity) {
            channels = entityCount.count;
          }
        });
      }
      state.showCount = shows;
      state.channelCount = channels;
    },
    setMonthly: (state, action) => {
      let { data, error } = action.payload;
      let dataObj = {
        labels: [],
        data: [],
      };

      if (error) {
        dataObj = { ...dataObj, error };
      } else if (data.length) {
        let init = data[0].month;

        let start = moment(init);
        let end = moment(new Date());
        let rangeInMonths = end.diff(start, 'months') + 1;

        for (let i = 0; i < rangeInMonths; i++) {
          let event = data[i];

          if (event) {
            dataObj.labels.push(`${event.month}-01`);
            dataObj.data.push(event.count);
          } else {
            let deducedMonth = `${moment(new Date()).add((i + 1) - rangeInMonths, 'months').format('YYYY-MM')}`;
            dataObj.labels.push(`${deducedMonth}-01`);
            dataObj.data.push(0);
          }
        }
      }
      state.monthlyData = dataObj;
    },
    setProgressiveMonthly: (state, action) => {
      let dataObj = {
        labels: [],
        data: {
          main: [],
          delta: [],
          totals: [],
        },
      };

      let { data, error } = action.payload;

      if (error) {
        dataObj = { ...dataObj, error };
      } else if (data.EPISODE && data.EPISODE.length) {
        let episodeData = data.EPISODE;

        let init = episodeData[0].month;
        let start = moment(init);
        let end = moment(new Date());
        let rangeInMonths = end.diff(start, 'months') + 1;

        let main = 0;
        let j = 0;
        for (let i = 0; i < rangeInMonths; i++) {
          let temp = moment(init);
          let monthStr = temp.add(i, 'months').format('YYYY-MM');

          if (episodeData[j] && episodeData[j].month === monthStr) {
            dataObj.labels.push(episodeData[j].month);
            dataObj.data.main.push(main);
            dataObj.data.delta.push(episodeData[j].count);
            main += episodeData[j].count;
            j++;
          } else {
            dataObj.labels.push(monthStr);
            dataObj.data.main.push(main);
            dataObj.data.delta.push(0);
          }
          dataObj.data.totals.push(0.4);
        }
      }
      state.progressiveMonthlyData = dataObj;
    },
    setProgressiveSumMonthly: (state, action) => {
      let dataObj = {
        labels: [],
        data: {
          main: [],
          delta: [],
          totals: [],
        },
      };

      let { data, error } = action.payload;

      if (error) {
        dataObj = { ...dataObj, error };
      } else if (data.length) {
        let init = data[0].month;
        let start = moment(init);
        let end = moment(new Date());
        let rangeInMonths = end.diff(start, 'months') + 1;

        let main = 0;
        let j = 0;
        for (let i = 0; i < rangeInMonths; i++) {
          let temp = moment(init);
          let monthStr = temp.add(i, 'months').format('YYYY-MM');
          dataObj.labels.push(`${monthStr}-01`);
          dataObj.data.main.push(Math.ceil(main / (1000 * 60)));

          if (data[j] && data[j].month === monthStr) {
            let delta = data[j].millis;
            dataObj.data.delta.push(Math.ceil(delta / (1000 * 60)));
            main += delta;
            j++;
          } else {
            dataObj.data.delta.push(0);
          }
          dataObj.data.totals.push(4);
        }
      }
      state.progressiveSumMonthlyData = dataObj;
    },
    setProgressiveRecurringEventSumPeriodic: (state, action) => {
      let dataObj = {
        labels: [],
        data: {
          main: [],
          delta: [],
          totals: [],
        },
      };

      let { data, error, periodType = PERIOD_TYPES.MONTHLY.name } = action.payload;

      if (error) {
        state.totalListened = -1;
        dataObj = { ...dataObj, error };
      } else if (!data.length) {
        logger.debug('No data available');
        state.totalListened = 0;
      } else {
        let init = data[0].period;
        let start = moment(init);
        let end = moment(new Date());

        const momentPeriod = PERIOD_TYPES[periodType].moment;
        const momentFmt = PERIOD_TYPES[periodType].fmt;

        let range = end.diff(start, momentPeriod) + 1;

        let main = 0;
        let j = 0;
        for (let i = 0; i < range; i++) {
          let temp = moment(init);
          let periodStr = temp.add(i, momentPeriod).format(momentFmt);
          dataObj.labels.push(PERIOD_TYPES.MONTHLY.name === periodType ? `${periodStr}-01` : periodStr);
          dataObj.data.main.push(Math.ceil(main / (1000 * 60)));

          if (data[j] && data[j].period === periodStr) {
            let delta = data[j].sum;
            dataObj.data.delta.push(Math.ceil(delta / (1000 * 60)));
            main += delta;
            j++;
          } else {
            dataObj.data.delta.push(0);
          }
          dataObj.data.totals.push(0.4);
        }
        let count = dataObj.labels.length;
        let total = (dataObj.data.main[count - 1] + dataObj.data.delta[count - 1]);
        state.totalListened = total;
      }
      state.progressiveRecurringEventSumPeriodicData = dataObj;
    },
    setPopularEpisode: (state, action) => {
      const { data, error } = action.payload;

      if (error) {
        state.popularEpisode = { ...state.popularEpisode, error };
      } else if (data.length) {
        const [topEpisode] = data;
        state.popularEpisode = topEpisode;
      } else {
        state.popularEpisode = { ...state.popularEpisode, episode: -1 };
      }
    },
    setListeningTimesOfDay: (state, action) => {
      const unixTimeZero = new Date(0);
      let dataObj = { labels: [], data: [] };

      let { data, error } = action.payload;

      if (error) {
        dataObj = { ...dataObj, error };
      } else if (data.length) {
        let hours = action.payload.data;

        let j = 0;
        for (let i = 0; i < 24; i++) {
          let temp = moment(unixTimeZero).set('h', 0).set('minute', 0);
          let totalStr = temp.add(i, 'h').format('YYYY-MM-DD HH:MM');

          let hour = j < hours.length ? parseInt(hours[j].hour, 10) : j;

          dataObj.labels.push(totalStr);

          if (j < hours.length && hour === i) {
            dataObj.data.push(hours[j].occurence);
            j++;
          } else {
            dataObj.data.push(0);
          }
        }
      }
      state.hourlyData = dataObj;
    },
  },
});

export const postQuery = (type, filters) => async (dispatch) => {
  try {
    const data = await analyticsApi.postAnalyticsQuery(type, filters);

    if (COUNT === type) {
      dispatch(analyticsSlice.actions.setCounts({ data }));
    } else if (MONTHLY === type) {
      dispatch(analyticsSlice.actions.setMonthly({ data }));
    } else if (PROGRESSIVE_COUNT === type) {
      dispatch(analyticsSlice.actions.setProgressiveMonthly({ data }));
    } else if (PROGRESSIVE_SUM === type) {
      dispatch(analyticsSlice.actions.setProgressiveSumMonthly({ data }));
    } else if (PROGRESSIVE_RECURRING_EVT_SUM === type) {
      let [singleFltr] = filters;
      let { periodType } = singleFltr;
      dispatch(analyticsSlice.actions.setProgressiveRecurringEventSumPeriodic({ data, periodType }));
    } else if (POPULAR_EPISODE === type) {
      dispatch(analyticsSlice.actions.setPopularEpisode({ data }));
    } else if (HOURLY_RECURRING_EVT_COUNT === type) {
      dispatch(analyticsSlice.actions.setListeningTimesOfDay({ data }));
    }
  } catch (e) {
    if (COUNT === type) {
      dispatch(analyticsSlice.actions.setCounts({ error: true }));
    } else if (POPULAR_EPISODE === type) {
      dispatch(analyticsSlice.actions.setPopularEpisode({ error: true }));
    } else if (PROGRESSIVE_RECURRING_EVT_SUM === type) {
      dispatch(analyticsSlice.actions.setProgressiveRecurringEventSumPeriodic({ error: true }));
    } else if (HOURLY_RECURRING_EVT_COUNT === type) {
      dispatch(analyticsSlice.actions.setListeningTimesOfDay({ error: true }));
    } else if (MONTHLY === type) {
      dispatch(analyticsSlice.actions.setMonthly({ error: true }));
    } else if (PROGRESSIVE_COUNT === type) {
      dispatch(analyticsSlice.actions.setProgressiveMonthly({ error: true }));
    } else if (PROGRESSIVE_SUM === type) {
      dispatch(analyticsSlice.actions.setProgressiveSumMonthly({ error: true }));
    }
    logger.error(e.message);
    throw e;
  }
};

export const {
  clear,
} = analyticsSlice.actions;

// Export Reducer
export default analyticsSlice.reducer;
