export default {
  CHANNEL_ROLE: {
    ADMIN: 1,
    MEMBER: 2,
  },
  SEARCH_TYPES: {
    USER: 2,
    ORGANIZATION: 3,
  },
  MAX_PRODUCER_COUNT: 25,
  DEFAULT_DATE: '1970-01-01T00:00:00.000Z',
  USERS_PAGE_SIZE: 25,
  EPISODES_PAGE_SIZE: 50,
  ANALYTICS: {
    ENTITY: {
      SHOW: 'SHOW',
      CHANNEL: 'CHANNEL',
      EPISODE: 'EPISODE',
    },
    QUERY_TYPES: {
      COUNT: 'COUNT',
      MONTHLY: 'MONTHLY',
      PROGRESSIVE_COUNT: 'PROGRESSIVE_COUNT',
      PROGRESSIVE_SUM: 'PROGRESSIVE_SUM',
      PROGRESSIVE_RECURRING_EVT_SUM: 'PROGRESSIVE_RECURRING_EVT_SUM',
      POPULAR_EPISODE: 'POPULAR_EPISODE',
      HOURLY_RECURRING_EVT_COUNT: 'HOURLY_RECURRING_EVT_COUNT',
    },
    PERIOD_TYPES: {
      MONTHLY: {
        moment: 'months', fmt: 'YYYY-MM', name: 'MONTHLY', chartjs: 'month',
      },
      DAILY: {
        moment: 'days', fmt: 'YYYY-MM-DD', name: 'DAILY', chartjs: 'day',
      },
    },
  },
  TEMPLATES: {
    MEETING_SETTINGS: {
      RECURRENCE: 'c2e57ba2-3ab5-4654-ac39-e0bf5226b5f6',
      PUBLISHER: '57a260b4-a17c-40de-bae8-c70f71bb6330',
    },
  },
  CHANNEL_CREATION_SOURCE: {
    SHOW: 'show',
    FLOW: 'flow',
  },
};
