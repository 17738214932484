import { API } from './api';

export default class AuthApi {
  constructor() {
    this.api = new API();
  }

  async discoverTenant(tenant) {
    let data = await this.api.read(`tenantHomeDiscovery?home=${tenant}`);

    return data.data;
  }

  async getUser() {
    let data = await this.api.read('user');

    return data.data;
  }

  async checkUser(email) {
    let data = await this.api.read('auth/info', {
      customParamMap: {
        email,
      },
    });
    return data.data;
  }
}
