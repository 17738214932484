import React from 'react';
import { PencilIcon, PlusCircleIcon, TrashIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import i18n from '../../../i18n';

export default function EpisodeStructureList({
  structure, canEdit, onEditClick, onDeleteClick,
}) {
  return (
    <ol className="flex flex-col w-full mt-4">
      {structure == null && (
      <>
        <li className="group/li flex flex-col w-full rounded min-h-[10rem] py-4 px-3 animate-pulse bg-gray-300" />
        <li className="group/li flex flex-col w-full rounded min-h-[10rem] py-4 px-3 animate-pulse bg-gray-300 mt-4" />
        <li className="group/li flex flex-col w-full rounded min-h-[10rem] py-4 px-3 animate-pulse bg-gray-300 mt-4" />
      </>
      )}
      {structure && structure.map((block) => (
        <>
          <li key={block.blockId} className={classNames('group/li flex flex-col w-full border border-gray-300 rounded min-h-[10rem] py-4 px-3', !canEdit && 'my-4')}>
            <div className="flex flex-row w-full justify-between px-1 border border-transparent">
              <h3 className="text-lg font-bold">{block.title}</h3>
              <div className="flex flex-row items-center">
                <span className="text-base font-semibold text-gray-500">{`${block.timeIndicationInSeconds} min`}</span>
                {canEdit && (
                <>
                  <button
                    type="button"
                    className="p-2 rounded-full hover:bg-gray-200 active:bg-gray-300 ml-2"
                    onClick={() => onEditClick(block, false, null)}
                  >
                    <span className="sr-only">Edit Block</span>
                    <PencilIcon className="h-4 w-4 text-primary-500" />
                  </button>
                  <button
                    type="button"
                    className="p-2 rounded-full hover:bg-gray-200 active:bg-gray-300"
                    onClick={() => onDeleteClick(block.blockId)}
                  >
                    <span className="sr-only">Delete Block</span>
                    <TrashIcon className="h-4 w-4 text-primary-500" />
                  </button>

                </>
                )}
              </div>
            </div>
            <div className="text-gray-600 mt-4 pb-1 px-1 whitespace-pre-line">
              {block.content}
            </div>
          </li>
          {canEdit && (
          <li key={`${block.blockId}+add`} className="flex justify-center py-2">
            <button
              type="button"
              className="text-sm flex flex-row border active:bg-gray-300 border-gray-500 py-1 px-2 rounded-md hover:bg-gray-100 hover:text-gray-700"
              onClick={() => onEditClick({}, true, block.blockId)}
            >
              <PlusCircleIcon className="h-5 w-5" />
              <span className="pl-1 text-gray-700">{i18n.t('episodeDetails.preparation.add')}</span>
            </button>
          </li>
          )}
        </>
      ))}
      {canEdit && structure && structure.length === 0 && (
      <li className="flex justify-center py-2">
        <button
          type="button"
          className="text-sm flex flex-row border active:bg-gray-300 border-gray-500 py-1 px-2 rounded-md hover:bg-gray-100 hover:text-gray-700"
          onClick={() => onEditClick({}, true, null)}
        >
          <PlusCircleIcon className="h-5 w-5" />
          <span className="pl-1 text-gray-700">{i18n.t('episodeDetails.preparation.add')}</span>
        </button>
      </li>
      )}
    </ol>
  );
}
