import React, { useEffect, useState } from 'react';
import i18n from '../../i18n';

import LoginCard from './AuthCards/LoginCard';
import NewPasswordCard from './AuthCards/NewPasswordCard';
import ForgotPasswordCard from './AuthCards/ForgotPasswordCard';
import SignUpCard from './AuthCards/SignUpCard';
import ConfirmEmailCard from './AuthCards/ConfirmEmailCard';

export const CARDS = {
  LOGIN: 0,
  RESET_REQUIRED: 1,
  FORGOT_PASSWORD: 2,
  CONFIRM_NEW_PASSWORD: 3,
  SIGNUP: 4,
  CONFIRM_EMAIL: 5,
};

export default function OneUser({ initialCard = CARDS.LOGIN }) {
  const [activeCard, setActiveCard] = useState(initialCard);
  const [temporaryEmail, setTemporaryEmail] = useState(null);
  const [temporaryPassword, setTemporaryPassword] = useState(null);

  useEffect(() => {
    if (initialCard !== activeCard) {
      setActiveCard(initialCard);
    }
  }, [initialCard]);

  const resetRequired = (email) => {
    setTemporaryEmail(email);
    setActiveCard(CARDS.RESET_REQUIRED);
  };

  const forgotPasswordSuccess = (email) => {
    setTemporaryEmail(email);
    setActiveCard(CARDS.CONFIRM_NEW_PASSWORD);
  };

  const needConfirmEmail = (email, password) => {
    setTemporaryEmail(email);
    setTemporaryPassword(password);
    setActiveCard(CARDS.CONFIRM_EMAIL);
  };

  let renderCard = null;

  switch (activeCard) {
    case CARDS.LOGIN:
      renderCard = (<LoginCard resetRequired={resetRequired} needConfirmEmail={needConfirmEmail} />);
      break;
    case CARDS.RESET_REQUIRED:
      renderCard = (
        <NewPasswordCard
          title={i18n.t('oneuser.resetRequiredCard.title')}
          subtitle={i18n.t('oneuser.resetRequiredCard.subtitle')}
          email={temporaryEmail}
        />
      );
      break;
    case CARDS.FORGOT_PASSWORD:
      renderCard = (<ForgotPasswordCard success={forgotPasswordSuccess} />);
      break;
    case CARDS.CONFIRM_NEW_PASSWORD:
      renderCard = (
        <NewPasswordCard
          title={i18n.t('oneuser.forgotPasswordCard.title')}
          subtitle={i18n.t('oneuser.forgotPasswordCard.subtitle')}
          email={temporaryEmail}
        />
      );
      break;
    case CARDS.SIGNUP:
      renderCard = (<SignUpCard />);
      break;
    case CARDS.CONFIRM_EMAIL:
      renderCard = (<ConfirmEmailCard email={temporaryEmail} password={temporaryPassword} />);
      break;
    default:
      break;
  }

  return (
    <div className="bg-[url('/public/unsplash.jpg')] bg-cover w-[100vw] h-[100vh] py-8 flex justify-center items-center relative inset-0 overflow-auto">
      {renderCard}
    </div>
  );
}
