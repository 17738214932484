import React, { useRef } from 'react';
import {
  mergeProps,
  useFocusRing,
  useSlider,
  useSliderThumb,
  VisuallyHidden,
} from 'react-aria';
import { useSliderState } from 'react-stately';
import clsx from 'clsx';
import Utils from '../../../../../../util/Utils';

function parseTime(seconds) {
  let hours = Math.floor(seconds / 3600);
  let minutes = Math.floor((seconds - hours * 3600) / 60);
  seconds = seconds - hours * 3600 - minutes * 60;
  return [hours, minutes, seconds];
}

function Thumb(props) {
  let {
    state, trackRef, focusProps, isFocusVisible, index,
  } = props;
  let inputRef = useRef(null);
  let { thumbProps, inputProps } = useSliderThumb(
    { index, trackRef, inputRef },
    state,
  );

  return (
    <div
      className="absolute top-1/2 -translate-x-1/2"
      style={{
        left: `${state.getThumbPercent(index) * 100}%`,
      }}
    >
      <div
        {...thumbProps}
        onMouseDown={(...args) => {
          thumbProps.onMouseDown(...args);
          props.onChangeStart?.();
        }}
        onPointerDown={(...args) => {
          thumbProps.onPointerDown(...args);
          props.onChangeStart?.();
        }}
        className={clsx(
          'h-4 rounded-full',
          isFocusVisible || state.isThumbDragging(index)
            ? 'w-1.5 bg-primary-900'
            : 'w-1 bg-primary-700',
        )}
      >
        <VisuallyHidden>
          <input ref={inputRef} {...mergeProps(inputProps, focusProps)} />
        </VisuallyHidden>
      </div>
    </div>
  );
}

export default function Slider(props) {
  let trackRef = useRef(null);
  let state = useSliderState(props);
  let {
    groupProps, trackProps, labelProps, outputProps,
  } = useSlider(
    props,
    state,
    trackRef,
  );
  let { focusProps, isFocusVisible } = useFocusRing();

  return (
    <div
      {...groupProps}
      className={clsx('absolute inset-x-0 bottom-full flex flex-auto touch-none items-center md:relative', props.vertical ? 'flex-col' : 'gap-6')}
    >
      {props.label && (
        <label className="sr-only" {...labelProps}>
          {props.label}
        </label>
      )}
      <div
        {...trackProps}
        onMouseDown={(...args) => {
          trackProps.onMouseDown(...args);
          props.onChangeStart?.();
        }}
        onPointerDown={(...args) => {
          trackProps.onPointerDown(...args);
          props.onChangeStart?.();
        }}
        ref={trackRef}
        className="relative w-full bg-slate-100 md:rounded-full"
      >
        <div
          className={clsx(
            'h-2 md:rounded-r-md md:rounded-l-xl',
            isFocusVisible || state.isThumbDragging(0)
              ? 'bg-primary-900'
              : 'bg-primary-700',
          )}
          style={{
            width:
              state.getThumbValue(0) === 0
                ? 0
                : `calc(${state.getThumbPercent(0) * 100}% - ${
                  isFocusVisible || state.isThumbDragging(0)
                    ? '0.3125rem'
                    : '0.25rem'
                })`,
          }}
        />
        <Thumb
          index={0}
          state={state}
          trackRef={trackRef}
          onChangeStart={props.onChangeStart}
          focusProps={focusProps}
          isFocusVisible={isFocusVisible}
        />
      </div>
      <div className="hidden items-center gap-2 md:flex">
        <output
          {...outputProps}
          aria-live="off"
          className={clsx(
            'hidden rounded-md px-1 py-0.5 font-mono text-sm leading-6 md:block',
            state.getThumbMaxValue(0) === 0 && 'opacity-0',
            isFocusVisible || state.isThumbDragging(0)
              ? 'bg-primary-100 text-primary-900'
              : 'text-primary-500',
          )}
        >
          {Utils.formatTime(state.getThumbValue(0), state.getThumbMaxValue(0))}
        </output>
        <span className="text-sm leading-6 text-primary-300" aria-hidden="true">
          /
        </span>
        <span
          className={clsx(
            'hidden rounded-md px-1 py-0.5 font-mono text-sm leading-6 text-primary-500 md:block',
            state.getThumbMaxValue(0) === 0 && 'opacity-0',
          )}
        >
          {Utils.formatTime(state.getThumbMaxValue(0))}
        </span>
      </div>
    </div>
  );
}
